<template>
  <div
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
    "
  >
    <ion-card
      style="width: 80%; margin: 0 auto; text-align: center"
      color="light"
    >
      <ion-card-header>
        <ion-card-title>What Columns is {{ props.column }} in ?</ion-card-title>
        <ion-card-subtitle
          >Click on the data that looks like {{ props.column }} from the
          CSV</ion-card-subtitle
        >
      </ion-card-header>
      <ion-card-content style="overflow-x: auto">
        <table style="width: 100%">
          <thead>
            <tr>
              <th
                v-for="(header, index) in Object.keys(uploadCSV.fileData[0])"
                :key="index"
              >
                <ion-item color="medium">
                  <ion-label style="text-align: center">
                    {{ header }}
                  </ion-label>
                </ion-item>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, rowIndex) in uploadCSV.fileData.slice(0, 10)"
              :key="`row-${rowIndex}`"
            >
              <td
                v-for="(cell, cellIndex) in row"
                :key="`cell-${cellIndex}`"
                @mouseover="isColumnHovered = cellIndex"
                @mouseout="isColumnHovered = null"
                @click="selectedDataKey = cellIndex"
              >
                <ion-item
                  :color="
                    isColumnHovered === cellIndex ||
                    selectedDataKey === cellIndex
                      ? 'primary'
                      : ''
                  "
                >
                  <ion-label style="text-align: center">
                    {{ cell }}
                  </ion-label>
                </ion-item>
              </td>
            </tr>
          </tbody>
        </table>
      </ion-card-content>
      <div style="display: flex; width: 100%">
        <ion-button
          expand="full"
          @click="confirm"
          style="flex: 2"
          v-if="selectedDataKey"
          >Confirm</ion-button
        >
        <ion-button expand="full" color="medium" @click="cancel" style="flex: 2"
          >Cancel</ion-button
        >
      </div>
    </ion-card>
  </div>
</template>

<script setup>
import { defineComponent, defineProps, defineEmits, ref } from "vue";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonButton,
  IonLabel,
  IonItem,
} from "@ionic/vue";
import { useUploadCSVStore } from "@/stores/UploadCSVStore";

defineComponent({
  name: "SelectData",
});
const selectedDataKey = ref(props.columnAssociationKey);
const isColumnHovered = ref(null);
const props = defineProps({ column: String, columnAssociationKey: String });
const emit = defineEmits(["dataSelected"]);
const uploadCSV = useUploadCSVStore();
console.log(selectedDataKey.value);
function confirm() {
  emit("dataSelected", selectedDataKey.value);
}
function cancel() {
  emit("dataSelected", props.columnAssociationKey);
}
</script>
