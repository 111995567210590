<template>
  <form @submit.prevent="register">
    <ion-item>
      <ion-label position="stacked">Email</ion-label>
      <ion-input
        placeholder="john@example.com"
        inputmode="email"
        type="email"
        v-model="user.email"
        @ionBlur="v$.email.$touch"
      ></ion-input>
      <ion-note color="danger" v-if="v$.email.$errors.length">{{
        v$.email.$errors[0].$message
      }}</ion-note>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Full Name</ion-label>
      <ion-input
        placeholder="John Doe"
        v-model="user.full_name"
        @ionBlur="v$.full_name.$touch"
      ></ion-input>
      <ion-note color="danger" v-if="v$.full_name.$errors.length">{{
        v$.full_name.$errors[0].$message
      }}</ion-note>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Phone Number</ion-label>
      <ion-input
        inputmode="tel"
        placeholder="888-888-8888"
        type="tel"
        v-model="user.phone"
        @ionBlur="v$.phone.$touch"
      ></ion-input>
      <ion-note color="danger" v-if="v$.phone.$errors.length">{{
        v$.phone.$errors[0].$message
      }}</ion-note>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Password</ion-label>
      <ion-input
        placeholder="Password"
        type="password"
        v-model="user.password"
        @ionBlur="v$.password.$touch"
      ></ion-input>
      <ion-note color="danger" v-if="v$.password.$errors.length">{{
        v$.password.$errors[0].$message
      }}</ion-note>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Confirm Password</ion-label>
      <ion-input
        placeholder="Confirm Password"
        type="password"
        v-model="user.confirmPassword"
        @ionBlur="v$.confirmPassword.$touch"
      ></ion-input>
      <ion-note color="danger" v-if="v$.confirmPassword.$errors.length">{{
        v$.confirmPassword.$errors[0].$message
      }}</ion-note>
    </ion-item>
    <ion-button expand="full" type="submit" v-if="!loading"
      >Register</ion-button
    >
    <ion-button expand="full" v-if="loading"><ion-spinner /></ion-button>
  </form>
</template>

<script setup>
import { defineComponent, reactive, ref, defineEmits } from "vue";
import {
  IonInput,
  IonButton,
  IonSpinner,
  IonLabel,
  IonItem,
  IonNote,
} from "@ionic/vue";
import useVuelidate from "@vuelidate/core";
import { required, email, numeric } from "@vuelidate/validators";
import { showToast } from "@/utils/useIonicComponents.js";
import { api } from "@/utils/axios";
import { useAuthStore } from "@/stores/authStore";

defineComponent({
  name: "RegisterForm",
});
const emit = defineEmits(["registered"]);
const authStore = useAuthStore();
const loading = ref(false);
const user = reactive({
  email: "",
  full_name: "",
  phone: "",
  password: "",
  confirmPassword: "", // Add confirm password to reactive state
});


const rules = {
  email: { required, email },
  password: { required },
  full_name: { required },
  phone: { required, numeric },
  confirmPassword: { // Add validation rule for confirm password
    required,
    sameAsPassword: (value) => value === user.password || 'Passwords must match',
  },
};


const v$ = useVuelidate(rules, user);


async function register() {
  const isFormValid = await v$.value.$validate();
  if (isFormValid) {
    loading.value = true;
    try {
      // Ensure passwords match before proceeding
      if(user.password !== user.confirmPassword) {
        showToast("Passwords do not match.", "danger", 6000);
        loading.value = false;
        return;
      }
      const result = await api.post("/auth/signup", {
        user: user,
      });
      if (result.status === 201) {
        emit("registered");
        showToast("Registration Successfull", "success", 6000);
        const result = await authStore.login({
          email: user.email,
          password: user.password,
        });
        if (result) {
          v$.value.$reset();
          user.email = user.password = "";
        }
        loading.value = false;
      }
    } catch (err) {
      loading.value = false;
      console.log(err);
      showToast(
        "Something went wrong. If this persists, please contact us.",
        "danger",
        6000
      );
    }
  }
}
</script>
