<template>
    <div>
        <div class="scoreboard">
            <div class="scoreboard-header">
                <div class="team-column">
                    Team
                </div>
                <div class="inning-column" v-for="inning of parseInt(scorekeepStore.maxInningsCount)" :key="inning">
                    {{ inning }}
                </div>
                <div class="inning-column bold">
                    R
                </div>
                <div class="inning-column bold">
                    H
                </div>
            </div>
            <div class="team-row">
                <div class="team-column">
                    {{ scorekeepStore.awayTeam.name }}
                </div>
                 <div class="inning-column" v-for="inning of parseInt(scorekeepStore.maxInningsCount)" :key="inning">
                    <template v-if="inning <= scorekeepStore.currentInning">
                        {{ scorekeepStore.awayInningScores[inning - 1] }}
                    </template>
                </div>
                <!-- <div class="inning-column" v-for="inning in scorekeepStore.maxInningsCount" :key="inning">
                    {{ scorekeepStore.awayInningScores[inning - 1] }}
                </div> -->
                <div class="inning-column bold ">
                    {{  scorekeepStore.awayInningScores.reduce((a, b) => a + b, 0) }}
                </div>
                <div class="inning-column bold ">
                    {{ scorekeepStore.awayHits.length }}
                </div>
            </div>
            <div class="team-row">
                <div class="team-column">
                    {{ scorekeepStore.homeTeam.name }}
                </div>
                <div class="inning-column" v-for="inning in parseInt(scorekeepStore.maxInningsCount)" :key="inning">
                    <template v-if="inning <= scorekeepStore.currentInning && !(inning === scorekeepStore.currentInning && scorekeepStore.currentHalf === 0)">
                        {{ scorekeepStore.homeInningScores[inning - 1] }}
                    </template>
                </div>
                <!-- <div class="inning-column" v-for="inning in scorekeepStore.maxInningsCount" :key="inning">
                    {{ scorekeepStore.homeInningScores[inning - 1] }}
                </div> -->
                <div class="inning-column bold">
                    {{  scorekeepStore.homeInningScores.reduce((a, b) => a + b, 0) }}
                </div>
                <div class="inning-column bold">
                    {{ scorekeepStore.homeHits.length }}
                </div>
            </div>
        </div>
        
        <div class="outs" v-if="scorekeepStore.gameEnded === false">
            
            <div class="outs-text">
                {{  scorekeepStore.currentHalf === 0 ? 'TOP' : 'BOTTOM' }} of {{ scorekeepStore.currentInning }}
            </div>
            <div class="outs-text">
                Outs
            </div>
            <div class="out-circle" :class="{ filled: scorekeepStore.currentOuts > 0 }"></div>
            <div class="out-circle" :class="{ filled: scorekeepStore.currentOuts > 1 }"></div>
            <div class="out-circle" :class="{ filled: scorekeepStore.currentOuts > 2 }"></div>
        </div>

        <div class="outs" v-if="scorekeepStore.gameEnded === true">
            FINAL
        </div>
    </div>
</template>

<script setup>
import { useScorekeepStore } from "@/stores/scorekeepStore"

const scorekeepStore = useScorekeepStore()
</script>

<style scoped>
.scoreboard {
    border-bottom: 1px solid #333;
}
.scoreboard-header, .team-row {
    display: flex;
    justify-content: space-between;
    
}

.scoreboard-header {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    padding: 5px;
    background: rgba(150,150,150,0.1)
}

.team-row {
    padding: 5px;
}

.team-column {
    flex: 0 0 80px; /* fixed width for team name column */
    text-align: left;
}

.inning-column {
    flex: 1; /* equally distribute remaining space */
    text-align: center;
}

.bold {
    font-weight: 700;
}

.outs {
    display: flex;
    margin: 5px 0;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
    align-items: center; /* Align items vertically in the center */
    justify-content: center; /* Center items horizontally */
     /* Optional: adds space between the text and the circles */
}

.outs-text {
    margin-right: 10px;
    height: 15px;
    line-height: 15px;
}

.out-circle {
    width: 15px;
    height: 15px;
    
    border-radius: 50%;
    background: #555;
    margin: 5px;
    display: inline-block;
}

.out-circle.filled {
    background: yellow;
}
</style>
