<template>
  <page-wrapper>
    <sitewide-notice />
    <ion-header>
      <ion-toolbar class="header-toolbar">
        <ion-buttons slot="start">
          <ion-menu-button color="dark"></ion-menu-button>
        </ion-buttons>
        <ion-title>Demos</ion-title>
      </ion-toolbar>
    </ion-header>
    <card-wrapper
      cardTitle="Scoreboard Controller"
      id="featured"
      cardSubtitle="Change the main sections based on the page you are on."
      v-if="scoreboard"
    >
      <ion-item>
        <ion-label position="stacked">Away Team Name</ion-label>
        <ion-input v-model="scoreboard.away_team"></ion-input>
      </ion-item>
      <hr>
      <ion-item>
        <ion-label position="stacked">Home Team Name</ion-label>
        <ion-input v-model="scoreboard.home_team"></ion-input>
      </ion-item>
      <br><br><br>
      <ion-row class="ion-no-padding">
        <ion-col>
          <ion-item>
            <ion-label position="stacked">Away Score</ion-label>
            <ion-input style="font-size: 40px;" v-model="scoreboard.away_score"></ion-input>
          </ion-item>
          <ion-button color="success" style="font-size: 20px;" expand="full" @click="incrementAwayScore">+</ion-button>
          <ion-button color="danger" style="font-size: 20px;" expand="full" @click="decrementAwayScore">-</ion-button>
        </ion-col>
        <ion-col>
          <ion-item>
            <ion-label position="stacked">Home Score</ion-label>
            <ion-input style="font-size: 40px;" v-model="scoreboard.home_score"></ion-input>  
          </ion-item>
          <ion-button color="success" style="font-size: 20px;" expand="full" @click="incrementHomeScore">+</ion-button>
          <ion-button color="danger" style="font-size: 20px;" expand="full" @click="decrementHomeScore">-</ion-button>
        </ion-col>
      </ion-row>
    </card-wrapper>
  </page-wrapper>
</template>

<script setup>
import { defineComponent, onMounted, ref, watch } from "vue";
import CardWrapper from "@/components/layouts/CardWrapper.vue";
import SitewideNotice from "@/components/header/SiteNotice.vue";
import PageWrapper from "@/components/layouts/PageWrapper.vue";
import { api } from "@/utils/axios";
import { IonItem, IonInput } from "@ionic/vue";
import { useRouter } from 'vue-router';
const router = useRouter();

defineComponent({
  name: "ScoreboardControllerPage",
});

let scoreboard = ref(null);

onMounted(() => {
  const password = prompt("Please enter the password to access this page:");
  if (password !== "7137") { // Replace 'yourPasswordHere' with the actual password
    alert("Incorrect password!");
    router.push('/'); // Redirects to the homepage or another route
  } else {
    getScoreboard();
  }
});

const getScoreboard = async () => {
  try {
    const response = await api.get("/api/get-scoreboard-diamond-1");
    scoreboard.value = response.data;
  } catch (error) {
    console.error(error);
  }
};

const saveScoreboard = async () => {
  try {
    const response = await api.post("/api/save-scoreboard-diamond-1", scoreboard.value);
    if(response.data.success) {
      console.log('Scoreboard saved');
    }
  } catch (error) {
    console.error(error);
  }
};

watch(() => scoreboard?.value?.away_team, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    saveScoreboard();
  }
});

watch(() => scoreboard?.value?.home_team, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    saveScoreboard();
  }
});

const incrementAwayScore = () => {
  scoreboard.value.away_score++;
  saveScoreboard();
};

const decrementAwayScore = () => {
  if (scoreboard.value.away_score > 0) {
    scoreboard.value.away_score--;
    saveScoreboard();
  }
};

const incrementHomeScore = () => {
  scoreboard.value.home_score++;
  saveScoreboard();
};

const decrementHomeScore = () => {
  if (scoreboard.value.home_score > 0) {
    scoreboard.value.home_score--;
    saveScoreboard();
  }
};
</script>

<style>
/* Add your styles here if needed */
</style>
 