<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <slot></slot>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { defineComponent } from "vue";
import { IonPage, IonContent } from "@ionic/vue";

defineComponent({
  name: "PageWrapper",
});
</script>
