<template>
  <div class="contact-us" id="contact">

    <ion-row>
      <ion-col size="3"></ion-col>
      <ion-col size="6">
        <ion-item>
          <ion-label position="stacked">Your Name</ion-label>
          <ion-input type="text" v-model="name"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Your Email</ion-label>
          <ion-input type="email" v-model="email"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Your Message</ion-label>
          <ion-textarea v-model="message"></ion-textarea>
        </ion-item>
        <ion-button
          expand="full"
          style="margin: 1rem 0"
          color="primary"
          @click="onSaveForm"
          >Send</ion-button
        >
      </ion-col>
      <ion-col size="3"></ion-col>
    </ion-row>
  </div>
</template>
<script setup>
import { defineComponent, ref } from "vue";
import { IonTextarea, IonInput, IonButton, IonRow, IonCol, IonItem, IonLabel } from "@ionic/vue";
import { api } from "@/utils/axios";
import { showToast } from "@/utils/useIonicComponents.js";

defineComponent({
  name: "ContactUs",
});

const name = ref(null);
const email = ref(null);
const message = ref(null);

const onSaveForm = async () => {
  console.log(name.value, email.value, message.value);
  api.post("/api/contact-us", {
    name: name.value,
    email: email.value,
    message: message.value,
  }).then((res) => {
    if(res.data.success === true) {
      showToast(
        "Your message has been sent! Please check your spam for response after 48 hours.",
        "success",
        6000
      );
      name.value = null;
      email.value = null;
      message.value = null;
    }
  })
};

</script>
<style scoped>
.tally-app {
  background: #121212 !important;
}
</style>
