<template>
    <base-modal modal-title="Set Your Registration Prices" @close="close(false)">
        <template #headerStart>
            <ion-button @click="close(false)"> Close </ion-button>
        </template>
        <div>
            <h1 style="color: var(--ion-color-dark); text-align: center; text-transform: uppercase;">
                <span
                    style="background-color: var(--ion-color-primary); padding: 0 10px"
                >
                    Teams
                </span>
            </h1>
            <ion-item>
                <ion-label position="stacked">Team Registration Fee</ion-label>
                <ion-input v-model="teamRegistrationFee"></ion-input>
            </ion-item>
            <ion-item>
                <ion-checkbox v-model="teamRegistrationChooseTeam"></ion-checkbox>
                <ion-label>Allow user to register a new team</ion-label>
            </ion-item>
            <p>Allow user to register a new team list they will fill out their team name, and the team will be added to Slo-Pitch Central when they successfully register.</p>
            <p>Leaving this unselected means they have to choose a team (that is unpaid) you've already created in Slo-Pitch Central.</p>
            
            <h1 style="color: var(--ion-color-dark); text-align: center; text-transform: uppercase;">
                <span
                    style="background-color: var(--ion-color-primary); padding: 0 10px"
                >
                    Individuals
                </span>
            </h1>
            <p>Leave Individual Registration Fee empty if you do not need registration for individuals.</p>
            <ion-item>
                <ion-label position="stacked">Individual Registration Fee</ion-label>
                <ion-input v-model="individualRegistrationFee"></ion-input>
            </ion-item>
            <ion-item>
                <ion-checkbox v-model="individualRegistrationChooseTeam"></ion-checkbox>
                <ion-label>Have user choose team from list of teams in your Teams tab</ion-label>
            </ion-item>
  
            <div style="text-align: center; margin-top: 10px;">
                <ion-button @click="savePrices">Save Prices</ion-button>
            </div>
        </div>
    </base-modal>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
import { ref } from "vue";
import { modalController, IonButton, IonItem, IonLabel, IonInput } from "@ionic/vue";
import BaseModal from "@/components/layouts/BaseModal.vue";
import { useLeagueStore } from "@/stores/leagueStore";
import { api } from "@/utils/axios";
import { showToast } from "@/utils/useIonicComponents";

const leagueStore = useLeagueStore();
const teamRegistrationFee = ref(leagueStore.league.team_registration_price || null);
const individualRegistrationFee = ref(leagueStore.league.individual_registration_price || null);
const teamRegistrationChooseTeam = ref(leagueStore.league.team_registration_choose_team || null);
const individualRegistrationChooseTeam = ref(leagueStore.league.individual_registration_choose_team || null);

async function close(success = false) {
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss(success);
}

const savePrices = () => {
    api.post('/api/registration/save-prices', {
        league_slug: leagueStore.league.slug,
        team_registration_choose_team: teamRegistrationChooseTeam.value,
        individual_registration_choose_team: individualRegistrationChooseTeam.value,
        team_registration_price: teamRegistrationFee.value,
        individual_registration_price: individualRegistrationFee.value
    }).then(async () => {
        await leagueStore.reloadEverything(leagueStore.league.slug);
        showToast(
            "Prices officially set!",
            "success",
            3000
        );
        close()
    })
}
</script>
