import { defineStore } from "pinia";
import { ref, computed, watch } from "vue";
import { api } from "@/utils/axios";

import { useAuthStore } from "@/stores/authStore";

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}


export const useScorekeepStore = defineStore("scorekeep", () => {

    const authStore = useAuthStore();
    
    const basicPlay = {
        hitResult: null, // single, double, triple, home run, walk, hit by pitch, error, fielder's choice, out
        outResult: false,
        substitution: false,
        coming_out: null,
        going_in: null,
        scoredResult: false,
        fieldersChoiceResult: false,
        errorResult: false,
        runners: {
            third: { movesTo: null, outResult: false, scoredResult: false },
            second: { movesTo: null, outResult: false, scoredResult: false },
            first: { movesTo: null, outResult: false, scoredResult: false }
        }
    }

    const customScorekeptGameId = ref(null);
    console.log('customScorekeptGameId', customScorekeptGameId)

    const spcId = ref(null);
    const startTime = ref(null);
    const startDate = ref(null);
    const location = ref(null);
    const maxInningsCount = ref(7);
    const currentInning = ref(1);
    const currentHalf = ref(0)
    const currentOuts = ref(0);
    const runsPerInning = ref('5') // always need to parse this as a number
    const gameAtBatId = ref(0);

    const gameIsSaved = ref(false)

    const isHalfInningChangeover = ref(false)

    const gameEnded = ref(false)

    const currentPlay = ref(JSON.parse(JSON.stringify(basicPlay)));
    // console.log('currentPlay', currentPlay)
    const positions = ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'LCF', 'CF', 'RCF', 'RF', 'DH', 'Rotate IF', 'Rotate OF', '5-Man']

   

    const awayTeam = ref({
        name: ''
    });
    const homeTeam = ref({
        name: ''
    });

    const awayInningScores = ref([0,0,0,0,0,0,0]);
    const homeInningScores = ref([0,0,0,0,0,0,0]);

    const awayTotalScore = computed(() => {
        return awayInningScores.value.reduce((a, b) => a + b, 0);
    })
    const homeTotalScore = computed(() => {
        return homeInningScores.value.reduce((a, b) => a + b, 0);
    })
    
    const setLineups = ref(false)


    const awayTeamLineup = ref([
        {
            id: uuidv4(),
            name: '',
            position: 'P',
            number: '',
            substitution_id: null,
            reentried: false
        },
        {
            id: uuidv4(),
            name: '',
            position: 'C',
            number: '',
            substitution_id: null,
            reentried: false
        },
        {
            id: uuidv4(),
            name: '',
            position: '1B',
            number: '',
            substitution_id: null,
            reentried: false
        },
        {
            id: uuidv4(),
            name: '',
            position: '2B',
            number: '',
            substitution_id: null,
            reentried: false
        },
        {
            id: uuidv4(),
            name: '',
            position: 'SS',
            number: '',
            substitution_id: null,
            reentried: false
        },
        {
            id: uuidv4(),
            name: '',
            position: '3B',
            number: '',
            substitution_id: null,
            reentried: false
        },
        {
            id: uuidv4(),
            name: '',
            position: 'LF',
            number: '',
            substitution_id: null,
            reentried: false
        },
        {
            id: uuidv4(),
            name: '',
            position: 'LCF',
            number: '',
            substitution_id: null,
            reentried: false
        },
        {
            id: uuidv4(),
            name: '',
            position: 'RCF',
            number: '',
            substitution_id: null,
            reentried: false
        },
        {
            id: uuidv4(),
            name: '',
            position: 'RF',
            number: '',
            substitution_id: null,
            reentried: false
        }
    ]);

    const awayTeamBench = ref([

    ])

    const homeTeamLineup = ref([
        {
            id: uuidv4(),
            name: '',
            position: 'P',
            number: '',
            substitution_id: null,
            reentried: false
        },
        {
            id: uuidv4(),
            name: '',
            position: 'C',
            number: '',
            substitution_id: null,
            reentried: false
        },
        {
            id: uuidv4(),
            name: '',
            position: '1B',
            number: '',
            substitution_id: null,
            reentried: false
        },
        {
            id: uuidv4(),
            name: '',
            position: '2B',
            number: '',
            substitution_id: null,
            reentried: false
        },
        {
            id: uuidv4(),
            name: '',
            position: 'SS',
            number: '',
            substitution_id: null,
            reentried: false
        },
        {
            id: uuidv4(),
            name: '',
            position: '3B',
            number: '',
            substitution_id: null,
            reentried: false
        },
        {
            id: uuidv4(),
            name: '',
            position: 'LF',
            number: '',
            substitution_id: null,
            reentried: false
        },
        {
            id: uuidv4(),
            name: '',
            position: 'LCF',
            number: '',
            substitution_id: null,
            reentried: false
        },
        {
            id: uuidv4(),
            name: '',
            position: 'RCF',
            number: '',
            substitution_id: null,
            reentried: false
        },
        {
            id: uuidv4(),
            name: '',
            position: 'RF',
            number: '',
            substitution_id: null,
            reentried: false
        }
    ]);

    const homeTeamBench = ref([])

    const awayTeamPlayByPlay = ref([]);
    const homeTeamPlayByPlay = ref([]);

    const outs = ref(0);

    const setMaxInningsCount = (innings) => {
        maxInningsCount.value = innings;
    }

    const addPlayerToAwayLineup = () => {
        const player = {
            id: uuidv4(),
            name: '',
            position: 'Rotate IF',
            number: '',
            substitution_id: null,
            reentried: false
        }
        awayTeamLineup.value.push(player);
    }

    const addPlayerToHomeLineup = () => {
        const player = {
            id: uuidv4(),
            name: '',
            position: 'Rotate IF',
            number: '',
            substitution_id: null,
            reentried: false
        }
        homeTeamLineup.value.push(player);
    }

    const addPlayerToAwayBench = () => {
        const player = {
            id: uuidv4(),
            name: '',
            position: 'Rotate IF',
            number: '',
            substitution_id: null,
            from_bench: true,
            reentried: false
        }
        awayTeamBench.value.push(player);
    }

    const addPlayerToHomeBench = () => {
        const player = {
            id: uuidv4(),
            name: '',
            position: 'Rotate IF',
            number: '',
            substitution_id: null,
            from_bench: true,
            reentried: false
        }
        homeTeamBench.value.push(player);
    }

    const removePlayerFromAwayBench = (index) => {
        awayTeamBench.value.splice(index, 1);
    };

    const removePlayerFromHomeBench = (index) => {
        homeTeamBench.value.splice(index, 1);
    };

    // Watcher to update inning scores when max innings count changes
    watch(maxInningsCount, (newValue, oldValue) => {
        
        var innings = parseInt(newValue);
        if (newValue !== oldValue) {
            awayInningScores.value = Array(innings).fill(0);
            homeInningScores.value = Array(innings).fill(0);
        }
    });

    const removePlayerFromAwayLineup = (index) => {
        awayTeamLineup.value.splice(index, 1);
    };
    
    const removePlayerFromHomeLineup = (index) => {
        homeTeamLineup.value.splice(index, 1);
    };

    const saveLineups = () => {
        // console.log('check')
        setLineups.value = true;

        saveState(customScorekeptGameId.value)
    };

    const atBat = computed(() => {
        let lineup;
        let playByPlay;
    
        if (currentHalf.value === 0) {
            lineup = awayTeamLineup.value;
            playByPlay = awayTeamPlayByPlay.value.filter(play => play.substitution === false);
        } else {
            lineup = homeTeamLineup.value;
            playByPlay = homeTeamPlayByPlay.value.filter(play => play.substitution === false);
        }

        console.log('playByPlay',{ awayTeamPlayByPlay, homeTeamPlayByPlay})
    
        // Calculate the index of the player who is at bat
        const atBatIndex = playByPlay.length % lineup.length;
        // console.log('atBatIndex', atBatIndex)
        return lineup[atBatIndex];
    });

    const onDeck = computed(() => {
        let lineup;
        let playByPlay;
    
        if (currentHalf.value === 0) {
            lineup = awayTeamLineup.value;
            playByPlay = awayTeamPlayByPlay.value.filter(play => play.substitution === false);
        } else {
            lineup = homeTeamLineup.value;
            playByPlay = homeTeamPlayByPlay.value.filter(play => play.substitution === false);
        }
    
        // Calculate the index of the player who is on deck
        const onDeckIndex = (playByPlay.length + 1) % lineup.length;
        return lineup[onDeckIndex];
    })

    const inTheHole = computed(() => {
        let lineup;
        let playByPlay;

        if (currentHalf.value === 0) {
            lineup = awayTeamLineup.value;
            playByPlay = awayTeamPlayByPlay.value.filter(play => play.substitution === false);
        }
        else {
            lineup = homeTeamLineup.value;
            playByPlay = homeTeamPlayByPlay.value.filter(play => play.substitution === false);
        }

        // Calculate the index of the player who is in the hole
        const inTheHoleIndex = (playByPlay.length + 2) % lineup.length;
        return lineup[inTheHoleIndex];
    })
    // console.log(atBat)

    const onFirstBase = ref(null);
    const onSecondBase = ref(null);
    const onThirdBase = ref(null);
    const scoringRunners = ref([]);

    const awayScoredRunners = ref([]);
    const homeScoredRunners = ref([]);

    const awayHits = computed(() => {
        return awayTeamPlayByPlay.value.filter(play => 
            play.hitResult === 'single' || 
            play.hitResult === 'double' || 
            play.hitResult === 'triple' || 
            play.hitResult === 'homerun'
        );
    })
    const homeHits = computed(() => {
        return homeTeamPlayByPlay.value.filter(play =>
            play.hitResult === 'single' ||
            play.hitResult === 'double' ||
            play.hitResult === 'triple' ||
            play.hitResult === 'homerun'
        );
    })


    // console.log('onFirstBase', onFirstBase)
    // console.log('onSecondBase', onSecondBase)
    // console.log('onThirdBase', onThirdBase)
    // console.log('scoringRunners', scoringRunners)

    const generatePlayByPlayResult = (play) => {
        // add one to gameAtBatId to keep track of the atBatId
        gameAtBatId.value++
        
        const playByPlay = {
            ...play,
            inning: JSON.parse(JSON.stringify(currentInning.value)),
            half: JSON.parse(JSON.stringify(currentHalf.value)),
            outs: JSON.parse(JSON.stringify(currentOuts.value)),
            atBatId: JSON.parse(JSON.stringify(gameAtBatId.value)),
            atBat: { ...atBat.value },
            onFirstBase: { ...onFirstBase.value },
            onSecondBase: { ...onSecondBase.value },
            onThirdBase: { ...onThirdBase.value },
        };
        // console.log('generatePlayByPlayResult', playByPlay)
        return { ...playByPlay };
    }

    const createPlayByPlay = (play) => {
        if(currentHalf.value === 0) {
            awayTeamPlayByPlay.value.push(play);
        }
        if(currentHalf.value === 1) {
            homeTeamPlayByPlay.value.push(play);
        }
    }

    const undoPlayByPlay = (play) => {
        console.log('play', play)
    }


    
    console.log('undoPlayByPlay', undoPlayByPlay)

    
    

    const updateCurrentPlay = (play) => {

        
        // console.log('updateCurrentPlay', play)
        const updateObject = (obj, data) => {
            Object.keys(data).forEach(key => {
                if (typeof data[key] === 'object' && data[key] !== null && !Array.isArray(data[key])) {
                    if (!obj[key]) obj[key] = {};  // Ensure the nested object exists
                    updateObject(obj[key], data[key]);  // Recursive update
                } else {
                    obj[key] = data[key];  // Direct update
                }
            });
        };
    
        updateObject(currentPlay.value, play);

        if (play.hitResult) {
            switch (play.hitResult) {
                case 'doubleplay':
                    if (onFirstBase.value) {
                        currentPlay.value.runners.first.movesTo = null;
                        currentPlay.value.runners.first.outResult = true;
                        currentPlay.value.runners.first.scoredResult = false;
                    }
                    if (onSecondBase.value) {
                        currentPlay.value.runners.second.movesTo = null;
                        currentPlay.value.runners.second.outResult = true;
                        currentPlay.value.runners.second.scoredResult = false;
                    }
                    if (onThirdBase.value) {
                        currentPlay.value.runners.third.movesTo = null;
                        currentPlay.value.runners.third.outResult = true;
                        currentPlay.value.runners.third.scoredResult = false;
                    }
                    break;
                case 'walk':
                    if (onFirstBase.value) {
                        currentPlay.value.runners.first.movesTo = 'second';
                        currentPlay.value.runners.first.scoredResult = false;
                        currentPlay.value.runners.first.outResult = false;
                        if (onSecondBase.value) {
                            currentPlay.value.runners.second.movesTo = 'third';
                            currentPlay.value.runners.second.outResult = false;
                            currentPlay.value.runners.second.scoredResult = false;
                            if (onThirdBase.value) {
                                currentPlay.value.runners.third.movesTo = 'home';
                                currentPlay.value.runners.third.scoredResult = true;
                                currentPlay.value.runners.third.outResult = false;
                            }
                        }
                    }
                    break;
                case 'single':
                    if (onFirstBase.value) {
                        currentPlay.value.runners.first.movesTo = 'second';
                        currentPlay.value.runners.first.scoredResult = false;
                        currentPlay.value.runners.first.outResult = false;
                        
                    }
                    if (onSecondBase.value) {
                        currentPlay.value.runners.second.movesTo = 'third';
                        currentPlay.value.runners.second.scoredResult = false;
                        currentPlay.value.runners.second.outResult = false;
                    }
                    if (onThirdBase.value) {
                        currentPlay.value.runners.third.movesTo = 'home';
                        currentPlay.value.runners.third.scoredResult = true;
                        currentPlay.value.runners.third.outResult = false;
                    }
                    
                    break;
                case 'double':
                    if (onFirstBase.value) {
                        currentPlay.value.runners.first.movesTo = 'third';
                        currentPlay.value.runners.first.scoredResult = false;
                        currentPlay.value.runners.first.outResult = false;
                    }
                    if (onSecondBase.value) {
                        currentPlay.value.runners.second.movesTo = 'home';
                        currentPlay.value.runners.second.scoredResult = true;
                        currentPlay.value.runners.second.outResult = false;
                    }
                    if (onThirdBase.value) {
                        currentPlay.value.runners.third.movesTo = 'home';
                        currentPlay.value.runners.third.scoredResult = true;
                        currentPlay.value.runners.third.outResult = false;
                    }
                    
                    break;
                case 'triple':
                    if (onFirstBase.value) {
                        currentPlay.value.runners.first.movesTo = 'home';
                        currentPlay.value.runners.first.scoredResult = true;
                        currentPlay.value.runners.first.outResult = false;
                    }
                    if (onSecondBase.value) {
                        currentPlay.value.runners.second.movesTo = 'home';
                        currentPlay.value.runners.second.scoredResult = true;
                        currentPlay.value.runners.second.outResult = false;
                    }
                    if (onThirdBase.value) {
                        currentPlay.value.runners.third.movesTo = 'home';
                        currentPlay.value.runners.third.scoredResult = true;
                        currentPlay.value.runners.third.outResult = false;
                    }
                    
                    break;
                case 'homerun':
                    if (onFirstBase.value) {
                        currentPlay.value.runners.first.movesTo = 'home';
                        currentPlay.value.runners.first.scoredResult = true;
                        currentPlay.value.runners.first.outResult = false;
                    }
                    if (onSecondBase.value) {
                        currentPlay.value.runners.second.movesTo = 'home';
                        currentPlay.value.runners.second.scoredResult = true;
                        currentPlay.value.runners.second.outResult = false;
                    }
                    if (onThirdBase.value) {
                        currentPlay.value.runners.third.movesTo = 'home';
                        currentPlay.value.runners.third.scoredResult = true;
                        currentPlay.value.runners.third.outResult = false;
                    }
                    // Assuming scoringRunners holds the list of runners who scored
                    break;
                case 'sacfly':
                    if (onFirstBase.value) {
                        currentPlay.value.runners.first.movesTo = 'second';
                        currentPlay.value.runners.first.scoredResult = false;
                        currentPlay.value.runners.first.outResult = false;
                    }
                    if (onSecondBase.value) {
                        currentPlay.value.runners.second.movesTo = 'third';
                        currentPlay.value.runners.second.scoredResult = false;
                        currentPlay.value.runners.second.outResult = false;
                    }
                    if (onThirdBase.value) {
                        currentPlay.value.runners.third.movesTo = 'home';
                        currentPlay.value.runners.third.scoredResult = true;
                        currentPlay.value.runners.third.outResult = false;
                    }
                    break;
                // Add more cases as needed for other hit results
            }
        }
    
        // after updating the current play, assume locations for other runners intelligently based on the play
    }

    const submitPlay = () => {
        
        // todo store the play
        const officialPlayByPlayResult = JSON.parse(JSON.stringify(generatePlayByPlayResult(currentPlay.value)));
        // console.log('officialPlayByPlayResult', officialPlayByPlayResult)

        let runsScored = 0;
        currentPlay.value.runners.third.scoredResult === true ? runsScored++ : null;
        currentPlay.value.runners.second.scoredResult === true ? runsScored++ : null;
        currentPlay.value.runners.first.scoredResult === true ? runsScored++ : null;
        currentPlay.value.scoredResult === true ? runsScored++ : null;
        // console.log('runsScored', runsScored)
        let outsRecorded = 0;
        currentPlay.value.outResult === true ? outsRecorded++ : null;
        currentPlay.value.runners.third.outResult === true ? outsRecorded++ : null;
        currentPlay.value.runners.second.outResult === true ? outsRecorded++ : null;
        currentPlay.value.runners.first.outResult === true ? outsRecorded++ : null;
        // console.log('outsRecorded', outsRecorded)


        currentOuts.value += outsRecorded;

        if(currentOuts.value >= 3) {
            // currentOuts.value = 0;
            // // make sure the awayInningScores/homeInningScores are updated
            // onFirstBase.value = null;
            // onSecondBase.value = null;
            // onThirdBase.value = null;

            createPlayByPlay(officialPlayByPlayResult)
            clearPlay()

            flipHalfInning()
            saveState(customScorekeptGameId.value)
            return

            // if(currentHalf.value === 0) {
            //     currentHalf.value = 1;
            //     return 
            // }
            // if(currentHalf.value === 1) {
            //     currentHalf.value = 0;
            //     currentInning.value++;
            //     return
            // }
        } 

        if(currentPlay.value.runners.third.outResult) {
            onThirdBase.value = null
        }
        
        // move the runners first, then the hitter so there's no overlap
        if(currentPlay.value.runners.third.movesTo) {
            if(currentPlay.value.runners.third.movesTo === 'home') {
                // console.log('blah')
                scoringRunners.value.push(onThirdBase.value)
                onThirdBase.value = null
            }
        }

        if(currentPlay.value.runners.second.outResult) {
            onSecondBase.value = null
        }

        if(currentPlay.value.runners.second.movesTo) {
            if(currentPlay.value.runners.second.movesTo === 'home') {
                // console.log('blah')
                scoringRunners.value.push(onSecondBase.value)
                onSecondBase.value = null
            }
            if(currentPlay.value.runners.second.movesTo === 'third') {
                // console.log('blah')
                onThirdBase.value = { ...onSecondBase.value }
                onSecondBase.value = null
            }
        }

        if(currentPlay.value.runners.first.outResult) {
            onFirstBase.value = null
        }

        if(currentPlay.value.runners.first.movesTo) {
            
            if(currentPlay.value.runners.first.movesTo === 'home') {
                // console.log('blah')
                scoringRunners.value.push(onFirstBase.value)
                onFirstBase.value = null
            }
            if(currentPlay.value.runners.first.movesTo === 'third') {
                // console.log('blah')
                onThirdBase.value = { ...onFirstBase.value }
                onFirstBase.value = null
            }
            if(currentPlay.value.runners.first.movesTo === 'second') {
                // console.log('blah')
                onSecondBase.value = { ...onFirstBase.value }
                onFirstBase.value = null
            }
        }

        if(currentPlay.value.hitResult) {
            if(currentPlay.value.hitResult === 'single') {
                onFirstBase.value = { ...atBat.value }
            }
            if(currentPlay.value.hitResult === 'double') {
                onSecondBase.value = { ...atBat.value }
            }
            if(currentPlay.value.hitResult === 'triple') {
                onThirdBase.value ={ ...atBat.value }
            }
            if(currentPlay.value.hitResult === 'homerun') {
                scoringRunners.value.push(atBat.value)
            }
            if(currentPlay.value.hitResult === 'walk') {
                onFirstBase.value = { ...atBat.value }
            }
            if(currentPlay.value.hitResult === 'sacfly') {
                console.log('sacfly')
            }
            if(currentPlay.value.hitResult === 'doubleplay') {
                console.log('doubleplay')
            }   
        }


        /// update the scoreboard
        if(currentHalf.value === 0) {
            awayInningScores.value[currentInning.value - 1] += runsScored;
        }
        if(currentHalf.value === 1) {
            homeInningScores.value[currentInning.value - 1] += runsScored;
        }



        // update scoringRunners to awayScoredRunners/homeScoredRunners, and then clear scoringRunners
        if(currentHalf.value === 0) {
            awayScoredRunners.value.push(...scoringRunners.value)
            scoringRunners.value = []
        }
        if(currentHalf.value === 1) {
            homeScoredRunners.value.push(...scoringRunners.value)
            scoringRunners.value = []
        }
        
        createPlayByPlay(officialPlayByPlayResult)

        if (!isNaN(parseInt(runsPerInning.value)) && currentInning.value !== maxInningsCount.value) {
            if(currentHalf.value === 0) {
                if(awayInningScores.value[currentInning.value - 1] > parseInt(runsPerInning.value)) {
                    console.log('should be in here')
                    awayInningScores.value[currentInning.value - 1] = parseInt(runsPerInning.value)
                }

                if(awayInningScores.value[currentInning.value - 1] === parseInt(runsPerInning.value)) {
                    console.log('should be in here 2')
                    flipHalfInning()
                }
            }
            if(currentHalf.value === 1) {
                if(homeInningScores.value[currentInning.value - 1] > parseInt(runsPerInning.value)) {
                    console.log('should be in here 3')
                    homeInningScores.value[currentInning.value - 1] = parseInt(runsPerInning.value)
                }

                if(homeInningScores.value[currentInning.value - 1] === parseInt(runsPerInning.value)) {
                    console.log('should be in here 4')
                    flipHalfInning()
                }
            }
        }
        
        
        clearPlay()
        saveState(customScorekeptGameId.value)
    }

    const flipHalfInning = () => {
        currentOuts.value = 0;
        // make sure the awayInningScores/homeInningScores are updated
        onFirstBase.value = null;
        onSecondBase.value = null;
        onThirdBase.value = null;


        clearPlay()

        if(currentHalf.value === 0) {
            currentHalf.value = 1;
            return 
        }
        if(currentHalf.value === 1) {
            currentHalf.value = 0;
            currentInning.value++;
            return
        }
    }

    const undoPlay = () => {
        if (currentHalf.value === 0 && awayTeamPlayByPlay.value.length > 0) {
            undoLastPlay(awayTeamPlayByPlay.value);
        } else if (currentHalf.value === 1 && homeTeamPlayByPlay.value.length > 0) {
            undoLastPlay(homeTeamPlayByPlay.value);
        }
    };

    const undoLastPlay = (playByPlay) => {
        if (playByPlay.length === 0) return;
    
        const lastPlay = playByPlay.pop();
        console.log('lastPlay', lastPlay)

        let runsScored = 0;
        if (lastPlay.runners.third.scoredResult) {
            runsScored++;
            scoringRunners.value.pop()
        }
        if (lastPlay.runners.second.scoredResult) {
            runsScored++;
            scoringRunners.value.pop()
        }
        if (lastPlay.runners.first.scoredResult) {
            runsScored++;
            scoringRunners.value.pop()
        }
        if (lastPlay.scoredResult) {
            runsScored++;
            // Assuming the batter himself scored
            scoringRunners.value.pop()
        }

    
            // Reverse the runs scored
        if (currentHalf.value === 0) {
            awayInningScores.value[currentInning.value - 1] -= runsScored;
            awayScoredRunners.value.length -= runsScored;
        } else {
            homeInningScores.value[currentInning.value - 1] -= runsScored;
            homeScoredRunners.value.length -= runsScored;
        }

        // let outsRecorded = 0;
        // currentPlay.value.outResult === true ? outsRecorded++ : null;
        // currentPlay.value.runners.third.outResult === true ? outsRecorded++ : null;
        // currentPlay.value.runners.second.outResult === true ? outsRecorded++ : null;
        // currentPlay.value.runners.first.outResult === true ? outsRecorded++ : null;
    
        // filter out/remove the runners that were added to the runners scored array
        



        // Reverse the outs
        currentOuts.value = lastPlay.outs;
    
        // Reset base runners based on the last play state
        onFirstBase.value = Object.keys(lastPlay.onFirstBase).length === 0 ? null : lastPlay.onFirstBase;
        onSecondBase.value = Object.keys(lastPlay.onSecondBase).length === 0 ? null : lastPlay.onSecondBase;
        onThirdBase.value = Object.keys(lastPlay.onThirdBase).length === 0 ? null : lastPlay.onThirdBase;
    
        // If inning or half needs to be reversed
        if (currentOuts.value === 0 && lastPlay.outs > 0) {
            if (currentHalf.value === 0) {
                currentHalf.value = 1;
                currentInning.value--;
            } else {
                currentHalf.value = 0;
            }
        }
    };


    const clearPlay = () => {
        currentPlay.value = JSON.parse(JSON.stringify(basicPlay))
        
    }

    const endGameEarly = () => {
        gameEnded.value = true
        saveState(customScorekeptGameId.value)
    }

    const readyToSubmitPlay = computed(() => {
        if(currentPlay.value.hitResult === null &&
            currentPlay.value.outResult === false &&
            currentPlay.value.scoredResult === false &&
            currentPlay.value.fieldersChoiceResult === false &&
            currentPlay.value.errorResult === false) {
            return false
        }
        return true
    })

    const checkIfPlayIsValid = computed(() => {
        return 'test'
    })
    console.log(checkIfPlayIsValid)

   

    function generatePlaySummary(play) {
        let summary = '';
        
        if (play.hitResult) {
            const batterName = atBat.value.name;
            
            let action = '';
            switch (play.hitResult) {
                case 'single':
                    action = 'hits a single';
                    break;
                case 'double':
                    action = 'hits a double';
                    break;
                case 'triple':
                    action = 'hits a triple';
                    break;
                case 'homerun':
                    action = 'hits a home run';
                    break;
                case 'walk':
                    action = 'walks';
                    break;
                case 'doubleplay':
                    action = 'hits into a double play';
                    break;
                case 'out':
                    action = 'hits into an out';
                    break;
                case 'error':
                    action = 'reaches on an error';
                    break;
                case 'fieldersChoice':
                    action = 'reaches on a fielder\'s choice';
                    break;
                default:
                    action = 'plays';
                    break;
            }
            summary += `${batterName} ${action}. `;
        }
    
        const basePaths = ['first', 'second', 'third'];
        basePaths.forEach((base, index) => {
            const runner = play.runners[base];
            if (runner && runner.movesTo) {
                const nextBase = runner.movesTo === 'home' ? 'scores' : `moves to ${runner.movesTo}`;
                const runnerName = index === 0 ? onFirstBase.value.name :
                                   index === 1 ? onSecondBase.value.name :
                                   onThirdBase.value.name;
                summary += `${runnerName} ${nextBase}. `;
            }
        });
    
        return summary.trim();
    }


    const playSummary = computed(() => {
        return generatePlaySummary(currentPlay.value);
    });

    function generateLogSummary(play) {
        let summary = '';
        

        let runsScored = 0;
        play.runners.third.scoredResult === true ? runsScored++ : null;
        play.runners.second.scoredResult === true ? runsScored++ : null;
        play.runners.first.scoredResult === true ? runsScored++ : null;
        play.scoredResult === true ? runsScored++ : null;
        
        let outsRecorded = 0;
        play.outResult === true ? outsRecorded++ : null;
        play.runners.third.outResult === true ? outsRecorded++ : null;
        play.runners.second.outResult === true ? outsRecorded++ : null;
        play.runners.first.outResult === true ? outsRecorded++ : null;

          // Check for substitutions and add to summary if present
        if (play.coming_out && play.going_in) {
            summary += `${play.coming_out.name} was substituted for ${play.going_in.name}. `;
        }

        if (play.hitResult) {
            const batterName = play.atBat.name;

            let action = '';
            switch (play.hitResult) {
                case 'single':
                    action = 'hits a single';
                    break;
                case 'double':
                    action = 'hits a double';
                    break;
                case 'triple':
                    action = 'hits a triple';
                    break;
                case 'homerun':
                    action = 'hits a home run';
                    break;
                case 'walk':
                    action = 'walks';
                    break;
                case 'doubleplay':
                    action = 'hits into a double play';
                    break;
                case 'out':
                    action = 'hits into an out';
                    break;
                case 'error':
                    action = 'reaches on an error';
                    break;
                case 'fieldersChoice':
                    action = 'reaches on a fielder\'s choice';
                    break;
                default:
                    action = 'plays';
                    break;
            }
            summary += `${batterName} ${action}. `;
        }
    
        const basePaths = ['first', 'second', 'third'];
        basePaths.forEach((base, index) => {
            const runner = play.runners[base];
            if (runner && runner.movesTo) {
                const nextBase = runner.movesTo === 'home' ? 'scores' : `moves to ${runner.movesTo}`;
                const runnerName = index === 0 ? play.onFirstBase.name :
                                   index === 1 ? play.onSecondBase.name :
                                   play.onThirdBase.name;
                summary += `${runnerName} ${nextBase}. `;
            }
        });
        
        if (play.outs > 0) {
            let outs = play.outs + outsRecorded
            if(outs > 3) {
                outs = 3
            }
            const outsText = outs === 1  ? '1 out' : `${outs} outs`;
            summary += ` ${outsText}.`;
        }

        if (runsScored > 0) {   
            const runsText = runsScored === 1  ? '1 run in' : `${runsScored} runs in`;
            summary += ` ${runsText}.`;
        }

        if(play.outs + outsRecorded >= 3) {
            summary += ` Inning over.`
        }
    
        return summary.trim();
    }
    
    // const currentInningPlaySummary = computed(() => {
        
    // })

    const fullGamePlayByPlay = computed(() => {
        let fullGameLog = [];
        const totalInnings = currentInning.value;
        
    
        for (let inning = 1; inning <= totalInnings; inning++) {
            // Process the top of the inning (away team)
            fullGameLog.push(`Top of Inning ${inning}`);
            awayTeamPlayByPlay.value.forEach(play => {
                console.log('play', play)
                if (play.inning === inning && play.half === 0) {
                    fullGameLog.push(generateLogSummary(play));
                }
            });
    
            // Process the bottom of the inning (home team)
            fullGameLog.push(`Bottom of Inning ${inning}`);
            homeTeamPlayByPlay.value.forEach(play => {
                if (play.inning === inning && play.half === 1) {
                    fullGameLog.push(generateLogSummary(play));
                }
            });
        }
    
        return fullGameLog
    });

    const checkLineupValidity = (lineup) => {
        const positionCounts = {};
        const messages = [];
    
        // Initialize position counts
        positions.forEach(position => {
            positionCounts[position] = 0;
        });
    
        // Count each position in the lineup
        lineup.forEach(player => {
            if (Object.prototype.hasOwnProperty.call(positionCounts, player.position)) {
                positionCounts[player.position]++;
            }
        });
    
        // Adjust requirements based on lineup size and specific conditions
        const requiredPositions = ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'RF'];
        if (lineup.length > 10) {
            requiredPositions.push('DH', 'Rotate IF', 'Rotate OF');
        }
        if (positionCounts['5-Man'] > 0) {
            requiredPositions.push('CF');
        }
    
        // Generate messages based on counts
        requiredPositions.forEach(position => {
            if (positionCounts[position] > 1) {
                messages.push(`You have multiple people set as the ${position}.`);
            } else if (positionCounts[position] === 0) {
                messages.push(`You have no ${position}.`);
            }
        });
    
        return messages;
    };

    watch(() => currentInning.value, (newVal) => {
        if(newVal > maxInningsCount.value) {
            gameEnded.value = true
            saveState(customScorekeptGameId.value)
        }
    })

    watch(() => currentHalf.value, (newVal, oldVal) => {
        if(oldVal !== newVal) {
            isHalfInningChangeover.value = true
            saveState(customScorekeptGameId.value)
        }
    })

    const makeSubstitution = (selectedLineupPlayer, selectedBenchPlayer, team) => {
        console.log('makeSubstitutionIWEUIWUEOIWUje')
        let lineup, bench;
        if (team === 'awayTeam') {
            lineup = awayTeamLineup.value;
            bench = awayTeamBench.value;
        } else if (team === 'homeTeam') {
            lineup = homeTeamLineup.value;
            bench = homeTeamBench.value;
        } else {
            return; // Invalid team specified
        }
    
        // Find the index of the lineup player and bench player
        const lineupIndex = lineup.findIndex(player => player.id === selectedLineupPlayer.id);
        const benchIndex = bench.findIndex(player => player.id === selectedBenchPlayer.id);
    
        if (lineupIndex === -1 || benchIndex === -1) {
            console.error('Player not found in lineup or bench');
            return; // Player not found
        }
    
        // Swap the players
        const lineupPlayer = lineup[lineupIndex];
        const benchPlayer = bench[benchIndex];
    
        // Update substitution IDs for tracking
        lineupPlayer.substitution_id = benchPlayer.id;
        benchPlayer.substitution_id = lineupPlayer.id;
    
        // Swap the players in the lineup and bench
        lineup.splice(lineupIndex, 1, benchPlayer);
        bench.splice(benchIndex, 1, lineupPlayer);

        // Check if the substituted player is currently on a base and update accordingly
        if (onFirstBase.value && onFirstBase.value.id === selectedLineupPlayer.id) {
            onFirstBase.value = benchPlayer;
        }
        if (onSecondBase.value && onSecondBase.value.id === selectedLineupPlayer.id) {
            onSecondBase.value = benchPlayer;
        }
        if (onThirdBase.value && onThirdBase.value.id === selectedLineupPlayer.id) {
            onThirdBase.value = benchPlayer;
        }
        currentPlay.value.substitution = true
        currentPlay.value.coming_out = lineupPlayer
        currentPlay.value.going_in = benchPlayer
        const officialPlayByPlayResult = generatePlayByPlayResult(currentPlay.value)
        createPlayByPlay(officialPlayByPlayResult)

        currentPlay.value.substitution = false
        currentPlay.value.coming_out = null
        currentPlay.value.going_in = null

        console.log('lineup', lineup)
        console.log('bench', bench)
    }

    const reenterPlayerToOriginalSpot = (selectedBenchPlayer, team) => {
        let lineup, bench;
        if (team === 'awayTeam') {
            lineup = awayTeamLineup.value;
            bench = awayTeamBench.value;
        } else if (team === 'homeTeam') {
            lineup = homeTeamLineup.value;
            bench = homeTeamBench.value;
        } else {
            return; // Invalid team specified
        }
    
        // Find the player in the bench and the corresponding lineup player by substitution_id
        const benchIndex = bench.findIndex(player => player.id === selectedBenchPlayer.id);
        const lineupIndex = lineup.findIndex(player => player.substitution_id === selectedBenchPlayer.id);
    
        if (benchIndex === -1 || lineupIndex === -1) {
            console.error('Player not found in lineup or bench for re-entry');
            return; // Player not found
        }
    
        // Get the players
        const benchPlayer = bench[benchIndex];
        const lineupPlayer = lineup[lineupIndex];

        // Update reentry status
        benchPlayer.reentried = true;
        lineupPlayer.reentried = true;
    
        // Swap the players back
        lineup.splice(lineupIndex, 1, benchPlayer);
        bench.splice(benchIndex, 1, lineupPlayer);
    
     
    
        // Clear substitution IDs after reentry
        // benchPlayer.substitution_id = null;
        // lineupPlayer.substitution_id = null;
    
        // Check if the re-entered player needs to be placed back on base
        if (onFirstBase.value && onFirstBase.value.id === lineupPlayer.id) {
            onFirstBase.value = benchPlayer;
        }
        if (onSecondBase.value && onSecondBase.value.id === lineupPlayer.id) {
            onSecondBase.value = benchPlayer;
        }
        if (onThirdBase.value && onThirdBase.value.id === lineupPlayer.id) {
            onThirdBase.value = benchPlayer;
        }
    
        // Mark the play as a reentry for play-by-play logging
        currentPlay.value.substitution = true;
        currentPlay.value.coming_out = lineupPlayer;
        currentPlay.value.going_in = benchPlayer;
        const officialPlayByPlayResult = generatePlayByPlayResult(currentPlay.value);
        createPlayByPlay(officialPlayByPlayResult);
    
        currentPlay.value.substitution = false;
        currentPlay.value.coming_out = null;
        currentPlay.value.going_in = null;
    
        console.log('Re-entered lineup', lineup);
        console.log('Updated bench', bench);
    }
    

    const resetGameToStart = () => {
        clearState()
        // refresh the page
        window.location.reload()   

    }



    const saveState = (gameId, startDateTime = true) => {        
        console.log('attempting to save state')
        const state = {
            spcId: spcId.value,
            startDate: startDate.value,
            location: location.value,
            currentInning: currentInning.value,
            currentHalf: currentHalf.value,
            currentOuts: currentOuts.value,
            maxInningsCount: maxInningsCount.value,
            awayTeam: awayTeam.value,
            homeTeam: homeTeam.value,
            awayInningScores: awayInningScores.value,
            homeInningScores: homeInningScores.value,
            awayTeamLineup: awayTeamLineup.value,
            homeTeamLineup: homeTeamLineup.value,
            awayTeamBench: awayTeamBench.value,
            homeTeamBench: homeTeamBench.value,
            awayTeamPlayByPlay: awayTeamPlayByPlay.value,
            homeTeamPlayByPlay: homeTeamPlayByPlay.value,
            gameEnded: gameEnded.value,
            setLineups: setLineups.value,
            onFirstBase: onFirstBase.value,
            onSecondBase: onSecondBase.value,
            onThirdBase: onThirdBase.value,
            scoringRunners: scoringRunners.value,
            awayScoredRunners: awayScoredRunners.value,
            homeScoredRunners: homeScoredRunners.value,
        };
        console.log('state', state)
        if(startDateTime && startTime.value === null) {
            state.startTime = new Date()   
        } else {
            state.startTime = startTime.value
        }
        localStorage.setItem(`scorekeepState_${gameId}`, JSON.stringify(state));
        
        console.log('saved state to localStorage', `scorekeepState_${gameId}`)

        return gameId
    };

    // Function to clear the saved state from localStorage
    const clearState = (gameId) => {
        localStorage.removeItem(`scorekeepState_${gameId}`);
    };

    // Function to load the state from localStorage
    const loadState = (gameId) => {
        
        console.log('attempting to load state')
        console.log('Attempting to load gameId', gameId)
        const state = JSON.parse(localStorage.getItem(`scorekeepState_${gameId}`));
        console.log('state', state)
        if (state) {
            spcId.value = state.spcId;
            startTime.value = state.startTime;
            startDate.value = state.startDate;
            location.value = state.location;
            currentInning.value = state.currentInning;
            currentHalf.value = state.currentHalf;
            currentOuts.value = state.currentOuts;
            maxInningsCount.value = state.maxInningsCount;
            awayTeam.value = state.awayTeam;
            homeTeam.value = state.homeTeam;
            awayInningScores.value = state.awayInningScores;
            homeInningScores.value = state.homeInningScores;
            awayTeamLineup.value = state.awayTeamLineup;
            homeTeamLineup.value = state.homeTeamLineup;
            awayTeamBench.value = state.awayTeamBench;
            homeTeamBench.value = state.homeTeamBench;
            awayTeamPlayByPlay.value = state.awayTeamPlayByPlay;
            homeTeamPlayByPlay.value = state.homeTeamPlayByPlay;
            gameEnded.value = state.gameEnded;
            setLineups.value = state.setLineups;
            onFirstBase.value = state.onFirstBase;
            onSecondBase.value = state.onSecondBase;
            onThirdBase.value = state.onThirdBase;
            scoringRunners.value = state.scoringRunners;
            awayScoredRunners.value = state.awayScoredRunners;
            homeScoredRunners.value = state.homeScoredRunners;
        }
        checkIfGameIsSaved()
        return state
    };

    // loadState();

    const listSavedGameStates = () => {
        const allKeys = Object.keys(localStorage);
        const gameStateKeys = allKeys.filter(key => key.startsWith('scorekeepState_'));
        const gameStates = gameStateKeys.map(key => {
            const gameId = key.replace('scorekeepState_', '');
            return { gameId, state: JSON.parse(localStorage.getItem(key)) };
        });
        return gameStates;
    };
    
    const saveToGameServer = async () => {
        let currentState = loadState(customScorekeptGameId.value);
        currentState.custom_game_id = customScorekeptGameId.value
        currentState.user_id = authStore.user.id
        api.post(`/api/scorekeep/save-game`, currentState).then(results => {
            console.log('results', results)
        })
        console.log('currentState', currentState)

    }

    const checkIfGameIsSaved = () => {
        api.get(`/api/scorekeep/check-game-saved/${customScorekeptGameId.value}`).then(results => {
            if(results.data.success === true) {
                console.log('checkIfGameSavedtrue')
                gameIsSaved.value = true
                clearState(customScorekeptGameId.value)

            } else {
                gameIsSaved.value = false
            }
        })
    }















    return {
        setLineups,
        spcId,
        startTime,
        startDate,
        location,
        currentInning,
        currentHalf,
        currentOuts,
        maxInningsCount,
        outs,
        awayTeam,
        homeTeam,
        awayInningScores,
        homeInningScores,
        awayTotalScore,
        homeTotalScore,
        awayTeamLineup,
        homeTeamLineup,
        // functions
        setMaxInningsCount,
        checkLineupValidity,
        positions,
        addPlayerToAwayLineup,
        addPlayerToHomeLineup,
        removePlayerFromAwayLineup,
        removePlayerFromHomeLineup,
        saveLineups,
        atBat,
        currentPlay,
        updateCurrentPlay,
        readyToSubmitPlay,
        submitPlay,
        onFirstBase,
        onSecondBase,
        onThirdBase,
        awayScoredRunners,
        homeScoredRunners,
        awayHits,
        homeHits,
        playSummary,
        generatePlaySummary,
        generateLogSummary,
        awayTeamPlayByPlay,
        homeTeamPlayByPlay,
        fullGamePlayByPlay,
        onDeck,
        inTheHole,
        gameEnded,
        undoPlay,
        endGameEarly,
        runsPerInning,
        flipHalfInning,
        isHalfInningChangeover,
        awayTeamBench,
        addPlayerToAwayBench,
        homeTeamBench,
        addPlayerToHomeBench,
        removePlayerFromAwayBench,
        removePlayerFromHomeBench,
        makeSubstitution,
        reenterPlayerToOriginalSpot,
        clearPlay,
        saveState,
        clearState,
        loadState,
        resetGameToStart,
        listSavedGameStates,
        customScorekeptGameId,
        saveToGameServer,
        checkIfGameIsSaved,
        gameIsSaved
    }
})
