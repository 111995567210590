<template>
    <div style="margin-top: 2rem;">
        
        <ion-grid>
            <ion-row>
                <ion-col><ion-note class="ion-text-center">Please list your diamonds and parks in the order you want them prioritized. In the future we will allow you to reorder them.</ion-note></ion-col>
            </ion-row>
            <ion-row v-for="(park, index) of parksData.parks" :key="index" class="row-bottom">
                <ion-col>
                    <ion-item>
                        <ion-label position="stacked">Park Name</ion-label>
                        <ion-input class="park-name" v-model="park.name" placeholder="Enter park name here..."></ion-input>
                    </ion-item>
                    <div class="button-wrapper">
                        <ion-button 
                            expand="full"
                            color="primary" 
                            style="margin-top: 2rem;"
                            @click="addPark">
                                <ion-icon :icon="addOutline"></ion-icon>
                                Add Park
                            </ion-button>
                    </div>
                </ion-col>
                <ion-col>
                    <div v-for="(diamond, diamondIndex) in park.diamonds" :key="diamondIndex" class="diamond-row">
                        <ion-item class="flex-item">
                            <ion-label position="stacked">Diamond Name</ion-label>
                            <ion-input v-model="diamond.name" placeholder="Enter diamond name here..."></ion-input>
                        </ion-item>
                        <ion-button 
                            expand="full"
                            color="danger" 
                            @click="removeDiamond(index, diamondIndex)"><ion-icon :icon="trashOutline"></ion-icon></ion-button>
                    </div>
                    <div class="button-wrapper">
                        <ion-button 
                            color="tertiary"
                            expand="full" style="margin-top: 2rem;" @click="addDiamond(index)">
                                <ion-icon :icon="addOutline"></ion-icon>
                                Add Diamond
                        </ion-button>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</template>

<script>
import { IonButton, IonGrid, IonRow, IonCol, IonLabel, IonInput, IonItem, IonIcon, IonNote } from '@ionic/vue';
import { trashOutline, addOutline } from 'ionicons/icons';

export default {
    emits: ['update:parksData'],
    data() {
        return {
            trashOutline,
            addOutline
        }
    },
    components: {
        IonButton,
        IonGrid,
        IonRow,
        IonCol,
        IonLabel,
        IonInput,
        IonItem,
        IonIcon,
        IonNote
    },
    props: {
        parksData: Object,
    },
    methods: {
        addPark() {
            let parksData = structuredClone(this.parksData);
            parksData.parks.push({ name: '', diamonds: [] });
            this.$emit('update:parksData', parksData);
        },
        addDiamond(parkIndex) {
            let parksData = structuredClone(this.parksData);
            if (parksData.parks[parkIndex]) {
                parksData.parks[parkIndex].diamonds.push({
                    name: '',
                    bookings: []
                });
                this.$emit('update:parksData', parksData);
            } else {
                console.error(`Park at index ${parkIndex} does not exist.`);
            }
        },
        removeDiamond(parkIndex, diamondIndex) {
            let parksData = structuredClone(this.parksData);
            if (parksData.parks[parkIndex] && parksData.parks[parkIndex].diamonds[diamondIndex]) {
                parksData.parks[parkIndex].diamonds.splice(diamondIndex, 1);
                this.$emit('update:parksData', parksData);
            } else {
                console.error(`Diamond at index ${diamondIndex} in park at index ${parkIndex} does not exist.`);
            }
        },
    },
};
</script>

<style scoped>
    .diamond-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .flex-item {
        flex-grow: 1;
    }

    .button-wrapper {
        max-width: 250px;
        margin: 0 auto;
    }

    .row-bottom {
        padding-bottom: 2rem;
        border-bottom: 2px solid #ddd;
        margin-bottom: 2rem;
    }

    .row-bottom:last-child {
        border-bottom: none;
        margin-bottom: 0;
    }

    .park-name {
        font-size: 30px;
    }
</style>

