<template>
  <ion-list>
    
    <div class="header-container"  v-if="leagueStore.league.rules || authStore.isConvenor" >
        <h1 style="color: var(--ion-color-dark); text-align: center; text-transform: uppercase;">
          <span
              style="background-color: var(--ion-color-primary); padding: 0 10px"
          >
              {{ leagueStore.league.type }}-Specific Rules
          </span>
        </h1>
        <div class="header-plus-button" @click="openRulesModal" v-if="authStore.isConvenor">
            <ion-icon :icon="pencilOutline"></ion-icon>
        </div>
    </div>
    <div style="background: #1f2124;">
      <QuillEditor
        contentType="delta"
        :options="options"
        theme=""
        :ref="editor"
        placeholder="No Rules Defined"
        @ready="setContent($event, leagueStore.league.rules)"
        v-if="leagueStore.league.rules"
      />
      <ion-item v-else><ion-label>No Rules Defined</ion-label></ion-item>
    </div>
  </ion-list>
</template>
<script setup>
import { defineComponent, ref, onBeforeUnmount, emit } from "vue";
import { IonList, IonLabel, IonItem, IonIcon } from "@ionic/vue";
import { pencilOutline } from "ionicons/icons";
import { debounce } from "lodash";
import { useLeagueStore } from "@/stores/leagueStore";
import { QuillEditor } from "@vueup/vue-quill";
import { openModal } from "@/utils/useIonicComponents.js";
import RulesModal from "@/components/info/RulesModal.vue";
import { useAuthStore } from "@/stores/authStore";

defineComponent({
  name: "RulesComponent",
});
const leagueStore = useLeagueStore();
const authStore = useAuthStore();
const editor = ref();
const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);
const options = {
  readOnly: true,
  enable: false,
};

window.addEventListener("resize", debounce(resizeHandler, 1000));

function resizeHandler() {
  if (window.innerWidth >= 960) {
    showDesktopMode.value = true;
  } else {
    showDesktopMode.value = false;
  }
}

async function openRulesModal() {
  const response = await openModal(RulesModal, "rulesModal");
  if (response === true) {
    emit("rulesUpdated");
  }
}

onBeforeUnmount(() => {
  window.removeEventListener("resize", resizeHandler);
});
function setContent(event, content) {
  event.setContents(JSON.parse(content), "api");
}
</script>

<style scoped>
  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .header-plus-button {
    margin-left: 8px; /* Adjust as needed for spacing */
    margin-top: 10px;
    width: 39px;
    height: 39px;
    line-height: 41px;
    background: #333;
  }

  .header-plus-button:hover {
      background: #444;
      transition: 0.5s;
      cursor: pointer;
  }

</style>