<template>
  <ion-segment v-model="selectedDate" mode="md" @ionChange="handleSegmentChange" scrollable>
    <ion-segment-button v-for="day in weekDays" :key="day.date" :value="day.date">
      <ion-label>
        <div>{{ day.dayName }}</div>
        <div>{{ day.dayNumber }}</div>
      </ion-label>
    </ion-segment-button>
  </ion-segment>
  <!-- <ion-segment v-model="selectedDate" @ionChange="handleSegmentChange" mode="md">
    <ion-segment-button value="today">
      <ion-label>Today</ion-label>
    </ion-segment-button>
    <ion-segment-button value="tomorrow">
      <ion-label>Tomorrow</ion-label>
    </ion-segment-button>
    <ion-segment-button value="weekend">
      <ion-label>This Weekend</ion-label>
    </ion-segment-button>
  </ion-segment> -->

  <div v-if="loading" class="spinner-container">
    <ion-spinner></ion-spinner>
  </div>
   <ion-row class="ion-no-padding">
    <ion-col size-sm="4" size="12" v-for="event in featuredEvents" :key="event.id">
      <div 
        class="custom-card" 
        @click="navigateToEvent(event)"
      >
        <div class="card-content">
          <div class="text-content">
            <h2>{{ event.name }}</h2>
            <p>{{ event.city }}</p>
            <!-- Add more text content here if needed -->
          </div>
          <div class="arrow-container">
            <ion-icon :icon="chevronForward" />
          </div>
        </div>
      </div>
     
      <!-- <ion-card class="ion-no-margin ion-no-padding" :color="event.color || 'primary'">
        <ion-card-header>
          <ion-card-title>{{ event.name }}</ion-card-title>
          <ion-card-subtitle>{{ event.city }}</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
          <ion-button
            size="small"
            color="light"
            expand="full"
            :router-link="`/${event.type}/${event.slug}/schedule`"
          >
            Go Now
          </ion-button>
        </ion-card-content>
      </ion-card> -->
    </ion-col>
  </ion-row>
  <ion-button color="tertiary" expand="full" @click="openUserEventsModal"     class="search-button">Not in this list? Search For Your League/Tournament</ion-button>
</template>
<script setup>
import { defineComponent, onMounted, ref, computed } from "vue";
import { api } from "@/utils/axios";
import {
  IonButton,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonIcon,
  // IonCard,
  // IonCardContent,
  // IonCardTitle,
  // IonCardHeader,
  // IonCardSubtitle,
  onIonViewWillEnter,
  IonSpinner
} from "@ionic/vue";
import LeagueSwitchModal from "@/components/LeagueSwitchModal.vue";
import { chevronForward } from 'ionicons/icons';
import { openModal } from "@/utils/useIonicComponents.js";
import { useRouter, useRoute } from 'vue-router';

const featuredEvents = ref([]);
const selectedDate = ref('today'); // Default to 'today'
const loading = ref(false);

const router = useRouter();
const route = useRoute();

const weekDays = computed(() => {
  const days = [];
  const today = new Date();
  for (let i = 0; i < 7; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() + i);
    days.push({
      date: date.toISOString().split('T')[0],
      dayName: date.toLocaleDateString('en-US', { weekday: 'short' }),
      dayNumber: date.getDate(),
    });
  }
  return days;
});

// Set the initial selected date to today
selectedDate.value = weekDays.value[0].date;

const navigateToEvent = (event) => {
  router.push(`/${event.type}/${event.slug}/schedule`);
};

const handleSegmentChange = (event) => {
  selectDate(event.detail.value);
};

async function openUserEventsModal() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "SwitchLeague",
    },
  });
  setTimeout(() => {
    openModal(LeagueSwitchModal, "LeagueSwitchModal");
  }, 1);
}


const grabFeaturedEvents = async () => {
  loading.value = true;
  featuredEvents.value = [];
  console.log('selectedDate.value', selectedDate.value);
  try {
    const response = await api.get(`/api/featured-events?date=${selectedDate.value}`);
    featuredEvents.value = response.data;
    console.log('grabFeaturedEvents', response.data);
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};

const selectDate = (date) => {
  selectedDate.value = date;
  grabFeaturedEvents();
};

onMounted(grabFeaturedEvents);
onIonViewWillEnter(grabFeaturedEvents);

defineComponent({
  name: "FeaturedEvents",
});
</script>

<style scoped>
.custom-card {
  
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  background-color: #7438e9;
}

.custom-card:hover {
  background: #5d25cc;
}

.card-content {
  display: flex;
  align-items: stretch;
  min-height: 100px;
}

.text-content {
  flex-grow: 1;
  padding: 12px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-content h2 {
  margin: 0 0 4px 0;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}

.text-content p {
  margin: 0;
  font-size: 14px;
  text-align: left;
}

.arrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* Fixed width */
  flex-shrink: 0; /* Prevent shrinking */
  background-color: rgba(0, 0, 0, 0.4);
}

.arrow-container ion-icon {
  font-size: 24px;
  color: white;
}

.search-button {
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 20px;
}

@media (max-width: 576px) {
  .search-button {
    font-size: 12px;
  }
}

@media (max-width: 360px) {
  .search-button {
    font-size: 12px;
  }
}
</style>