import { defineStore } from "pinia";
import { reactive, ref, computed, watch } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { useLeagueStore } from "@/stores/leagueStore";
import { useGameStore } from "@/stores/gameStore";
import { useReportGameStore } from "@/stores/reportGameStore";
import { useNewGameStore } from "@/stores/newGameStore";
// import { api } from "@/utils/axios";
// import router from "@/router/index";
// import { showToast, showLoading } from "@/utils/useIonicComponents";
export const usePreferenceStore = defineStore("preference", () => {
  const authStore = useAuthStore();
  const leagueStore = useLeagueStore();
  const gameStore = useGameStore();
  const reportGameStore = useReportGameStore();
  const newGameStore = useNewGameStore();
  const isLeagueDataLoaded = ref(false);

  watch(
    () => leagueStore.isLoading,
    (isLoading) => {
      isLeagueDataLoaded.value = !isLoading;
    }
  );

  const adminOverride = reactive(
    JSON.parse(localStorage.getItem("adminOverride")) || {}
  );
  const umpireOverride = reactive(
    JSON.parse(localStorage.getItem("umpireOverride")) || {}
  );

  const showTeamIDs = ref(
    JSON.parse(localStorage.getItem("showTeamIDs")) || false
  );

  const currentShownDates = reactive(
    // to remember the date the user was on
    JSON.parse(localStorage.getItem("currentShownDates")) || {}
  );

  const filters = reactive(JSON.parse(localStorage.getItem("filters")) || {});
  const selectedGames = reactive(
    JSON.parse(localStorage.getItem("selectedGames")) || {}
  );
  const reportGames = reactive(
    JSON.parse(localStorage.getItem("reportGames")) || {}
  );
  const newGames = reactive(JSON.parse(localStorage.getItem("newGames")) || {});
  const notificationLastOpened = reactive(
    JSON.parse(localStorage.getItem("notificationLastOpened")) || {}
  );

  const favouriteTeams = reactive(
    JSON.parse(localStorage.getItem("favouriteTeams")) || {}
  );

  const shortCodes = reactive(
    JSON.parse(localStorage.getItem("shortCodes")) || {}
  );

  const welcomeModalsDismissed = reactive(
    JSON.parse(localStorage.getItem("welcomeModalsDismissed")) || {}
  );
  //keep track of last 5 visited events by the user
  const recentEvents = reactive(
    JSON.parse(localStorage.getItem("recentEvents")) || {}
  );

  const gamesLayout = reactive(
    JSON.parse(localStorage.getItem("gamesLayout")) || {}
  );
  const standingsSegement = reactive(
    JSON.parse(localStorage.getItem("standingsSegement")) || {}
  );

  const newAnnouncement = reactive(
    JSON.parse(localStorage.getItem("newAnnouncement")) || {}
  );

  const editRules = reactive(
    JSON.parse(localStorage.getItem("editRules")) || {}
  );

  function setStandingsSegement(division) {
    let index = 0;
    if (authStore.isLoggedIn) {
      index = authStore.isLoggedIn;
    }
    if (!standingsSegement[index]) {
      standingsSegement[index] = {};
    }
    standingsSegement[index][leagueStore.league.id] = division;
    localStorage.setItem(
      "standingsSegement",
      JSON.stringify(standingsSegement)
    );
  }
  function setUserNotificationLastOpened() {
    const datetime = new Date();
    let index = 0;
    if (authStore.isLoggedIn) {
      index = authStore.isLoggedIn;
    }
    if (!notificationLastOpened[index]) {
      notificationLastOpened[index] = {};
    }
    notificationLastOpened[index][leagueStore.league.id] =
      datetime.toISOString();
    localStorage.setItem(
      "notificationLastOpened",
      JSON.stringify(notificationLastOpened)
    );
  }

  const userNotificationLastOpened = computed(() => {
    if (notificationLastOpened[authStore.isLoggedIn || 0]) {
      if (
        notificationLastOpened[authStore.isLoggedIn || 0][leagueStore.league.id]
      ) {
        return notificationLastOpened[authStore.isLoggedIn || 0][
          leagueStore.league.id
        ];
      }
    }
    return null;
  });

  const userStandingsSegement = computed(() => {
    if (standingsSegement[authStore.isLoggedIn || 0]) {
      if (standingsSegement[authStore.isLoggedIn || 0][leagueStore.league.id]) {
        return standingsSegement[authStore.isLoggedIn || 0][
          leagueStore.league.id
        ];
      }
    }
    return null;
  });

  function setGamesLayout(layout) {
    let index = 0;
    if (authStore.isLoggedIn) {
      index = authStore.isLoggedIn;
    }
    if (!gamesLayout[index]) {
      gamesLayout[index] = {};
    }
    gamesLayout[index][leagueStore.league.id] = layout;
    localStorage.setItem("gamesLayout", JSON.stringify(gamesLayout));
  }
  const getGamesLayout = computed(() => {
    if (gamesLayout[authStore.isLoggedIn || 0]) {
      if (gamesLayout[authStore.isLoggedIn || 0][leagueStore.league.id]) {
        return gamesLayout[authStore.isLoggedIn || 0][leagueStore.league.id];
      }
    }
    return "grid";
  });

  function setAdminOverride() {
    let index = 0;
    if (authStore.isLoggedIn) {
      index = authStore.isLoggedIn;
    }
    adminOverride[index] = true;
    localStorage.setItem("adminOverride", JSON.stringify(adminOverride));
  }

  const getAdminOverride = computed(() => {
    let index = 0;
    if (authStore.isLoggedIn) {
      index = authStore.isLoggedIn;
    }
    return adminOverride[index];
  });
  const getUmpireOverride = computed(() => {
    let index = 0;
    if (authStore.isLoggedIn) {
      index = authStore.isLoggedIn;
    }
    return umpireOverride[index];
  });
  function setUmpireOverride() {
    let index = 0;
    if (authStore.isLoggedIn) {
      index = authStore.isLoggedIn;
    }
    umpireOverride[index] = true;
    localStorage.setItem("umpireOverride", JSON.stringify(umpireOverride));
  }

  function setAsRecentEvents() {
    let index = authStore.isLoggedIn ? authStore.isLoggedIn : 0;
    if (!recentEvents[index]) {
      recentEvents[index] = [];
    }
    if (leagueStore.league) {
      const DuplicateIndex = recentEvents[index].findIndex(
        (event) => event.leagueId === leagueStore.league.id
      );
      if (DuplicateIndex > -1) {
        recentEvents[index].splice(DuplicateIndex, 1);
      }
      recentEvents[index].unshift({
        leagueName: leagueStore.leagueName,
        leagueSlug: leagueStore.league.slug,
        leagueId: leagueStore.league.id,
        leagueType: leagueStore.league.type,
      });
      localStorage.setItem("recentEvents", JSON.stringify(recentEvents));
    } else {
      console.error("Cannot set as recent events, league is not loaded");
    }
  }

  function setFavouriteTeams(teams) {
    let index = 0;
    if (authStore.isLoggedIn) {
      index = authStore.isLoggedIn;
    }
    if (!favouriteTeams[index]) {
      favouriteTeams[index] = {};
    }
    // Check if leagueStore.league is not null before accessing its id
    if (leagueStore.league) {
      favouriteTeams[index][leagueStore.league.id] = teams;
      localStorage.setItem("favouriteTeams", JSON.stringify(favouriteTeams));
    } else {
      // Handle the case where leagueStore.league is null
      console.error("Cannot set favourite teams, league is not loaded");
    }
  }

  function setFilters(filter) {
    let index = authStore.isLoggedIn ? authStore.isLoggedIn : 0;
    if (!filters[index]) {
      filters[index] = {};
    }
    if (leagueStore.league) {
      filters[index][leagueStore.league.id] = filter;
      localStorage.setItem("filters", JSON.stringify(filters));
    } else {
      console.error("Cannot set filters, league is not loaded");
    }
  }

  function resetShortCode() {
    let index = authStore.isLoggedIn ? authStore.isLoggedIn : 0;
    if (leagueStore.league) {
      if (shortCodes[index]) {
        shortCodes[index] = shortCodes[index].filter(
          (code) => code.leagueId !== leagueStore.league.id
        );
        localStorage.setItem("shortCodes", JSON.stringify(shortCodes));
      }
    }
  }

  function setShortCodes(shortcode) {
    let index = authStore.isLoggedIn ? authStore.isLoggedIn : 0;
    if (leagueStore.league) {
      const team = leagueStore.league.teams.find(
        (team) => team.shortcode === shortcode
      );
      const shortcodeObj = {
        name: team.name,
        shortcode,
        leagueName: leagueStore.leagueName,
        leagueSlug: leagueStore.league.slug,
        leagueId: leagueStore.league.id,
        leagueType: leagueStore.league.type,
      };
      if (!shortCodes[index]) {
        shortCodes[index] = [shortcodeObj];
      } else {
        shortCodes[index].push(shortcodeObj);
      }
      localStorage.setItem("shortCodes", JSON.stringify(shortCodes));
    } else {
      console.error("Cannot set short codes, league is not loaded");
    }
  }

  function setCurrentShownDates(shownDate) {
    let index = authStore.isLoggedIn ? authStore.isLoggedIn : 0;
    if (!currentShownDates[index]) {
      currentShownDates[index] = {};
    }
    if (leagueStore.league) {
      currentShownDates[index][leagueStore.league.id] = shownDate;
      localStorage.setItem(
        "currentShownDates",
        JSON.stringify(currentShownDates)
      );
    } else {
      console.error("Cannot set current shown dates, league is not loaded");
    }
  }

  const getCurrentShownDates = computed(() => {
    let index = 0;
    if (authStore.isLoggedIn) {
      index = authStore.isLoggedIn;
    }

    if (!isLeagueDataLoaded.value) {
      console.warn(
        "League data is not loaded yet. Cannot set as recent events."
      );
      return;
    }

    if (currentShownDates[index]) {
      return currentShownDates[index][leagueStore.league.id]
        ? currentShownDates[index][leagueStore.league.id]
        : null;
    }
    return null;
  });

  function setWelcomeModalsDismissed() {
    let index = 0;
    if (authStore.isLoggedIn) {
      index = authStore.isLoggedIn;
    }
    if (!welcomeModalsDismissed[index]) {
      welcomeModalsDismissed[index] = [];
      welcomeModalsDismissed[index] = [leagueStore.league.id];
    } else {
      welcomeModalsDismissed[index].push(leagueStore.league.id);
    }
    localStorage.setItem(
      "welcomeModalsDismissed",
      JSON.stringify(welcomeModalsDismissed)
    );
  }

  const userFavouriteTeams = computed(() => {
    const index = authStore.isLoggedIn || 0;
    if (
      leagueStore.league &&
      favouriteTeams[index] &&
      favouriteTeams[index][leagueStore.league.id]
    ) {
      return favouriteTeams[index][leagueStore.league.id];
    } else {
      return [];
    }
  });

  const userFilter = computed(() => {
    const index = authStore.isLoggedIn || 0;
    if (leagueStore.league && filters[index]) {
      return filters[index][leagueStore.league.id] || [];
    }
    return [];
  });

  const userShortCodes = computed(() => {
    if (shortCodes[authStore.isLoggedIn || 0]) {
      return shortCodes[authStore.isLoggedIn || 0];
    } else {
      return [];
    }
  });

  const userRecentEvents = computed(() => {
    if (recentEvents[authStore.isLoggedIn || 0]) {
      return recentEvents[authStore.isLoggedIn || 0];
    } else {
      return [];
    }
  });

  //Function to find the shortcode given league and team name
  function getShortcode(homeTeam, awayTeam, leagueId) {
    return userShortCodes.value.filter(
      (shortcode) =>
        (shortcode.name === homeTeam || shortcode.name === awayTeam) &&
        shortcode.leagueId === leagueId
    );
  }

  const userWelcomeModalDismissed = computed(() => {
    if (welcomeModalsDismissed[authStore.isLoggedIn || 0]) {
      return welcomeModalsDismissed[authStore.isLoggedIn || 0];
    } else {
      return [];
    }
  });

  function setUserSelectedGames() {
    const index = authStore.isLoggedIn;
    if (!selectedGames[index]) {
      selectedGames[index] = {};
    }
    // Check if leagueStore.league is not null before accessing its id
    if (leagueStore.league) {
      selectedGames[index][leagueStore.league.id] = gameStore.selectedGames;
      localStorage.setItem("selectedGames", JSON.stringify(selectedGames));
    } else {
      // Handle the case where leagueStore.league is null
      console.error("Cannot set selected games, league is not loaded");
    }
  }

  const getUserSelectedGames = computed(() => {
    const index = authStore.isLoggedIn || 0;
    if (
      leagueStore.league &&
      selectedGames[index] &&
      selectedGames[index][leagueStore.league.id]
    ) {
      return selectedGames[index][leagueStore.league.id];
    }
    return [];
  });

  function setUserReportGames() {
    let index = 0;
    if (authStore.isLoggedIn) {
      index = authStore.isLoggedIn;
    }
    if (!reportGames[index]) {
      reportGames[index] = {};
    }
    reportGames[index][leagueStore.league.id] = reportGameStore.games;
    localStorage.setItem("reportGames", JSON.stringify(reportGames));
  }

  const getUserReportGames = computed(() => {
    if (reportGames[authStore.isLoggedIn || 0]) {
      if (reportGames[authStore.isLoggedIn || 0][leagueStore.league.id]) {
        return reportGames[authStore.isLoggedIn || 0][leagueStore.league.id];
      }
    }
    return [];
  });

  function setUserNewGames() {
    let index = authStore.isLoggedIn;
    if (!newGames[index]) {
      newGames[index] = {};
    }
    newGames[index][leagueStore.league.id] = [...newGameStore.games];
    localStorage.setItem("newGames", JSON.stringify(newGames));
  }

  const getUserNewGames = computed(() => {
    if (newGames[authStore.isLoggedIn]) {
      if (newGames[authStore.isLoggedIn][leagueStore.league.id]) {
        return newGames[authStore.isLoggedIn][leagueStore.league.id];
      }
    }
    return [];
  });

  function setNewAnnouncement(announcement) {
    let index = authStore.isLoggedIn;
    if (!newAnnouncement[index]) {
      newAnnouncement[index] = {};
    }
    newAnnouncement[index][leagueStore.league.id] = announcement;
    localStorage.setItem("newAnnouncement", JSON.stringify(newAnnouncement));
  }

  const getUserAnnouncement = computed(() => {
    if (newAnnouncement[authStore.isLoggedIn]) {
      if (newAnnouncement[authStore.isLoggedIn][leagueStore.league.id]) {
        return newAnnouncement[authStore.isLoggedIn][leagueStore.league.id];
      }
    }
    return {};
  });

  function setEditRules(rules) {
    let index = authStore.isLoggedIn;
    if (!editRules[index]) {
      editRules[index] = {};
    }
    editRules[index][leagueStore.league.id] = rules;
    localStorage.setItem("editRules", JSON.stringify(editRules));
  }
  const getUserEditRules = computed(() => {
    if (editRules[authStore.isLoggedIn]) {
      if (editRules[authStore.isLoggedIn][leagueStore.league.id]) {
        return editRules[authStore.isLoggedIn][leagueStore.league.id];
      }
    }
    return {};
  });

  function toggleShowTeamIDs() {
    console.log("trigger");
    if (authStore.isAdmin) {
      showTeamIDs.value = !showTeamIDs.value;
      localStorage.setItem("showTeamIDs", JSON.stringify(showTeamIDs.value));
    }
  }

  return {
    currentShownDates,
    recentEvents,
    favouriteTeams,
    shortCodes,
    adminOverride,
    umpireOverride,
    userFavouriteTeams,
    userShortCodes,
    welcomeModalsDismissed,
    userWelcomeModalDismissed,
    getCurrentShownDates,
    userRecentEvents,
    getAdminOverride,
    getUmpireOverride,
    filters,
    userFilter,
    getGamesLayout,
    userStandingsSegement,
    userNotificationLastOpened,
    getUserSelectedGames,
    getUserReportGames,
    getUserNewGames,
    getUserAnnouncement,
    getUserEditRules,
    showTeamIDs,
    setAdminOverride,
    setUmpireOverride,
    setFavouriteTeams,
    setShortCodes,
    setWelcomeModalsDismissed,
    getShortcode,
    setCurrentShownDates,
    setAsRecentEvents,
    setFilters,
    setGamesLayout,
    setStandingsSegement,
    setUserNotificationLastOpened,
    setUserSelectedGames,
    setUserReportGames,
    setUserNewGames,
    setNewAnnouncement,
    setEditRules,
    toggleShowTeamIDs,
    resetShortCode,
  };
});
