<template>
  <base-modal modal-title="Add Games">
    <template #headerStart>
      <ion-button @click="cancel"> Cancel </ion-button>
    </template>
    <!-- <template #headerEnd>
      <ion-button @click="addNewGames"
        ><ion-icon :icon="addCircleOutline"></ion-icon> Game
      </ion-button>
    </template> -->

    <add-new-game :invalid-row-indices="invalidRowIndices" />
    <!-- <add-new-game
      v-if="showDesktopMode"
      :invalid-row-indices="invalidRowIndices"
    /> -->
    <!-- <add-new-game-mobile v-else /> -->

    <template #footer>
      <ion-button expand="full" @click="saveGames">Save</ion-button>
    </template>
  </base-modal>
</template>

<script setup>
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import { IonButton, modalController } from "@ionic/vue";
import BaseModal from "@/components/layouts/BaseModal.vue";
// import AddNewGameMobile from "@/components/schedule/new-game/mobile-view/AddNewGameMobile.vue";
import AddNewGame from "@/components/schedule/new-game/desktop-view/AddNewGame.vue";
import { required, sameAs, not } from "@vuelidate/validators";
import { useNewGameStore } from "@/stores/newGameStore";
import useVuelidate from "@vuelidate/core";
import { showToast } from "@/utils/useIonicComponents";
import { debounce } from "lodash";
import { useLeagueStore } from "@/stores/leagueStore";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { useRoute, useRouter } from "vue-router";
const invalidRowIndices = ref([]);
defineComponent({
  name: "AddGameModal",
});
const newGameStore = useNewGameStore();
const leagueStore = useLeagueStore();
const preferenceStore = usePreferenceStore();

const saveInterval = ref();

const router = useRouter();
const route = useRoute();
router.replace({
  query: {
    ...route.query,
    open_modal: "NewGames",
  },
});

// const selectedSegment = ref('all');

onMounted(async () => {
  if (leagueStore.divisions.length === 0) {
    await showToast(
      "No teams found! Please add teams before adding games or Import Games.",
      "danger",
      6000
    );
    setTimeout(() => {
      cancel();
    }, 1);
  } else {
    newGameStore.games = preferenceStore.getUserNewGames;
    if (newGameStore.games.length === 0) {
      newGameStore.addNewGame();
    }
  }
  saveInterval.value = setInterval(persistGames, 2500);
});
const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);

// function segmentChanged(event) {
//   selectedSegment.value = event.detail.value;
// }

// const v$ = useVuelidate();
// async function addNewGames() {
// const isFormValid = await v$.value.$validate();
// if (isFormValid) {
// newGameStore.addNewGame();
// } else {
//   showToast("Valid data in all the fields required", "danger", 6000);
// }
// }

function persistGames() {
  // console.log(newGameStore)
  // console.log("Auto save data");
  preferenceStore.setUserNewGames();
}

function getValidationRules(game) {
  return {
    start_date: { required },
    start_time: { required },
    end_time: { required },
    park_name: { required },
    // diamond_signifier: { required },
    division_id: { required },
    select_away_team: {
      required,
      sameAsHome: not(sameAs(() => game.select_home_team)),
    },
    select_home_team: {
      required,
      sameAsAway: not(sameAs(() => game.select_away_team)),
    },
    mode: { required },
  };
}

async function saveGames() {
  let allValid = true;
  invalidRowIndices.value = []; // Reset the array before validation

  for (let i = 0; i < newGameStore.games.length; i++) {
    const game = newGameStore.games[i];
    const rules = getValidationRules(game);
    const v$ = useVuelidate(rules, game);
    // console.log("rules", rules);
    // console.log("game", game);
    await v$.value.$validate();
    // show me what rules failed for each row
    // console.log("v$", v$.value.$errors);
    if (v$.value.$errors.length > 0) {
      allValid = false;
      invalidRowIndices.value.push(i); // Store the index of the invalid row
    }
  }
  // console.log("allValid", allValid);
  if (!allValid) {
    // Handle invalid rows
    const invalidRowsMessage = invalidRowIndices.value
      .map((index) => index + 1)
      .join(", ");
    // console.log(invalidRowsMessage);
    showToast(`Invalid data in rows: ${invalidRowsMessage}`, "danger", 6000);
  }
  // Proceed with the rest of the save logic...

  let result = await newGameStore.saveGames();
  // console.log("result", result);
  if (result.error) {
    //
  } else {
    await modalController.dismiss();
    newGameStore.games.length = 0;
    preferenceStore.setUserNewGames();
  }
}

// async function saveGames() {
//   const isFormValid = await v$.value.$validate();
//   if (isFormValid) {
//     await newGameStore.saveGames();
//     await modalController.dismiss();
//     newGameStore.games.length = 0;
//     preferenceStore.setUserNewGames();
//   } else {
//     showToast("Valid data in all the fields required", "danger", 6000);
//   }
// }

async function cancel() {
  clearInterval(saveInterval.value);
  persistGames();
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss();
}

window.addEventListener("resize", debounce(resizeHandler, 1000));

function resizeHandler() {
  if (window.innerWidth >= 960) {
    showDesktopMode.value = true;
  } else {
    showDesktopMode.value = false;
  }
}
onBeforeUnmount(() => {
  window.removeEventListener("resize", resizeHandler);
});
</script>
