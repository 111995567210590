import { parse, isValid, format } from "date-fns";

const DATE_FORMATS = [
  // when figuring out date the formats below will be matched in order
  "yyyy/MM/dd",
  "yyyy-MM-dd",
  "yyyy.MM.dd",
  "dd/MM/yyyy",
  "dd-MM-yyyy",
  "dd.MM.yyyy",
  "MM/dd/yyyy",
  "MM-dd-yyyy",
  "MM.dd.yyyy",
  "yyyy/dd/MM",
  "yyyy-dd-MM",
  "yyyy.dd.MM",
  "yyyyMMdd", // Compact format
  "ddMMyyyy", // Compact format
  "MMddyyyy", // Compact format
  "yyyyddMM", // Compact format
  "yy/MM/dd", // Year in two digits
  "yy-MM-dd",
  "yy.MM.dd",
  "dd/MM/yy",
  "dd-MM-yy",
  "dd.MM.yy",
  "MM/dd/yy",
  "MM-dd-yy",
  "MM.dd.yy",
  "yy/dd/MM",
  "yy-dd-MM",
  "yy.dd.MM",
  "dd/yyyy/MM",
  "dd-yyyy-MM",
  "dd.yyyy.MM",
  "MM/yyyy/dd",
  "MM-yyyy-dd",
  "MM.yyyy.dd",
];

const TIME_FORMATS = [
  // when figuring out time the formats below will be matched in order
  "HH:mm:ss", // 24-hour with seconds
  "HH:mm", // 24-hour without seconds
  "HH.mm", // 24-hour with dot separator
  "HH mm", // 24-hour with space separator
  "h:mm:ss a", // 12-hour with seconds and am/pm
  "h:mm a", // 12-hour without seconds and am/pm
  "h.mm a", // 12-hour with dot separator and am/pm
  "h mm a", // 12-hour with space separator and am/pm
  "HHmmss", // 24-hour compact
  "HHmm", // 24-hour compact without seconds
  "hmmss a", // 12-hour compact with seconds and am/pm
  "hmm a", // 12-hour compact without seconds and am/pm
];

function extractDateTimeFormat(dateTimeFormats, dateTime) {
  const dateTimeMap = dateTimeFormats.map((t) =>
    parse(dateTime, t, new Date())
  );

  const valid = dateTimeMap.find((t) => isValid(t));

  if (valid) {
    return dateTimeMap.indexOf(valid);
  }

  return null;
}

export function parseDate(date) {
  console.log(extractDateTimeFormat(DATE_FORMATS, date));
  const date_format = DATE_FORMATS[extractDateTimeFormat(DATE_FORMATS, date)];
  if (date_format) {
    return format(parse(date, date_format, new Date()), "yyyy-MM-dd");
  } else {
    return null;
  }
}

export function parseTime(time) {
  const time_format = TIME_FORMATS[extractDateTimeFormat(TIME_FORMATS, time)];
  if (time_format) {
    return format(parse(time, time_format, new Date()), "HH:mm:ss");
  } else {
    return null;
  }
}
