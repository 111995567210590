<template>
    <page-wrapper>
        <div style="margin-top: 50px;">
            <img :src="leagueStore.league.logo_url || 'https://cdn.blacktiecollab.com/slopitchcentral/images/slopitchcentral.png'" style="max-width: 300px;
            width: 100%; display: block; margin: 0 auto;" alt="">
        </div>
        <div v-if="selectedPageRef === null">
            <h1 style="color: var(--ion-color-dark); text-align: center; text-transform: uppercase;">
                <span
                    style="background-color: var(--ion-color-primary); padding: 0 10px"
                >
                    Register for {{ leagueStore.league.name }}
                </span>
            </h1>
            <ion-row>
                <ion-col v-if="leagueStore.league.team_registration_price">
                    <ion-card style="text-align: center;">
                        <ion-card-header>
                            <ion-card-title>Register Your Team!</ion-card-title>
                            <ion-card-subtitle>Registration is simple and only takes a few minutes!</ion-card-subtitle>
                        </ion-card-header>
                        <ion-card-content>
                            <ion-button @click="selectedPageRef = 'team'">Register Your Team</ion-button>
                        </ion-card-content>
                    </ion-card>
                </ion-col>
                <ion-col v-if="leagueStore.league.individual_registration_price">
                    <ion-card style="text-align: center;">
                        <ion-card-header>
                            <ion-card-title>Register Yourself!</ion-card-title>
                            <ion-card-subtitle>Registration is simple and only takes a few minutes!</ion-card-subtitle>
                        </ion-card-header>
                        <ion-card-content>
                            <ion-button @click="selectedPageRef = 'yourself'">Register Yourself</ion-button>
                        </ion-card-content>
                    </ion-card>
                </ion-col>
            </ion-row>
        </div>
        <div style="max-width: 400px; width: 100%; margin: 0 auto; margin-top: 40px;">
            <div v-if="selectedPageRef === 'team'">
                <h3 style="color: var(--ion-color-dark); text-align: center; text-transform: uppercase;"  v-if="leagueStore.league.team_registration_choose_team">
                    <span
                        style="background-color: var(--ion-color-primary); padding: 0 10px"
                    >
                        Select Existing Team
                    </span>
                </h3>
                <ion-item v-if="leagueStore.league.team_registration_choose_team">
                    <ion-label>Select team from list</ion-label>
                    <ion-select v-model="selectedExistingTeam">
                        <ion-select-option :value="team" v-for="(team, key) of leagueStore.league.teams" :key="key">
                            {{ team.name }}
                        </ion-select-option>
                    </ion-select>
                </ion-item>
                <h3 style="color: var(--ion-color-dark); text-align: center; text-transform: uppercase;">
                    <span
                        style="background-color: var(--ion-color-primary); padding: 0 10px"
                    >
                        {{ leagueStore.league.team_registration_choose_team ? 'or' : '' }} Create New Team
                    </span>
                </h3>
                <ion-item>
                    <ion-label position="stacked">Team Name</ion-label>  
                    <ion-input v-model="createdTeamName"></ion-input>
                </ion-item>
            </div>
            <div v-if="selectedPageRef === 'yourself'">
                
            </div>
            <div v-if="selectedPageRef !== null">
                <h3 style="color: var(--ion-color-dark); text-align: center; text-transform: uppercase;">
                    <span
                        style="background-color: var(--ion-color-primary); padding: 0 10px"
                    >
                        Your Personal Details
                    </span>
                </h3>
                <ion-item>
                    <ion-label position="stacked">Your Full Name</ion-label>
                    <ion-input v-model="personalDetails.name"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">Your Email</ion-label>
                    <ion-input type="email" v-model="personalDetails.email"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">Your Phone</ion-label>
                    <ion-input type="tel" v-model="personalDetails.phone"></ion-input>
                </ion-item>
                <h3 style="color: var(--ion-color-dark); text-align: center; text-transform: uppercase;">
                    <span
                        style="background-color: var(--ion-color-primary); padding: 0 10px"
                    >
                        Payment Details
                    </span>
                </h3>
                <ion-item>
                    <ion-label position="stacked">Select Payment Method</ion-label>
                    <ion-select v-model="paymentMethod">
                        <ion-select-option value="credit_card" v-if="leagueStore.league.stripe_account_confirmed === 1">Credit Card</ion-select-option>
                        <ion-select-option value="emt" v-if="leagueStore.league.emt_email">Email Money Transfer</ion-select-option>
                        <ion-select-option value="cash" v-if="leagueStore.league.accepts_cash_day_of">Cash Day Of</ion-select-option>
                    </ion-select>
                </ion-item>
                <div v-if="paymentMethod === 'credit_card'">
                    <ion-item>
                        <ion-label>Credit Card</ion-label>
                        <ion-input v-model="creditCardDetails"></ion-input>
                    </ion-item>
                </div>
            </div>
        </div>
    </page-wrapper>
</template>

<script setup>
    import PageWrapper from '@/components/layouts/PageWrapper.vue';
    import { IonRow, IonCol, IonCard, IonCardContent, IonSelect, IonSelectOption, IonItem, IonInput, IonLabel, IonCardTitle, IonCardHeader, IonButton, IonCardSubtitle } from '@ionic/vue';
    import { ref } from 'vue';
    import { useLeagueStore } from '@/stores/leagueStore';

    const leagueStore = useLeagueStore();

    const selectedPageRef = ref(null);

    const selectedExistingTeam = ref(null)

    const createdTeamName = ref(null)

    const personalDetails = ref({
        name: '',
        email: '',
        phone: ''
    })

    const paymentMethod = ref(null)

    const creditCardDetails = ref({
        card: '',
        expiry: '',
        cvv: ''
    })
</script>
