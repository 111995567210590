<template>
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <ion-title>Manage Sponsors</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="emitClose">
              <ion-icon :icon="closeOutline" />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-segment mode="md" v-model="segment">
          <ion-segment-button value="add">
            <ion-label>Add</ion-label>
          </ion-segment-button>
          <ion-segment-button value="edit">
            <ion-label>Edit</ion-label>
          </ion-segment-button>
        </ion-segment>
        <div class="ion-padding">
          <div v-if="segment === 'add'">
            <div v-if="sponsorImageThumbnail">
                <img :src="sponsorImageThumbnail" />
                <ion-button expand="full" color="danger" @click="resetSponsorImage">Remove</ion-button>
            </div>
            <div v-else
                class="image-upload-container"
                @drop.prevent="onFilePicked($event, 'sponsorImage')"
                @dragover.prevent>
                <ion-item lines="none">
                    <ion-label>Drag & Drop here or</ion-label>
                    <input type="file" @change="onFilePicked($event, 'sponsorImage')" accept="image/*" />
                </ion-item>
            </div>
            <ion-item>
                <ion-label position="stacked">Title</ion-label>
                <ion-input v-model="linkTitle" placeholder="Enter title"></ion-input>
            </ion-item>
       
            
            <ion-item>
              <ion-label position="stacked">URL</ion-label>
              <ion-input v-model="linkUrl" type="url" placeholder="Enter URL"></ion-input>
            </ion-item>
            <ion-button expand="block" @click="submitLink">Save</ion-button>
            <br>
            <br>
            <br>
            <br>
            <ion-button expand="block" color="light" @click="clearForm">Clear</ion-button>
          </div>
          <div v-if="segment === 'edit'">
            <ion-list>
              <ion-item v-for="linkItem in linkItems" :key="linkItem.id" class="ion-align-items-center">
                <ion-label>
                  <h2>{{ linkItem.sponsor_name }}</h2>
                  <p>{{ linkItem.sponsor_link }}</p>
                </ion-label>
                <ion-button color="danger" @click="deleteLink(linkItem.id)">Delete</ion-button>
              </ion-item>
            </ion-list>
          </div>
        </div>
      </ion-content>
    </ion-page>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonSegment, IonSegmentButton, IonLabel, IonList, IonItem, IonInput } from '@ionic/vue';
  import { closeOutline } from 'ionicons/icons';
  import { defineEmits } from 'vue';
  import { api } from "@/utils/axios";
  import { useLeagueStore } from "@/stores/leagueStore";
  
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  
  const leagueStore = useLeagueStore();

  const segment = ref('add');
  const linkItems = ref([]);
  
  const linkType = ref(null);
  const linkUrl = ref('');
  const pageContent = ref('');
  const isEditMode = ref(false);
  const selectedLinkItem = ref(null);
  const sponsorImageThumbnail = ref(null);

  const linkTitle = ref('');
  
//   const editorOptions = {
//     // ... your editor options ...
//   };
  
  const emit = defineEmits(['close']);
  
  const emitClose = () => {
    emit('close');
  };

  const onFilePicked = async (event) => {
        const files = event.target.files || event.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0];
            if (file.type.startsWith('image/')) {
                try {
                    const uploadedImageUrl = await uploadFile(file, 'sponsorImage');
                    sponsorImageThumbnail.value = uploadedImageUrl;
                } catch (error) {
                    console.error('Error uploading image:', error);
                }
            }
        }
    }

    async function uploadFile(file, type) {
        
        const access_key = "6720c8ce-1e73-4f38-be0c52815ece-593f-4260";
        const base_url = "https://la.storage.bunnycdn.com";
        const storageName = "blacktiecollab-la";
        const path = "/slopitchcentral/sponsors";
        const file_name = `${leagueStore.league.id}-${type}-${file.name}`;
        const cdn_url = "cdn.blacktiecollab.com";
        
        const response = await fetch(`${base_url}/${storageName}${path}/${file_name}`, {
            method: "PUT",
            headers: {
            AccessKey: `${access_key}`,
            },
            body: file,
        });

        if (!response.ok) {
            throw new Error('Failed to upload image');
        }

        return `https://${cdn_url}${path}/${file_name}`;
    }

    const resetSponsorImage = () => {
        sponsorImageThumbnail.value = null;
    };

  const submitLink = async () => {
        try {
            // let uploadedImageUrl = sponsorImageThumbnail.value;

            // try {
            //     uploadedImageUrl = await uploadFile(sponsorImageThumbnail.value, 'sponsorImage');
            // } catch (error) {
            //     console.error('Error uploading image:', error);
            //     return; // Stop the submission if the image upload fails
            // }

            const payload = {
                name: linkTitle.value,
                type: linkType.value,
                url: linkUrl.value,
                image: sponsorImageThumbnail.value
            };

            const response = await api.post(`/players/${leagueStore.league.slug}/save-sponsors`, payload);
            console.log(response);
            clearForm();
            emitClose();
        } catch (error) {
            console.error('Error submitting link:', error);
        }
    };

    const clearForm = () => {
        linkTitle.value = '';
        linkType.value = null;
        linkUrl.value = '';
        pageContent.value = '';
        isEditMode.value = false;
        selectedLinkItem.value = null;
    };

const deleteLink = async (linkId) => {
  try {
    await api.post(`/players/${leagueStore.league.slug}/delete-sponsor`, {
        id: linkId
    });
    // Refresh the list of links after deletion
    fetchLinks();
  } catch (error) {
    console.error('Error deleting link:', error);
  }
};

const fetchLinks = async () => {
  try {
    const response = await api.get(`/players/${leagueStore.league.slug}/sponsors`);
    linkItems.value = response.data;
  } catch (error) {
    console.error('Error fetching links:', error);
  }
};

onMounted(() => {
  fetchLinks();
});
  
</script>