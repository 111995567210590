<template>
    <div style="margin-top: 2rem;">

        <ion-grid>
            <ion-row>
                <ion-col>
                    
                    <ion-button @click="openModal([computedDatesArray[0]])">
                        <ion-icon :icon="addOutline"></ion-icon>
                        Add Park Booking
                    </ion-button>

                    
                    <ion-button :color="deleteMode ? 'danger' : 'dark'" @click="deleteMode = !deleteMode">
                        <ion-icon :icon="trashOutline"></ion-icon>
                        Delete Mode {{ deleteMode ? 'On' : 'Off' }}
                    </ion-button>
                    
                </ion-col>
            </ion-row>
        </ion-grid>
        <ion-grid>
            <ion-row>
                <ion-col class="ion-col" v-for="day of daysOfWeek" :key="day.value">
                    <div class="day-label" v-on:click="deleteMode ? null : openModal(datesByDayOfWeek[day.value])">
                        {{  day.label }}
                    </div>
                    
                    <div 
                        v-for="date in datesByDayOfWeek[day.value]" 
                        :key="date" 
                        class="date-label"
                        :style="{ borderLeft: `4px solid ${datesWithColors[date]}`}"
                        v-on:click="deleteMode ? clearBookings([date]) : openModal([date])"
                    >
                        <div class="month-word">
                            {{  datesWithMonthNames[date] }} {{ datesWithDayNumber[date] }} {{ bookingsData[date] && bookingsData[date].length ? ' - ' + bookingsData[date].length + ' Diamonds' : '' }}
                        </div>
                        
                        <div v-for="booking in bookingsData[date]" :key="booking.startTime" class="booking-details">
                            <div class="booking-park"> 
                                {{ booking.park_name }} - {{ booking.diamond_name }}    
                            </div>
                            <div class="booking-time">
                                {{ booking.startTime }} - {{ booking.endTime }}
                            </div> 
                        </div>
                    </div>
               
                </ion-col>
            </ion-row>
          
        </ion-grid>    

        
        
        <ion-modal :is-open="showModal" @didDismiss="showModal = false">
            <ion-header>
                <ion-toolbar>
                    
                    <ion-title>New Booking</ion-title>
                    <ion-buttons slot="end">
                        <ion-button color="dark" @click="showModal = false">
                            <ion-icon :icon="closeOutline"></ion-icon>
                        </ion-button>
                    </ion-buttons>
                </ion-toolbar>
            </ion-header> 
            <ion-content :fullscreen="true">
                <div class="modal-content">
                    <ion-row>
                        <ion-col>
                            <ion-row>
                                <ion-col size="7">
                                    <!-- select the park or parks -->
                                    <ion-row>
                                        <ion-col>
                                            <ion-item :color="bookingDetails.parks.length === 0 ? 'danger' : ''">
                                                <ion-label>
                                                    Select Park(s) to Apply Bookings To
                                                </ion-label>
                                                <ion-select 
                                                    :multiple="true" 
                                                    v-model="bookingDetails.parks"
                                                    :compareWith="compareWith">
                                                    <ion-select-option 
                                                        v-for="(diamond, key) of computedParkDiamondCombos" 
                                                        :key="key"
                                                        :value="diamond">
                                                        {{ diamond.park_diamond_name }}
                                                    </ion-select-option>
                                                </ion-select>
                                            </ion-item>
                                            
                                        </ion-col>
                                    </ion-row>
                                    <ion-item>
                                        <ion-label position="stacked">
                                            Select Date(s)
                                        </ion-label>
                                        <!-- <ion-datetime-button datetime="datesSelected"></ion-datetime-button> -->
                                        <ion-datetime 
                                            :value="bookingDetails.dates" 
                                            id="datesSelected" 
                                            @ionChange="updateBookingDates" 
                                            :multiple="true" 
                                            :prefer-wheel="false" 
                                            presentation="date"
                                            :min="minDate"
                                            :max="maxDate"
                                            :key="datesKey">
                                        </ion-datetime>
                                        <!-- <ion-datetime presentation="date" prefer-wheel="false"></ion-datetime> -->
                                    </ion-item>
                                    
                                    <!-- time columns -->
                                    <ion-row>
                                        <ion-col>
                                            <ion-item>
                                                <ion-label position="stacked">
                                                    Start Time
                                                </ion-label>
                                                <ion-datetime-button datetime="startTime"></ion-datetime-button>
                                            </ion-item>
                                        </ion-col>
                                        <ion-col>
                                            <ion-item>
                                                <ion-label  position="stacked">
                                                    End Time
                                                </ion-label>
                                                <ion-datetime-button datetime="endTime"></ion-datetime-button>
                                            </ion-item>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            <ion-item>
                                                <ion-label position="stacked">Game Length</ion-label>
                                                <ion-input v-model="bookingDetails.gameLength" type="number"></ion-input>
                                            </ion-item>
                                        </ion-col>
                                        <ion-col>
                                            <ion-item>
                                                <ion-label position="stacked">Break Between Games</ion-label>
                                                <ion-input v-model="bookingDetails.breakBetweenGames" type="number"></ion-input>
                                            </ion-item>
                                        </ion-col>
                                    </ion-row>
                                    <ion-note>
                                        Based on the start and end time, you can have {{ gameInfo.gameSlots }} game slots with {{ gameInfo.leftoverTime }} minutes left over.
                                    </ion-note>
                                    
                                    <!-- need to display a warning for each holiday marked -->
                                    <div>
                                        <ion-note color="danger" v-if="sortedDates.some(isHoliday)">
                                            Warning: Some dates you've selected are holidays. They are highlighted in red.
                                        </ion-note>
                                    </div>
                                    <ion-button v-on:click="saveBooking()">
                                        Save Booking
                                    </ion-button>
                                    
                                    
                                </ion-col>
                                <ion-col size="5">
                                    <span v-if="bookingDetails.parks.length > 0">
                                        <ion-item>
                                            <ion-label>Parks Selected</ion-label>
                                        </ion-item>
                                        <ion-chip v-for="(park, index) in bookingDetails.parks" :key="index">
                                            {{ park.park_diamond_name }}
                                            <ion-icon :icon="closeOutline" @click="removePark(index)"></ion-icon>
                                        </ion-chip>
                                    </span>
                                    <ion-item>
                                        <ion-label>Dates Selected</ion-label>
                                    </ion-item>
                                    <ion-chip v-for="(date, index) in sortedDates" :color="isHoliday(date) ? 'danger' : ''" :key="date" @click="removeDate(index)">
                                        <ion-icon :icon="calendarOutline"></ion-icon>
                                        <ion-label>{{ formatDate(date) }}</ion-label>
                                        <ion-icon :icon="closeOutline"></ion-icon>
                                    </ion-chip>
                                    <!-- <ion-button size="small" v-on:click="clearBookings()" color="danger" style="margin-top: 30px;">
                                        Clear Previous Bookings on Dates Selected
                                    </ion-button> -->
                                </ion-col>
                            </ion-row>
                        </ion-col>
                    </ion-row>
                </div>
                

               


                
            </ion-content> 
            <ion-modal :keep-contents-mounted="true">
                <ion-datetime :value="bookingDetails.startTime" id="startTime" @ionChange="updateStartTime" presentation="time"></ion-datetime>
            </ion-modal>
            <ion-modal :keep-contents-mounted="true">
                <ion-datetime :value="bookingDetails.endTime" id="endTime" @ionChange="updateEndTime" presentation="time"></ion-datetime>
            </ion-modal>
        </ion-modal>
    </div>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonModal, IonButton, IonIcon, IonHeader, IonToolbar, IonTitle, IonButtons, IonContent, IonItem, IonLabel, IonDatetime, IonDatetimeButton, IonNote, IonChip, IonInput, IonSelect, IonSelectOption } from '@ionic/vue';
import { addOutline, closeOutline, calendarOutline, trashOutline } from 'ionicons/icons';
import Holidays from 'date-holidays';
import { showToast } from "@/utils/useIonicComponents.js";
export default {
    data() {
        return {
            addOutline,
            closeOutline,
            calendarOutline,
            trashOutline,
            datesKey: 0,
            deleteMode: false,
            showModal: false,
            bookingDetails: {
                dates: [],
                parks: [],
                startTime: null,
                endTime: null,
                gameLength: null,
                breakBetweenGames: null
            } // you can store in here first, and then apply it to the parent when done
        }
    },
    components: {
        IonGrid, 
        IonRow,
        IonCol,
        IonModal,
        IonButton,
        IonIcon,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButtons,
        IonContent,
        IonItem,
        IonLabel,
        IonDatetime,
        IonDatetimeButton,
        IonNote,
        IonChip,
        IonInput,
        IonSelect,
        IonSelectOption
    },
    props: {
        daysOfWeek: Array,
        parksData: Object,
        scheduleData: Object,
        bookingsData: Object
    },
    computed: {
        hd() {
            let country = '';
            if (this.scheduleData.country === 'Canada') {
                country = 'CA';
            } else if (this.scheduleData.country === 'United States') {
                country = 'US';
            }
            return new Holidays(country);
        },
        holidays() {
            return this.hd.getHolidays(new Date().getFullYear()).map(holiday => holiday.date);
        },
        minDate() {
            if (!this.scheduleData || !this.scheduleData.startMonth) {
                return null;
            }
            let [startYear, startMonth] = this.scheduleData.startMonth.split('T')[0].split('-');
            let start = new Date(startYear, startMonth - 1, 1); // Set day to 1
            // Adjust start date to the first Monday before or on startMonth
            start.setDate(start.getDate() - ((start.getDay() + 6) % 7));
            return start.toISOString().split('T')[0];
        },
        maxDate() {
            if (!this.scheduleData || !this.scheduleData.endMonth) {
                return null;
            }
            let [endYear, endMonth] = this.scheduleData.endMonth.split('T')[0].split('-');
            let end = new Date(endYear, endMonth, 0); // Set day to 0 to get the last day of the previous month
            // Adjust end date to the last Sunday after or on endMonth
            end.setDate(end.getDate() + (7 - end.getDay()) % 7);
            return end.toISOString().split('T')[0];
        },
        computedDatesArray() {
            if (!this.scheduleData || !this.scheduleData.startMonth || !this.scheduleData.endMonth) {
                return [];
            }

            let [startYear, startMonth] = this.scheduleData.startMonth.split('T')[0].split('-');
            let start = new Date(startYear, startMonth - 1); // Months are 0-indexed in JavaScript

            let [endYear, endMonth] = this.scheduleData.endMonth.split('T')[0].split('-');
            let end = new Date(endYear, endMonth - 1); // Months are 0-indexed in JavaScript

            // Adjust start date to the first Monday before or on startMonth
            start.setDate(start.getDate() - ((start.getDay() + 6) % 7));

            // Adjust end date to the last Sunday after or on endMonth
            end.setDate(end.getDate() + (7 - end.getDay()) % 7);

            let datesArray = [];
            while(start <= end) {
                datesArray.push(new Date(start).toISOString().split('T')[0]);
                start.setDate(start.getDate() + 1);
            }


            return datesArray;
        },
        datesByDayOfWeek() {
            const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            const reduce =  this.daysOfWeek.reduce((acc, day) => {
                acc[day.value] = this.computedDatesArray.map(date => {
                    const utcDate = new Date(date);
                    const localDate = new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate());
                    return localDate.getDay() === days.indexOf(day.value.toLowerCase()) ? localDate.toISOString().split('T')[0] : null;
                }).filter(Boolean);
                return acc;
            }, {});
            
            return reduce
        },
        datesWithColors() {
            const colors = ['#9c9ab3', '#9494b3', '#8da6b8', '#88d0d1', '#a0b39e', '#9cb39e', '#bfbfa6', '#bf9f88', '#bf8f77', '#bf7b66', '#bf5b56', '#bf8e9e'];
            const object = {}

            for(const date of this.computedDatesArray) {
                object[date] = colors[new Date(date).getMonth()]
            }
            return object
        },
        datesWithMonthNames() {
            const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
            const object = {}

            for(const date of this.computedDatesArray) {
                const localDate = new Date(date + 'T00:00');
                object[date] = monthNames[localDate.getMonth()]
            }
            return object
        },
        datesWithDayNumber() {
            const object = {}

            for(const date of this.computedDatesArray) {
                const localDate = new Date(date + 'T00:00');
                object[date] = localDate.getDate()   
            }
            return object
        },
        computedParkDiamondCombos() {
            if (!this.parksData || !this.parksData.parks) {
                return [];
            }

            let combos = [];
            for (const park of this.parksData.parks) {
                for (const diamond of park.diamonds) {
                    combos.push({
                        park_name: park.name,
                        diamond_name: diamond.name,
                        park_diamond_name: `${park.name} - ${diamond.name}`
                    });
                }
            }

            return combos;
        },
        sortedDates() {
            return [...this.bookingDetails.dates].sort((a, b) => new Date(a) - new Date(b));
        },
        gameInfo() {
            if (!this.bookingDetails.startTime || !this.bookingDetails.endTime || !this.bookingDetails.gameLength || !this.bookingDetails.breakBetweenGames) {
                return { gameSlots: 0, leftoverTime: 0 };
            }

            const startTime = new Date(`1970-01-01T${this.bookingDetails.startTime}Z`);
            const endTime = new Date(`1970-01-01T${this.bookingDetails.endTime}Z`);
            const totalMinutes = (endTime - startTime) / 60000;  // Convert milliseconds to minutes

            const gameLength = parseInt(this.bookingDetails.gameLength);
            const breakBetweenGames = parseInt(this.bookingDetails.breakBetweenGames);
            const slotLength = gameLength + breakBetweenGames;

            const gameSlots = Math.floor(totalMinutes / slotLength);
            const leftoverTime = totalMinutes % slotLength;

            return { gameSlots, leftoverTime };
        },

    },
    methods: {
       
        openModal(dates = []) {
            
            this.bookingDetails.dates = dates;
            if (this.bookingsData[dates[0]]) {
                this.bookingDetails.parks = this.bookingsData[dates[0]].map(booking => {
                    return this.computedParkDiamondCombos.find(diamond => diamond.park_diamond_name === booking.park_diamond_name);
                });
            } else {
                this.bookingDetails.parks = [];
            }

            this.bookingDetails.gameLength = this.scheduleData.gameLength;
            this.bookingDetails.breakBetweenGames = this.scheduleData.breakBetweenGames;


            if (dates.length > 0) {
                const firstDate = new Date(dates[0]);
                const dayOfWeek = firstDate.getDay();

                // Check if it's a weekday (1-5) or weekend (0 or 6)
                if (dayOfWeek >= 1 && dayOfWeek <= 5) {
                    // It's a weekday
                    this.bookingDetails.startTime = this.scheduleData.defaultWeekdayStartTime;
                    this.bookingDetails.endTime = this.scheduleData.defaultWeekdayEndTime;
                } else {
                    // It's a weekend
                    this.bookingDetails.startTime = this.scheduleData.defaultWeekendStartTime;
                    this.bookingDetails.endTime = this.scheduleData.defaultWeekendEndTime;
                }
            }

          
            this.showModal = true;
        },
        updateBookingDates(event) {
            
            this.bookingDetails.dates = event.detail.value
        },
        updateStartTime(event) {
            this.bookingDetails.startTime = event.detail.value;
        },
        updateEndTime(event) {
            this.bookingDetails.endTime = event.detail.value;
        },
        utcDates() {
            return this.bookingDetails.dates.map(date => {
                const localDate = new Date(date);
                return new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate()).toISOString().split('T')[0];
            });
        },
        compareWith(option, selected) {
            return option.park_diamond_name === selected.park_diamond_name;
        },
        formatDate(dateString) {
            const date = new Date(dateString + 'T00:00');
            const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
            return date.toLocaleDateString(undefined, options);
        },
        removeDate(index) {
            const dateToRemove = this.sortedDates[index];
            const indexOfDateToRemoveInOriginalArray = this.bookingDetails.dates.indexOf(dateToRemove);
            if (indexOfDateToRemoveInOriginalArray !== -1) {
                let datesCopy = [...this.bookingDetails.dates];
                datesCopy.splice(indexOfDateToRemoveInOriginalArray, 1);
                
                this.bookingDetails.dates = datesCopy;
                this.datesKey++;
            }
        },
        isHoliday(date) {
            const formattedDate = new Date(date).toISOString().split('T')[0];
            return this.holidays.map(holiday => new Date(holiday).toISOString().split('T')[0]).includes(formattedDate);
        },
        removePark(index) {
            this.bookingDetails.parks.splice(index, 1);
        },
        clearBookings(date) {
            var confirm = window.confirm("Are you sure you want to clear all bookings on the selected dates?");
            if (!confirm) {
                return;
            }
            this.$emit('clearBookings', date);
            this.showModal = false;
        },
        saveBooking() {
            
            if (this.bookingDetails.parks.length === 0 || this.bookingDetails.dates.length === 0) {
                showToast("Make sure you've selected a park and a date.", "danger", 6000);
                return;
            }

            this.$emit('saveBooking', this.bookingDetails);
            this.showModal = false;
        },
    }
}
</script>

<style scoped>

.ion-col:hover {
    background: rgba(255,255,255,0.025)
}

.day-label {
    padding: 10px;
    text-align: center;
    background: #222;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
}

.day-label:hover {
    background: rgba(255,255,255,0.1);
    transition: 0.5s;
}

.date-label {
    /* background: #222; */
    margin: 5px 0;
    border: 1px solid #333;
    min-height: 80px;   
    max-height: 80px;
    position: relative;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.date-label:hover {
    background: rgba(255,255,255,0.1);
    cursor: pointer;
    max-height: 1000px; /* Set this to a value larger than what .date-label could ever be */
    overflow: visible;
}

.month-word {
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    /* background: #000; */
}



.full-date {
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 700;
    
    right: 0;
    top: 0;
}
.modal-content {
    padding-bottom: 50px; /* adjust as needed */
}

ion-modal {
    --height: 100%;
}

.booking-details {
    padding: 5px;
    padding-left: 10px;
    background: #444;
    border-radius: 5px;
    margin-bottom: 5px;
}

.booking-park {
    font-size: 12px;
}

.booking-time {
    font-size: 10px;
}
</style>