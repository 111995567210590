<template>
    <ion-page></ion-page>
</template>

<script>
import { IonPage } from '@ionic/vue';
export default {
    name: 'UserDashboardPage',
    props: {
        
    },
    components: {
        IonPage
    },
    methods: {

    
    }
};

</script>