<template>
  <ion-page>
    <ion-content>
      <ion-header>
        <ion-toolbar class="header-toolbar">
          <ion-buttons slot="start">
            <ion-menu-button menu="main-menu" color="dark"></ion-menu-button>
          </ion-buttons>
          <ion-title>Tutorial League</ion-title>
        </ion-toolbar>
      </ion-header>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { defineComponent } from "vue";
import {
  IonHeader,
  IonMenuButton,
  IonButtons,
  IonToolbar,
  IonTitle,
  IonPage,
  IonContent,
  onIonViewDidEnter,
} from "@ionic/vue";
import { openModal } from "@/utils/useIonicComponents.js";
import InitialModal from "@/components/tutorial/InitialModal.vue";
defineComponent({
  name: "TutorialPage",
});

onIonViewDidEnter(() => {
  openModal(InitialModal, "initialModal");
});
</script>
