<template>
  <ion-fab vertical="bottom" horizontal="start" slot="fixed">
    <ion-fab-button @click="presentSelectOptions">
      <ion-icon :icon="cogOutline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</template>

<script setup>
import { defineComponent, defineEmits, watchEffect } from "vue";
import { IonFab, IonFabButton, IonIcon } from "@ionic/vue";
import { cogOutline } from "ionicons/icons";
import { openModal, showAlert } from "@/utils/useIonicComponents.js";
import AccountModal from "@/components/account/AccountModal.vue";
import ChangePasswordModal from "@/components/account/ChangePasswordModal.vue";
import { useAuthStore } from "@/stores/authStore";
import { useRouter } from "vue-router";

defineComponent({
  name: "AccountFab",
});
const emit = defineEmits(["accountUpdated"]);
const authStore = useAuthStore();
const router = useRouter();

async function openAccountModal() {
  const response = await openModal(AccountModal, "accountModal");
  if (response === true) {
    emit("accountUpdated");
  }
}
async function openChangePasswordModal() {
  const response = await openModal(ChangePasswordModal, "changePasswordModal");
  if (response === true) {
    emit("accountUpdated");
  }
}

async function presentSelectOptions() {
  const choice = await showAlert({
    header: "Select Action",
    buttons: ["OK"],
    inputs: [
      {
        label: "Logout of Account",
        type: "radio",
        value: "logout",
      },
      {
        label: "Edit Your Details",
        type: "radio",
        value: "details",
      },

      {
        label: "Change Password",
        type: "radio",
        value: "password",
      },
    ],
  });
  if (choice.data?.values) {
    if (choice.data.values === "logout") {
      await authStore.logout();
    }
    if (choice.data.values === "details") {
      openAccountModal();
    }
    if (choice.data.values === "password") {
      openChangePasswordModal();
    }
  }
}
watchEffect(() => {
  if (authStore.isLoggedIn === false) {
    router.push("/");
  }
});
</script>
