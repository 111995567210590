<template>
  <div
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    "
  >
    <ion-card
      style="width: 60rem; text-align: center; height: 30rem"
      class="upload-card"
      color="light"
    >
      <div>
        <ion-card-header>
          <ion-card-title>Upload CSV</ion-card-title>
          <ion-card-subtitle>Select your csv file to import</ion-card-subtitle>
        </ion-card-header>

        <ion-card-content>
          <ion-button @click="fileInput.click()">
            Select CSV
            <ion-icon slot="end" :icon="cloudUploadOutline"></ion-icon>
          </ion-button>
        </ion-card-content>
        <a
          href="https://cdn.blacktiecollab.com/slopitchcentral/Slo-Pitch Central CSV - Enter Your Schedule HERE.csv"
          target="_blank"
          style="background: #fff; padding: 10px;"
          >Download Our CSV Template</a
        >
      </div>
    </ion-card>
    <input
      type="file"
      style="display: none"
      ref="fileInput"
      @change="onFilePicked"
      accept=".csv"
    />
  </div>
</template>

<script setup>
import { defineComponent, ref } from "vue";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonButton,
  IonIcon,
  modalController,
} from "@ionic/vue";
import { cloudUploadOutline } from "ionicons/icons";
import { showLoading, showToast, showAlert } from "@/utils/useIonicComponents";
import Papa from "papaparse";
import { api } from "@/utils/axios";
import { useLeagueStore } from "@/stores/leagueStore";

defineComponent({
  name: "UploadComponent",
});
const fileInput = ref();
const fileData = ref();
const leagueStore = useLeagueStore();

async function onFilePicked(event) {
  await parseCSV(event.target.files[0]);
  fileInput.value.value = "";
}

async function parseCSV(file) {
  let loading = await showLoading("Parsing CSV. Please Wait...");
  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    complete: async function (results) {
      fileData.value = results.data;
      for (const element of fileData.value) {
        for (const key in element) {
          element[key] = element[key].trim();
          if (element[key] === "") {
            delete element[key];
          }
        }

        if (element.team_color && !isValidHexColor(element.team_color)) {
          loading.dismiss();
          const userConsent = await showAlertForRandomColor(element.team_color);
          if (userConsent) {
            loading = await showLoading("Parsing CSV. Please Wait...");
            delete element.team_color;
          } else {
            return; // Stop processing if user does not consent
          }
        }
      }

      loading.dismiss();
      if (
        fileData.value.length === 0 ||
        !Object.prototype.hasOwnProperty.call(fileData.value[0], "team_name") ||
        !Object.prototype.hasOwnProperty.call(
          fileData.value[0],
          "division_name"
        )
      ) {
        showToast(
          "CSV must have team_name and division_name headers",
          "danger"
        );
        return;
      }

      // Check if columns are not empty
      for (let row of fileData.value) {
        if (!row.team_name || !row.division_name) {
          showToast(
            "team_name and division_name columns must not be empty",
            "danger"
          );
          return;
        }
      }
      console.log(fileData.value);
      saveTeams();
      // associateHeaders();
      // const ionNav = document.querySelector("ion-nav");
      // ionNav.push(AssociateColumns);
    },
    error: function (error) {
      console.log(error);
      loading.dismiss();
      showToast("Error Parsing CSV. Please try again!", "danger");
    },
  });
}
function isValidHexColor(hex) {
  const regExp = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
  return regExp.test(hex);
}
async function showAlertForRandomColor(hex) {
  const choice = await showAlert({
    header: "Invalid Hex Color Found",
    message: `The color ${hex} provided is not valid. Do you want to generate a random color?`,
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
      },
      {
        text: "OK",
        role: "confirm",
      },
    ],
  });
  console.log("choice", choice);
  if (choice.role === "confirm") {
    return true;
  }
  return false;
}

async function saveTeams() {
  const loading = await showLoading("Saving Teams. Please Wait...");
  const result = await api.post("/api/import-teams", {
    event: leagueStore.league.id,
    teams: fileData.value,
  });
  loading.dismiss();

  if (result.data.type === "success") {
    await leagueStore.reloadEverything(leagueStore.league.slug);
    showToast("Teams successfully Added.", "success", 6000);
    await modalController.dismiss();
  } else {
    showToast(
      "Something went wrong. If this persists, please contact us.",
      "danger",
      6000
    );
  }
}
</script>
<style scoped>
.upload-card {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='7' stroke-dasharray='6%2c 25' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
