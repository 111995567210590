<template>
    <div class="wrapper" style="margin-top: 2rem;">

        <p>The goal of this page is to speed up your schedule generation. If there's other features you feel would help speed it up, let us know.</p>
        <ion-grid>
            <div class="custom-card">   
                <h2>Start & End Month</h2>
                <ion-row>
                    <ion-col>
                        <ion-item class="no-border no-padding">
                            <ion-label position="stacked">Start Month</ion-label>
                            <ion-datetime-button class="park-name" datetime="datetime"></ion-datetime-button>
                        </ion-item>
                    </ion-col>
                    <ion-col>
                        <ion-item class="no-border no-padding">
                            <ion-label position="stacked">End Month</ion-label>
                            <ion-datetime-button class="park-name" datetime="datetimeEnd"></ion-datetime-button>
                        </ion-item>
                    </ion-col>
                </ion-row>
            </div>
            <div class="custom-card">

                <h2>Game Lengths</h2>
                <p>This is a preset of the game length. This can be overridden when necessary while generating your schedule.</p>
                <ion-row>
                    <ion-col>
                        <ion-item class="no-padding">
                            <ion-label class="text-center" position="stacked">Game Length (in minutes)</ion-label>
                            <ion-input class="park-name" type="number" :value="scheduleData.gameLength" @input="updateGameLength"></ion-input>
                        </ion-item>       
                    </ion-col>
                    <ion-col>
                        <ion-item class="no-padding">
                            <ion-label class="text-center" position="stacked">Break Between Games (in minutes)</ion-label>
                            <ion-input class="park-name" type="number" :value="scheduleData.breakBetweenGames" @input="updateBreakBetweenGames"></ion-input>
                        </ion-item>
                    </ion-col>
                </ion-row>
            </div>
            <div class="custom-card">
                <h2>Holidays</h2>
                <p>This is so we remind you of national holidays when generating the schedule.</p>
                <ion-row>
                    <ion-col>
                        <ion-item>
                            <ion-label>Country</ion-label>
                            <ion-select @ionChange="updateCountry" :value="scheduleData.country">
                                <ion-select-option v-for="country in countries" :key="country" :value="country">
                                    {{ country }}
                                </ion-select-option>
                            </ion-select>
                            
                        </ion-item>
                    </ion-col>
                </ion-row>
            </div>
            <div class="custom-card">
                <h2>Weekday Start & End Time</h2>
                <p>This presets your weekday selectors in the Reservations step to speed up your workflow.</p>
                <ion-row>
                    <ion-col>
                        <ion-item class="no-border no-padding">
                            <ion-label position="stacked"><u>Weekday</u> Start Time</ion-label>
                            <ion-datetime-button class="park-name" datetime="defaultWeekdayStartTime"></ion-datetime-button>
                        </ion-item>
                    </ion-col>
                    <ion-col>
                        <ion-item class="no-border no-padding">
                            <ion-label position="stacked"><u>Weekday</u> End Time</ion-label>
                            <ion-datetime-button class="park-name" datetime="defaultWeekdayEndTime"></ion-datetime-button>
                        </ion-item>
                    </ion-col>
                </ion-row>
            </div>
          
            <div class="custom-card">
                <h2>Weekend Start & End Time</h2>
                <p>This presets your weekend selectors in the Reservations step to speed up your workflow.</p>
                
                <ion-row>
                    <ion-col>
                        <ion-item class="no-border no-padding">
                            <ion-label position="stacked"><u>Weekend</u> Start Time</ion-label>
                            <ion-datetime-button class="park-name" datetime="defaultWeekendStartTime"></ion-datetime-button>
                        </ion-item>
                    </ion-col>
                    <ion-col>
                        <ion-item class="no-border no-padding">
                            <ion-label position="stacked"><u>Weekend</u> End Time</ion-label>
                            <ion-datetime-button class="park-name" datetime="defaultWeekendEndTime"></ion-datetime-button>
                        </ion-item>
                    </ion-col>
                </ion-row>
            </div>
            
        </ion-grid>
        
            
        <ion-modal :keep-contents-mounted="true">
            <ion-datetime 
                :value="scheduleData.startMonth"
                @ionChange="updateStartMonth"
                presentation="month-year"
                id="datetime"></ion-datetime>
        </ion-modal>
        <ion-modal :keep-contents-mounted="true">
            <!-- existing start month datetime -->
            <ion-datetime 
                :value="scheduleData.endMonth"
                @ionChange="updateEndMonth"
                presentation="month-year"
                id="datetimeEnd"></ion-datetime>
        </ion-modal>
        <ion-modal :keep-contents-mounted="true">
            <!-- existing start month datetime -->
            <ion-datetime 
                :value="scheduleData.defaultWeekdayStartTime"
                @ionChange="updateDefaultWeekdayStartTime"
                presentation="time"
                id="defaultWeekdayStartTime"></ion-datetime>
        </ion-modal>
        <ion-modal :keep-contents-mounted="true">
            <!-- existing end month datetime -->
            <ion-datetime 
                :value="scheduleData.defaultWeekdayEndTime"
                @ionChange="updateDefaultWeekdayEndTime"
                presentation="time"
                id="defaultWeekdayEndTime"></ion-datetime>
        </ion-modal>
        <ion-modal :keep-contents-mounted="true">
            <!-- existing start month datetime -->
            <ion-datetime 
                :value="scheduleData.defaultWeekendStartTime"
                @ionChange="updateDefaultWeekendStartTime"
                presentation="time"
                id="defaultWeekendStartTime"></ion-datetime>
        </ion-modal>
        <ion-modal :keep-contents-mounted="true">
            <!-- existing end month datetime -->
            <ion-datetime 
                :value="scheduleData.defaultWeekendEndTime"
                @ionChange="updateDefaultWeekendEndTime"
                presentation="time"
                id="defaultWeekendEndTime"></ion-datetime>
        </ion-modal>
        <ion-button @click="clearSavedData()">Clear Autosave Data</ion-button>
    </div>
</template>

<script>
import { IonDatetime, IonDatetimeButton, IonModal, IonItem, IonLabel, IonInput, IonGrid, IonRow, IonCol, IonSelect, IonSelectOption, IonButton } from '@ionic/vue';

export default {
  components: {
    IonDatetime,
    IonDatetimeButton,
    IonModal,
    IonItem,
    IonLabel,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonSelect,
    IonSelectOption,
    IonButton
  },
  props: {
    scheduleData: Object,
    countries: Array,
  },
  emits: ['update:scheduleData'],
  methods: {
    clearSavedData() {
        var r = confirm('Are you sure you want to do this? This will clear all autosaved data.')
        if (r == true) {
            this.$emit('clearSavedData')
        }
        return;
    },
    updateStartMonth(event) {
      this.$emit('update:scheduleData', { ...this.scheduleData, startMonth: event.detail.value });
    },
    updateEndMonth(event) {
        this.$emit('update:scheduleData', { ...this.scheduleData, endMonth: event.detail.value });
    },
    updateGameLength(event) {
      this.$emit('update:scheduleData', { ...this.scheduleData, gameLength: event.target.value });
    },
    updateBreakBetweenGames(event) {
      this.$emit('update:scheduleData', { ...this.scheduleData, breakBetweenGames: event.target.value });
    },
    updateCountry(event) {
      this.$emit('update:scheduleData', { ...this.scheduleData, country: event.target.value });
    },
    updateDefaultWeekdayStartTime(event) {
        this.$emit('update:scheduleData', { ...this.scheduleData, defaultWeekdayStartTime: event.detail.value });
    },
    updateDefaultWeekdayEndTime(event) {
        this.$emit('update:scheduleData', { ...this.scheduleData, defaultWeekdayEndTime: event.detail.value });
    },
    updateDefaultWeekendStartTime(event) {
        this.$emit('update:scheduleData', { ...this.scheduleData, defaultWeekendStartTime: event.detail.value });
    },
    updateDefaultWeekendEndTime(event) {
        this.$emit('update:scheduleData', { ...this.scheduleData, defaultWeekendEndTime: event.detail.value });
    },
  },
};
</script>

<style scoped>
    .no-border {
        --border: 0px !important;
        --border-color: transparent;
    }

    .wrapper {
        max-width: 600px;
        margin: 0 auto;
    }

    .park-name {
        font-size: 30px;
        text-align: center;
    }
     
    .text-center {
        text-align: center;
    }

    .custom-card {
        padding: 10px;
        margin-bottom: 5rem;
        border: 2px solid #ddd;
    }

    .custom-card h2 {
        margin-top: 0px;
        margin-top: -10px;
        margin-left: -10px;
        margin-right: -10px;

        text-align: center;
        background: #fff;
        padding: 10px;
        color: #333;
    }

    .custom-card p {
        text-align: center;
    }
</style>