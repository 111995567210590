<template>
  <span>
    <h1
      style="
        color: var(--ion-color-dark);
        text-align: center;
        text-transform: uppercase;
      "
    >
      <span style="background-color: var(--ion-color-primary); padding: 0 10px">
        2. ENTER YOUR TEAM SHORTCODE*
      </span>
    </h1>
    <h3
      style="
        font-size: 14px;
        color: var(--ion-color-dark);
        text-align: center;
        text-transform: uppercase;
      "
    >
      <span
        style="background-color: var(--ion-color-primary); padding: 3px 10px"
      >
        (If you have it)
      </span>
    </h3>
    <p style="text-align: center">
      Save your shortcode now so you don't need to remember it again on this
      device. Your league or tournament will provide this to you!
    </p>
    <ion-item>
      <ion-input
        :disabled="isCorrect"
        style="text-align: center; text-decoration: uppercase"
        placeholder="TEAM SHORTCODE"
        debounce="1000"
        @ionChange="checkShortcode"
        v-model="shortcode"
      ></ion-input>
    </ion-item>
    <div v-if="isCorrect" style="text-align: center">
      <p>
        Your team: <strong>{{ teamName }}</strong>
      </p>
      <ion-button size="small" @click="resetInput">Reset</ion-button>
    </div>
  </span>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { IonInput, IonItem, IonButton } from "@ionic/vue";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { useLeagueStore } from "@/stores/leagueStore";
import { showToast } from "@/utils/useIonicComponents";

const preferenceStore = usePreferenceStore();
const leagueStore = useLeagueStore();
const shortcode = ref("");
const isCorrect = ref(false);
const teamName = ref("");

async function checkShortcode(ev) {
  if (leagueStore.shortcodes.includes(ev.detail.value.toUpperCase())) {
    preferenceStore.setShortCodes(ev.detail.value.toUpperCase());
    showToast("Shortcode Saved!", "success", 6000);
    isCorrect.value = true;
    const currentLeagueShortcodes = preferenceStore.userShortCodes.find(
      (sc) => sc.leagueId === leagueStore.league.id
    );
    if (currentLeagueShortcodes) {
      teamName.value = currentLeagueShortcodes.name;
    }
  } else {
    if (ev.detail.value) {
      showToast("Failed to find Shortcode, Please try again!", "danger", 6000);
    }
  }
}

function resetInput() {
  shortcode.value = "";
  isCorrect.value = false;
  preferenceStore.resetShortCode();
}

// On component mount, autofill the shortcode if available
onMounted(() => {
  const currentLeagueShortcodes = preferenceStore.userShortCodes.find(
    (sc) => sc.leagueId === leagueStore.league.id
  );
  if (currentLeagueShortcodes) {
    shortcode.value = currentLeagueShortcodes.shortcode;
    teamName.value = currentLeagueShortcodes.name; // Set the team name
    isCorrect.value = true;
  }
});
</script>
