<template>
  <base-modal modal-title="Submit Score">
    <report-score :game="game" v-model="shortcode"></report-score>
    <template #footer>
      <ion-button expand="full" @click="submit">Submit</ion-button>
    </template>
  </base-modal>
</template>

<script setup>
import { defineComponent, defineProps, ref } from "vue";
import { IonButton, modalController } from "@ionic/vue";
import BaseModal from "@/components/layouts/BaseModal.vue";
import ReportScore from "@/components/schedule/report-score/ReportScore.vue";
import { showToast } from "@/utils/useIonicComponents";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { useLeagueStore } from "@/stores/leagueStore";

defineComponent({
  name: "ReportScoreModal",
});
const props = defineProps({ game: Object });
const preferenceStore = usePreferenceStore();
const leagueStore = useLeagueStore();

const shortcode = ref("");
// console.log(props.game);
async function submit() {
  // console.log(
  //   shortcode.value,
  //   props.game.awayTeam.shortcode,
  //   props.game.homeTeam.shortcode
  // );
  if (
    leagueStore.league.admin_code &&
    shortcode.value === leagueStore.league.admin_code
  ) {
    console.log("ADMIN Override");
    preferenceStore.setAdminOverride();
    await modalController.dismiss(true);
  } else if (
    leagueStore.league.umpire_code &&
    shortcode.value === leagueStore.league.umpire_code
  ) {
    console.log("Umpire Override");
    preferenceStore.setUmpireOverride();
    await modalController.dismiss(true);
  } else if (
    leagueStore.league.can_teams_report === 1 &&
    (shortcode.value === props.game.awayTeam.shortcode ||
      shortcode.value === props.game.homeTeam.shortcode)
  ) {
    preferenceStore.setShortCodes(shortcode.value);
    await modalController.dismiss(true);
  } else {
    // if (
    //   shortcode.value === props.game.awayTeam.shortcode ||
    //   shortcode.value === props.game.homeTeam.shortcode ||
    //   shortcode.value === leagueStore.league.umpire_code
    // ) {
    //   preferenceStore.setShortCodes(shortcode.value);
    //   await modalController.dismiss(true);
    // } else {
    //   showToast("Invalid Shortcode!", "danger", 6000);
    // }
      showToast("Invalid Shortcode!", "danger", 6000);
  }
}
</script>
