<template>
    <div ref="content">
        <!-- <div class="step">Step One</div> -->
        <div class="scoreboard">
            <ion-segment mode="md" v-model="selectedSegment">
                <ion-segment-button value="awayTeam">
                    <ion-label>{{ scorekeepStore.awayTeam.name || 'Away Team' }}</ion-label>
                </ion-segment-button>
                <ion-segment-button value="homeTeam">
                    <ion-label>{{ scorekeepStore.homeTeam.name || 'Home Team' }}</ion-label>
                </ion-segment-button>
            </ion-segment>

            <div v-if="selectedSegment === 'awayTeam' || selectedSegment === 'homeTeam'">
                <!-- {{ scorekeepStore[selectedSegment].name }} -->
                <ion-list v-if="selectedSegment === 'awayTeam'">
                    <!-- The reorder gesture is disabled by default, enable it to drag and drop items -->
                    <div style="display: flex; justify-content: space-between; align-items: center; background: #222;">
                        <div class="lineup-title" style="flex-grow: 1;">
                            Lineup
                        </div>
                        <ion-button
                            v-on:click="scorekeepStore.addPlayerToAwayLineup()"
                            size="small">
                            Add Another Player to Lineup
                        </ion-button>
                    </div>
                    
                    <ion-reorder-group :disabled="false" @ionItemReorder="handleReorder($event)">
                        <ion-item 
                            v-for="(lineup, index) of scorekeepStore.awayTeamLineup"
                            :key="index"
                        >   
                            <ion-input 
                                :value="index + 1"
                                placeholder="#"
                                :disabled="true"
                                style="max-width: 40px; font-size: 12px;"
                            ></ion-input>
                            <ion-input 
                                v-model="lineup.number"
                                placeholder="#"
                                style="max-width: 40px;"
                            ></ion-input>
                            <ion-input 
                                v-model="lineup.name"
                                placeholder="Player Name"
                            ></ion-input>
                            <ion-select 
                                v-model="lineup.position"
                                placeholder="Position"
                            >
                                <ion-select-option value="P">P</ion-select-option>
                                <ion-select-option value="C">C</ion-select-option>
                                <ion-select-option value="1B">1B</ion-select-option>
                                <ion-select-option value="2B">2B</ion-select-option>
                                <ion-select-option value="3B">3B</ion-select-option>
                                <ion-select-option value="SS">SS</ion-select-option>
                                <ion-select-option value="5-Man">5-Man</ion-select-option>
                                <ion-select-option value="LF">LF</ion-select-option>
                                <ion-select-option value="LCF">LCF</ion-select-option>
                                <ion-select-option value="CF">CF</ion-select-option>
                                <ion-select-option value="RCF">RCF</ion-select-option>
                                <ion-select-option value="RF">RF</ion-select-option>
                                <ion-select-option value="DH">DH</ion-select-option>
                                <ion-select-option value="Rotate IF">Rotate IF</ion-select-option>
                                <ion-select-option value="Rotate OF">Rotate OF</ion-select-option>
                            </ion-select>
                        
                            <ion-reorder slot="end"></ion-reorder>
                            <ion-button
                                color="danger"
                                v-if="scorekeepStore.awayTeamLineup.length > 10"
                                @click="scorekeepStore.removePlayerFromAwayLineup(index)"
                            >
                                <ion-icon :icon="trashOutline"></ion-icon>
                            </ion-button>
                        </ion-item>
                    </ion-reorder-group>
                    <div style="display: flex; justify-content: space-between; align-items: center; background: #222;">
                        <div class="lineup-title" style="flex-grow: 1;">
                            Bench
                        </div>
                        <ion-button
                            v-on:click="scorekeepStore.addPlayerToAwayBench()"
                            size="small">
                            Add Another Player to Bench
                        </ion-button>
                    </div>
                    <ion-item 
                        v-for="(lineup, index) of scorekeepStore.awayTeamBench"
                        :key="index"
                    >   
                        <ion-input 
                            :value="index + 1"
                            placeholder="#"
                            :disabled="true"
                            style="max-width: 40px; font-size: 12px;"
                        ></ion-input>
                        <ion-input 
                            v-model="lineup.number"
                            placeholder="#"
                            style="max-width: 40px;"
                        ></ion-input>
                        <ion-input 
                            v-model="lineup.name"
                            placeholder="Player Name"
                        ></ion-input>
                        <ion-button
                            color="danger"
                            v-if="scorekeepStore.awayTeamBench.length > 0"
                            @click="scorekeepStore.removePlayerFromAwayBench(index)"
                        >
                            <ion-icon :icon="trashOutline"></ion-icon>
                        </ion-button>
                    </ion-item>
                    <div v-if="awayTeamMessages.length > 0">
                        <ul>
                            <li v-for="message in awayTeamMessages" :key="message">{{ message }}</li>
                        </ul>
                        <p>If you are okay with this, you can continue to submit the lineup.</p>
                    </div>
                </ion-list>
                <ion-list v-if="selectedSegment === 'homeTeam'">
                    <!-- The reorder gesture is disabled by default, enable it to drag and drop items -->
                    <div style="display: flex; justify-content: space-between; align-items: center; background: #222;">
                        <div class="lineup-title" style="flex-grow: 1;">
                            Lineup
                        </div>
                        <ion-button
                            v-on:click="scorekeepStore.addPlayerToHomeLineup()"
                            size="small">
                            Add Another Player to Lineup
                        </ion-button>
                    </div>
                    <ion-reorder-group :disabled="false" @ionItemReorder="handleReorder($event)">
                        <ion-item 
                            v-for="(lineup, index) of scorekeepStore.homeTeamLineup"
                            :key="index"
                        >   
                            <ion-input 
                                :value="index + 1"
                                placeholder="#"
                                :disabled="true"
                                style="max-width: 40px; font-size: 12px;"
                            ></ion-input>
                            <ion-input 
                                v-model="lineup.number"
                                placeholder="#"
                                style="max-width: 40px;"
                            ></ion-input>
                            <ion-input 
                                v-model="lineup.name"
                                placeholder="Player Name"
                            ></ion-input>
                            <ion-select 
                                v-model="lineup.position"
                                placeholder="Position"
                            >
                                <ion-select-option 
                                    :value="position" 
                                    v-for="position of scorekeepStore.positions"
                                    :key="position"
                                >
                                    {{ position }}
                                </ion-select-option>
                                
                            </ion-select>
                        
                            <ion-reorder slot="end"></ion-reorder>
                            <ion-button
                                color="danger"
                                v-if="scorekeepStore.homeTeamLineup.length > 10"
                                @click="scorekeepStore.removePlayerFromHomeLineup(index)"
                            >
                                <ion-icon :icon="trashOutline"></ion-icon>
                            </ion-button>
                        </ion-item>

                    </ion-reorder-group>
                    <div style="display: flex; justify-content: space-between; align-items: center; background: #222;">
                        <div class="lineup-title" style="flex-grow: 1;">
                            Bench
                        </div>
                        <ion-button
                            v-on:click="scorekeepStore.addPlayerToHomeBench()"
                            size="small">
                            Add Another Player to Bench
                        </ion-button>
                    </div>
                    <ion-item 
                        v-for="(lineup, index) of scorekeepStore.homeTeamBench"
                        :key="index"
                    >   
                        <ion-input 
                            :value="index + 1"
                            placeholder="#"
                            :disabled="true"
                            style="max-width: 40px; font-size: 12px;"
                        ></ion-input>
                        <ion-input 
                            v-model="lineup.number"
                            placeholder="#"
                            style="max-width: 40px;"
                        ></ion-input>
                        <ion-input 
                            v-model="lineup.name"
                            placeholder="Player Name"
                        ></ion-input>
                        <ion-button
                            color="danger"
                            v-if="scorekeepStore.homeTeamBench.length > 0"
                            @click="scorekeepStore.removePlayerFromHomeLineup(index)"
                        >
                            <ion-icon :icon="trashOutline"></ion-icon>
                        </ion-button>
                    </ion-item>
                    <div v-if="homeTeamMessages.length > 0">
                        <ul>
                            <li v-for="message in homeTeamMessages" :key="message">{{ message }}</li>
                        </ul>
                    </div>
                </ion-list>
                <ion-button color="success" expand="full" @click="saveLineupsAndScrollTop">Save Lineups & Start Game</ion-button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useScorekeepStore } from "@/stores/scorekeepStore"
import { IonSegment, IonSegmentButton, IonLabel, IonReorder, IonReorderGroup, IonInput, IonSelect, IonSelectOption, IonButton, IonItem, IonIcon, IonList } from "@ionic/vue";

import { trashOutline } from "ionicons/icons";

const scorekeepStore = useScorekeepStore()

import { ref, computed } from 'vue';


const content = ref(null);

const selectedSegment = ref('awayTeam');

const handleReorder = (event) => {
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    console.log('Dragged from index', event.detail.from, 'to', event.detail.to);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    event.detail.complete();
};

const awayTeamMessages = computed(() => {
    return scorekeepStore.checkLineupValidity(scorekeepStore.awayTeamLineup);
});

const homeTeamMessages = computed(() => {
    return scorekeepStore.checkLineupValidity(scorekeepStore.homeTeamLineup);
});

const saveLineupsAndScrollTop = async () => {
    scorekeepStore.saveLineups();
    // if (content.value) {
    //     await content.value.scrollToTop(500); // 500 is the duration of the scroll animation in milliseconds
    // }
};
</script>

<style scoped>

.scoreboard-header, .team-row {
    display: flex;
    justify-content: space-between;
    
}

.scoreboard-header {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    padding: 5px;
    background: rgba(150,150,150,0.1)
}

.team-row {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center; /* Add this line */
}

.team-column {
    flex: 0 0 80px; /* fixed width for team name column */
    text-align: left;
}

.inning-column {
    flex: 1; /* equally distribute remaining space */
    text-align: center;
}

.bold {
    font-weight: 700;
}

.lineup-title {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0;
    margin-left: 20px;
    text-transform: uppercase;
}
</style>
