<template>
    <div class="scorecard-at-bat">
        <div 
            :class="[
                'diamond', 
                computedHitClasses
            ]"
        >
            <div class="out-line"></div>

        </div>
        <!-- <div class="other-diamonds"></div> -->
        <div 
            :class="[
                'other-diamonds',
                'diamond-first-base',
                computedFirstClasses
            ]">
            <div class="out-line"></div>
        </div>
        <div 
            :class="[
                'other-diamonds', 
                'diamond-second-base',
                computedSecondClasses
            ]">
            <div class="out-line"></div>
        </div>
        <div 
            :class="[
                'other-diamonds', 
                'diamond-third-base',
                computedThirdClasses
            ]">
            <div class="out-line"></div>
        </div>
        <div class="at-bat-result">{{ computedAtBatResult }}</div>
    </div>
</template>

<script setup>
import { defineProps, watch, onMounted, ref, computed } from 'vue';

// import { useScorekeepStore } from "@/stores/scorekeepStore"

// const scorekeepStore = useScorekeepStore()

const props = defineProps({
    player: Object,
    inning: Number,
    index: Number,
    currentLineup: Array,
    currentPlayByPlay: Array,
    selectedSegment: String
});

const hitPlay = ref(null);
const firstBasePlay = ref(null);
const secondBasePlay = ref(null);
const thirdBasePlay = ref(null);

watch(() => props.selectedSegment, () => {
    console.log('updatePlays', props.selectedSegment)
    console.log('updatePlays', props.currentPlayByPlay)
    updatePlays();
}, { deep: true });

onMounted(() => {
    updatePlays();
});

// find the plays involving this player
function updatePlays() {
    const findHitPlay = props.currentPlayByPlay.find(play => play.substitution === false && play.atBat && play.atBat.id === props.player.id && play.inning === props.inning);
    
    if(findHitPlay) {
        hitPlay.value = findHitPlay;
    }
    
    const findFirstBasePlay = props.currentPlayByPlay.find(play => play.substitution === false && play.onFirstBase && play.onFirstBase.id === props.player.id && play.inning === props.inning && play.runners.first.movesTo);

    if(findFirstBasePlay) {
        firstBasePlay.value = findFirstBasePlay;
    }

    const findSecondBasePlay = props.currentPlayByPlay.find(play => play.substitution === false && play.onSecondBase && play.onSecondBase.id === props.player.id && play.inning === props.inning && play.runners.second.movesTo);

    if(findSecondBasePlay) {
        secondBasePlay.value = findSecondBasePlay;
    }

    const findThirdBasePlay = props.currentPlayByPlay.find(play => play.substitution === false && play.onThirdBase && play.onThirdBase.id === props.player.id && play.inning === props.inning && play.runners.third.movesTo);
    
    if(findThirdBasePlay) {
        thirdBasePlay.value = findThirdBasePlay;
    }
}

const computedAtBatResult = computed(() => {
    
    let atBatResults = [];
    if (hitPlay.value) {
        if (hitPlay.value.hitResult === 'single') {
            atBatResults.push('1B');
        }
        if (hitPlay.value.hitResult === 'double') {
            atBatResults.push('2B');
        }
        if (hitPlay.value.hitResult === 'triple') {
            atBatResults.push('3B');
        }
        if (hitPlay.value.hitResult === 'homerun') {
            atBatResults.push('HR');
        }
        if (hitPlay.value.hitResult === 'walk') {
            atBatResults.push('BB');
        }
        if (hitPlay.value.outResult === true) {
            atBatResults.push('Out');
        }
        if (hitPlay.value.hitResult === 'sacfly') {
            atBatResults.push('Sac');
        }
    }
    if (firstBasePlay.value && firstBasePlay.value.runners.first) {
        if (firstBasePlay.value.runners.first.outResult === true) {
            atBatResults.push('Out');
        }
    }
    if (secondBasePlay.value && secondBasePlay.value.runners.second) {
        if (secondBasePlay.value.runners.second.outResult === true) {
            atBatResults.push('Out');
        }
    }
    if (thirdBasePlay.value && thirdBasePlay.value.runners.third) {
        if (thirdBasePlay.value.runners.third.outResult === true) {
            atBatResults.push('Out');
        }
    }
    return atBatResults.join(', ');
});

const computedHitClasses = computed(() => {
    let classes = [];

    if(hitPlay.value) {
        if(hitPlay.value.hitResult === 'single' || hitPlay.value.hitResult === 'walk') {
            classes.push('hometofirst');
        }
        if(hitPlay.value.hitResult === 'double') {
            classes.push('hometofirst');
            classes.push('firsttosecond');
        }
        if(hitPlay.value.hitResult === 'triple') {
            classes.push('hometofirst');
            classes.push('firsttosecond');
            classes.push('secondtothird');
        }
        if(hitPlay.value.hitResult === 'homerun') {
            classes.push('hometofirst');
            classes.push('firsttosecond');
            classes.push('secondtothird');
            classes.push('thirdtohome');
        }
        if(hitPlay.value.outResult === true) {
            classes.push('hometoout');
        }
        if(hitPlay.value.scoredResult === true) {
            classes.push('scoredrunner')
        }
    }

    return classes;
});

const computedFirstClasses = computed(() => {
    let classes = [];

    if(firstBasePlay.value) {
        if(firstBasePlay.value.runners.first.movesTo === 'second') {
            classes.push('firsttosecond');
        }
        if(firstBasePlay.value.runners.first.movesTo === 'third') {
            classes.push('firsttosecond');
            classes.push('secondtothird')
        }
        if(firstBasePlay.value.runners.first.movesTo === 'home') {
            classes.push('firsttosecond');
            classes.push('secondtothird')
            classes.push('thirdtohome')
        }
        if(firstBasePlay.value.runners.first.outResult === true) {
            classes.push('firsttoout')
        }
        if(firstBasePlay.value.runners.first.scoredResult === true) {
            classes.push('scoredrunner')
        }
    }

    return classes;
});

const computedSecondClasses = computed(() => {
    let classes = [];

    if(secondBasePlay.value) {
        console.log('secondBasePlay', secondBasePlay.value)
        if(secondBasePlay.value.runners.second.movesTo === 'third') {
            classes.push('secondtothird');
        }
        if(secondBasePlay.value.runners.second.movesTo === 'home') {
            classes.push('secondtothird');
            classes.push('thirdtohome')
        }
        if(secondBasePlay.value.runners.second.outResult === true) {
            classes.push('secondtoout')
        }
        if(secondBasePlay.value.runners.second.scoredResult === true) {
            classes.push('scoredrunner')
        }
    }

    return classes;
});

const computedThirdClasses = computed(() => {
    let classes = [];

    if(thirdBasePlay.value) {
        if(thirdBasePlay.value.runners.third.movesTo === 'home') {
            classes.push('thirdtohome');
        }
        if(thirdBasePlay.value.runners.third.outResult === true) {
            classes.push('thirdtoout')
        }
        if(thirdBasePlay.value.runners.third.scoredResult === true) {
            classes.push('scoredrunner')
        }
    }

    return classes;
});



</script>

<style scoped>
.scorecard-at-bat {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    
}

.diamond {
    margin-left: 15px;
    margin-top: 15px;
    width: 30px;
    height: 30px;
    border: 1px dashed #555;
    transform: rotate(45deg);
    position: absolute;
}

.other-diamonds {
    margin-left: 15px;
    margin-top: 15px;
    width: 30px;
    height: 30px;
    /* border: 1px dashed #555; */
    transform: rotate(45deg);
    position: absolute;
}

.at-bat-result {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 12px;
    text-align: right;
    font-weight: bold;
    color: #fff;
}

.hometofirst {
    border-right: 2px solid #fff;
}
.firsttosecond {
    border-top: 2px solid #fff;
}
.secondtothird {
    border-left: 2px solid #fff;
}
.thirdtohome {
    border-bottom: 2px solid #fff;
}

.diamond-first-base.firsttosecond, .diamond-first-base.secondtothird, .diamond-first-base.thirdtohome {
    border-color: #7438e9;
}

.diamond-second-base.firsttosecond, .diamond-second-base.secondtothird, .diamond-second-base.thirdtohome {
    border-color: #52bffc;
}

.diamond-third-base.firsttosecond, .diamond-third-base.secondtothird, .diamond-third-base.thirdtohome {
    border-color: #fcd041;
}

.diamond.hometoout > .out-line {
    border-right: 2px solid #f00;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-top: 0px;
    right: -10px;
    
    transform: rotate(90deg);
}

.other-diamonds.firsttoout > .out-line {
    border-right: 2px solid #f00;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-top: -10px;
}

.other-diamonds.secondtoout > .out-line {
    border-top: 2px solid #f00;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-top: 20px;
    margin-left: -10px;
}

.other-diamonds.thirdtoout > .out-line {
    border-left: 2px solid #f00;
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    margin-bottom: -10px;
    margin-left: 20px;
}

.scoredrunner {
    background: rgba(255,255,255,0.5)
}

/* firstbase */
/* #7438e9 */

/* secondbase */
/* #52bffc */

/* thirdbase */
/* #fcd041 */

/* homeplate */
/* #34d76e */

</style>
