import {
  toastController,
  loadingController,
  modalController,
  alertController,
} from "@ionic/vue";

export async function showToast(message, color, duration = 4000) {
  const toast = await toastController.create({
    message,
    color,
    duration,
    cssClass: "custom-toast",
  });
  toast.present();
}
export async function showLoading(message, duration = 10000) {
  const loading = await loadingController.create({
    message,
    duration,
  });
  await loading.present();
  return loading;
}

export async function openModal(component, cssClass, componentProps) {
  const modal = await modalController.create({
    component,
    backdropDismiss: false,
    swipeToClose: false,
    keyboardClose: false,
    cssClass,
    componentProps,
  });
  modal.present();
  let modalResponse = await modal.onDidDismiss();
  return modalResponse?.data;
}

// todo: change all modals to this. Increases functionality.
export async function openModalInstance(component, cssClass, componentProps) {
  const modal = await modalController.create({
    component,
    backdropDismiss: false,
    swipeToClose: false,
    keyboardClose: false,
    cssClass,
    componentProps,
  });
  await modal.present();
  return modal; // Return the modal instance here
}

export async function showAlert(options) {
  const alert = await alertController.create(options);
  await alert.present();
  const input = await alert.onDidDismiss();
  return input;
}
