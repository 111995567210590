<template>
  <tr style="height: 3rem" @click="selectGames">
    <td>{{ props.game.event_specific_game_id }}</td>
    <td colspan="2" v-if="props.game.game_status === 'postponed'">
      Postponed due to {{ props.game.reschedule_reason }}
    </td>
    <td v-else>
      {{
        props.game.start_time
          ? format(
              set(new Date(), {
                hours: props.game.start_time.split(":")[0],
                minutes: props.game.start_time.split(":")[1],
              }),
              "h:mm aa"
            )
          : "TBA"
      }}
    </td>
    <td v-if="props.game.game_status !== 'postponed'">
      {{
        props.game.start_time
          ? format(
              set(new Date(), {
                hours: props.game.start_time.split(":")[0],
                minutes: props.game.start_time.split(":")[1],
              }),
              "h:mm aa"
            )
          : "TBA"
      }}
    </td>
    <td>{{ props.game.park_name }}</td>
    <td>
      {{ props.game.diamond_signifier }}
    </td>
    <td
      style="border-left-style: solid; border-width: 0.75rem"
      :style="[
        {
          borderColor: uniqolor(props.game.division_id, {
            lightness: [25, 30],
          }).color,
        },
      ]"
    >
      {{ props.game.division_id }}
    </td>
    <td
      style="border-left-style: solid; border-width: 0.75rem"
      :style="[
        {
          borderColor: props.game.awayTeam?.color
            ? props.game.awayTeam?.color
            : uniqolor(
                props.game.awayTeam?.name || props.game.away_team_freetext
              ).color,
        },
      ]"
    >
      {{
        props.game.awayTeam?.name ||
        props.game.away_team_freetext_formatted_game_num ||
        props.game.away_team_freetext_formatted
      }}
      <span
        style="color: var(--ion-color-medium)"
        v-if="props.game.awayTeam && preferenceStore.showTeamIDs"
        >| {{ props.game.awayTeam?.id }}</span
      >
    </td>
    <game-table-scores
      :key="resetScore"
      :game="props.game"
      @resetGame="resetScore++"
    />
    <td
      style="border-right-style: solid; border-width: 0.75rem"
      :style="[
        {
          borderColor: props.game.homeTeam?.color
            ? props.game.homeTeam?.color
            : uniqolor(
                props.game.homeTeam?.name || props.game.home_team_freetext
              ).color,
        },
      ]"
    >
      <span v-if="leagueStore.league?.show_home_away === 1">at </span
      >{{
        props.game.homeTeam?.name ||
        props.game.home_team_freetext_formatted_game_num ||
        props.game.home_team_freetext_formatted
      }}
      <span
        style="color: var(--ion-color-medium)"
        v-if="props.game.homeTeam && preferenceStore.showTeamIDs"
        >| {{ props.game.homeTeam?.id }}</span
      >
    </td>
    <td>
      {{ props.game.mode }}
    </td>
    <game-table-actions @resetGame="resetScore++" :game="props.game" />
  </tr>
</template>
<script setup>
import { defineComponent, defineProps, ref, computed } from "vue";
import { useLeagueStore } from "@/stores/leagueStore";
import GameTableScores from "@/components/schedule/game-table/GameTableScores";
import GameTableActions from "@/components/schedule/game-table/GameTableActions";
import uniqolor from "uniqolor";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { useGameStore } from "@/stores/gameStore";
import { useAuthStore } from "@/stores/authStore";

import { format, set } from "date-fns";

defineComponent({
  name: "GameTableRows",
});
const props = defineProps({ game: Object });
const preferenceStore = usePreferenceStore();
const leagueStore = useLeagueStore();
const resetScore = ref(0); //to reset score by re rendering game-table-scores

const gameStore = useGameStore();
const authStore = useAuthStore();

const isGameSelected = computed(() => {
  const game = gameStore.selectedGames.filter(
    (game) => game.id === props.game.id
  );
  return game.length > 0;
});
function selectGames() {
  // if (gameStore.showSelectGames) {
  console.log("game to be submitted", props.game);

  if (authStore.isConvenor) {
    if (!isGameSelected.value) {
      gameStore.showSelectGames = true;
      gameStore.selectGame(props.game);
    } else {
      gameStore.removeSelectedGame(props.game);
    }
  }

  // }
}
</script>
<style scoped>
tr:nth-child(odd) {
  background-color: var(--ion-color-light);
}
</style>
