<template>
  <div>
    <div v-if="authStore.isConvenor" style="padding: 20px; background: #333">
      <b>Convenor Note</b>
      <br />
      This is the page that your players will see when they log in. You can
      customize it by adding links as well as latest news. They won't see this
      notes, or the <ion-icon :icon="addOutline"></ion-icon> buttons.
      <br />
      You can add/manage latest news and links by hitting the
      <ion-icon :icon="addOutline"></ion-icon> buttons below.
    </div>
    <div class="player-wrapper">
      <div class="header-container">
        <h1
          style="
            color: var(--ion-color-dark);
            text-align: center;
            text-transform: uppercase;
          "
        >
          <span
            style="background-color: var(--ion-color-primary); padding: 0 10px"
          >
            Latest News
          </span>
        </h1>
        <div
          class="header-plus-button"
          @click="showNewsModal = true"
          v-if="authStore.isConvenor"
        >
          <ion-icon :icon="addOutline"></ion-icon>
        </div>
      </div>
      <div class="swiper-container">
        <swiper
          :modules="modules"
          :slides-per-view="1"
          :space-between="30"
          :loop="true"
          :autoplay="{ delay: 3000, disableOnInteraction: false }"
        >
          <swiper-slide v-for="(newsItem, index) in latestNews" :key="index">
            <div
              class="news-slide"
              :style="
                newsItem.img_url
                  ? `background-image: url(${newsItem.img_url}); background-size: cover; background-position: center center; background-repeat: no-repeat;`
                  : ''
              "
            >
              <div class="news-content">
                <h3>{{ newsItem.title }}</h3>
                <p>{{ newsItem.preview }}</p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div id="pwDashboard"></div>

      <div
        class="header-container"
        v-if="sponsors.length > 0 || authStore.isConvenor"
      >
        <h1
          style="
            color: var(--ion-color-dark);
            text-align: center;
            text-transform: uppercase;
          "
        >
          <span
            style="background-color: var(--ion-color-primary); padding: 0 10px"
          >
            <!-- todo grab league/tournament -->
            Sponsors
          </span>
        </h1>
        <div
          class="header-plus-button"
          @click="showSponsorsModal = true"
          v-if="authStore.isConvenor"
        >
          <ion-icon :icon="addOutline"></ion-icon>
        </div>
      </div>
      <div class="sponsors-container">
        <swiper
          :modules="modules"
          :slides-per-view="3"
          :space-between="30"
          :loop="true"
          :autoplay="{ delay: 3000, disableOnInteraction: false }"
        >
          <swiper-slide v-for="(sponsor, index) in sponsors" :key="index">
            <a
              :href="sponsor.sponsor_link"
              class="sponsor-slide"
              :style="
                sponsor.img_url
                  ? `background-image: url(${sponsor.img_url}); background-size: cover; background-position: center center; background-repeat: no-repeat;`
                  : ''
              "
            >
            </a>
          </swiper-slide>
        </swiper>
      </div>

      <div class="header-container">
        <h1
          style="
            color: var(--ion-color-dark);
            text-align: center;
            text-transform: uppercase;
          "
        >
          <span
            style="background-color: var(--ion-color-primary); padding: 0 10px"
          >
            <!-- todo grab league/tournament -->
            Links
          </span>
        </h1>
        <div
          class="header-plus-button"
          @click="showLinksModal = true"
          v-if="authStore.isConvenor"
        >
          <ion-icon :icon="addOutline"></ion-icon>
        </div>
      </div>
      <ion-row>
        <ion-col>
          <div id="pwDashboard2"></div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <div class="padding">
            <ion-button
              @click="goToOurLinks('schedule')"
              expand="full"
              fill="outline"
              color="primary"
            >
              <ion-icon
                :icon="calendarOutline"
                style="margin-right: 10px"
              ></ion-icon>
              Check out our Schedule
            </ion-button>
          </div>
          <div class="padding">
            <ion-button
              @click="goToOurLinks('standings')"
              fill="outline"
              expand="full"
              color="primary"
            >
              <ion-icon
                :icon="statsChartOutline"
                style="margin-right: 10px"
              ></ion-icon>
              View our Standings
            </ion-button>
          </div>
        </ion-col>
        <ion-col>
          <div class="padding">
            <ion-button
              @click="goToOurLinks('teams')"
              expand="full"
              fill="outline"
              color="primary"
            >
              <ion-icon
                :icon="peopleOutline"
                style="margin-right: 10px"
              ></ion-icon>
              View Teams
            </ion-button>
          </div>
          <div class="padding">
            <ion-button
              @click="showContactModal = true"
              expand="full"
              color="primary"
            >
              <ion-icon style="margin-right: 10px" :icon="mailOutline"></ion-icon>
              Contact {{ leagueStore.league.name }}
            </ion-button>
            <!-- <ion-button
                          
                          expand="full"
                          fill="outline"
                          color="primary">
                          <ion-icon :icon="bookOutline" style="margin-right: 10px;"></ion-icon>
                          View Rules
                      </ion-button> -->
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col v-for="(link, index) of links" :key="index">
          <div class="padding">
            <ion-button
              @click="directToTheirLink(link)"
              expand="full"
              fill="outline"
              color="primary"
            >
              {{ link.link_title }}
            </ion-button>
          </div>
        </ion-col>
      </ion-row>
    </div>
    <!-- <ion-row>
            <ion-col>
                <div class="padding">
                    <ion-button
                        @click="showContactModal = true"
                        expand="full"
                        color="primary">
                        <ion-icon 
                            style="margin-right: 10px;"
                            :icon="mailOutline"></ion-icon>
                        Contact {{ leagueStore.league.name }}
                    </ion-button>
                </div>
            </ion-col>
        </ion-row> -->
    <rules-component :key="refreshKey" />
    <info-component />
    <ion-modal
      :is-open="showNewsModal"
      @close="showNewsModal = false"
      @did-dismiss="showNewsModal = false"
    >
      <news-modal></news-modal>
    </ion-modal>
    <ion-modal
      :is-open="showContactModal"
      @did-dismiss="showContactModal = false"
    >
      <contact-modal @close="showContactModal = false"> </contact-modal>
    </ion-modal>
    <ion-modal :is-open="showLinksModal" @did-dismiss="showLinksModal = false">
      <links-modal @close="showLinksModal = false"> </links-modal>
    </ion-modal>
    <ion-modal
      :is-open="showSponsorsModal"
      @did-dismiss="showSponsorsModal = false"
    >
      <sponsor-modal @close="showSponsorsModal = false"> </sponsor-modal>
    </ion-modal>
  </div>
</template>

<script setup>
import { IonIcon, IonButton, IonRow, IonCol, IonModal } from "@ionic/vue";
import { onMounted } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { useLeagueStore } from "@/stores/leagueStore";
import { ref } from "vue";
const authStore = useAuthStore();
import {
  addOutline,
  peopleOutline,
  statsChartOutline,
  calendarOutline,
  mailOutline,
} from "ionicons/icons";

import { useRouter } from "vue-router";

const leagueStore = useLeagueStore();
const router = useRouter();
import InfoComponent from "@/components/info/InfoComponent.vue";
import NewsModal from "./NewsModal.vue";
import LinksModal from "./LinksModal.vue";
import ContactModal from "./ContactModal.vue";
import SponsorModal from "./SponsorModal.vue";
import RulesComponent from "@/components/info/RulesComponent.vue";

import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { api } from "@/utils/axios";
// import NewAdComponent from "../ads/NewAdComponent.vue";

const modules = [Pagination, Navigation, Autoplay];

const showNewsModal = ref(false);
const showContactModal = ref(false);
const showLinksModal = ref(false);
const showSponsorsModal = ref(false);
const refreshKey = ref(0);

const latestNews = ref([]);
const links = ref([]);
const sponsors = ref([]);

const news = [
  // Populate with your latest news items
  {
    title: `Welcome to ${leagueStore.league.name}'s Site`,
    preview:
      "Links below to check out the schedule, standings, rules and more!",
  },
  // ... more news items ...
];

function goToOurLinks(page) {
  const leagueSlug = leagueStore.league.slug; // Make sure leagueStore.league.slug is reactive and available
  const basePath = router.currentRoute.value.path.includes("/tournament/")
    ? "tournament"
    : "league";
  router.push(`/${basePath}/${leagueSlug}/${page}`);
}

onMounted(async () => {
  try {
    const leagueSlug = leagueStore.league.slug;
    const [newsResponse, linksResponse, sponsorsResponse] = await Promise.all([
      api.get(`/players/${leagueSlug}/latest-news`),
      api.get(`/players/${leagueSlug}/links`),
      api.get(`/players/${leagueSlug}/sponsors`),
    ]);

    if (newsResponse.data.length === 0) {
      latestNews.value = news;
    } else {
      latestNews.value = newsResponse.data;
    }

    sponsors.value = sponsorsResponse.data;

    links.value = linksResponse.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
});

function directToTheirLink(link) {
  if (link.content) {
    // It's an internal link
    const basePath = router.currentRoute.value.path.includes("/tournament/")
      ? "tournament"
      : "league";
    const leagueSlug = leagueStore.league.slug;
    router.push(`/${basePath}/${leagueSlug}/news/${link.link_url}`);
  } else {
    // It's an external link
    window.open(link.link_url, "_blank");
  }
}

// const getBackgroundStyle = (newsItem) => {
//   if (newsItem.img_url) {
//     return {
//     //   backgroundImage: `url(${encodeURI(newsItem.img_url)})`,
//       backgroundSize: 'cover',
//       backgroundPosition: 'center',
//       backgroundRepeat: 'no-repeat',
//       // Add !important if necessary to override any existing styles
//       backgroundImage: `url(${encodeURI(newsItem.img_url)}) !important`
//     };
//   }
//   return {};
// };
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.header-plus-button {
  margin-left: 8px; /* Adjust as needed for spacing */
  margin-top: 10px;
  width: 39px;
  height: 39px;
  line-height: 41px;
  background: #333;
}

.header-plus-button:hover {
  background: #444;
  transition: 0.5s;
  cursor: pointer;
}

.padding {
  padding: 10px;
}

.swiper-container {
  width: 100%;
  height: 350px; /* Fixed height as you specified */
}

.swiper {
  height: 100%;
}

.swiper-wrapper {
  height: 100%;
}

.swiper-slide {
  height: 100%; /* This ensures that each slide takes full height of the swiper-container */
}

.news-slide {
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end; /* Aligns the content to the bottom */
}

.news-content {
  background-color: rgba(128, 128, 128, 0.7); /* Grey with transparency */
  padding: 20px;
  width: 100%; /* Ensures the content area spans the entire width of the slide */
  box-sizing: border-box; /* Ensures padding is included in width */
}

.sponsor-slide {
  height: 200px;
  display: block;
  width: 100%;
}

.player-wrapper {
  max-width: 800px;
  margin: 0 auto;
}
</style>
