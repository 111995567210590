<template>
  <div class="overflow-table">
    <table>
      <tr style="height: 3rem">
        <th>#</th>
        <th>Start</th>
        <th>End</th>
        <th>Venue</th>
        <th>Diamond</th>
        <th>Division</th>
        <th>Away Team</th>
        <th>Score</th>
        <th>Score</th>
        <th>Home Team</th>
        <th>Mode</th>
        <th v-if="gameStore.showSelectGames">Select Games</th>
        <th v-if="reportGames.games.length > 0">Reset</th>
      </tr>
      <game-table-rows
        v-for="game in gameStore.filteredGamesCurrentDate"
        :key="game.id"
        :game="game"
      />
    </table>
  </div>
  <no-game-card v-if="gameStore.filteredGamesCurrentDate.length < 1" />
  <div class="in-content"></div>
</template>
<script setup>
import { defineComponent } from "vue";

import { useGameStore } from "@/stores/gameStore";
import { useReportGameStore } from "@/stores/reportGameStore";
import NoGameCard from "@/components/schedule/NoGameCard.vue";
import GameTableRows from "@/components/schedule/game-table/GameTableRows.vue";
// import NewAdComponent from "@/components/ads/NewAdComponent.vue";

defineComponent({
  name: "ScheduledGamesTable",
});
const gameStore = useGameStore();
const reportGames = useReportGameStore();
// reportGames.games = []; // to reset edited score, when component is re rendered.
</script>
<style scoped>
table {
  width: 100%;
  text-align: center;
  min-width: 1000px;
}
th {
  border-bottom: 1px solid var(--ion-color-medium);
}

.overflow-table {
  overflow-x: auto; /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}
</style>
