<template>
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <ion-title>Email Confirmation</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <div v-if="loading">Confirming...</div>
        <div v-else-if="confirmed">Your email has been successfully confirmed! Redirecting you to the league...</div>
        <div v-else>Failed to confirm your email. Please try again.</div>
      </ion-content>
    </ion-page>
  </template>
  
  <script setup>
  import { onMounted, ref } from 'vue';
  import { IonTitle, IonHeader, IonContent, IonPage, IonToolbar } from '@ionic/vue';
  import { useRoute, useRouter } from 'vue-router';
  import api from '@/utils/axios';
  const router = useRouter();
  const route = useRoute();
  const loading = ref(true);
  const confirmed = ref(false);
  
  onMounted(async () => {
    const slug = route.params.token;

    try {
      const response = await api.post(`/api/confirm-email/${slug}`);
      // Assuming the API returns a success status for a valid slug
        if (response.status === 200 && response.data.league_slug) {
            confirmed.value = true;
            // Redirect after a short delay to show confirmation message
            setTimeout(() => {
                router.push(`/league/${response.data.league_slug}/dashboard`);
            }, 1500); // Adjust delay as needed
        } else {
            confirmed.value = false;
        }
    } catch (error) {
      console.error('Confirmation error:', error);
    } finally {
      loading.value = false;
    }
  });
  </script>