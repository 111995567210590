<template>
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <ion-title>Manage News</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="emitClose">
              <ion-icon :icon="closeOutline" />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-segment mode="md" v-model="segment">
          <ion-segment-button value="add">
            <ion-label>Add</ion-label>
          </ion-segment-button>
          <ion-segment-button value="edit">
            <ion-label>Edit</ion-label>
          </ion-segment-button>
        </ion-segment>
        <div class="ion-padding">
          <div v-if="segment === 'add'">
            <div v-if="newsImageThumbnail">
              <img :src="newsImageThumbnail" />
              <ion-button expand="full" color="danger" @click="resetNewsImage">Remove</ion-button>
            </div>
            <div v-else
                 class="image-upload-container"
                 @drop.prevent="onFilePicked($event, 'newsImage')"
                 @dragover.prevent>
              <ion-item lines="none">
                <ion-label>Drag & Drop here or</ion-label>
                <input type="file" @change="onFilePicked($event, 'newsImage')" accept="image/*" />
              </ion-item>
            </div>
            <ion-item>
              <ion-label position="stacked">Title</ion-label>
              <ion-input v-model="form.newsTitle" placeholder="Enter title"></ion-input>
            </ion-item>
            <div v-if="v$.newsTitle.$error">
              <span class="error-message">Title is required and must be at least 3 characters long.</span>
            </div>
            <ion-item>
              <ion-label position="stacked">Content</ion-label>
            </ion-item>
            <QuillEditor
                :options="editorOptions"
                theme="snow"
                :ref="editorRef"
                placeholder="Enter content here..."
                @ready="setNewsContent"
            />
            <div v-if="v$.newsContent.$error">
              <span class="error-message">Content is required.</span>
            </div>
            <ion-button expand="block" @click="submitNews">Save</ion-button>
            <ion-button
              v-if="isEditMode"
              expand="block"
              color="light"
              @click="clearForm"
            >Clear</ion-button>
          </div>
          <div v-if="segment === 'edit'">
            <ion-list>
              <ion-item v-for="newsItem in newsItems" :key="newsItem.id" class="ion-align-items-center">
                <ion-label>
                  <h2>{{ newsItem.title }}</h2>
                  <p>{{ newsItem.previewContent }}</p>
                </ion-label>
                <!-- <ion-button @click="editNews(newsItem)">Edit</ion-button> -->
                <ion-button color="danger" @click="deleteNews(newsItem.id)">Delete</ion-button>
              </ion-item>
            </ion-list>
          </div>
        </div>
      </ion-content>
    </ion-page>
  </template>
  
  <script setup>
  import { ref, reactive, onMounted } from 'vue';
  import { useVuelidate } from '@vuelidate/core';
  import { required, minLength } from '@vuelidate/validators';
  import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonSegment, IonSegmentButton, IonLabel, IonList, IonItem, IonInput } from '@ionic/vue';
  import { closeOutline } from 'ionicons/icons';
  import { defineEmits } from 'vue';
  import { api } from "@/utils/axios";
  import { useLeagueStore } from "@/stores/leagueStore";
  import { QuillEditor } from '@vueup/vue-quill';
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  import { showToast } from "@/utils/useIonicComponents.js";
//   import { useRouter, useRoute } from "vue-router";
  
  const leagueStore = useLeagueStore();
  const segment = ref('add');
  const newsItems = ref([]);
  const editorRef = ref(null);
  const isEditMode = ref(false);

//   const router = useRouter();
//   const route = useRoute();

  const newsImageThumbnail = ref(null);
  
  // Reactive state for form fields
  const form = reactive({
    newsTitle: '',
    newsContent: '',
    newsImage: null
  });
  
  // Vuelidate rules
  const rules = {
    newsTitle: { required, minLength: minLength(3) },
    newsContent: { required },
    newsImage: { required }
  };
  
  // Setup Vuelidate
  const v$ = useVuelidate(rules, form);
  
  // Function to submit the news
  const submitNews = async () => {
    v$.value.$touch(); // Touch all fields to trigger validation


    let uploadedImageUrl = form.newsImage; // Use existing image URL by default

    // Check if a new image file is selected and upload it
    if (typeof form.newsImage === 'object' && form.newsImage !== null) {
        try {
            uploadedImageUrl = await uploadFile(form.newsImage, 'newsImage');
        } catch (error) {
            console.error('Error uploading image:', error);
            return; // Stop the submission if the image upload fails
        }
    }

    try {
        const response = await api.post(`/players/${leagueStore.league.slug}/save-latest-news`, {
            title: form.newsTitle,
            content: form.newsContent,
            image: uploadedImageUrl // Use the uploaded image URL
        });

        triggerNewsGetWhenever()
        console.log(response);
        showToast("News added to your ticker!", "success", 6000);
        clearForm();
        emitClose();
    } catch (error) {
        console.error('Error submitting news:', error);
    }
  };
  
  // Function to clear the form
  const clearForm = () => {
    form.newsTitle = '';
    form.newsContent = '';
    form.newsImage = null;
    segment.value = 'add';
    isEditMode.value = false;
    v$.value.$reset(); // Reset validation state

    // Clear the Quill editor content

    const editor = editorRef.value.getQuill();
    editor.setContents('');

    emitClose()

  };
  
  // Function to handle file selection
  const onFilePicked = async (event) => {
    const files = event.target.files || event.dataTransfer.files;
    if (files.length > 0) {
        const file = files[0];
        if (file.type.startsWith('image/')) {
        const uploadedImageUrl = await uploadFile(file, 'newsImage');
        form.newsImage = uploadedImageUrl;
        newsImageThumbnail.value = uploadedImageUrl;
        }
    }
  };
  
  const editorOptions = {
    // ... your editor options ...
  };

  // Add the uploadFile function from AppearanceModal.vue here
 async function uploadFile(file, type) {
    const access_key = "6720c8ce-1e73-4f38-be0c52815ece-593f-4260";
    const base_url = "https://la.storage.bunnycdn.com";
    const storageName = "blacktiecollab-la";
    const path = "/slopitchcentral/news";
    const file_name = `${leagueStore.league.id}-${type}-${file.name}`;
    const cdn_url = "cdn.blacktiecollab.com";

    const response = await fetch(`${base_url}/${storageName}${path}/${file_name}`, {
        method: "PUT",
        headers: {
        AccessKey: `${access_key}`,
        },
        body: file,
    });

    if (!response.ok) {
        throw new Error('Failed to upload image');
    }

    return `https://${cdn_url}${path}/${file_name}`;
 }
  
  // Function to set the content in the Quill editor and update form.newsContent
  const setNewsContent = (editor) => {
    editor.on('text-change', () => {
        form.newsContent = editor.root.innerHTML;
        // Use optional chaining to prevent errors if v$.newsContent is undefined
        
        v$.newsContent?.$touch();
    });

    // Set the initial content if it exists
    if (form.newsContent) {
        editor.setContents(form.newsContent);
    }
  };

  const deleteNews = (id) => {

    api.post(`/players/${leagueStore.league.slug}/delete-news`, {
        id: id
    }).then((response) => {
        console.log(response);
        triggerNewsGetWhenever()
        showToast("News deleted from your ticker!", "success", 6000);
    }).catch((error) => {
        console.error('Error deleting news:', error);
    });
  }
  
  // Function to reset the news image
  const resetNewsImage = () => {
    form.newsImage = null;
    newsImageThumbnail.value = null;
  };

  const emit = defineEmits(['close']);
  
  const emitClose = () => {
    emit('close');
  };  
  
  onMounted(async () => {
    try {
      const response = await api.get(`/players/${leagueStore.league.slug}/latest-news`);
      newsItems.value = response.data;
      
    } catch (error) {
      console.error('Error fetching news items:', error);
    }
  });

  const triggerNewsGetWhenever = async () => {
    try {
      const response = await api.get(`/players/${leagueStore.league.slug}/latest-news`);
      newsItems.value = response.data;
    } catch (error) {
      console.error('Error fetching news items:', error);
    }
  }

  
    onMounted(async () => {
        try {
        const response = await api.get(`/players/${leagueStore.league.slug}/latest-news`);
        newsItems.value = response.data;
        } catch (error) {
        console.error('Error fetching news items:', error);
        }
    });
  
  </script>