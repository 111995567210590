import { defineStore } from "pinia";
import { ref } from "vue";
import { openModal } from "@/utils/useIonicComponents.js";
import ReportScoreModal from "@/components/schedule/report-score/ReportScoreModal.vue";
import { useAuthStore } from "@/stores/authStore";
import { useLeagueStore } from "@/stores/leagueStore";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { api } from "@/utils/axios";
import { showToast, showLoading, showAlert } from "@/utils/useIonicComponents";
import { useGameStore } from "./gameStore";
import { format } from "date-fns";

export const useReportGameStore = defineStore("reportGame", () => {
  const games = ref([]);
  const shortcodes = ref([]);

  async function addGamesToReport(game) {
    const authStore = useAuthStore();
    const leagueStore = useLeagueStore();
    const preferenceStore = usePreferenceStore();
    const index = games.value.findIndex((g) => g.id === game.id);
    if (index < 0) {
      // add game if not already
      if (authStore.isConvenor) {
        games.value.push(game);
        preferenceStore.setUserReportGames();
        return true;
      } else {
        //check if shortcode available in localstorage
        const codeArr = preferenceStore.getShortcode(
          game.homeTeam.name,
          game.awayTeam.name,
          leagueStore.league.id
        );
        if (
          codeArr.length > 0 ||
          preferenceStore.getAdminOverride ||
          preferenceStore.getUmpireOverride
        ) {
          games.value.push(game);
          preferenceStore.setUserReportGames();
          return true;
        } else {
          // open shortcode model if shortcode not found in localstorage
          const response = await openModal(
            ReportScoreModal,
            "reportScoreModal",
            {
              game,
            }
          );
          if (response === true) {
            games.value.push(game);
            preferenceStore.setUserReportGames();
            return true;
          } else {
            return false;
          }
        }
      }
    } else {
      games.value[index] = game;
      preferenceStore.setUserReportGames();
    }
  }
  function getGameUserReportScore(game) {
    // function used when the page is loaded and user has previously added scores but not reported
    const index = games.value.findIndex((g) => g.id === game.id);
    if (index > -1) {
      return [
        games.value[index].away_team_score,
        games.value[index].home_team_score,
      ];
    }
    return [];
  }
  function removeGameFromReport(game) {
    const index = games.value.findIndex((g) => g.id === game.id);
    if (index >= 0) {
      console.log("remove", game);
      games.value.splice(index, 1);
      const preferenceStore = usePreferenceStore();
      preferenceStore.setUserReportGames();
    }
  }

  function clearGamesFromReport() {
    games.value = []; // Clear all games from the report
    const preferenceStore = usePreferenceStore();
    preferenceStore.setUserReportGames(); // Update the preference store accordingly
  }
  // const showReportButton = computed(() => {
  //   return games.value.length > 0 ? true : false;
  // });

  let showReportButton = ref(false);

  async function sendReportRequest() {
    const loading = await showLoading(
      "Reporting Scores. Please Wait...",
      1000000000
    );
    const leagueStore = useLeagueStore();
    const authStore = useAuthStore();
    const preferenceStore = usePreferenceStore();
    const reportRequest = [];
    for (const game of games.value) {
      if (game.score_reported && !authStore.isConvenor) {
        // check if edited score is only reported by convenor
        showToast("Invalid Request!", "danger", 6000);
        return;
      }
      let shortcode = "";
      if (!authStore.isConvenor) {
        // if not convenor find shortcode in localstorage
        const codeArr = preferenceStore.getShortcode(
          game.homeTeam.name,
          game.awayTeam.name,
          leagueStore.league.id
        );
        if (codeArr.length > 0) {
          shortcode = codeArr[0].shortcode;
        } else if (preferenceStore.getAdminOverride) {
          // to do: this code should be dynamic and pulled from the api
          shortcode = leagueStore.league.admin_code;
        } else if (preferenceStore.getUmpireOverride) {
          // to do: this code should be dynamic and pulled from the api
          shortcode = leagueStore.league.umpire_code;
        } else {
          showToast("Invalid Request!", "danger", 6000);
          return;
        }
      }
      reportRequest.push(
        api.post(
          `/api/events/${leagueStore.league.id}/games/${game.id}/report`,
          {
            home_score: game.home_team_score,
            away_score: game.away_team_score,
            shortcode,
            edit: game.score_reported === 1 ? true : false,
            user: { id: authStore.user?.id },
          }
        )
      );
    }

    try {
      const results = await Promise.all(reportRequest);
      loading.dismiss();
      await leagueStore.reloadEverything(leagueStore.league.slug);
      console.log("reloadEverything");
      const fails = results.filter((result) => result.data.type === "failure");
      const failedGames = fails.map((fail) => {
        const gameStore = useGameStore();
        const game_id = Number(fail.config.url.split("/")[5]);
        const game = gameStore.games.find((game) => game.id === game_id);
        return `${game.awayTeam.name} x ${game.homeTeam.name} @ ${format(
          new Date(game.datetime),
          "EEEE, MMM do h:mm aa"
        )}: ${fail.data.error}`;
      });
      if (failedGames.length > 0) {
        showAlert({
          header: `${fails.length} error${
            fails.length > 1 ? "s" : ""
          } when reporting score`,
          message: `${failedGames}`,
          buttons: [
            {
              text: "OK",
              role: "confirm",
              handler: async () => {
                return 0;
              },
            },
          ],
        });
      } else {
        showToast("Scores Reported Successfully!", "success");
      }

      Promise.resolve();
    } catch (err) {
      await leagueStore.reloadEverything(leagueStore.league.slug);
      console.log(err);
      showToast("Score Reporting Failed", "danger");
      loading.dismiss();
      Promise.reject();
    }
  }

  // function updateSingleGameStore(game) {
  //   console.log("updateSingleGameStore", game)
  //   // Find the index of the game to be updated
  //   const gameIndex = games.value.findIndex(lgame => lgame.id === game.id);
  //   if (gameIndex === -1) {
  //     // Game not found
  //     console.error("Game not found with ID:", game.id);
  //     return;
  //   }
  //   console.log('games.value[gameIndex]', games.value[gameIndex])
  //   // Update the game scores locally
  //   games.value[gameIndex].home_team_score = game.home_team_score;
  //   games.value[gameIndex].away_team_score = game.away_team_score;
  //   games.value[gameIndex].score_reported = 1;
  //   // Notify Vue to re-render any components using this game's data
  //   games.value = [...games.value];
  // }

  return {
    games,
    shortcodes,
    showReportButton,
    addGamesToReport,
    removeGameFromReport,
    sendReportRequest,
    getGameUserReportScore,
    clearGamesFromReport,
    // updateSingleGameStore
  };
});
