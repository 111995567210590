<template>
  <form @submit.prevent="login">
    <ion-item class="ion-no-padding">
      <ion-input
        inputmode="email"
        placeholder="Email"
        type="email"
        v-model="user.email"
        @ionBlur="v$.email.$touch"
      ></ion-input>
    </ion-item>
    <ion-label color="danger" v-if="v$.email.$errors.length">{{
      v$.email.$errors[0].$message
    }}</ion-label>
    <ion-item class="ion-no-padding">
      <ion-input
        placeholder="Password"
        :type="showPassword ? 'text' : 'password'"
        v-model="user.password"
        @ionBlur="v$.password.$touch"
      >
      </ion-input>
      <ion-icon
        slot="end"
        style="cursor: pointer"
        :icon="showPassword ? eyeOff : eye"
        @click="toggleShowPassword"
      ></ion-icon>
    </ion-item>
    <ion-label color="danger" v-if="v$.password.$errors.length">
      {{ v$.password.$errors[0].$message }}
    </ion-label>
    <br />
    <ion-button expand="block" type="submit" v-if="!loading">Login</ion-button>
    <ion-button expand="block" v-if="loading"><ion-spinner /></ion-button>
  </form>
</template>

<script setup>
import { defineComponent, reactive, ref, defineEmits } from "vue";
import {
  IonInput,
  IonButton,
  IonSpinner,
  IonLabel,
  IonItem,
  IonIcon,
} from "@ionic/vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { useAuthStore } from "@/stores/authStore";
import { eye, eyeOff } from "ionicons/icons";

defineComponent({
  name: "LoginComponent",
});
const emit = defineEmits(["loggedIn"]);
const authStore = useAuthStore();
const loading = ref(false);
const user = reactive({
  email: "",
  password: "",
});

const rules = {
  email: { required, email },
  password: { required },
};

const v$ = useVuelidate(rules, user);

const showPassword = ref(false);

function toggleShowPassword() {
  showPassword.value = !showPassword.value;
}

async function login() {
  const isFormValid = await v$.value.$validate();
  if (isFormValid) {
    loading.value = true;
    const result = await authStore.login({
      email: user.email,
      password: user.password,
    });
    if (result) {
      v$.value.$reset();
      user.email = user.password = "";
      emit("loggedIn");
    }
    loading.value = false;
  }
}
</script>
