<template>
    <base-modal :modal-title="modalTitle">
        <ion-segment v-model="segment" mode="md">
            <ion-segment-button value="login">
                <ion-label>Login</ion-label>
            </ion-segment-button>
            <ion-segment-button value="register">
                <ion-label>Register</ion-label>
            </ion-segment-button> 
            <ion-segment-button value="forgot">
                <ion-label>Forgot Password</ion-label>
            </ion-segment-button> 
        </ion-segment>
        <card-wrapper :cardTitle="cardTitle" :cardSubtitle="cardSubtitle">
            <div v-if="segment === 'login'">
                <login-component @loggedIn="close(true)" />
            </div>
            <div v-if="segment === 'register'">
                <register-form @registered="close(true)" />
            </div>
            <div v-if="segment === 'forgot'">
                <form @submit.prevent="requestResetEmail">
                    <ion-label style="margin-bottom: 1rem"
                        >Please Enter your registered email</ion-label
                    >
                    <ion-input
                        inputmode="email"
                        placeholder="Email"
                        type="email"
                        v-model="user.email"
                        @ionBlur="v$.email.$touch"
                    ></ion-input>
                    <ion-label color="danger" v-if="v$.email.$errors.length">{{
                        v$.email.$errors[0].$message
                    }}</ion-label>
                    <ion-button
                        style="margin-top: 1rem"
                        expand="full"
                        type="submit"
                        v-if="!loading"
                        >Send Reset Email</ion-button
                    >
                    <ion-button expand="full" v-if="loading"><ion-spinner /></ion-button>
                </form>
            </div>
        </card-wrapper>
    </base-modal>
</template>

<script setup>
import { reactive, ref, computed } from 'vue';
import { required, email } from "@vuelidate/validators";
import { modalController, IonSegment, IonSegmentButton, IonLabel } from "@ionic/vue";
import { defineComponent } from "vue";
import CardWrapper from "@/components/layouts/CardWrapper.vue";
import BaseModal from "@/components/layouts/BaseModal.vue";
import RegisterForm from "@/components/register/RegisterForm.vue";
import LoginComponent from "@/components/login/LoginComponent.vue";
import useVuelidate from "@vuelidate/core";
import { api } from "@/utils/axios";
import { showToast } from "@/utils/useIonicComponents.js";

defineComponent({
    name: "AuthModal",
});

const segment = ref('login');

const modalTitle = computed(() => {
    switch (segment.value) {
        case 'login':
            return 'Login';
        case 'register':
            return 'Register';
        case 'forgot':
            return 'Forgot Password';
        default:
            return 'Login/Register';
    }
});

const cardTitle = computed(() => {
    switch (segment.value) {
        case 'login':
            return 'Login';
        case 'register':
            return 'Register';
        case 'forgot':
            return 'Reset Password';
        default:
            return '';
    }
});

const cardSubtitle = computed(() => {
    switch (segment.value) {
        case 'login':
            return 'Login to your account';
        case 'register':
            return 'Register for free';
        case 'forgot':
            return 'Request a password reset';
        default:
            return '';
    }
});

async function close(success = false) {
    await modalController.dismiss(success);
}

const loading = ref(false);
const user = reactive({
  email: "",
});

const rules = {
  email: { required, email },
};

const v$ = useVuelidate(rules, user);

async function requestResetEmail() {
  const isFormValid = await v$.value.$validate();
  if (isFormValid) {
    loading.value = true;
    try {
      const result = await api.post("/auth/reset-password", {
        email: user.email,
      });
      if (result.status === 200) {
        console.log("success");
        showToast("Successfully Sent Reset Email", "success");
        loading.value = false;
        modalController.dismiss();
      }
    } catch (err) {
      loading.value = false;
      console.log(err);
      showToast(
        "Something went wrong. If this persists, please contact us.",
        "danger",
        6000
      );
    }
  }
}
</script>