<template>
  <div class="qa-wrapper">
    <div class="question">What is slo-pitch?</div>
    <div class="answer">
      <p>
        Slo-pitch, also known as slow-pitch softball, is a variation of the
        sport of softball. The main distinguishing feature of slo-pitch is the
        speed at which the ball is pitched to the batter.
      </p>
      <p>Here are some key characteristics of slo-pitch:</p>
      <p>
        Pitching Style: As the name suggests, in slo-pitch softball, the ball is
        pitched slowly to the batter. The pitch must have an arc to it, with a
        height typically between 6 to 12 feet off the ground, depending on the
        league or specific rules of the game.
      </p>
      <p>
        Equipment: Equipment for slo-pitch includes a larger ball than that used
        in baseball (usually 12 inches for adult play), bats that are often made
        of composite or metal materials.
      </p>
      <p>
        Strategy: Due to the slower speed of the pitch, batters often have a
        higher chance of making contact with the ball, which can lead to higher
        scoring games and a greater emphasis on fielding.
      </p>
    </div>
  </div>
  <div class="qa-wrapper">
    <div class="question">Is Slo-Pitch Central free?</div>
    <div class="answer">
      <p>
        It is! And we intend to make sure it stays free forever by creating a
        sustainable premium product.
      </p>
      <p>
        Early adopters will receive the ability to test premium features for
        free, and will be given a discount when offered to sign up for premium.
      </p>
    </div>
  </div>

  <div class="qa-wrapper">
    <div class="question">Will there be advertising on the free platform?</div>
    <div class="answer">
      <p>
        We may choose at times to use ad servers until we're ready to launch our
        full offering.
      </p>
      <p>
        Our goal is to create a sustainable platform without ads or any sort of
        burden on the user interface. Leagues & tournaments already have enough
        going on.
      </p>
      <p>
        We plan to do this by offering premium features in the future that will
        be pay-to-use.
      </p>
    </div>
  </div>

  <div class="qa-wrapper">
    <div class="question">What can you tell us so far about premium?</div>
    <div class="answer">
      <p>
        Premium features are still under development. Register your account, and
        we will update you on premium features as they become available!
      </p>
    </div>
  </div>

  <div class="qa-wrapper">
    <div class="question">What are auto-updating standings?</div>
    <div class="answer">
      <p>
        Auto updating standings is a feature that when a score is reported, the
        standings will update appropriately.
      </p>

      <p>
        Event co-ordinators can edit scores, which will undo the standings
        created previously, and apply the new standings, keeping you up to date
        without lifting a finger.
      </p>

      <p>We still allow you to edit your standings manually, too.</p>
    </div>
  </div>

  <div class="qa-wrapper">
    <div class="question">Do team owners need accounts?</div>
    <div class="answer">
      <p>
        Nope! When uploading your schedule, teams will be automatically
        generated from the schedule list. Those teams will have auto generated
        shortcodes, or you can edit them and create your own.
      </p>

      <p>
        Teams simply need to remember their shortcode, for example "JF20", when
        entering their score. No log in needed!
      </p>

      <p>
        We may in the future offer features that require team owners to "claim"
        their team. We intend to do this as passwordless (but secure) as
        possible using 2FA inspired techniques.
      </p>
    </div>
  </div>

  <div class="qa-wrapper">
    <div class="question">What does upload any CSV schedule mean?</div>
    <div class="answer">
      <p>
        Every league uses a different scheduling software. We do require a few
        rules followed, like a CSV header row, but we can accept any scheduling
        software's CSV and turn it into a finished game list!
      </p>

      <p>
        And if you manage to trick us, we'll upload it for you and build it into
        our software!
      </p>

      <p>
        We also provide a standard CSV file to work with if you want to just use
        it instead.
      </p>

      <ion-button
        href="https://docs.google.com/spreadsheets/d/1fHhYqaB2ERPH9eRpxI0bPxK7D6CY4K5h_EXVYJRnUHA/edit?usp=sharing"
        target="_blank"
        >Download the CSV</ion-button
      >
    </div>
  </div>

  <div class="qa-wrapper">
    <div class="question">
      Can your software intelligently place teams for playoffs?
    </div>
    <div class="answer">
      <p>Simply put: yes.</p>

      <p>
        These features are still in beta but have been used successfully by a
        few leagues.
      </p>

      <p>
        We offer the ability to label a game as "___ Seed" - which will place
        the top seed in the correct game when all other games are played in the
        schedule.
      </p>

      <p>
        We also offer the abilty to move a team on using "Winner of ___" and
        "Loser of ___" which will intelligently update and move on the team to
        the next game.
      </p>
    </div>
  </div>
</template>
<script setup>
import { defineComponent } from "vue";
import { IonButton } from "@ionic/vue";
defineComponent({
  name: "FrequentlyAskedQuestions",
});
</script>
<style scoped>
.qa-wrapper {
  padding: 10px 0;
  line-height: 1.5;
}

.question {
  padding-bottom: 5px;
  font-weight: 700;
  background: #7d38f0;
  padding: 10px;
  color: #fff;
}

.answer {
  padding: 10px;
  font-size: 18px;
  line-height: 1.5;

  
}

.answer p { 
  margin-bottom: 10px;    
  font-size: 18px;
}
</style>
