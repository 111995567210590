<template>
    <ion-page>
        <ion-header>
            <ion-toolbar class="header-toolbar">
                <!-- <ion-buttons slot="start">
                    <ion-back-button
                        defaultHref="/ballparks"
                        color="dark"
                    ></ion-back-button>
                </ion-buttons> -->
                <ion-title>Scorekeeper</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <div>
                <ion-card v-if="authStore.user">
                    <ion-card-header>
                        <ion-card-title>Games Saved on Server</ion-card-title>
                        <ion-card-subtitle>When logged in, sync games to the server in order to keep them forever.</ion-card-subtitle>
                    </ion-card-header>
                    <ion-card-content>
                        <ion-list>
                            <ion-item v-for="game in savedOnServer" :key="game.gameId" >
                                <ion-label>
                                    {{ game.away_team_name }} vs {{ game.home_team_name }} <br>
                                    <span style="font-size: 12px;" v-if="game.start_time">Started On {{ new Date(game.start_time).toLocaleDateString() }} at {{ new Date(game.start_time).toLocaleTimeString() }}</span>
                                    <br>
                                    <!-- You can add more details here if needed -->
                                    <span 
                                        style="font-size: 12px; font-weight: 700;"
                                        v-if="game.game_ended === 0"    
                                    >
                                        Currently: {{ game.current_half === 0 ? 'Top' : 'Bottom' }} of Inning {{ game.current_inning }}
                                    </span>
                                </ion-label>                                
                                <!-- <ion-button
                                    @click="goToGame(game.gameId)"
                                    style="margin-right: 20px;"
                                >
                                    View
                                </ion-button> -->
                                <!-- <ion-button
                                    @click="deleteStateGame(game.gameId)"
                                    color="danger">
                                    Delete
                                </ion-button> -->
                            </ion-item>
                        </ion-list>
                    </ion-card-content>
                </ion-card>
                <ion-card>
                    <ion-card-header>
                        <ion-card-title>Games Saved on Device</ion-card-title>
                        <ion-card-subtitle>You can save up to 10 games locally on your device. Register an account to store more.</ion-card-subtitle>
                    </ion-card-header>
                    <ion-card-content>
                        <ion-list>
                            <ion-item v-for="game in savedGames" :key="game.gameId" >
                                <ion-label>
                                    {{ game.state.awayTeam.name }} vs {{ game.state.homeTeam.name }} <br>
                                    <span style="font-size: 12px;" v-if="game.state.startTime">Started On {{ new Date(game.state.startTime).toLocaleDateString() }} at {{ new Date(game.state.startTime).toLocaleTimeString() }}</span>
                                    <br>
                                    <span 
                                        style="font-size: 12px; font-weight: 700;"
                                        v-if="game.state.gameEnded === false"
                                    >Currently: {{ game.state.currentHalf === 0 ? 'Top' : 'Bottom' }} of Inning {{ game.state.currentInning }}</span>
                                    <span v-else>Game Ended</span>
                                    <!-- You can add more details here if needed -->
                                </ion-label>                                
                                <ion-button
                                    @click="goToGame(game.gameId)"
                                    style="margin-right: 20px;"
                                >
                                    View
                                </ion-button>
                                <ion-button
                                    @click="deleteStateGame(game.gameId)"
                                    color="danger">
                                    Delete
                                </ion-button>
                            </ion-item>
                        </ion-list>        
                    </ion-card-content>
                    <!-- <ion-button 
                        fill="clear"
                           
                    >
                        Sync Finished Games to Server
                    </ion-button> -->
                </ion-card>
                
                <ion-button
                    expand="full"
                    v-if="savedGames.length <= 10"
                    v-on:click="createNewGame()">Start New Game</ion-button>

                <ion-button
                    v-if="savedGames.length > 9"
                    expand="full"
                    disabled
                >
                    You have reached the maximum number of saved games. To save more games, register an account and save your games to the server.
                </ion-button>
            </div>
        </ion-content>
    </ion-page>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { IonButton, IonList, IonItem, IonLabel, IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonCardSubtitle, onIonViewWillEnter } from '@ionic/vue';

import { api } from '@/utils/axios'
import { useAuthStore } from '@/stores/authStore'

import { useScorekeepStore } from '@/stores/scorekeepStore'
import { useRouter } from 'vue-router'

const authStore = useAuthStore()
const scorekeepStore = useScorekeepStore()
const router = useRouter()

const savedGames = ref([]);

const savedOnServer = ref([])
// import ScoreboardPage from '@/components/scorekeep/ScoreboardPage.vue'

// const selectedSegment = ref('aacreateNewGame')

onMounted(() => {
    console.log('onMounted')
    const games = scorekeepStore.listSavedGameStates()
    console.log('games', games)
    savedGames.value = games
    getGamesFromServer()
})

onIonViewWillEnter(() => {
    console.log('ionViewWillEnter')
    const games = scorekeepStore.listSavedGameStates()
    console.log('games', games)
    savedGames.value = games
    getGamesFromServer()
})

const deleteStateGame = (gameId) => {
    console.log('deleteStateGame', gameId)
    scorekeepStore.clearState(gameId)
    const games = scorekeepStore.listSavedGameStates()
    console.log('games', games)
    savedGames.value = games
}


function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}


const getGamesFromServer = () => {
    console.log('getGamesFromServer')
    if (authStore.user) {
        api.get(`/api/scorekeep/get-user-games/${authStore.user.id}`)
        .then(response => {
            console.log('response', response)
            savedOnServer.value = response.data
        })
        .catch(error => {
            console.error('error', error)
        })
    } else {
        console.log('No user logged in');
    }
}

const goToGame = (gameId) => {
    router.push({ name: 'scorekeepGame', params: { game_id: gameId } });
}

const createNewGame = () => {
    console.log('createNewGame')
    const slug = scorekeepStore.saveState(uuidv4(), true)
    console.log('slug', slug)

    // redirect to /scorekeep/slug
    router.push({ name: 'scorekeepGame', params: { game_id: slug } })


    // selectedSegment.value = 'createNewGame'
}


</script>
