<template>
  <base-modal modal-title="Register">
    <card-wrapper cardTitle="Register" cardSubtitle="Register for free">
      <register-form @registered="close(true)" />
    </card-wrapper>
  </base-modal>
</template>

<script setup>
import { modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import CardWrapper from "@/components/layouts/CardWrapper.vue";
import BaseModal from "@/components/layouts/BaseModal.vue";
import RegisterForm from "@/components/register/RegisterForm.vue";

defineComponent({
  name: "RegisterModal",
});

async function close(success = false) {
  await modalController.dismiss(success);
}
</script>
