<template>
  <table>
    <tr style="height: 3rem">
      <th style="padding-right: 10px; width: 8%">Start Date</th>
      <th style="padding-right: 10px; width: 8%">Start Time</th>
      <th style="padding-right: 10px; width: 8%">End Time</th>
      <th style="padding-right: 10px; width: 10%">Park Name</th>
      <th style="padding-right: 10px; width: 10%">Diamond</th>
      <th style="width: 13%">Division</th>
      <th style="width: 13%">Away Team</th>
      <th style="width: 13%">Home Team</th>
      <th style="width: 8%" v-if="props.choice === 'EditTeams'">Mode</th>
      <th style="width: 8%" v-if="props.choice === 'EditTeams'">
        If Necessary
      </th>
      <th
        style="width: 11%"
        v-if="props.choice !== 'Delete' && props.choice !== 'EditTeams'"
      >
        Reason
      </th>
      <th style="width: 5%">Actions</th>
    </tr>
    <edit-selected-game-form
      v-for="(game, index) in gameStore.selectedGames"
      :key="game"
      :index="index"
      :choice="props.choice"
    />
  </table>
</template>

<script setup>
import { defineComponent, defineProps } from "vue";
import EditSelectedGameForm from "./EditSelectedGameForm.vue";
import { useGameStore } from "@/stores/gameStore";

defineComponent({
  name: "EditSelectedGame",
});
const props = defineProps({ choice: String });
const gameStore = useGameStore();
</script>
<style scoped>
.input-row {
  height: 3.5rem;
}
table {
  width: 100%;
}
th {
  border-bottom: 1px solid var(--ion-color-medium);
}
</style>
