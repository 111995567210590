<template>
  <div style="margin-top: 2rem">
    <ion-grid>
      <ion-row
        v-for="(division, index) of teamsData.divisions"
        :key="index"
        class="row-bottom"
      >
        <ion-col class="division-data-row">
          <div class="team-data-row">
            <ion-item class="flex-item">
              <ion-label position="stacked">Division Name</ion-label>
              <ion-input
                class="division-name"
                v-model="division.name"
                placeholder="Enter division name here..."
              ></ion-input>
            </ion-item>
            <ion-button
              expand="full"
              color="light"
              @click="openDivisionModal(division)"
              ><ion-icon :icon="cogOutline"></ion-icon>
            </ion-button>
            <ion-button
              expand="full"
              color="danger"
              @click="removeDivision(index)"
              ><ion-icon :icon="trashOutline"></ion-icon>
            </ion-button>
          </div>
          <div class="special-requests" style="margin-left: 15px">
            <span
              v-for="(specialRequestKey, key) of division.specialRequests"
              :key="specialRequestKey"
            >
              <div v-if="specialRequestKey" :outline="true" class="new-chip">
                <b>{{ formattedDivisionSpecialRequests[key] }}:</b>&nbsp;
                <span v-if="key === 'cannot_play_at_parks'"
                  >&nbsp;{{
                    specialRequestKey
                      .map((item) => item.park_diamond_name)
                      .join(", ")
                  }}</span
                >
                <span v-else>&nbsp;{{ specialRequestKey }}</span>
              </div>
            </span>
          </div>
          <div class="button-wrapper">
            <ion-button
              expand="full"
              color="primary"
              style="margin-top: 2rem"
              @click="addDivision"
            >
              <ion-icon :icon="addOutline"></ion-icon>
              Add Division
            </ion-button>
          </div>
        </ion-col>
        <ion-col>
          <div
            v-for="(team, teamIndex) in division.teams"
            :key="teamIndex"
            class="team-row"
          >
            <div class="team-data-row">
              <div
                style="width: 40px; height: 40px; margin-top: 20px"
                :style="{ backgroundColor: team.color.color }"
              ></div>
              <ion-item class="flex-item">
                <ion-label position="stacked">Team Name</ion-label>
                <ion-input
                  v-model="team.name"
                  placeholder="Enter team name here..."
                ></ion-input>
              </ion-item>

              <ion-button
                expand="full"
                style="margin-top: 20px"
                @click="openModal(team)"
              >
                <ion-icon :icon="cogOutline"></ion-icon>
              </ion-button>
              <ion-button
                expand="full"
                color="danger"
                style="margin-top: 21px"
                @click="removeTeam(index, teamIndex)"
                ><ion-icon :icon="trashOutline"></ion-icon
              ></ion-button>
            </div>
            <div class="special-requests">
              <span
                v-for="(specialRequestKey, key) of team.specialRequests"
                :key="specialRequestKey"
              >
                <div v-if="specialRequestKey" :outline="true" class="new-chip">
                  <b>{{ formattedSpecialRequests[key] }}:</b>
                  {{ specialRequestKey }}
                </div>
              </span>
            </div>
          </div>
          <div class="button-wrapper">
            <ion-button
              color="tertiary"
              expand="full"
              style="margin-top: 2rem"
              @click="addTeam(index)"
            >
              <ion-icon :icon="addOutline"></ion-icon>
              Add Team
            </ion-button>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-modal
      :is-open="showDivisionModal"
      @didDismiss="showDivisionModal = false"
    >
      <ion-header>
        <ion-toolbar>
          <ion-title
            >Edit <u>{{ selectedDivision.name }}</u> Settings</ion-title
          >
          <ion-buttons slot="end">
            <ion-button color="dark" @click="showDivisionModal = false">
              <ion-icon :icon="closeOutline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-item>
          <ion-label position="stacked">Assigned Dates</ion-label>
          <ion-select
            :multiple="true"
            v-model="selectedDivision.specialRequests.plays_on_these_days"
          >
            <ion-select-option value="any"
              >Schedule on Any Date</ion-select-option
            >
            <ion-select-option value="monday">Mondays</ion-select-option>
            <ion-select-option value="tuesday">Tuesdays</ion-select-option>
            <ion-select-option value="wednesday">Wednesdays</ion-select-option>
            <ion-select-option value="thursday">Thursdays</ion-select-option>
            <ion-select-option value="friday">Fridays</ion-select-option>
            <ion-select-option value="saturday">Saturdays</ion-select-option>
            <ion-select-option value="sunday">Sundays</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label position="stacked"
            >Prevent This Division from Playing at Certain Diamonds:</ion-label
          >
          <ion-select
            :multiple="true"
            v-model="selectedDivision.specialRequests.cannot_play_at_parks"
            :compare-with="compareWith"
          >
            <ion-select-option
              v-for="(diamond, key) of computedParkDiamondCombos"
              :key="key"
              :value="diamond"
            >
              {{ diamond.park_diamond_name }}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <!-- <ion-item>
                    <ion-label position="stacked">Preferred Start Time</ion-label>
                    <ion-select v-model="selectedDivision.specialRequests.preferred_start_time">
                        <ion-select-option value="earliest">Earliest Start Time</ion-select-option>
                        <ion-select-option value="latest">Latest Start Time</ion-select-option>
                        <ion-select-option value="none">None</ion-select-option>
                    </ion-select>
                </ion-item> -->
        <!-- <ion-item>
                    <ion-label position="stacked">Preference Frequency</ion-label>
                    <ion-select v-model="selectedDivision.specialRequests.preference_frequency">
                        <ion-select-option value="always">Always</ion-select-option>
                        <ion-select-option value="sometimes">Sometimes</ion-select-option>
                        <ion-select-option value="never">Never</ion-select-option>
                    </ion-select>
                </ion-item> -->
        <!-- <ion-item>
                    <ion-label position="stacked">Notes</ion-label>
                    <ion-textarea v-model="selectedDivision.specialRequests.notes"></ion-textarea>
                </ion-item> -->
      </ion-content>
    </ion-modal>

    <ion-modal :is-open="showModal" @didDismiss="showModal = false">
      <ion-header>
        <ion-toolbar>
          <ion-title
            >Edit <u>{{ selectedTeam.name }}</u> Settings</ion-title
          >
          <ion-buttons slot="end">
            <ion-button color="dark" @click="showModal = false">
              <ion-icon :icon="closeOutline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-item>
          <ion-label position="stacked">Color</ion-label>
          <ion-input v-model="selectedTeam.color.color"></ion-input>
          <div
            style="width: 20px; height: 20px; border: 1px solid #000"
            :style="{ backgroundColor: selectedTeam.color.color }"
          ></div>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Assigned Dates</ion-label>
          <ion-select
            :multiple="true"
            v-model="selectedTeam.specialRequests.plays_on_these_days"
          >
            <ion-select-option value="any"
              >Schedule on Any Date</ion-select-option
            >
            <ion-select-option value="monday">Mondays</ion-select-option>
            <ion-select-option value="tuesday">Tuesdays</ion-select-option>
            <ion-select-option value="wednesday">Wednesdays</ion-select-option>
            <ion-select-option value="thursday">Thursdays</ion-select-option>
            <ion-select-option value="friday">Fridays</ion-select-option>
            <ion-select-option value="saturday">Saturdays</ion-select-option>
            <ion-select-option value="sunday">Sundays</ion-select-option>
          </ion-select>
        </ion-item>
        <!-- <ion-item>
                    <ion-label position="stacked">Preferred Start Time</ion-label>
                    <ion-select v-model="selectedTeam.specialRequests.preferred_start_time">
                        <ion-select-option value="earliest">Earliest Start Time</ion-select-option>
                        <ion-select-option value="latest">Latest Start Time</ion-select-option>
                        <ion-select-option value="none">None</ion-select-option>
                    </ion-select>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">Preference Frequency</ion-label>
                    <ion-select v-model="selectedTeam.specialRequests.preference_frequency">
                        <ion-select-option value="always">Always</ion-select-option>
                        <ion-select-option value="sometimes">Sometimes</ion-select-option>
                        <ion-select-option value="never">Never</ion-select-option>
                    </ion-select>
                </ion-item> -->
        <ion-item>
          <ion-label position="stacked">Coach's Name</ion-label>
          <ion-input
            v-model="selectedTeam.specialRequests.coach_name"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Coach's Email</ion-label>
          <ion-input
            v-model="selectedTeam.specialRequests.coach_email"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Coach's Phone</ion-label>
          <ion-input
            v-model="selectedTeam.specialRequests.coach_phone"
          ></ion-input>
        </ion-item>
        <!-- <ion-item>
                    <ion-label position="stacked">Notes</ion-label>
                    <ion-textarea v-model="selectedTeam.specialRequests.notes"></ion-textarea>
                </ion-item> -->
      </ion-content>
    </ion-modal>
  </div>
</template>

<script>
import {
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonItem,
  IonIcon,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  //   IonTextarea,
  //   IonChip,
  IonContent,
} from "@ionic/vue";
import {
  trashOutline,
  addOutline,
  cogOutline,
  closeOutline,
} from "ionicons/icons";
import uniqolor from "uniqolor";

export default {
  emits: ["update:teamsData", "nextStep"],
  data() {
    return {
      trashOutline,
      addOutline,
      cogOutline,
      closeOutline,
      showModal: false,
      showDivisionModal: false,
      selectedDivision: {
        name: null,
        color: null,
        specialRequests: {
          cannot_play_at_parks: [],
          plays_on_these_days: ["any"],
          preferred_start_time: "none", // earliest start time, latest start time, none
          preference_frequency: "never",
          notes: null, // options include always, sometimes, never
        },
      },
      selectedTeam: {
        name: null,
        color: null,
        specialRequests: {
          preferred_start_time: "none",
          plays_on_these_days: ["any"],
          preference_frequency: "never",
          coach_name: null,
          coach_email: null,
          coach_phone: null,
          notes: null,
        },
      },
    };
  },
  components: {
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonInput,
    IonItem,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    // IonTextarea,
    // IonChip,
    IonContent,
  },
  props: {
    teamsData: Object,
    parksData: Object,
  },
  computed: {
    computedParkDiamondCombos() {
      if (!this.parksData || !this.parksData.parks) {
        return [];
      }

      let combos = [];
      for (const park of this.parksData.parks) {
        for (const diamond of park.diamonds) {
          combos.push({
            park_name: park.name,
            diamond_name: diamond.name,
            park_diamond_name: `${park.name} - ${diamond.name}`,
          });
        }
      }

      return combos;
    },
    formattedDivisionSpecialRequests() {
      let formattedRequests = {};
      for (let key in this.selectedDivision.specialRequests) {
        let formattedKey = key
          .split("_") // split the string into words
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // capitalize the first letter of each word
          .join(" "); // join the words back together with spaces
        formattedRequests[key] = formattedKey;
      }
      return formattedRequests;
    },
    formattedSpecialRequests() {
      let formattedRequests = {};
      for (let key in this.selectedTeam.specialRequests) {
        let formattedKey = key
          .split("_") // split the string into words
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // capitalize the first letter of each word
          .join(" "); // join the words back together with spaces
        formattedRequests[key] = formattedKey;
      }
      return formattedRequests;
    },
  },
  methods: {
    openModal(team) {
      this.selectedTeam = team;
      this.showModal = true;
    },
    compareWith(option1, option2) {
      return option1.park_diamond_name === option2.park_diamond_name;
    },
    openDivisionModal(division) {
      console.log("option");
      this.selectedDivision = division;
      this.showDivisionModal = true;
    },
    addDivision() {
      let teamsData = structuredClone(this.teamsData);
      teamsData.divisions.push({
        name: "",
        color: uniqolor.random(),
        specialRequests: {
          cannot_play_at_parks: [],
          plays_on_these_days: ["any"],
          preferred_start_time: "none", // earliest start time, latest start time, none
          preference_frequency: "never",
          notes: null, // options include always, sometimes, never
        },
        teams: [],
      });
      this.$emit("update:teamsData", teamsData);
    },
    addTeam(divisionIndex) {
      let teamsData = structuredClone(this.teamsData);
      if (teamsData.divisions[divisionIndex]) {
        teamsData.divisions[divisionIndex].teams.push({
          name: "",
          color: uniqolor.random(),
          specialRequests: {
            preferred_start_time: "none", // earliest start time, latest start time, none
            preference_frequency: "never",
            plays_on_these_days: ["any"],
            coach_name: null,
            coach_email: null,
            coach_phone: null,
            notes: null, // options include always, sometimes, never
          },
        });
        this.$emit("update:teamsData", teamsData);
      } else {
        console.error(`Division at index ${divisionIndex} does not exist.`);
      }
    },
    removeDivision(divisionIndex) {
      let teamsData = structuredClone(this.teamsData); // Deep copy
      if (teamsData.divisions[divisionIndex]) {
        teamsData.divisions.splice(divisionIndex, 1);
        this.$emit("update:teamsData", teamsData);
      } else {
        console.error(`Division at index ${divisionIndex} does not exist.`);
      }
    },
    removeTeam(divisionIndex, teamIndex) {
      let teamsData = structuredClone(this.teamsData);
      if (
        teamsData.divisions[divisionIndex] &&
        teamsData.divisions[divisionIndex].teams[teamIndex]
      ) {
        teamsData.divisions[divisionIndex].teams.splice(teamIndex, 1);
        this.$emit("update:teamsData", teamsData);
      } else {
        console.error(
          `Team at index ${teamIndex} in division at index ${divisionIndex} does not exist.`
        );
      }
    },
    formatKey(key) {
      return key
        .split("_") // split the string into words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // capitalize the first letter of each word
        .join(" "); // join the words back together with spaces
    },
  },
};
</script>

<style scoped>
.team-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.team-data-row {
  display: flex;
  flex-direction: row;
}

.team-row > div {
  width: 100%;
}

.flex-item {
  flex-grow: 1;
}

.button-wrapper {
  max-width: 250px;
  margin: 0 auto;
}

.row-bottom {
  padding-bottom: 2rem;
  border-bottom: 2px solid #ddd;
  margin-bottom: 2rem;
}

.row-bottom:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.division-name {
  font-size: 30px;
}

.font-size {
  font-size: 10px;
  font-weight: 700;
}

.new-chip {
  font-size: 11px;

  margin: 0 5px;
}

.special-requests {
  display: none;
}

.team-row:hover .special-requests {
  display: block;
}

.division-data-row:hover .special-requests {
  display: block;
}
</style>
