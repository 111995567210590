<template>
  <ion-grid>
    <ion-row>
      <template v-for="(game, index) in gameStore.filteredGamesCurrentDate" :key="game.id">
        <!-- Ad placement for mobile -->
        <ion-col class="recurring-mobile-ad" v-if="isPlatform('mobile') && index > 0 && index % 3 === 0" size="12">
          <div :id="`mobileRectAtf${getMobileAdIndex(index)}`"></div>
        </ion-col>
        
        <!-- Ad placement for desktop -->
        <ion-col class="recurring-desktop-ad" v-if="isPlatform('desktop') && index > 0 && index % 6 === 0" size="12">
          <div :id="`inlineRectAtf${getDesktopAdIndex(index)}`"></div>
        </ion-col>
        
        <!-- Game card -->
        <ion-col size="12" size-md="6" size-xl="4">
          <game-card :game="game" />
        </ion-col>
      </template>
      
      <!-- No games message -->
      <ion-col v-if="gameStore.filteredGamesCurrentDate.length === 0" size="12">
        <no-game-card />
      </ion-col>
      
      <!-- Additional ad placements -->
      <ion-col class="additional-mobile-ad" v-if="shouldShowAdditionalMobileAd" size="12">
        <div id="mobileRectAtf2"></div>
      </ion-col>
      
      <ion-col class="additional-desktop-ad" v-if="shouldShowAdditionalDesktopAd" size="12">
        <div id="inlineRectAtf2"></div>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script setup>
import { defineComponent, computed } from "vue";
import { IonGrid, IonRow, IonCol, isPlatform } from "@ionic/vue";

import { useGameStore } from "@/stores/gameStore";
import GameCard from "./GameCard.vue";
import NoGameCard from "@/components/schedule/NoGameCard.vue";
// import NewAdComponent from "@/components/ads/NewAdComponent.vue";

defineComponent({
  name: "ScheduledGamesGrid",
});
const gameStore = useGameStore();

const shouldShowAdditionalMobileAd = computed(() => {
  return isPlatform('mobile') && 
         gameStore.filteredGamesCurrentDate.length > 0 &&
         gameStore.filteredGamesCurrentDate.length % 3 !== 0;
});

const shouldShowAdditionalDesktopAd = computed(() => {
  const gamesCount = gameStore.filteredGamesCurrentDate.length;
  console.log('shouldShowAdditionalDesktopAd', isPlatform('desktop') && 
  (gamesCount < 6 || (gamesCount > 6 && gamesCount % 6 !== 0)));
  return isPlatform('desktop') && 
         (gamesCount < 6 || (gamesCount > 6 && gamesCount % 6 !== 0));
});


const getMobileAdIndex = (gameIndex) => {
  return Math.floor(gameIndex / 3);
};

const getDesktopAdIndex = (gameIndex) => {
  return Math.floor(gameIndex / 6);
};

</script>
