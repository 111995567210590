<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-title>Submit Scores</ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="emitClose">
                        <ion-icon :icon="closeOutline" />
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content style="position: relative;">
            <span v-for="game of props.games" :key="game.id">
                <game-card-popup-wrapper
                    :game="game"
                    :inPopup="true"
                ></game-card-popup-wrapper>
            </span>
        </ion-content>
    </ion-page>
</template>

<script setup>
import { IonPage, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonContent } from "@ionic/vue";
// import GameCardHeader from "@/components/schedule/game-grid/GameCardHeader.vue";
import GameCardPopupWrapper from "@/components/schedule/game-grid/GameCardPopupWrapper.vue";
// import GameCardContent from "@/components/schedule/game-grid/GameCardContent.vue";
// import GameCardFooter from "@/components/schedule/game-grid/GameCardFooter.vue";
import { useReportGameStore } from "@/stores/reportGameStore";
import { closeOutline } from "ionicons/icons";
// import { closeOutline, alertCircleOutline } from "ionicons/icons";
// import { differenceInSeconds } from "date-fns";
import {  defineProps, defineEmits } from "vue";

const emit = defineEmits(['close']);
// const resetScore = ref(0);
// const showReportButton = ref(false)

const reportStore = useReportGameStore()

const props = defineProps({
    games: {
        type: Array,
        required: true,
    },
});

const emitClose = () => {
    emit('close');
};

reportStore.clearGamesFromReport()

</script>