<template>
  <ion-button @click="showAnnouncements">
    <ion-icon size="large" color="dark" :icon="notificationsCircleOutline" />
    <ion-badge
      v-if="notificationCount > 0"
      style="
        font-size: 10px;
        position: absolute;
        bottom: 15px;
        left: 18px;
        --padding-end: 4px;
        --padding-start: 4px;
      "
      color="danger"
      >{{ notificationCount }}</ion-badge
    >
  </ion-button>
</template>
<script setup>
import { defineComponent, computed } from "vue";
import { IonButton, IonIcon, IonBadge } from "@ionic/vue";
import { useLeagueStore } from "@/stores/leagueStore";
import { notificationsCircleOutline } from "ionicons/icons";
import AnnouncementModal from "@/components/announcements/AnnouncementModal.vue";
import { openModal } from "@/utils/useIonicComponents.js";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { compareAsc } from "date-fns";
defineComponent({
  name: "AnnouncementNotificationComponent",
});
const leagueStore = useLeagueStore();
const preferenceStore = usePreferenceStore();
const notificationCount = computed(() => {
  const unread = leagueStore.league.announcements.filter(
    (announcement) =>
      compareAsc(
        new Date(announcement.created_at),
        new Date(preferenceStore.userNotificationLastOpened)
      ) >= 0
  );
  return unread.length;
});

function showAnnouncements() {
  openModal(AnnouncementModal, "announcementModal");
}
</script>
