<template>
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <ion-title>{{ newsItem.title }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <div v-if="newsItem">
          <img :src="newsItem.image" alt="Header Image" />
          <h2>{{ newsItem.title }}</h2>
          <p>{{ newsItem.date }}</p>
          <div v-html="newsItem.content"></div>
        </div>
        <div v-else>
          <p>Loading news...</p>
        </div>
      </ion-content>
    </ion-page>
  </template>
  
  <script setup>
  import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
  import { ref, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import api from '@/utils/axios';
  
  const route = useRoute();
  const newsItem = ref(null);
  
  onMounted(async () => {
    const slug = route.params.newsSlug;
    try {
      const response = await api.get(`/players/latest-news/${slug}`);
      newsItem.value = response.data;
    } catch (error) {
      console.error('Error fetching news item:', error);
    }
  });
  </script>