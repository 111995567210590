<template>
  <div>
    <ion-list>
      <ion-item
        :class="filteredTeam(team)"
        v-for="(team, team_key) in props.division.teams"
        :key="team_key"
        :style="{
          // '--border-color': team?.color ? team?.color : uniqolor(team.name).color,
          'border-left': `3px solid ${
            team?.color ? team?.color : uniqolor(team.name).color
          }`,
        }"
      >
        <ion-label>
          <h2>{{ team.name }}</h2>
          <p v-if="authStore.isConvenor">
            Shortcode: {{ team.shortcode }}
            <a
              @click="copyText(team.shortcode)"
              style="margin-left: 10px; cursor: pointer"
              >Click to Copy</a
            >
          </p>
          <!-- <p v-if="team.coach_full_name && authStore.isConvenor">
            Coach: {{ team.coach_full_name }}
            Email: {{ team.coach_email }}
            Phone: {{ team.coach_phone }}
          </p> -->
        </ion-label>
        <ion-button
          v-if="authStore.isConvenor"
          slot="end"
          @click="setOpen(team_key, true)"
          :style="{
            '--background': team?.color
              ? team?.color
              : uniqolor(team.name).color,
          }"
        >
          <ion-icon :icon="ellipsisVerticalOutline"></ion-icon>
        </ion-button>
        <ion-action-sheet
          v-if="authStore.isConvenor"
          :is-open="isOpen[team_key]"
          :header="`${team.name} Actions`"
          :buttons="getActionSheetButtons(team)"
          @didDismiss="setOpen(team_key, false)"
        ></ion-action-sheet>
      </ion-item>
    </ion-list>
  </div>
</template>

<script setup>
import { defineComponent, defineProps, reactive, watch } from "vue";
import EditTeamsModal from "@/components/teams/EditTeamsModal.vue";
import { openModal } from "@/utils/useIonicComponents.js";
import { useGameStore } from "@/stores/gameStore";
// import { useAuthStore } from "@/stores/authStore";
import { showToast } from "@/utils/useIonicComponents.js";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/authStore";

const authStore = useAuthStore();

import {
  IonList,
  IonItem,
  IonActionSheet,
  IonIcon,
  IonButton,
  IonLabel,
} from "@ionic/vue";

import {
  // shareOutline,
  // personCircleOutline,
  // atOutline,
  // callOutline,
  // createOutline,
  ellipsisVerticalOutline,
} from "ionicons/icons";

// const authStore = useAuthStore();
import uniqolor from "uniqolor";

const gameStore = useGameStore();
const route = useRoute();

defineComponent({
  name: "TeamsDesktop",
});
const props = defineProps({ division: Object });

async function openEditModal(team) {
  const editTeamsModalData = {
    team,
    division: props.division.division_name,
    leagueSlug: route.params.leagueSlug,
  };
  localStorage.setItem(
    "editTeamsModalData",
    JSON.stringify(editTeamsModalData)
  );
  await openModal(EditTeamsModal, "editTeamsModal", {
    team: team,
    division: props.division.division_name,
  });
}

const filteredTeam = (team) => {
  if (gameStore.gameFilters.team.includes(team.id)) {
    return "filtered-teams";
  }
  return "";
};

let isOpen = reactive({});

props.division.teams.forEach((team, team_key) => {
  isOpen[team_key] = false;
});

watch(
  () => props.division.teams,
  (newTeams) => {
    newTeams.forEach((team, team_key) => {
      if (isOpen[team_key] === undefined) {
        isOpen[team_key] = false;
      }
    });
  },
  { immediate: true }
);

const setOpen = (team_key, state) => {
  isOpen[team_key] = state;
};

let actionSheetButtons = {};

props.division.teams.forEach((team, team_key) => {
  actionSheetButtons[team_key] = [
    {
      text: "Edit Team",
      // icon: createOutline,
      handler: () => {
        openEditModal(team);
      },
    },
    {
      text: "Copy Shortcode",
      // icon: shareOutline,
      handler: () => {
        copyText(team.shortcode);
      },
    },
    {
      text: "View Contact Card",
      // icon: personCircleOutline,
      handler: () => {
        // Implement your logic to open the contact card modal
      },
    },
    {
      text: "Cancel",
      // icon: 'close',
      role: "cancel",
    },
  ];
});

const getActionSheetButtons = (team) => [
  {
    text: "Edit Team",
    // icon: createOutline,
    handler: () => {
      openEditModal(team);
    },
  },
  // {
  //   text: 'Copy Shortcode',
  //   // icon: shareOutline,
  //   handler: () => {
  //     copyText(team.shortcode);
  //   }
  // },
  {
    text: "View Contact Card",
    // icon: personCircleOutline,
    handler: () => {
      // Implement your logic to open the contact card modal
    },
  },
  {
    text: "Cancel",
    // icon: 'close',
    role: "cancel",
  },
];

const copyText = (text) => {
  const textarea = document.createElement("textarea");
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);

  showToast("Copied to clipboard.", "success", 3000);
};
</script>

<style scoped>
.team-card {
  border-top: 3px solid;
}

.team-card-header {
  font-size: 18px;
}
ion-card-subtitle {
  margin-top: 5px;
  font-size: 14px;
}
.filtered-teams {
  border-color: #7d38f0;
  border-width: 4px;
  border-style: solid;
  box-shadow: 0px 6px 6px 1px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 6px 6px 1px rgba(0, 0, 0, 0.3);
}

.team-card {
  border-left: 3px solid;
  --border-color: var(--ion-color-primary);
}
</style>
