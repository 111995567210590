<template>
  <ion-grid>
    <ion-row v-for="(games, dateIndex) in gameStore.dateWiseFilteredGames" :key="games.date">
      <ion-col size="12">
        <div class="date-card">
          <h1>
            <span class="date-label">
              {{ formatDate(games.date) }}
            </span>
          </h1>
        </div>
      </ion-col>
      
      <template v-for="(game, gameIndex) in games.games" :key="game.id">
        <!-- Ad placement for mobile -->
        <ion-col v-if="shouldShowMobileAd(gameIndex)" size="12" class="ad-slot mobile">
          <div :id="`mobileRectAtf${getMobileAdIndex(dateIndex, gameIndex)}`"></div>
        </ion-col>
        
        <!-- Ad placement for desktop -->
        <ion-col v-if="shouldShowDesktopAd(gameIndex)" size="12" class="ad-slot desktop">
          <div :id="`inlineRectAtf${getDesktopAdIndex(dateIndex, gameIndex)}`"></div>
        </ion-col>
        
        <!-- Game card -->
        <ion-col size="12" size-md="6" size-xl="4">
          <game-card :game="game" />
        </ion-col>
      </template>
      
      <!-- Additional mobile ad at the end of each day's games if needed -->
      <ion-col v-if="shouldShowAdditionalMobileAd(games.games.length)" size="12" class="ad-slot mobile">
        <div :id="`mobileRectAtf${getMobileAdIndex(dateIndex, games.games.length)}`"></div>
      </ion-col>

      <ion-col v-if="shouldShowAdditionalDesktopAd(games.games.length)" size="12" class="ad-slot desktop">
        <div :id="`inlineRectAtf${getDesktopAdIndex(dateIndex, games.games.length)}`"></div>
      </ion-col>
    </ion-row>
    
    <no-game-card v-if="gameStore.dateWiseFilteredGames.length < 1" />
  </ion-grid>
</template>

<script setup>
import { defineComponent } from "vue";
import { IonGrid, IonRow, IonCol, isPlatform } from "@ionic/vue";
import { format } from "date-fns";
import { useGameStore } from "@/stores/gameStore";
import { useReportGameStore } from "@/stores/reportGameStore";
import GameCard from "./GameCard.vue";
import NoGameCard from "@/components/schedule/NoGameCard.vue";

defineComponent({
  name: "ScheduledGamesAll",
});

const gameStore = useGameStore();
const reportGames = useReportGameStore();

// Reset edited scores when component is re-rendered
reportGames.games = [];

const formatDate = (date) => {
  return date !== "null"
    ? format(new Date(date.replace(/-/g, "/")), "EEEE, MMM do")
    : "TBA";
};

const shouldShowMobileAd = (index) => isPlatform('mobile') && index > 0 && index % 4 === 0;
const shouldShowDesktopAd = (index) => isPlatform('desktop') && index > 0 && index % 6 === 0;

const shouldShowAdditionalMobileAd = (gamesCount) => {
  return isPlatform('mobile') && gamesCount % 6 > 3;
};

const shouldShowAdditionalDesktopAd = (gamesCount) => {
  return isPlatform('desktop') && (gamesCount < 6 || (gamesCount > 6 && gamesCount % 6 !== 0));
};

const getMobileAdIndex = (dateIndex, gameIndex) => {
  return dateIndex * Math.ceil(gameStore.dateWiseFilteredGames[dateIndex].games.length / 4) + Math.floor(gameIndex / 4);
};

const getDesktopAdIndex = (dateIndex, gameIndex) => {
  return dateIndex * Math.ceil(gameStore.dateWiseFilteredGames[dateIndex].games.length / 6) + Math.floor(gameIndex / 6);
};
</script>

<style scoped>
ion-grid {
  margin-bottom: 40px;
}

.date-card {
  width: 100%;
  padding-bottom: 10px;
  text-align: center;
  margin-top: 15px;
}

.date-card h1 {
  text-transform: uppercase;
  font-weight: 700;
  color: var(--ion-color-dark);
}

.date-label {
  background-color: var(--ion-color-primary);
  padding: 0 10px;
}

@media (max-width: 600px) {
  .date-card h1 {
    font-size: 18px;
  }
}

.ad-slot {
  /* Add any specific styling for ad slots here */
}

.ad-slot.mobile {
  /* Mobile-specific ad slot styling */
}

.ad-slot.desktop {
  /* Desktop-specific ad slot styling */
}
</style>