<template>
  <base-modal modal-title="Sync with Slo-Pitch National" @close="close(false)">
    <template #headerStart>
      <ion-button @click="close(false)"> Close </ion-button>
    </template>
    <div v-if="activateRegistrationRef === false">
      <h1
        style="
          color: var(--ion-color-dark);
          text-align: center;
          text-transform: uppercase;
        "
      >
        <span
          style="background-color: var(--ion-color-primary); padding: 0 10px"
        >
          Sync Your Teams Easily!
        </span>
      </h1>
      <p>
        It's super easy to import your teams and divisions directly from your
        SPN account. To get started, log in below
        <b>WITH YOUR SLO-PITCH NATIONAL Account.</b>
      </p>
      <p>
        To note, at the moment, it is not possible to sync with Play Slo-Pitch
        events.
      </p>
      <br />
      <ion-item>
        <ion-label position="floating">Your SPN Email</ion-label>
        <ion-input v-model="email"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">Your SPN Password</ion-label>
        <ion-input type="password" v-model="password"></ion-input>
      </ion-item>
      <br />
      <ion-button expand="full" @click="syncWithSPN">Login</ion-button>
    </div>
    <div v-if="activateRegistrationRef === true">
      <div v-if="leagueStore.league.type === 'league'">
        <h1
          style="
            color: var(--ion-color-dark);
            text-align: center;
            text-transform: uppercase;
          "
        >
          <span
            style="background-color: var(--ion-color-primary); padding: 0 10px"
          >
            Select The League You Want to Sync
          </span>
        </h1>
        <ion-item>
          <ion-label>Select the League You Want to Sync With</ion-label>
          <ion-select v-model="selectedLeague">
            <ion-select-option
              v-for="league in leagues"
              :key="league.league_id"
              :value="league.league_id"
            >
              {{ league.name }} - {{ league.season }}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <div v-if="selectedLeague" style="margin-top: 30px">
          <h1
            style="
              color: var(--ion-color-dark);
              text-align: center;
              text-transform: uppercase;
            "
          >
            <span
              style="
                background-color: var(--ion-color-primary);
                padding: 0 10px;
              "
            >
              Select The Teams You Want to Import
            </span>
          </h1>
          <ion-list>
            <ion-item v-for="team in selectedTeams" :key="team.id">
              <ion-label>{{ team.name }}</ion-label>
              <ion-checkbox slot="start" v-model="team.selected"></ion-checkbox>
            </ion-item>
          </ion-list>

          <!-- Selected League: {{ selectedLeague }}  -->
        </div>
      </div>
      <div v-if="leagueStore.league.type === 'tournament'">
        <h1
          style="
            color: var(--ion-color-dark);
            text-align: center;
            text-transform: uppercase;
          "
        >
          <span
            style="background-color: var(--ion-color-primary); padding: 0 10px"
          >
            Select The Tournament You Want to Sync
          </span>
        </h1>
        <ion-select v-model="selectedTournament">
          <ion-select-option
            v-for="tournament in tournaments"
            :key="tournament.tournament_id"
            :value="tournament.tournament_id"
          >
            {{ tournament.name }} - {{ tournament.season }}
          </ion-select-option>
        </ion-select>

        <div v-if="selectedTournament" style="margin-top: 30px">
          <h1
            style="
              color: var(--ion-color-dark);
              text-align: center;
              text-transform: uppercase;
            "
          >
            <span
              style="
                background-color: var(--ion-color-primary);
                padding: 0 10px;
              "
            >
              Select The Teams You Want to Import
            </span>
          </h1>
          <ion-list>
            <ion-item v-for="team in selectedTeams" :key="team.id">
              <ion-label>{{ team.name }}</ion-label>
              <ion-checkbox slot="start" v-model="team.selected"></ion-checkbox>
            </ion-item>
          </ion-list>
          <!-- Selected Tournament: {{ selectedTournament }} -->
        </div>
      </div>
      <ion-button @click="importTeams" expand="full" style="margin-top: 15px"
        >Import Teams from SPN</ion-button
      >
      <br>
      <br>
      <br>
      <br>
      <ion-button @click="disconnectFromSPN">Disconnect from SPN</ion-button>
    </div>
  </base-modal>
</template>

<script setup>
import BaseModal from "@/components/layouts/BaseModal.vue";
import { useRouter, useRoute } from "vue-router";
import { watch, ref, onMounted } from "vue";
import {
  modalController,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonList,
  IonCheckbox,
} from "@ionic/vue";
import { useLeagueStore } from "@/stores/leagueStore";
// import axios from "axios";
// import { showToast } from "@/utils/useIonicComponents";
const router = useRouter();
const route = useRoute();
const leagueStore = useLeagueStore();
import { showLoading, showToast } from "@/utils/useIonicComponents";

import { api, sloPitchApi } from "@/utils/axios";
console.log("auth_code", leagueStore.league.spc_auth_code);
const activateRegistrationRef = ref(
  leagueStore.league.spc_auth_code ? true : false
);
const authCodeRef = ref(leagueStore.league.spc_auth_code || null);

const leagues = ref([]);
const tournaments = ref([]);

const selectedLeague = ref(null);
const selectedTournament = ref(null);
const selectedTeams = ref([]);

const email = ref("");
const password = ref("");

async function close(success = false) {
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss(success);
}

onMounted(() => {
  checkForRegistrationDetails();
});

const checkForRegistrationDetails = () => {
  if (leagueStore.league.spc_auth_code) {
    activateRegistrationRef.value = true;
    callTheAPIForLeaguesAndTournaments();
  }
};

const importTeams = async () => {
  const spnTeams = leagueStore.league.teams.map((team) => team.spn_team_id);
  const pickedTeams = selectedTeams.value.filter((team) => {
    if (spnTeams.includes(team.id)) {
      return false;
    } else if (team.selected === true) {
      return true;
    }
  });
  for (let i = 0; i < pickedTeams.length; i++) {
    pickedTeams[i].division_name =
      pickedTeams[i].division_gender_slug.toUpperCase() +
      " " +
      pickedTeams[i].division_rank_id;
    pickedTeams[i].coach_full_name =
      pickedTeams[i].first_name + " " + pickedTeams[i].last_name;
    pickedTeams[i].spn_team_id = pickedTeams[i].id;
    pickedTeams[i].coach_email = pickedTeams[i].email;
    pickedTeams[i].coach_phone = pickedTeams[i].phone;
  }

  const loading = await showLoading("Saving Teams. Please Wait...");
  await api.post("/api/set-spnsync-event", {
    eventId: leagueStore.league.id,
    spn_league_id: selectedLeague.value,
    spn_tournament_id: selectedTournament.value,
  });
  const result = await api.post("/api/import-teams", {
    event: leagueStore.league.id,
    teams: pickedTeams,
  });
  loading.dismiss();

  if (result.data.type === "success") {
    await leagueStore.reloadEverything(leagueStore.league.slug);
    showToast("Teams successfully Added.", "success", 6000);
    await modalController.dismiss();
  } else {
    showToast(
      "Something went wrong. If this persists, please contact us.",
      "danger",
      6000
    );
  }
};

const callTheAPIForLeaguesAndTournaments = async () => {
  const loading = await showLoading("Please Wait...");

  sloPitchApi
    .get(
      `/slopitchcentral/get-leagues-and-tournaments-list/${authCodeRef.value}`
    )
    .then((response) => {
      console.log("callTheAPIForLeaguesAndTournaments", response);
      leagues.value = response.data.leagues;
      tournaments.value = response.data.tournaments;
      selectedLeague.value = leagueStore.league.spn_league_id;
      selectedTournament.value = leagueStore.league.spn_tournament_id;
      loading.dismiss();
    });
};

const syncWithSPN = async () => {
  sloPitchApi
    .post("/slopitchcentral/attempt", {
      email: email.value,
      password: password.value,
    })
    .then((results) => {
      if(results.data.type === "success") {
      const auth_code = results.data.spc_auth_code;
      authCodeRef.value = auth_code;
      api
        .post("/api/spc-auth-code", {
          auth_code: auth_code,
          slug: leagueStore.league.slug,
        })
        .then((response) => {
          
          if (response.data.success === true) {
            activateRegistrationRef.value = true;
            callTheAPIForLeaguesAndTournaments()
          
          }
        });
      } else {
        showToast("Your password or email was incorrect.", "danger", 6000);
      }
    });
};

const disconnectFromSPN = async () => {
  api
    .post("/api/spc-auth-code", {
      auth_code: null,
      slug: leagueStore.league.slug,
    })
    .then((response) => {
      if (response.data.success === true) {
        activateRegistrationRef.value = false;
        authCodeRef.value = null;
        leagues.value = [];
        tournaments.value = [];
        selectedLeague.value = null;
        selectedTournament.value = null;
        selectedTeams.value = [];
      }
    });
};

function checkForSelectedTeams() {
  console.log(leagueStore.league.teams);
  const spnTeams = leagueStore.league.teams.map((team) => team.spn_team_id);
  selectedTeams.value.forEach((team) => {
    if (spnTeams.includes(team.id)) {
      team.selected = true;
    }
  });
}

// watch selectedLeague and selectedTournament
watch(selectedLeague, async (newVal) => {
  // Logic to execute when selectedLeague changes
  console.log("newVal", newVal);
  if(newVal === null) {
    return;
  }
  const loading = await showLoading("Please Wait...");

  sloPitchApi
    .get(`/slopitchcentral/get-teams/${authCodeRef.value}/${newVal}/league`)
    .then((response) => {
      console.log("teams", response);
      selectedTeams.value = response.data.teams;
      checkForSelectedTeams();
      loading.dismiss();
    });
});

// Watcher for selectedTournament
watch(selectedTournament, async (newVal) => {
  // Logic to execute when selectedTournament changes
  const loading = await showLoading("Please Wait...");
  console.log("newVal", newVal);
  sloPitchApi
    .get(`/slopitchcentral/get-teams/${authCodeRef.value}/${newVal}/tournament`)
    .then((response) => {
      console.log("teams", response);
      selectedTeams.value = response.data.teams;
      checkForSelectedTeams();
      loading.dismiss();
    });
});
</script>
