<template>
  <ion-content>
    <div style="display: flex; justify-content: center; align-items: center">
      <ion-card style="width: 80rem; text-align: center" color="light">
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col size="12" size-md="4">
              <div>
                <ion-card-header>
                  <ion-card-title>Confirm Your Locations</ion-card-title>
                  <ion-card-subtitle
                    >These are the locations found in your
                    CSV.</ion-card-subtitle
                  >
                </ion-card-header>
                <ion-card-content>
                  <ion-button
                    fill="outline"
                    color="danger"
                    @click="fixLocations = !fixLocations"
                    >Merge Locations or Fix Typo ?</ion-button
                  >
                </ion-card-content>

                <ion-card-content>
                  <ion-list>
                    <ion-item color="medium">
                      <ion-label style="text-align: center">Name</ion-label>
                    </ion-item>
                    <ion-item v-for="park in uploadCSV.getParks" :key="park">
                      <ion-label style="text-align: center">{{
                        park
                      }}</ion-label>
                    </ion-item>
                  </ion-list>
                </ion-card-content>
              </div>
            </ion-col>
            <ion-col size="12" size-md="8" v-if="fixLocations">
              <ion-card-header>
                <ion-card-title>Select Locations to Merge</ion-card-title>
                <ion-card-subtitle>Merge Duplicate Parks</ion-card-subtitle>
              </ion-card-header>
              <ion-card>
                <ion-list>
                  <ion-item>
                    <p>
                      Merge multiple locations by selecting a set of locations
                      and renaming them.
                    </p>
                  </ion-item>
                  <ion-item>
                    <p>
                      A single location can be renamed by selecting only one
                      location and renaming it.
                    </p>
                  </ion-item>
                </ion-list>
              </ion-card>
              <ion-card-content>
                <ion-grid>
                  <ion-row>
                    <ion-col
                      class="ion-align-self-center label-left-col"
                      size-md="5"
                      size="12"
                      ><ion-label>Select Locations to Merge</ion-label></ion-col
                    >
                    <ion-col
                      ><ion-select
                        style="
                          border-bottom: 3px solid var(--ion-color-medium);
                          text-align: center;
                        "
                        :multiple="true"
                        v-model="selectedParks"
                        @ion-change="parksChanged()"
                      >
                        <ion-select-option
                          v-for="park in uploadCSV.getParks"
                          :key="park"
                          :value="park"
                          >{{ park }}</ion-select-option
                        >
                      </ion-select></ion-col
                    >
                  </ion-row>
                  <ion-row style="margin-top: 2rem">
                    <ion-col
                      class="ion-align-self-center label-left-col"
                      size-md="5"
                      size="12"
                      ><ion-label>Name of Merged Location</ion-label></ion-col
                    >
                    <ion-col
                      ><ion-input
                        style="
                          border-bottom: 3px solid var(--ion-color-medium);
                          text-align: center;
                        "
                        v-model="mergeParkName"
                      >
                      </ion-input
                    ></ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
              <ion-button color="tertiary" expand="full" @click="mergeParks">
                Merge
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-button expand="full" style="margin-top: 4rem" @click="navnext">
          Next
        </ion-button>
      </ion-card>
    </div>
  </ion-content>
</template>

<script setup>
import { defineComponent, ref } from "vue";
import {
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { showToast } from "@/utils/useIonicComponents.js";
import { useUploadCSVStore } from "@/stores/UploadCSVStore";
import AssociateTeams from "@/components/schedule/upload-csv/AssociateTeams.vue";
import MergeDiamonds from "./MergeDiamonds.vue";

defineComponent({
  name: "MergeLocations",
});

const uploadCSV = useUploadCSVStore();
const selectedParks = ref();
const mergeParkName = ref();
const fixLocations = ref(false);

if (uploadCSV.getParks.length < 2) {
  navnext();
}

function parksChanged() {
  mergeParkName.value = selectedParks.value[0];
}

function mergeParks() {
  if (selectedParks.value) {
    uploadCSV.fileData.forEach((data) => {
      if (
        selectedParks.value.includes(
          data[uploadCSV.columnAssociation.park_name]
        )
      ) {
        data[uploadCSV.columnAssociation.park_name] = mergeParkName.value;
      }
    });
    selectedParks.value = [];
    showToast("Parks Merged", "success", 3000);
  } else {
    showToast("Please select parks to merge first", "danger", 2000);
  }
}

function navnext() {
  const ionNav = document.querySelector("ion-nav");
  if (uploadCSV.columnAssociation.diamond_signifier) {
    ionNav.push(MergeDiamonds);
  } else {
    ionNav.push(AssociateTeams);
  }
}
</script>
