<template>
  <ion-content>
    <div style="display: flex; justify-content: center; align-items: center">
      <ion-card style="width: 80rem; text-align: center" color="light">
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col size="12" size-md="4">
              <div>
                <ion-card-header>
                  <ion-card-title>Diamonds Currently in CSV</ion-card-title>
                  <ion-card-subtitle
                    >Duplicates can be merged using the merge
                    utitlity</ion-card-subtitle
                  >
                </ion-card-header>

                <ion-card-content>
                  <ion-button
                    fill="outline"
                    color="danger"
                    @click="fixDiamonds = !fixDiamonds"
                    >Merge Diamonds or Fix Typo ?</ion-button
                  >
                </ion-card-content>

                <ion-card-content>
                  <ion-list>
                    <ion-item color="medium">
                      <ion-label style="text-align: center">Name</ion-label>
                    </ion-item>
                    <ion-item
                      v-for="diamond in uploadCSV.getDiamonds"
                      :key="diamond"
                    >
                      <ion-label style="text-align: center">{{
                        diamond
                      }}</ion-label>
                    </ion-item>
                  </ion-list>
                </ion-card-content>
              </div>
            </ion-col>
            <ion-col size="12" size-md="8" v-if="fixDiamonds">
              <ion-card-header>
                <ion-card-title>Select Diamonds to Merge</ion-card-title>
                <ion-card-subtitle>Merge Duplicate Diamonds</ion-card-subtitle>
              </ion-card-header>
              <ion-card>
                <ion-list>
                  <ion-item>
                    <p>
                      Merge multiple Diamonds by selecting a set of diamonds and
                      renaming them.
                    </p>
                  </ion-item>
                  <ion-item>
                    <p>
                      A single diamond can be renamed by selecting only one
                      diamond and renaming it.
                    </p>
                  </ion-item>
                </ion-list>
              </ion-card>
              <ion-card-content>
                <ion-grid>
                  <ion-row>
                    <ion-col
                      class="ion-align-self-center label-left-col"
                      size-md="5"
                      size="12"
                      ><ion-label>Select Diamonds to Merge</ion-label></ion-col
                    >
                    <ion-col
                      ><ion-select
                        style="
                          border-bottom: 3px solid var(--ion-color-medium);
                          text-align: center;
                        "
                        :multiple="true"
                        v-model="selectedDiamonds"
                        @ion-change="diamondsChanged()"
                      >
                        <ion-select-option
                          v-for="diamond in uploadCSV.getDiamonds"
                          :key="diamond"
                          :value="diamond"
                          >{{ diamond }}</ion-select-option
                        >
                      </ion-select></ion-col
                    >
                  </ion-row>
                  <ion-row style="margin-top: 2rem">
                    <ion-col
                      class="ion-align-self-center label-left-col"
                      size-md="5"
                      size="12"
                      ><ion-label>Name of Merged Diamond</ion-label></ion-col
                    >
                    <ion-col
                      ><ion-input
                        style="
                          border-bottom: 3px solid var(--ion-color-medium);
                          text-align: center;
                        "
                        v-model="mergeDiamondName"
                      >
                      </ion-input
                    ></ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
              <ion-button color="tertiary" expand="full" @click="mergeDiamonds">
                Merge
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-button expand="full" style="margin-top: 4rem" @click="navnext">
          Next
        </ion-button>
      </ion-card>
    </div>
  </ion-content>
</template>

<script setup>
import { defineComponent, ref } from "vue";
import {
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { showToast } from "@/utils/useIonicComponents.js";
import { useUploadCSVStore } from "@/stores/UploadCSVStore";
import SelectScores from "@/components/schedule/upload-csv/SelectScores.vue";

defineComponent({
  name: "MergeDiamonds",
});

const uploadCSV = useUploadCSVStore();
const selectedDiamonds = ref();
const mergeDiamondName = ref();
const fixDiamonds = ref(false);

if (uploadCSV.getDiamonds.length < 2) {
  navnext();
}

function diamondsChanged() {
  mergeDiamondName.value = selectedDiamonds.value[0];
}

function mergeDiamonds() {
  if (selectedDiamonds.value) {
    uploadCSV.fileData.forEach((data) => {
      if (
        selectedDiamonds.value.includes(
          data[uploadCSV.columnAssociation.diamond_signifier]
        )
      ) {
        data[uploadCSV.columnAssociation.diamond_signifier] =
          mergeDiamondName.value;
      }
    });
    selectedDiamonds.value = [];
    showToast("Diamonds Merged", "success", 3000);
  } else {
    showToast("Please select Diamonds to merge first", "danger", 2000);
  }
}

function navnext() {
  const ionNav = document.querySelector("ion-nav");
  ionNav.push(SelectScores);
}
</script>
