<template>
  <span>
    <ion-card
      style="margin: 0.5rem 0rem"
      @dblclick="reportGame"
      @touchstart="handleTouchStart"
      :class="[
        newGameClass,
        'game-card',
        { selected: isGameSelected },
        { 'select-game': gameStore.showSelectGames },
      ]"
      @click="selectGames"
    >
      <game-card-header :game="props.game">
        <!-- @click="openReportModal" -->
        <game-card-actions @resetGame="resetScore++" :game="props.game" />
      </game-card-header>
      <game-card-content-view
        :key="resetScore"
        :game="props.game"
        :inPopup="false"
        @showReportButton="showReportButton"
        @resetGame="resetScore++"
      />
      <game-card-footer :game="props.game"></game-card-footer>
    </ion-card>
    <ion-modal :is-open="showModal" @did-dismiss="closeReportModal">
      <game-cards-popup
        :games="gameStore.selectedGames"
        @close="closeReportModal"
      >
      </game-cards-popup>
    </ion-modal>
    <ion-modal :is-open="showCardModal" @did-dismiss="closeReportModal">
      <game-card-popup :game="props.game" @close="closeReportModal">
      </game-card-popup>
    </ion-modal>
  </span>
</template>

<script setup>
import { defineComponent, defineProps, computed, ref } from "vue";
import { IonCard, IonModal } from "@ionic/vue";
import GameCardActions from "@/components/schedule/game-grid/GameCardActions.vue";
import GameCardHeader from "@/components/schedule/game-grid/GameCardHeader.vue";
import GameCardContentView from "@/components/schedule/game-grid/GameCardContentView.vue";
import GameCardFooter from "@/components/schedule/game-grid/GameCardFooter.vue";
import GameCardsPopup from "@/components/schedule/game-grid/GameCardsPopup.vue";
import GameCardPopup from "@/components/schedule/game-grid/GameCardPopup.vue";
import { differenceInSeconds } from "date-fns";
import { useLeagueStore } from "@/stores/leagueStore";
import { useAuthStore } from "@/stores/authStore";
import { showToast, openModal } from "@/utils/useIonicComponents.js";
import LoginModal from "@/components/login/LoginModal.vue";
import { useGameStore } from "@/stores/gameStore";
import { useReportGameStore } from "@/stores/reportGameStore";

const gameStore = useGameStore();
const authStore = useAuthStore();
const reportGames = useReportGameStore();

defineComponent({
  name: "GameCard",
});
const props = defineProps(["game"]);
const resetScore = ref(0); //to reset score by re rendering game-card-content
const leagueStore = useLeagueStore();

const showModal = ref(false);
const showCardModal = ref(false);

const newGameClass = computed(() => {
  if (differenceInSeconds(new Date(), new Date(props.game.created_at)) < 10) {
    return "new-game";
  }
  return "";
});

function openReportModal() {
  if (
    leagueStore.league.can_teams_report === 1 ||
    leagueStore.league.can_umpire_report === 1 ||
    leagueStore.league.can_admin_report === 1
  ) {
    showCardModal.value = true;
  } else {
    if (authStore.isLoggedIn) {
      showModal.value = true;
    } else {
      showToast("You Must Login to Submit Score", "danger", 6000);
      openLoginModal();
    }
  }
}

const showReportButton = () => {
  console.log("showReportButton on GameCard78");
};

async function openLoginModal() {
  await openModal(LoginModal, "loginModal");
}

function closeReportModal() {
  showModal.value = false;
  showCardModal.value = false;
  gameStore.removeSelectedGame(props.game);
  reportGames.showReportButton = false;
}

const isGameSelected = computed(() => {
  const game = gameStore.selectedGames.filter(
    (game) => game.id === props.game.id
  );
  return game.length > 0;
});

let lastTap = 0;

function handleTouchStart() {
  if (authStore.isConvenor) {
    const currentTime = new Date().getTime();
    const tapLength = currentTime - lastTap;
    if (tapLength < 500 && tapLength > 0) {
      reportGame();
    } else {
      lastTap = currentTime;
    }
  }
}

function reportGame() {
  console.log("triggered");
  if (authStore.isConvenor) {
    if (!isGameSelected.value) {
      gameStore.showSelectGames = true;
      gameStore.selectGame(props.game);
    }
    showModal.value = true;
  }
}

function selectGames() {
  // if (gameStore.showSelectGames) {

  if (authStore.isConvenor) {
    if (!isGameSelected.value) {
      gameStore.showSelectGames = true;
      gameStore.selectGame(props.game);
    } else {
      gameStore.removeSelectedGame(props.game);
    }
  } else {
    openReportModal();
  }

  // }
}
</script>
<style scoped>
ion-card {
  background: transparent;
}
@media only screen and (min-width: 1250px) {
  ion-card {
    min-width: 20rem;
  }
}
.new-game {
  animation: newgame 0.65s ease 0s 25 alternate;
}
@keyframes newgame {
  0% {
    box-shadow: 0px 0px 6px 1px rgba(152, 154, 162, 0.3);
    -webkit-box-shadow: 0px 0px 6px 1px rgba(152, 154, 162, 0.3);
  }
  25% {
    box-shadow: 0px 0px 6px 2px rgba(190, 192, 199, 0.3);
    -webkit-box-shadow: 0px 0px 6px 2px rgba(190, 192, 199, 0.3);
  }
  50% {
    box-shadow: 0px 0px 6px 4px rgba(210, 212, 219, 0.3);
    -webkit-box-shadow: 0px 0px 6px 4px rgba(210, 212, 219, 0.3);
  }
  100% {
    box-shadow: 0px 0px 6px 6px rgba(238, 239, 241, 0.3);
    -webkit-box-shadow: 0px 0px 6px 6px rgba(238, 239, 241, 0.3);
  }
}

.game-card {
  border: 2px solid transparent;
  cursor: pointer;
}

.select-game {
  border: 2px solid #645cfa;
  cursor: pointer;
}

.selected {
  border: 2px solid #34d76e;
  cursor: pointer;
}

/* .game-card:hover {
  border: 3px solid #ddd;
} */
</style>
