<template>
    <span>
        <ion-card :class="['game-card']">
            <div style="max-width: 400px; margin: 0 auto;">
            <game-card-header 
                :game="props.game" 
                :inPopup="true"
            />
            
            <game-card-content
                :key="resetScore"
                :game="props.game"
                :inPopup="true"
                @close="scoreReported"
                :convenorPopup="true"
                :clearScoreCount="clearScoreCount"
                @showReportButton="showReportButtonFunction"
                @hideReportButton="hideReportButtonFunction"
                :showReportButton="showReportButton"
                :reportScoreCount="reportScoreCount"
                @resetGame="resetScore++"
            />
            
            <game-card-footer 
                :game="props.game"
                :inPopup="true"
            />
        </div>
            <ion-row v-if="showReportButton">
                <ion-col>
                    <ion-button
                        expand="full"
                        color="success"
                        size="small"
                        @click="reportScoreCounter"
                        fill="solid">
                        <ion-icon :icon="alertCircleOutline"></ion-icon>&nbsp;
                        {{ game.score_reported === 1 ? 'UPDATE SCORE' : 'SUBMIT SCORE' }}
                    </ion-button>
                </ion-col>
                <ion-col>
                    <ion-button
                        expand="full"
                        color="danger"
                        size="small"
                        fill="solid"
                        @click="clearScores"
                    >
                        <ion-icon :icon="closeOutline"></ion-icon>&nbsp;
                        CLEAR

                    </ion-button>
                </ion-col>
            </ion-row>
            
            

            <!-- <game-card-actions 
                @resetGame="resetScore++" 
                :game="props.game" 
            /> -->
        </ion-card>
    </span>
</template>

<script setup>
import { IonCard, IonRow, IonCol, IonButton, IonIcon } from "@ionic/vue";
import GameCardHeader from "@/components/schedule/game-grid/GameCardHeader.vue";
import GameCardContent from "@/components/schedule/game-grid/GameCardContent.vue";
import GameCardFooter from "@/components/schedule/game-grid/GameCardFooter.vue";
import { closeOutline, alertCircleOutline } from "ionicons/icons";
// import { differenceInSeconds } from "date-fns";
import { ref, defineProps } from "vue";

// const emit = defineEmits(['close']);
const resetScore = ref(0);
const showReportButton = ref(false)

const clearScoreCount = ref(0)
const reportScoreCount = ref(0)


const props = defineProps({
    game: {
        type: Object,
        required: true,
    },
});

// const game = props.game

// const reportTheScore = () => {
//     const reportGame = Object.assign({}, props.game);
//     reportGame.away_team_score = awayScore.value;
//     reportGame.home_team_score = homeScore.value;
//     reportStore.showReportButton = true
//     const response = await reportStore.addGamesToReport(reportGame);
// }

const reportScoreCounter = () => {
    // console.log('reportScoreCounter on GameCardPopupWrapper')
    reportScoreCount.value++
}

const clearScores = () => {
    // console.log('clearScores on GameCardPopupWrapper')
    clearScoreCount.value++
}

const showReportButtonFunction = () => {
    showReportButton.value = true
    // console.log('showReportButton on GameCardSSSSSPopup')
}

const hideReportButtonFunction = () => {
    showReportButton.value = false
    // console.log('showReportButton', showReportButton.value)
    // console.log('hideReportButton on GameCardPopup')
}

const scoreReported = () => {
    // console.log('scoreReported on GameCardPopupWrapper')
    showReportButton.value = false
}


</script>
