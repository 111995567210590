<template>
  <tr
    :class="{ danger: isInvalidRow }"
    :style="{
      'background-color': newGameStore.games[props.index]?.part_of
        ? 'var(--ion-color-step-200)'
        : '',
    }"
  >
    <td
      v-if="
        !newGameStore.games[props.index]?.part_of &&
        newGameStore.games[props.index].best_of === 1
      "
    >
      <input
        style="box-sizing: border-box; width: 95%; text-align: center"
        class="add-games-input"
        type="number"
        @input="debouncedInput"
        v-model="currentTempId"
      />
      <!-- {{ newGameStore.games[props.index].tempGameId }} -->
    </td>
    <td v-else-if="newGameStore.games[props.index].best_of > 1">
      {{ newGameStore.games[props.index].tempGameId }}.1
    </td>
    <td v-else>
      {{ newGameStore.games[props.index]?.part_of }}.{{
        newGameStore.games[props.index]?.part_of_order
      }}
    </td>
    <td>
      <div
        :key="dateTimeRefreshKey"
        class="custom-datetime-button"
        @click="showDateModal = true"
      >
        <div v-if="game.start_date">
          <div class="date-name">
            {{
              format(parse(game.start_date, "yyyy-MM-dd", new Date()), "EEEE")
            }}
          </div>
          <div class="button-content">{{ game.start_date }}</div>
        </div>
        <div v-else>TBA</div>
      </div>

      <ion-modal
        class="fix-modal-height"
        :is-open="showDateModal"
        @didDismiss="showDateModal = false"
      >
        <ion-card style="display: flex; justify-content: space-around">
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            "
          >
            <ion-card-subtitle>Quick Picks </ion-card-subtitle>
            <ion-chip
              v-if="props.game.start_date !== null"
              style="font-size: large"
              @click="quickPick(null, 'date')"
            >
              TBA</ion-chip
            >
            <ion-chip
              v-else
              @click="quickPick(format(new Date(), 'yyyy-MM-dd'), 'date')"
              >Pick a date</ion-chip
            >
            <ion-chip
              style="font-size: large"
              v-if="props.index > 0"
              @click="
                quickPick(
                  newGameStore.games[props.index - 1].start_date,
                  'date'
                )
              "
              >Previous Game Date</ion-chip
            >
            <ion-chip
              style="font-size: large"
              @click="quickPick(format(new Date(), 'yyyy-MM-dd'), 'date')"
              >Today</ion-chip
            >
            <ion-chip
              style="font-size: large"
              @click="
                quickPick(
                  format(add(new Date(), { days: 1 }), 'yyyy-MM-dd'),
                  'date'
                )
              "
              >Tomorrow</ion-chip
            >
          </div>
          <ion-datetime
            v-if="props.game.start_date !== null"
            :key="dateTimeRefreshKey"
            @ion-change="
              props.game.start_date ? (showDateModal = false) : '';
              newGameStore.dateTimeChanged($event, 'date', props.index);
            "
            presentation="date"
            :id="'date' + props.index"
            :value="
              formatISO(
                parse(
                  newGameStore.games[props.index].start_date !== null
                    ? newGameStore.games[props.index].start_date
                    : '1970-01-01',
                  'yyyy-MM-dd',
                  new Date()
                )
              )
            "
          ></ion-datetime>
        </ion-card>
      </ion-modal>
    </td>
    <td>
      <!-- <ion-datetime-button
        :datetime="'start-time' + props.index"
      ></ion-datetime-button> -->

      <div class="custom-datetime-button" @click="showStartTimeModal = true">
        <div class="button-content" v-if="props.game.start_time !== null">
          {{
            format(parse(game.start_time, "HH:mm:ss", new Date()), "h:mm aa")
          }}
        </div>
        <div class="button-content" v-else>TBA</div>
      </div>

      <ion-modal
        class="fix-modal-height"
        :is-open="showStartTimeModal"
        @didDismiss="showStartTimeModal = false"
      >
        <ion-card style="display: flex; justify-content: space-around">
          <div style="width: 20rem">
            <ion-card-subtitle>Quick Picks </ion-card-subtitle>
            <ion-grid>
              <ion-row>
                <ion-col size="6">
                  <ion-chip
                    v-if="props.game.start_time !== null"
                    style="font-size: medium"
                    @click="quickPick(null, 'start_time')"
                  >
                    TBA</ion-chip
                  >
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col
                  v-for="time in frequentlyUsedTimes?.start_times"
                  :key="time"
                  size="6"
                >
                  <ion-chip
                    @click="quickPick(time, 'start_time')"
                    style="font-size: medium; padding: 0.93rem"
                    >{{
                      format(parse(time, "HH:mm:ss", new Date()), "h:mm aa	")
                    }}</ion-chip
                  >
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
          <ion-datetime
            v-if="props.game.start_time !== null"
            @ion-change="
              newGameStore.dateTimeChanged($event, 'start_time', props.index)
            "
            presentation="time"
            :minuteValues="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]"
            :id="'start-time' + props.index"
            :value="
              formatISO(
                parse(
                  newGameStore.games[props.index].start_time
                    ? newGameStore.games[props.index].start_time
                    : '00:00:00',
                  'HH:mm:ss',
                  new Date()
                )
              )
            "
          ></ion-datetime>
        </ion-card>
      </ion-modal>
    </td>
    <td>
      <!-- {{ game.end_time }} -->
      <!-- <ion-datetime-button
        :datetime="'end-time' + props.index"
      ></ion-datetime-button> -->

      <div
        class="custom-datetime-button"
        @click="showEndTimeModal = true"
        v-if="game.end_time"
      >
        <div class="button-content">
          {{ format(parse(game.end_time, "HH:mm:ss", new Date()), "h:mm aa") }}
        </div>
      </div>

      <div class="custom-datetime-button" v-else>
        <tooltip placement="bottom">
          <div class="button-content">TBA</div>
          <template #popper> Please select Start Time first </template>
        </tooltip>
      </div>

      <ion-modal
        class="fix-modal-height"
        :is-open="showEndTimeModal"
        @didDismiss="showEndTimeModal = false"
      >
        <ion-card style="display: flex; justify-content: space-around">
          <div style="max-width: 20rem">
            <ion-card-subtitle>Quick Picks</ion-card-subtitle>
            <ion-grid>
              <ion-row>
                <ion-col
                  v-for="time in frequentlyUsedTimes?.end_times"
                  :key="time"
                  size="6"
                >
                  <ion-chip
                    @click="quickPick(time, 'end_time')"
                    style="font-size: medium; padding: 0.93rem"
                    >{{
                      format(parse(time, "HH:mm:ss", new Date()), "h:mm aa	")
                    }}</ion-chip
                  >
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
          <ion-datetime
            @ion-change="
              newGameStore.dateTimeChanged($event, 'end_time', props.index)
            "
            presentation="time"
            :minuteValues="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]"
            :id="'end-time' + props.index"
            :value="
              formatISO(
                parse(
                  newGameStore.games[props.index].end_time,
                  'HH:mm:ss',
                  new Date()
                )
              )
            "
          ></ion-datetime>
        </ion-card>
      </ion-modal>
    </td>
    <td>
      <!-- {{  game.park_name }}
      <ion-input
        type="text"
        placeholder="Park Name"
        style="max-width: 10rem"
        v-model="newGameStore.games[props.index].park_name"
      /> -->
      <input
        style="box-sizing: border-box; width: 95%"
        class="add-games-input"
        type="text"
        placeholder="Park Name"
        :value="game.park_name"
        @input="updateValue($event, 'park_name')"
      />
    </td>
    <td>
      <!-- {{ game.diamond_signifier }} -->
      <input
        style="box-sizing: border-box; width: 95%"
        class="add-games-input"
        type="text"
        placeholder="Diamond Name"
        :value="game.diamond_signifier"
        @input="updateValue($event, 'diamond_signifier')"
      />
      <!-- <ion-input
        type="text"
        placeholder="Diamond"
        style="max-width: 10rem"
        v-model="newGameStore.games[props.index].diamond_signifier"
      /> -->
    </td>
    <td>
      <v-select
        placeholder="Select Division"
        :options="
          leagueStore.divisions
            .map((division) => division.division_name)
            .concat('Interdivision')
        "
        class="custom-v-select"
        :clearable="false"
        append-to-body
        :calculate-position="withPopper"
        v-model="newGameStore.games[props.index].division_id"
        :disabled="
          !!newGameStore.games[props.index]?.part_of ||
          newGameStore.games[props.index].best_of > 1
        "
        @option:selected="resetTeams"
      >
        <template #open-indicator="{ attributes }">
          <ion-icon
            :icon="caretDownOutline"
            style="font-size: 0.8rem"
            v-bind="attributes"
          ></ion-icon>
        </template>
      </v-select>
    </td>
    <td>
      <tooltip
        placement="bottom"
        :disabled="!!newGameStore.games[props.index].division_id"
      >
        <v-select
          placeholder="Away Team"
          :options="teamOptions"
          :clearable="false"
          append-to-body
          :calculate-position="withPopper"
          :disabled="
            !newGameStore.games[props.index].division_id ||
            !!newGameStore.games[props.index]?.part_of ||
            newGameStore.games[props.index].best_of > 1
          "
          v-model="newGameStore.games[props.index].select_away_team"
          :reduce="(game) => game.value"
          @option:selected="setMode"
        >
          <template #open-indicator="{ attributes }">
            <ion-icon
              :icon="caretDownOutline"
              style="font-size: 0.8rem"
              v-bind="attributes"
            ></ion-icon>
          </template>
        </v-select>

        <template #popper> Please select a divison first </template>
      </tooltip>
    </td>
    <td>
      <tooltip
        placement="bottom"
        :disabled="!!newGameStore.games[props.index].division_id"
      >
        <v-select
          placeholder="Home Team"
          :options="teamOptions"
          :clearable="false"
          append-to-body
          :calculate-position="withPopper"
          :disabled="
            !newGameStore.games[props.index].division_id ||
            !!newGameStore.games[props.index]?.part_of ||
            newGameStore.games[props.index].best_of > 1
          "
          v-model="newGameStore.games[props.index].select_home_team"
          :reduce="(game) => game.value"
          @option:selected="setMode"
        >
          <template #open-indicator="{ attributes }">
            <ion-icon
              :icon="caretDownOutline"
              style="font-size: 0.8rem"
              v-bind="attributes"
            ></ion-icon>
          </template>
        </v-select>
        <template #popper> Please select a divison first </template>
      </tooltip>
    </td>
    <td>
      <v-select
        placeholder="Mode"
        :options="gameTypes"
        :clearable="false"
        append-to-body
        :calculate-position="withPopper"
        v-model="newGameStore.games[props.index].mode"
        :reduce="(type) => type.value"
        :disabled="
          !!newGameStore.games[props.index]?.part_of ||
          newGameStore.games[props.index].best_of > 1
        "
      >
        <template #open-indicator="{ attributes }">
          <ion-icon
            :icon="caretDownOutline"
            style="font-size: 0.8rem"
            v-bind="attributes"
          ></ion-icon>
        </template>
      </v-select>
    </td>
    <td>
      <tooltip
        v-if="!newGameStore.games[props.index]?.part_of"
        placement="bottom"
        :disabled="
          !!newGameStore.games[props.index].select_away_team &&
          !!newGameStore.games[props.index].select_home_team
        "
      >
        <v-select
          :disabled="
            !newGameStore.games[props.index].select_away_team ||
            !newGameStore.games[props.index].select_home_team ||
            !!newGameStore.games[props.index]?.part_of
          "
          placeholder="Best of"
          :options="[1, 3, 5, 7]"
          :clearable="false"
          append-to-body
          :calculate-position="withPopper"
          v-model="newGameStore.games[props.index].best_of"
          @option:selected="bestOfSelected"
        >
          <template #open-indicator="{ attributes }">
            <ion-icon
              :icon="caretDownOutline"
              style="font-size: 0.8rem"
              v-bind="attributes"
            ></ion-icon>
          </template>
        </v-select>
        <template #popper> Please select teams </template>
      </tooltip>
    </td>
    <td>
      <div
        style="display: flex"
        v-if="!newGameStore.games[props.index]?.part_of"
      >
        <tooltip placement="bottom">
          <ion-button
            color="danger"
            fill="clear"
            @click="duplicateGame"
            style="text-align: center; font-size: 12px"
            ><ion-icon slot="icon-only" :icon="duplicateOutline"
          /></ion-button>
          <template #popper> Duplicate this game </template>
        </tooltip>
        <tooltip placement="bottom">
          <ion-button
            color="danger"
            fill="clear"
            @click="newGameStore.removeGame(props.index)"
            style="text-align: center; font-size: 12px"
            ><ion-icon slot="icon-only" :icon="closeCircleOutline"
          /></ion-button>
          <template #popper> Delete this game </template>
        </tooltip>
      </div>
      <div style="display: flex" v-else>
        <tooltip placement="bottom">
          <ion-button
            color="danger"
            fill="clear"
            @click="swapTeams"
            style="text-align: center; font-size: 12px"
            ><ion-icon slot="icon-only" :icon="swapHorizontalOutline"
          /></ion-button>
          <template #popper> Swap Home and Away Teams </template>
        </tooltip>
      </div>
    </td>
  </tr>
</template>
<script setup>
import {
  defineComponent,
  defineProps,
  computed,
  ref,
  defineEmits,
  watch,
} from "vue";
import { formatISO, parse, format, set, add } from "date-fns";
import { createPopper } from "@popperjs/core";
import {
  // IonInput,
  IonIcon,
  IonButton,
  IonDatetime,
  // IonDatetimeButton,
  IonModal,
  IonCard,
  IonCardSubtitle,
  IonChip,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";
import {
  closeCircleOutline,
  duplicateOutline,
  caretDownOutline,
  swapHorizontalOutline,
} from "ionicons/icons";
import { useLeagueStore } from "@/stores/leagueStore";
import { useNewGameStore } from "@/stores/newGameStore";
// import useVuelidate from "@vuelidate/core";
// import { required, sameAs, not } from "@vuelidate/validators";
import { showToast } from "@/utils/useIonicComponents";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useGameStore } from "@/stores/gameStore";
var ordinal = require("ordinal");
import { Tooltip } from "floating-vue";
import "floating-vue/dist/style.css";
import { debounce } from "lodash";

const showDateModal = ref(false);
const showStartTimeModal = ref(false);

watch(showDateModal, (value) => {
  if (value && !props.game.start_date) {
    quickPick(format(new Date(), "yyyy-MM-dd"), "date");
    showDateModal.value = true;
  }
});
watch(showStartTimeModal, (value) => {
  if (value && !props.game.start_time) {
    quickPick(
      format(set(new Date(), { minutes: 0, seconds: 0 }), "HH:mm:ss"),
      "start_time"
    );
    showStartTimeModal.value = true;
  }
});

defineComponent({
  name: "AddNewGameForm",
});
const props = defineProps({
  game: Object,
  index: Number,
  frequentlyUsedTimes: Object,
  invalidRowIndices: Array,
});
const leagueStore = useLeagueStore();
const newGameStore = useNewGameStore();
const gameStore = useGameStore();
const currentTempId = ref(newGameStore.games[props.index].tempGameId);
const dateTimeRefreshKey = ref(0);
const showEndTimeModal = ref(false);
// Define the emits
const emit = defineEmits(["update:modelValue"]);
// console.log("invalidRowIndices", props.invalidRowIndices);
if (newGameStore.games[props.index].best_of > 1) {
  // when a game is duplicated this will ensure that if it has best_of generated if any
  bestOfSelected(newGameStore.games[props.index].best_of);
}
// const rules = {
//   start_date: { required },
//   start_time: { required },
//   end_time: { required },
//   park_name: { required },
//   diamond_signifier: { required },
//   division_id: { required },
//   select_away_team: {
//     required,
//     sameAsHome: not(
//       sameAs(computed(() => newGameStore.games[props.index].select_home_team))
//     ),
//   },
//   select_home_team: {
//     required,
//     sameAsAway: not(
//       sameAs(computed(() => newGameStore.games[props.index].select_away_team))
//     ),
//   },
//   mode: { required },
// };

// const v$ = useVuelidate(rules, newGameStore.games[props.index]);

function quickPick(dateTime, type) {
  let payload;
  if (type === "start_time") {
    showStartTimeModal.value = false;
  }
  if (dateTime) {
    if (type === "date") {
      payload = formatISO(parse(dateTime, "yyyy-MM-dd", new Date()));
    } else {
      payload = formatISO(parse(dateTime, "HH:mm:ss", new Date()));
    }
  } else {
    payload = null;
  }
  newGameStore.dateTimeChanged(
    { detail: { value: payload } },
    type,
    props.index
  );
  if (type === "date") {
    // this is to reload the calender widget as it doesnt update on change
    dateTimeRefreshKey.value++;
    showDateModal.value = false;
  }
}

// function ifNecessaryChanged(ev) {
//   if (ev.detail.checked) {
//     newGameStore.games[props.index].if_necessary = 1;
//   } else {
//     newGameStore.games[props.index].if_necessary = 0;
//   }
// }

function swapTeams() {
  const awayTeam = newGameStore.games[props.index].select_away_team;
  newGameStore.games[props.index].select_away_team =
    newGameStore.games[props.index].select_home_team;
  newGameStore.games[props.index].select_home_team = awayTeam;
}

const gameTypes = computed(() => {
  if (leagueStore.league.type === "tournament") {
    return [
      { label: "Pre-Seed", value: "Practice" },
      { label: "Round Robin", value: "Season" },
      { label: "Eliminations", value: "Playoffs" },
    ];
  } else {
    return [
      { label: "Practice", value: "Practice" },
      { label: "Season", value: "Season" },
      { label: "Playoffs", value: "Playoffs" },
    ];
  }
});

const debouncedInput = debounce(tempGameIdChanged, 500);

function tempGameIdChanged(ev) {
  console.log(ev.target.valueAsNumber);
  if (ev.target.valueAsNumber) {
    if (
      !gameStore.games.some(
        (item) => item.event_specific_game_id === ev.target.valueAsNumber
      ) &&
      !newGameStore.games.some(
        (item) => item.tempGameId === ev.target.valueAsNumber
      )
    ) {
      newGameStore.games.forEach((item) => {
        // Check and update awayTeam if it matches the pattern
        if (
          item.select_away_team &&
          /winner-of-temp-\d+|loser-of-temp-\d+/.test(item.select_away_team)
        ) {
          item.select_away_team = item.select_away_team.replace(
            /\d+$/,
            ev.target.valueAsNumber
          );
        }

        // Check and update homeTeam if it matches the pattern
        if (
          item.select_home_team &&
          /winner-of-temp-\d+|loser-of-temp-\d+/.test(item.select_home_team)
        ) {
          item.select_home_team = item.select_home_team.replace(
            /\d+$/,
            ev.target.valueAsNumber
          );
        }
      });

      newGameStore.games[props.index].tempGameId = ev.target.valueAsNumber;
      currentTempId.value = ev.target.valueAsNumber;
    } else {
      currentTempId.value = newGameStore.games[props.index].tempGameId;
      showToast("This game id already exists", "danger", 3000);
    }
  }
}

function bestOfSelected(ev) {
  const mainGame = newGameStore.games[props.index];
  mainGame.mode = "Playoffs";
  //check if the user has already added best_of games
  const existingPartGames = newGameStore.games.filter(
    (game) => game.part_of === mainGame.tempGameId
  );
  if (ev === 1) {
    // if best of series is set to one remove all other games
    newGameStore.games = newGameStore.games.filter(
      (game) => game.part_of !== mainGame.tempGameId
    );
  } else if (ev < existingPartGames.length) {
    //remove the excess games if user selects a lower number than the current
    const trimmedGames = newGameStore.games.reduce((acc, game) => {
      const isPartOf = game.part_of === mainGame.tempGameId;
      const partOfCount = acc.filter(
        (g) => g.part_of === mainGame.tempGameId
      ).length;

      if (!isPartOf || (isPartOf && partOfCount < ev - 1)) {
        acc.push(game);
      }

      return acc;
    }, []);

    newGameStore.games = trimmedGames;
  } else if (ev > existingPartGames.length) {
    // add new part games in the series
    for (let i = 0; i < ev - existingPartGames.length - 1; i++) {
      newGameStore.addNewGame();
      const game = newGameStore.games[newGameStore.games.length - 1];
      game.division_id = mainGame.division_id;
      game.start_date = mainGame.start_date;
      game.start_time = mainGame.start_time;
      game.end_time = mainGame.end_time;
      if (i % 2 !== 0) {
        game.select_away_team = mainGame.select_away_team;
        game.select_home_team = mainGame.select_home_team;
      } else {
        game.select_away_team = mainGame.select_home_team;
        game.select_home_team = mainGame.select_away_team;
      }
      game.mode = "Playoffs";
      game.part_of = mainGame.tempGameId;
      game.part_of_order = existingPartGames.length + i + 2;
      delete game.tempGameId;
      switch (ev) {
        case 3:
          if (game.part_of_order === 3) {
            game.if_necessary = 1;
          }
          break;
        case 5:
          if (game.part_of_order > 3) {
            game.if_necessary = 1;
          }
          break;
        case 7:
          if (game.part_of_order > 4) {
            game.if_necessary = 1;
          }
          break;
        default:
          game.if_necessary = 0;
      }
      //put the game in correct position
      // add addNewGame() just puts it in the last index

      const gameIndex = newGameStore.games.length - 1;
      newGameStore.games.splice(gameIndex, 1);
      // find where to insert the game
      const insertIndex = newGameStore.games.findIndex(
        (g) =>
          g.part_of_order + 1 === game.part_of_order &&
          g.part_of === game.part_of
      );
      if (insertIndex >= 0) {
        // Insert at the correct position if part of present
        newGameStore.games.splice(insertIndex + 1, 0, game);
      } else {
        // if part of not present insert it after the actual game its part of
        const insertIndex = newGameStore.games.findIndex(
          (g) => g.tempGameId === mainGame.tempGameId
        );
        newGameStore.games.splice(insertIndex + 1, 0, game);
      }
    }
  }
}

function setMode(ev) {
  if (!Number(ev.value)) {
    newGameStore.games[props.index].mode = "Playoffs";
  } else {
    if (
      Number(newGameStore.games[props.index].select_away_team) &&
      Number(newGameStore.games[props.index].select_home_team) &&
      newGameStore.games[props.index].best_of === 1
    )
      newGameStore.games[props.index].mode = "Season";
  }
}

async function duplicateGame() {
  // const isFormValid = await v$.value.$validate();
  // if (isFormValid) {
  newGameStore.addNewGame(props.index);
  // } else {
  //   showToast("Valid data in all the fields required", "danger", 6000);
  // }
}

function updateValue($event, updatedKey) {
  // Emit the update event with the updated value
  const updatedValue = $event.target.value;
  // console.log("updatedKey", updatedKey);
  // console.log("updatedValue", updatedValue);
  emit("update:modelValue", { ...props.game, [updatedKey]: updatedValue });
}

const isInvalidRow = computed(() => {
  // console.log("test");
  return props.invalidRowIndices.includes(props.index);
});

function resetTeams() {
  newGameStore.games[props.index].select_away_team = null;
  newGameStore.games[props.index].select_home_team = null;
}
function withPopper(dropdownList, component) {
  dropdownList.style.width = "30rem";
  const popper = createPopper(component.$refs.toggle, dropdownList, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -1],
        },
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          component.$el.classList.toggle("drop-up", state.placement === "top");
        },
      },
    ],
  });

  return () => popper.destroy();
}

const teams = computed(() => {
  if (
    newGameStore.games[props.index].division_id &&
    newGameStore.games[props.index].division_id !== "Interdivision"
  ) {
    console.log('leagueStore.divisions', leagueStore)
    console.log('Searching for division:', newGameStore.games[props.index].division_id);
    const division = leagueStore.divisions.find(
      (division) => {
        console.log('Checking division:', division.division_name);
        return division.division_name === newGameStore.games[props.index].division_id;
      }
    );
    console.log('Found division:', division);
    console.log('division', division)
    return division?.teams || [];
  } else if (leagueStore.league?.teams) {
    return leagueStore.league.teams;
  } else {
    return [];
  }
});

const teamOptions = computed(() => {
  const options = [];
  for (const team of teams.value) {
    options.push({ label: team.name, value: team.id });
  }
  if (
    newGameStore.games[props.index].division_id &&
    newGameStore.games[props.index].division_id !== "Interdivision"
  ) {
    for (const index in newGameStore.games) {
      // winner of & loser of of temp games
      if (
        // newGameStore.games[index].mode !== "Playoffs" &&
        newGameStore.games[index].tempGameId !==
          newGameStore.games[props.index].tempGameId &&
        newGameStore.games[index].division_id ===
          newGameStore.games[props.index].division_id
      ) {
        options.push({
          label: `W G#
            ${newGameStore.games[index].tempGameId}
            |
            ${
              newGameStore.games[index].start_time
                ? format(
                    set(new Date(), {
                      hours: newGameStore.games[index].start_time.split(":")[0],
                      minutes:
                        newGameStore.games[index].start_time.split(":")[1],
                    }),
                    "h:mm a"
                  )
                : "TBA"
            }
        -
      ${
        newGameStore.games[index].start_date
          ? format(
              new Date(newGameStore.games[index].start_date.replace(/-/g, "/")),
              "EEE, MMM do"
            )
          : "TBA"
      }
      |
      ${
        newGameStore.games[index].park_name
          ? newGameStore.games[index].park_name
          : "TBA"
      }-${
            newGameStore.games[index].diamond_signifier
              ? newGameStore.games[index].diamond_signifier
              : "TBA"
          } (*)
            `,
          value: `winner-of-temp-${newGameStore.games[index].tempGameId}`,
        });
        options.push({
          label: `L G#
            ${newGameStore.games[index].tempGameId}
            |
            ${
              newGameStore.games[index].start_time
                ? format(
                    set(new Date(), {
                      hours: newGameStore.games[index].start_time.split(":")[0],
                      minutes:
                        newGameStore.games[index].start_time.split(":")[1],
                    }),
                    "h:mm a"
                  )
                : "TBA"
            }
        -
      ${
        newGameStore.games[index].start_date
          ? format(
              new Date(newGameStore.games[index].start_date.replace(/-/g, "/")),
              "EEE, MMM do"
            )
          : "TBA"
      }
      |
      ${
        newGameStore.games[index].park_name
          ? newGameStore.games[index].park_name
          : "TBA"
      }-${
            newGameStore.games[index].diamond_signifier
              ? newGameStore.games[index].diamond_signifier
              : "TBA"
          } (*)
            `,
          value: `loser-of-temp-${newGameStore.games[index].tempGameId}`,
        });
      }
    }

    for (const game of gameStore.games) {
      if (game.division_id === newGameStore.games[props.index].division_id) {
        options.push({
          label: `
    W G#${game.event_specific_game_id} |

        ${
          game.start_time
            ? format(
                set(new Date(), {
                  hours: game.start_time.split(":")[0],
                  minutes: game.start_time.split(":")[1],
                }),
                "h:mm a"
              )
            : "TBA"
        }

      -
      ${
        game.start_date
          ? format(new Date(game.start_date.replace(/-/g, "/")), "EEE, MMM do")
          : "TBA"
      }
      |
      ${game.park_name}-${game.diamond_signifier}
    `,
          value: `winner-of-${game.id}`,
        });
        options.push({
          label: `
    L G#${game.event_specific_game_id} |

    ${
      game.start_time
        ? format(
            set(new Date(), {
              hours: game.start_time.split(":")[0],
              minutes: game.start_time.split(":")[1],
            }),
            "h:mm a"
          )
        : "TBA"
    }

      -
      ${
        game.start_date
          ? format(new Date(game.start_date.replace(/-/g, "/")), "EEE, MMM do")
          : "TBA"
      }
      |
      ${game.park_name}-${game.diamond_signifier}
    `,
          value: `loser-of-${game.id}`,
        });
      }
    }
    const division = leagueStore.divisions.find(
      (division) =>
        division.division_name === newGameStore.games[props.index].division_id
    );

    for (let team = 1; team <= division.teams.length; team++) {
      options.push({
        label: `${ordinal(team)} Place -
        ${division.division_name}`,
        value: "placed-" + team + "-" + division.division_name,
      });
    }
  }

  return options;
});
</script>
<style scoped>
td {
  text-align: center;
  margin: 0 auto;
  /* border-right: 1px solid var(--ion-color-light); */
}
tr:nth-child(odd) {
  background-color: var(--ion-color-light);
}
</style>
<style scoped>
.fix-modal-height {
  --width: auto;
  --height: auto;
  --max-width: 100%;
  --max-height: 100%;
}

.v-select {
  --vs-controls-color: var(--ion-color-step-300);
  --vs-border-width: 0;

  --vs-dropdown-bg: var(--ion-background-color);
  --vs-dropdown-option-color: var(--ion-text-color);

  --vs-selected-bg: var(--ion-color-primary);
  --vs-selected-color: var(--ion-text-color);

  --vs-search-input-color: var(--ion-color-step-300);

  --vs-dropdown-option--active-bg: #664cc3;
  --vs-dropdown-option--active-color: #eeeeee;
  --vs-dropdown-min-width: 1rem;
  --vs-dropdown-option--deselect-color: red;

  --vs-disabled-bg: var(--ion-color-light);
  --vs-disabled-color: var(--ion-color-light);
  --vs-disabled-controls-color: var(--ion-color-light);
}
/* .vs__selected-options {
  flex-direction: row-reverse;
}
.vs--open .vs__selected-options {
  flex-direction: row;
}
.vs--open .vs__selected {
  flex-grow: 0;
} */
</style>
<style>
.vs__dropdown-menu {
  background-color: var(--ion-background-color);
  color: var(--ion-text-color);
}
.vs__dropdown-option--selected {
  background-color: var(--ion-color-medium);
  color: var(--ion-background-color);
}
.vs__dropdown-option--highlight {
  background-color: var(--ion-color-primary);
  color: var(--ion-text-color);
}
.vs__selected {
  background-color: var(--ion-background-color);
}

.add-games-input {
  border: none;
  height: 58px;
  background: transparent;
}

.custom-datetime-button {
  background: transparent;

  cursor: pointer;
  font-size: 14px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-content {
  padding: 5px;
}

.date-name {
  background: #444;
  text-transform: uppercase;
  font-size: 12px;
  padding: 2px;
  font-weight: 700;
}

tr {
  border-bottom: 1px solid #555;
}

td {
  border-right: 1px solid #555;
}

td:first-child {
  border-left: 1px solid #555;
}

td:hover {
  background-color: var(--ion-color-step-50);
}

.v-select {
  cursor: pointer;
}
.vs__dropdown-toggle {
  height: 58px;
  cursor: pointer;
  background: transparent !important;
}
.vs__selected-options,
.vs__search {
  background: transparent !important;
}
.danger {
  background: #421c20 !important;
}
</style>
