<template>
    <ion-page>
        <ion-header>
            <ion-toolbar class="header-toolbar">
                <ion-buttons slot="start">
                    <ion-back-button
                        defaultHref="/scorekeep"
                        color="dark"
                    ></ion-back-button>
                </ion-buttons>
                <ion-title>Scorekeeper</ion-title>
                <ion-buttons slot="end">
                    <ion-button
                        color="dark"
                        v-on:click="scorekeepStore.setLineups = false"
                    >
                        Edit Lineups
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content  v-if="scorekeepStore.gameEnded === false" style="display: flex; flex-direction: column; height: 100%;">
            <div style="flex: 1; display: flex; flex-direction: column; overflow: hidden;">
                <span v-if="scorekeepStore.setLineups && scorekeepStore.gameEnded === false">
                    
                    <ScoreboardView></ScoreboardView>
                    <ion-segment mode="md" v-model="selectedSegment">
                        <ion-segment-button value="playByPlay">
                            <ion-label>Scorekeep</ion-label>
                        </ion-segment-button>
                        <ion-segment-button value="gameLog">
                            <ion-label>Log</ion-label>
                        </ion-segment-button>
                        <ion-segment-button value="viewScorecard">
                            <ion-label>Card</ion-label>
                        </ion-segment-button>
                        <ion-segment-button value="admin">
                            <ion-label>Admin</ion-label>
                        </ion-segment-button>
                    </ion-segment>
                    <div v-if="selectedSegment === 'playByPlay'">
                        <div style="width: 100%; margin-top: 20px; margin-bottom: 20px;" v-if="currentBench.length > 0">
                            <ion-button 
                                size="small"
                                fill="outline"
                                color="dark"
                                expand="full"
                                v-on:click="openSubstitutionModel()">
                                Make a Substitution
                            </ion-button>
                        </div>
                        <OnDeckView></OnDeckView>
                        <AtBatView></AtBatView>
                        <PlaySummaryView></PlaySummaryView>
                        <OnBaseView></OnBaseView>
                    </div>
                    <div v-if="selectedSegment === 'gameLog'">
                        <GameLogView></GameLogView>
                    </div>
                    <div v-if="selectedSegment === 'viewScorecard'" style="flex: 1; overflow: auto;">
                        <ScorecardView></ScorecardView>
                    </div>
                    <div v-if="selectedSegment === 'admin'">
                        <FinalOptionsView></FinalOptionsView>
                    </div>
                   
                    
                    
                    
                    <!-- {{  scorekeepStore.atBat }} -->
                </span>
                <span v-if="!scorekeepStore.setLineups">
                    <!-- setAwayTeamName -->
                    <ion-item>
                        <ion-label position="stacked">Max Innings</ion-label>
                        <ion-input type="number" v-model="scorekeepStore.maxInningsCount"></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-label position="stacked">Away Team Name</ion-label>
                        <ion-input v-model="scorekeepStore.awayTeam.name"></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-label position="stacked">Home Team Name</ion-label>
                        <ion-input v-model="scorekeepStore.homeTeam.name"></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-label position="stacked">Runs Per Inning</ion-label>
                        <ion-input v-model="scorekeepStore.runsPerInning"></ion-input>
                    </ion-item>
                    <!-- setHomeTeamName -->
                    <SetLineups></setLineups>
                </span>
            </div>

            <div 
                v-if="scorekeepStore.readyToSubmitPlay"
                class="submit-play">
                <ion-button
                    v-on:click="scorekeepStore.clearPlay"
                    color="danger">
                    Clear
                </ion-button>
                <ion-button 
                    v-on:click="scorekeepStore.submitPlay" 
                    expand="full">Submit Play & Move to Next Batter</ion-button>
            
            </div>

            <div class="half-inning-changeover" v-if="displayHalfInningChangeoverGraphic">
                {{  defaultText }}
            </div>

            <!-- test
            <ion-button v-on:click="setMaxInningsToNine">Set Max Innings to 9</ion-button> -->
        </ion-content>
        <ion-content v-if="scorekeepStore.gameEnded === true">
            <div style="flex: 1; display: flex; flex-direction: column; overflow: hidden; margin-top: 50px;">
                <ScoreboardView></ScoreboardView>
                <br><br><br>
                <div class="game-ended">
                    <div class="game-ended-header">
                        Game Over        
                    </div>
                    <div class="game-ended-content">
                        <p>Now choose what you want to do next:</p>
                        <ion-button v-if="!authStore.user">Login/Register an Account</ion-button>
                        <!-- <ion-button>Report the Score</ion-button> -->
                        <ion-button 
                            v-if="scorekeepStore.gameIsSaved === false"
                            v-on:click="saveToGameServer()"
                        >
                            Save to Game Server
                        </ion-button>
                        <!-- <ion-button>Email a Copy</ion-button> -->
                        <ion-button>Back to Main Page</ion-button>
                    </div>
                </div>
                
            </div>
        </ion-content>
    </ion-page>
 </template>
  
<script setup>
    import { ref, watch, computed, onMounted } from 'vue';
    import { IonPage, IonContent, IonButton, IonItem, IonLabel, IonInput, IonSegment, IonSegmentButton, IonButtons, IonBackButton, IonTitle, IonHeader, IonToolbar } from '@ionic/vue';
    import { showToast } from "@/utils/useIonicComponents.js";
    import { useRoute } from 'vue-router';
    import ScoreboardView from '@/components/scorekeep/ScoreboardView.vue';
    import ScorecardView from '@/components/scorekeep/ScorecardView.vue';
    import SetLineups from '../components/scorekeep/SetLineups.vue';
    import AtBatView from '../components/scorekeep/AtBatView.vue';
    import OnDeckView from '../components/scorekeep/OnDeckView.vue';
    import OnBaseView from '../components/scorekeep/OnBaseView.vue';
    import GameLogView from '../components/scorekeep/GameLogView.vue';
    import FinalOptionsView from '../components/scorekeep/FinalOptionsView.vue';
    import PlaySummaryView from '../components/scorekeep/PlaySummaryView.vue';
    import { useScorekeepStore } from "@/stores/scorekeepStore"
    import { openModal } from "@/utils/useIonicComponents.js";
    import { useAuthStore } from "@/stores/authStore";
    import SubstitutionModel from '../components/scorekeep/SubstitutionModel.vue';

    const scorekeepStore = useScorekeepStore()
    const route = useRoute();
    const authStore = useAuthStore();

    onMounted(() => {
        const gameId = route.params.game_id; // Assuming 'slug' is the route parameter name
        console.log('onMounted', gameId)
        scorekeepStore.customScorekeptGameId = gameId;
        scorekeepStore.loadState(gameId); // Optionally load the game state if it exists
    });


    const displayHalfInningChangeoverGraphic = ref(false);

    const defaultText = ref(`${scorekeepStore.currentHalf === 0 ? 'Top' : 'Bottom'} of ${scorekeepStore.currentInning}`)

    const selectedSegment = ref('playByPlay');

    const openSubstitutionModel = () => {
        openModal(SubstitutionModel, 'substitutionModel');
    }   
    // import { ref } from 'vue';

    // const setMaxInningsToNine = () => {
    //     console.log('setMaxInningsToNine');
    //     scorekeepStore.setMaxInningsCount(9);
    // }

    const currentBench = computed(() => {
        return scorekeepStore.currentHalf === 0 ? scorekeepStore.awayTeamBench : scorekeepStore.homeTeamBench;
    });

    const saveToGameServer = () => {
        console.log('saveToGameServer');
        scorekeepStore.saveGameToServer();

        showToast("Saved Game to the Server", "success");
    }

    watch(() => scorekeepStore.isHalfInningChangeover, (newValue) => {
    if (newValue) {
        // Display something for 5 seconds
        displayHalfInningChangeoverGraphic.value = true
        
        setTimeout(() => {
            console.log('triggered')
            defaultText.value = `${scorekeepStore.currentHalf === 0 ? 'Top' : 'Bottom'} of ${scorekeepStore.currentInning}`;
        }, 1500);

        // Set a timeout to reset the flag after 5 seconds
        setTimeout(() => {
            console.log('triggered')
            displayHalfInningChangeoverGraphic.value = false;
            
            scorekeepStore.isHalfInningChangeover = false;
        }, 3000);
    }
});
</script>

<style scoped>
.submit-play {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    z-index: 700;
}

.half-inning-changeover {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 200px;
    background-color: #7438e9;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: #fff;
    text-align: center;
    font-size: 24px;
    z-index: 1000;
    text-transform: uppercase;
    animation: flipCoin 3s infinite; /* Adjust duration and iteration as needed */
}

.game-ended {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.game-ended-header {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
}

@keyframes flipCoin {
    0% {
        transform: translate(-50%, -50%) rotateY(0deg);
    }
    50% {
        transform: translate(-50%, -50%) rotateY(180deg);
    }
    100% {
        transform: translate(-50%, -50%) rotateY(360deg);
    }
}
</style>

  