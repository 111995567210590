<template>
  <base-modal modal-title="Import Your Schedule">
    <template #headerStart>
      <ion-button @click="cancel"> Cancel </ion-button>
    </template>
    <ion-nav :root="UploadComponent"></ion-nav>
  </base-modal>
</template>

<script setup>
import { defineComponent } from "vue";
import { IonNav, modalController, IonButton } from "@ionic/vue";
import BaseModal from "@/components/layouts/BaseModal.vue";
import UploadComponent from "@/components/schedule/upload-csv/UploadComponent.vue";
import { useRoute, useRouter } from "vue-router";

defineComponent({
  name: "UploadScheduleModal",
});
const router = useRouter();
const route = useRoute();
router.replace({
  query: {
    ...route.query,
    open_modal: "UploadCSV",
  },
});

async function cancel() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss();
}
</script>
