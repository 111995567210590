<template>
    <div>
        <div class="container">
            <div class="at-bat-at-bat-header">At Bat</div>
            
            <div class="at-bat-at-bat-container">
                <div class="player-details player-name">
                   {{ scorekeepStore.atBat.name }}
                </div>
                <div class="player-details">
                    <div class="player-pos">{{ scorekeepStore.atBat.position }} - #{{ scorekeepStore.atBat.number }}</div>
                    <div class="player-res">3 for 4, 2 2Bs</div>
                    
                </div>
              
                <div
                    class="fives-on-a-base" 
                    v-if="checkIfTheyAreMaxRunPerInning('home')">
                    {{ `${scorekeepStore.runsPerInning} is at the plate!`}}
                </div>
                <div class="choose-result-area">
                    <div class="choose-result">Choose Result</div>
                    <div class="at-bat-at-bat-selectors">
                        <ion-button
                            
                            color="tertiary"
                            :fill="scorekeepStore.currentPlay.hitResult === 'walk' ? 'solid' : 'outline'"

                            class="at-bat"
                            @click="scorekeepStore.updateCurrentPlay({ 
                                hitResult: 'walk', 
                                scoredResult: false,
                                outResult: false 
                            })"
                        >
                            Walk
                        </ion-button>
                        <ion-button
                            
                            color="primary"
                            :fill="scorekeepStore.currentPlay.hitResult === 'single' ? 'solid' : 'outline'"
                            class="at-bat"

                            @click="scorekeepStore.updateCurrentPlay({ 
                                hitResult: 'single', 
                                scoredResult: false,
                                outResult: false 
                            })"
                        >
                            1B
                        </ion-button>
                        <ion-button
                            
                            color="secondary"
                            :fill="scorekeepStore.currentPlay.hitResult === 'double' ? 'solid' : 'outline'"
                            class="at-bat"
                            @click="scorekeepStore.updateCurrentPlay({ 
                                hitResult: 'double', 
                                scoredResult: false,
                                outResult: false 
                            })"
                        >
                            2B
                        </ion-button>
                        <ion-button
                            
                            color="warning"
                            :fill="scorekeepStore.currentPlay.hitResult === 'triple' ? 'solid' : 'outline'" 
                            class="at-bat"
                            @click="scorekeepStore.updateCurrentPlay({ 
                                hitResult: 'triple', 
                                scoredResult: false,
                                outResult: false 
                            })"
                        >
                            3B
                        </ion-button>
                        <ion-button
                            
                            color="success"
                            :fill="scorekeepStore.currentPlay.hitResult === 'homerun' ? 'solid' : 'outline'"
                            class="at-bat"
                            @click="scorekeepStore.updateCurrentPlay({ 
                                hitResult: 'homerun', 
                                scoredResult: true,
                                outResult: false 
                            })"
                            
                        >
                            HR
                        </ion-button>
                        
                        <!-- <ion-button
                            size="small"
                            color="primary"
                            fill="outline" 
                        >
                            Custom
                        </ion-button> -->
                    </div>
                    <div class="at-bat-at-bat-selectors">
                        <ion-button
                            size="small"
                            color="danger"
                            :fill="scorekeepStore.currentPlay.hitResult === 'out' ? 'solid' : 'outline'"
                            class="at-bat"
                            @click="scorekeepStore.updateCurrentPlay({ 
                                hitResult: 'out', 
                                scoredResult: false,
                                outResult: true
                            })"
                        >
                            Out
                        </ion-button>
                        <ion-button
                            size="small"
                            color="danger"
                            v-if="isRunnerOnBase"
                            :fill="scorekeepStore.currentPlay.hitResult === 'sacfly' ? 'solid' : 'outline'"
                            class="at-bat"
                            @click="scorekeepStore.updateCurrentPlay({ 
                                hitResult: 'sacfly', 
                                scoredResult: false,
                                outResult: true
                            })"
                        >
                            Sac Fly
                        </ion-button>
                        <ion-button
                            size="small"
                            color="danger"
                            v-if="isRunnerOnBase"
                            :fill="scorekeepStore.currentPlay.hitResult === 'doubleplay' ? 'solid' : 'outline'"
                            class="at-bat"
                            @click="scorekeepStore.updateCurrentPlay({ 
                                hitResult: 'doubleplay', 
                                scoredResult: false,
                                outResult: true
                            })"
                        >
                            Double Play
                        </ion-button>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
// calculating who is at bat
import { IonButton } from '@ionic/vue';
import { computed } from 'vue';
import { useScorekeepStore } from "@/stores/scorekeepStore"
const scorekeepStore = useScorekeepStore()

console.log('scorekeepStore', scorekeepStore)
// if currentHalf = 0 then away is hitting
// if currentHalf = 1 then home is hitting



const isRunnerOnBase = computed(() => {
    return scorekeepStore.onThirdBase || scorekeepStore.onSecondBase || scorekeepStore.onFirstBase;
});


const checkIfTheyAreMaxRunPerInning = (base) => {
    console.log('base', base);
    if (isNaN(scorekeepStore.runsPerInning)) return false;
    
    let numberOfRunnersOn = 0;

    if (base === 'home') {
        numberOfRunnersOn++;
        if (scorekeepStore.onFirstBase) numberOfRunnersOn++;
        if (scorekeepStore.onSecondBase) numberOfRunnersOn++;
        if (scorekeepStore.onThirdBase) numberOfRunnersOn++;
    }

    if (base === 'first') {
        if (scorekeepStore.onFirstBase) numberOfRunnersOn++;
        if (scorekeepStore.onSecondBase) numberOfRunnersOn++;
        if (scorekeepStore.onThirdBase) numberOfRunnersOn++;
    }
    if (base === 'second') {
        if (scorekeepStore.onSecondBase) numberOfRunnersOn++;
        if (scorekeepStore.onThirdBase) numberOfRunnersOn++;
    }
    if (base === 'third') {
        if (scorekeepStore.onThirdBase) numberOfRunnersOn++;
    }

    let possibleRuns = 0;
    let inningIndex = scorekeepStore.currentInning - 1; // Adjust for zero-based index if necessary
    console.log('scorekeepStore.awayInningScores', scorekeepStore.awayInningScores)
    // Check if the inning index is within the bounds of the array
    if (inningIndex >= 0 && inningIndex < scorekeepStore.awayInningScores.length) {
        if (scorekeepStore.currentHalf === 0) {
            possibleRuns = scorekeepStore.awayInningScores[inningIndex] + numberOfRunnersOn;
        } else {
            possibleRuns = scorekeepStore.homeInningScores[inningIndex] + numberOfRunnersOn;
        }
    } else {
        console.error('Inning index is out of bounds:', inningIndex);
        return false;
    }

    return possibleRuns === parseInt(scorekeepStore.runsPerInning);
}

</script>

<style>
     .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid #555;
    }

    .at-bat-at-bat-header {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        border-top: 1px solid #555;
        border-bottom: 1px solid #555;
        width: 100%;
        text-align: center;
        background: rgb(116, 56, 233, 0.3);
    }

    .at-bat-at-bat-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px 10px;
        background: rgb(116, 56, 233, 0.1);
    }

    .choose-result-area {
        /* background: rgba(0, 0, 0, 0.9); */
        margin-top: 10px;
        padding: 5px;
    }

    .player-details {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .player-name {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: #fff;
        margin-bottom: 5px;
    }

    .player-pos {
        font-size: 12px;
        font-weight: 700;
        border-radius: 5px;
        color: #eee;
        
        padding: 5px;
        background: rgba(200,200,200,0.1)
    }

    .choose-result {
        font-size: 10px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
    }

    .player-res {
        font-size: 12px;
        color: #bbb;
        font-weight: 700;
        border-radius: 5px;
        font-style: italic;
        padding: 5px;
        background: rgba(200,200,200,0.1)
    }

    .at-bat-at-bat-selectors {
        display: flex;
        margin: 5px 0;
        justify-content: space-between; /* Adjusts spacing between buttons */
        width: 100%; /* Ensures the container takes full width */
    }

    ion-button.at-bat {
        flex: 1; /* Each button will take equal space in the container */
        margin: 0 2px; /* Optional: adds a small space between buttons */
    }

</style>