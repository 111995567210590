<template>
  <div>
    <ion-toolbar color="tertiary">
      <ion-buttons slot="start">
        <ion-button fill="solid" v-if="mergedScheduleLayout.length > 0" @click="openSettingsModal">
          Auto Generate Schedule
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button fill="solid" color="light" @click="openSettingsModal">
          <ion-icon :icon="cogOutline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <div>
      <ion-grid>
        <ion-row>
          <ion-col> </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <div class="no-rows" v-if="mergedScheduleLayout.length === 0">
              You don't have any bookings set in Step 3! Please go back and set
              some bookings.
            </div>
            <div
              v-for="daySchedule in mergedScheduleLayout"
              :key="daySchedule.date"
            >
              <ion-grid>
                <ion-row>
                  <ion-col size="10">
                    <div class="schedule-header">
                      {{ formatDate(daySchedule.date) }}
                    </div>
                  </ion-col>
                  <ion-col size="2">
                    <ion-button
                      size="small"
                      expand="full"
                      @click="clearDatesGames(daySchedule.date)"
                      >Clear Dates Games</ion-button
                    >
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="3">
                    <div class="row-header">Park - Diamond</div>
                  </ion-col>

                  <ion-col size="1.5">
                    <div class="row-header">Start Date</div>
                  </ion-col>
                  <ion-col size="1.5">
                    <div class="row-header">Time</div>
                  </ion-col>
                  <ion-col size="1.9">
                    <div class="row-header">Division Name</div>
                  </ion-col>
                  <ion-col size="1.9">
                    <div class="row-header">Away Team</div>
                  </ion-col>
                  <ion-col size="1.9">
                    <div class="row-header">Home Team</div>
                  </ion-col>
                </ion-row>

                <ion-row
                  class="data-row"
                  v-for="game in daySchedule.games"
                  :key="game.id"
                >
                  <ion-col size="3">
                    <div class="row-item">{{ game.park_diamond_name }}</div>
                  </ion-col>
                  <ion-col size="1.5">
                    <div class="row-item">{{ game.start_date }}</div>
                  </ion-col>
                  <ion-col size="1.5">
                    <div class="row-item">{{ game.start_time }}</div>
                  </ion-col>
                  <ion-col size="6">
                    <div class="full-row">
                      <div
                        class="row-item"
                        @click="openModal(game)"
                        :class="{
                          'filled-row': game.division_name,
                          selected: selectedRow && game.id === selectedRow.id,
                        }"
                      >
                        <ion-row class="ion-no-padding ion-no-margin">
                          <ion-col
                            v-if="game.division_name"
                            size="4"
                            class="ion-no-padding ion-no-margin"
                          >
                            <div>{{ game.division_name.division_name }}</div>
                          </ion-col>
                          <ion-col
                            v-if="game.away_team"
                            class="ion-no-padding ion-no-margin"
                            size="4"
                          >
                            <div
                              :style="{
                                borderLeft: game.flip_home
                                  ? `10px solid ${game.away_team.color.color}`
                                  : `10px solid ${game.home_team.color.color}`,
                              }"
                            >
                              {{
                                game.flip_home
                                  ? game.home_team.name
                                  : game.away_team.name
                              }}
                            </div>
                          </ion-col>

                          <ion-col
                            v-if="game.home_team"
                            class="ion-no-padding ion-no-margin"
                            size="4"
                          >
                            <div
                              :style="{
                                borderRight: game.flip_home
                                  ? `10px solid ${game.home_team.color.color}`
                                  : `10px solid ${game.away_team.color.color}`,
                              }"
                            >
                              {{
                                game.flip_home
                                  ? game.away_team.name
                                  : game.home_team.name
                              }}
                            </div>
                          </ion-col>
                        </ion-row>
                      </div>
                      <div>
                        <ion-button
                          class="ion-no-margin"
                          size="small"
                          fill="none"
                          style="margin-top: -10px"
                          @click="flipHome(game)"
                        >
                          <ion-icon :icon="swapHorizontalOutline"></ion-icon>
                        </ion-button>
                      </div>
                    </div>
                  </ion-col>
                  <!-- <ion-col size="2">
                                        <div class="row-item">{{ game.division_name }}</div>
                                    </ion-col>
                                    <ion-col size="2">
                                        <div class="row-item">{{ game.away_team }}</div>
                                    </ion-col>
                                    <ion-col size="2">
                                        <div class="row-item">{{ game.home_team }}</div>
                                    </ion-col> -->
                </ion-row>
              </ion-grid>
            </div>
          </ion-col>
          <ion-col size="0"> </ion-col>
        </ion-row>
      </ion-grid>

      <ion-modal
        :is-open="showModal"
        @didDismiss="closeModal()"
        class="tall-modal"
      >
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button
                color="danger"
                @click="deleteGame(this.selectedRow?.id)"
                v-if="this.selectedRow?.division_name"
              >
                <ion-icon :icon="trashOutline"></ion-icon> Delete Current
              </ion-button>
            </ion-buttons>
            <ion-title>Game Details</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="closeModal()" color="dark">
                <ion-icon :icon="closeOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <div class="modal-content" style="height: 100%; overflow: scroll">
          <!-- need a checkbox if doubleheaders to display doubleheaders -->
          <ion-item v-if="settings.singleDouble === 'double'">
            <ion-label>Assign Doubleheaders Automatically</ion-label>
            <ion-checkbox
              :checked="settings.assignDoubleheadersAutomatically"
              @ionChange="
                settings.assignDoubleheadersAutomatically =
                  $event.target.checked
              "
            >
            </ion-checkbox>
          </ion-item>
          <div class="search-bar">
            <ion-icon :icon="searchOutline" class="search-icon"></ion-icon>
            <ion-input
              placeholder="Search for division name or team name"
              :autofocus="true"
              :clearInput="true"
              v-model="searchTerm"
            ></ion-input>
          </div>

          <div class="round-robins">
            <div class="game-options">Round Robin Matchup Options</div>
            <p style="font-size: 12px">
              This is all the variations of games that your schedule can have.
              Select these to add them to the schedule. Doubleheaders will be
              auto populated to the timeslot below as well.
            </p>
            <p style="font-size: 12px">
              Games that do not fit the rules you've set up in various setting
              areas be greyed out.
            </p>

            <div
              class="division-row"
              v-for="(roundRobin, index) of filteredRoundRobins"
              :key="index"
            >
              <!-- move to divisional tabs -->
              <div class="division-header">
                {{ roundRobin.division_name }}
              </div>
              <div class="division-content">
                <div
                  v-for="(round, roundIndex) of roundRobin.schedule"
                  :key="round"
                  class="round-row"
                >
                  <div class="round-header">Round {{ roundIndex + 1 }}</div>
                  <div class="round-content">
                    <div
                      :class="[
                        'games-row',
                        {
                          'game-not-allowed': isGameNotAllowed(
                            game,
                            roundRobin
                          ),
                        },
                      ]"
                      v-for="(game, gameIndex) of round"
                      :key="gameIndex"
                      @click="selectGameIntoMatchup(game, roundRobin)"
                      :style="{
                        borderLeft: `8px solid ${game[0].color.color}`,
                        borderRight: `8px solid ${game[1].color.color}`,
                      }"
                    >
                      <div class="game-item">
                        <div class="game-team">
                          <div class="game-name">
                            {{ game[0].name }}
                          </div>
                          <div class="game-count">
                            Game Count:
                            {{ perTeamGameCount[game[0].color.color] }}
                            <!-- counts the number of times the team has been used in the schedule -->
                          </div>
                        </div>
                        <div class="game-team-vs">vs</div>
                        <div class="game-team">
                          <div class="game-name">
                            {{ game[1].name }}
                          </div>
                          <div class="game-count">
                            Game Count:
                            {{ perTeamGameCount[game[1].color.color] }}
                            <!-- counts the number of times the team has been used in the schedule -->
                          </div>
                        </div>
                      </div>
                      <div class="game-division-name">
                        Matchup Used:
                        {{
                          perMatchupCount[
                            game[0].color.color + game[1].color.color
                          ] || 0
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- starting here tomorrow, you want to create a succint ui for display teams with decent information -->
          <!-- remember it should be matchups -->
          <!-- review your notion -->
          <!-- need to do a check to make sure it's valid against selectedRow details -->
          <!-- clicking on it adds them to the schedule -->
          <!-- <pre>
                        {{ selectedRow }}
                    </pre> -->
        </div>
      </ion-modal>
      <ion-modal
        :is-open="showSettingsModal"
        @didDismiss="closeSettingsModal()"
      >
        <ion-header>
          <ion-toolbar>
            <ion-title>Settings</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="closeSettingsModal()">
                <ion-icon color="dark" :icon="closeOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <div class="modal-content" style="height: 100%; overflow: scroll">
          <!-- Settings content goes here -->
          <p>Game Play</p>
          <ion-item>
            <ion-label
              >Do you have max number of games a team can play per
              day?</ion-label
            >
            <ion-checkbox
              slot="end"
              @ionChange="
                settings.maxNumberOfGamesPerDayCheck = $event.target.checked
              "
              :value="settings.maxNumberOfGamesPerDayCheck"
            >
            </ion-checkbox>
          </ion-item>
          <ion-item v-if="settings.maxNumberOfGamesPerDayCheck === true">
            <ion-label position="stacked"
              >What's the max number of games they can play per day?</ion-label
            >
            <ion-input
              type="number"
              v-model="settings.maxNumberOfGamesPerDay"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-label
              >Do you have max number of games a team can play per
              week?</ion-label
            >
            <ion-checkbox
              slot="end"
              @ionChange="
                settings.maxNumberOfGamesPerWeekCheck = $event.target.checked
              "
              :value="settings.maxNumberOfGamesPerWeekCheck"
            >
            </ion-checkbox>
          </ion-item>
          <ion-item v-if="settings.maxNumberOfGamesPerWeekCheck === true">
            <ion-label position="stacked"
              >What's the max number of games they can play per week?</ion-label
            >
            <ion-input
              type="number"
              v-model="settings.maxNumberOfGamesPerWeek"
            ></ion-input>
          </ion-item>
          <ion-note v-if="settings.maxNumberOfGamesPerWeekCheck === true"
            >Weeks are considered Monday to Sunday</ion-note
          >
          <span v-if="teamsData.divisions.length > 1">
            <p>Cross-Divisional Play</p>
            <ion-item>
              <ion-label>Do divisions play each other? (Cross-Division)</ion-label>
              <ion-checkbox
                slot="end"
                @ionChange="
                  settings.divisionsPlayEachOther = $event.target.checked
                "
                :value="settings.divisionsPlayEachOther"
              >
              </ion-checkbox>
            </ion-item>
            <ion-item v-if="settings.divisionsPlayEachOther">
              <ion-label position="stacked">
                How many times should interdivision teams play? Note:
                Doubleheaders are a "Set" of games. So if you select 2
                games/set, each team will cross-division for 2 "Sets" of games (4 games total).
              </ion-label>
              <!-- ion-select, once, twice, three times, all the time, custom number -->
              <ion-select v-model="settings.interdivisionGames">
                <ion-select-option value="1">One Game or Sets</ion-select-option>
                <ion-select-option value="2">Two Games or Sets</ion-select-option>
                <ion-select-option value="3">Three Games or Sets</ion-select-option>
                <ion-select-option value="all"
                  >Intermingle (No Restrictions)</ion-select-option
                >
              </ion-select>
            </ion-item>
          </span>
          <ion-item v-if="settings.divisionsPlayEachOther">
            <ion-label>What divisions play each other?</ion-label>
          </ion-item>
          <div v-if="settings.divisionsPlayEachOther">
            <div
              v-for="(row, index) in settings.divisionsThatPlayEachOther"
              :key="index"
            >
              <ion-item>
                <ion-label position="stacked">Select Divisions</ion-label>
                <ion-select
                  multiple
                  v-model="settings.divisionsThatPlayEachOther[index]"
                >
                  <ion-select-option
                    v-for="division in teamsData.divisions"
                    :value="division"
                    :key="division.id"
                  >
                    {{ division.name }}
                  </ion-select-option>
                </ion-select>
                <ion-button slot="end" color="danger" @click="removeRow(index)">
                  <ion-icon :icon="trashOutline"></ion-icon>
                </ion-button>
              </ion-item>
            </div>
            <ion-note
              >In each row, add all the divisions that can play each other. For
              example if A can play B, and B can play C, but A does not play C,
              you want to create two rows. One with A & B selected. One with B &
              C selected.</ion-note
            >
            <ion-button expand="full" @click="addRow">Add Row</ion-button>

            <!-- add rows that play each other -->
          </div>

          <p>Game Scheduling</p>
          <ion-item>
            <ion-label>Are booked games single or double headers?</ion-label>
            <!-- single, doubleheader -->
            <ion-select v-model="settings.singleDouble">
              <ion-select-option value="single"
                >Singleheaders</ion-select-option
              >
              <ion-select-option value="double"
                >Doubleheaders</ion-select-option
              >
            </ion-select>
          </ion-item>
          <!-- show only if doubleheaders -->
          <ion-item v-if="settings.singleDouble === 'double'">
            <ion-label>Do you face the same team both times?</ion-label>
            <ion-select v-model="settings.sameTeamBothTimes">
              <ion-select-option :value="true">Yes</ion-select-option>
              <ion-select-option :value="false">No</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-button expand="full" style="margin-top: 20px;" @click="autoGeneratingSchedule()">
            <span v-if="generatingYourSchedule === false">Auto Generate Schedule using AI</span>
            <span v-if="generatingYourSchedule === true">Generating your schedule...</span>
          </ion-button>
          </div>
      </ion-modal>
      <!-- <pre style="font-size: 13px">
                {{ autoGeneratingSchedule }}
      </pre>
      <pre style="font-size: 13px; margin-top: 50px">
          {{ games }}
      </pre> -->
      
    </div>
  </div>
</template>

<script>
import { generateSchedule } from "@/utils/scheduleUtils"; // Adjust the path as necessary
import {
  IonToolbar,
  IonButtons,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonModal,
  IonHeader,
  IonTitle,
  // IonContent,
  // IonSearchbar,
  IonCheckbox,
  IonSelect,
  IonSelectOption,
  IonNote,
  IonItem,
  IonLabel,
  IonInput,
  IonIcon,
} from "@ionic/vue";
import moment from "moment";
import {
  closeOutline,
  searchOutline,
  cogOutline,
  trashOutline,
  swapHorizontalOutline,
} from "ionicons/icons";
var robin = require("roundrobin");

export default {
  data() {
    return {
      swapHorizontalOutline,
      closeOutline,
      searchOutline,
      cogOutline,
      trashOutline,
      showSettingsModal: false,
      showModal: false,
      selectedRow: null,
      searchTerm: "",
      showAutoGenerateModal: false,
      generatingYourSchedule: false,
      settings: {
        maxNumberOfGamesPerDayCheck: false,
        maxNumberOfGamesPerDay: 2,
        maxNumberOfGamesPerWeekCheck: false,
        maxNumberOfGamesPerWeek: 2,
        divisionsPlayEachOther: false,
        divisionsThatPlayEachOther: [],
        interdivisionGames: "all",
        singleDouble: "double",
        sameTeamBothTimes: true,
        assignDoubleheadersAutomatically: true,
      },
      // a game unique ID is the two colours of the two teams matching
    };
  },
  components: {
    IonToolbar,
    IonButtons,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonModal,
    IonHeader,
    IonTitle,
    // IonContent,
    // IonSearchbar,
    IonCheckbox,
    IonSelect,
    IonSelectOption,
    IonNote,
    IonItem,
    IonLabel,
    IonInput,
    IonIcon,
  },
  props: {
    scheduleData: Object,
    teamsData: Object,
    parksData: Object,
    daysOfWeek: Array,
    countries: Array,
    bookingsData: Object,
    games: Array,
  },
  mounted() {

  },
  computed: {
    computedScheduleLayout() {
      // Initialize an array to hold the schedule for each date
      const schedule = [];
      let gameId = 1; // Start a unique game ID count

      // Iterate through the bookings data to calculate game times
      Object.entries(this.bookingsData).forEach(([date, bookings]) => {
        const gamesForDate = {
          date: date,
          games: [],
        };

        bookings.forEach((booking) => {
          let startTime = moment(booking.startTime, "HH:mm");
          const endTime = moment(booking.endTime, "HH:mm");
          const gameLength = parseInt(booking.gameLength, 10);
          const breakBetweenGames = parseInt(booking.breakBetweenGames, 10);

          // Calculate the end time of the first game
          let gameEndTime = startTime.clone().add(gameLength, "minutes");

          // While there is enough time for another game, create game slots
          while (gameEndTime.isSameOrBefore(endTime)) {
            gamesForDate.games.push({
              id: gameId++,
              park_name: booking.park_name,
              diamond_name: booking.diamond_name,
              park_diamond_name: booking.park_diamond_name,
              start_time: startTime.format("HH:mm"),
              end_time: gameEndTime.format("HH:mm"),
              start_date: date,
              end_date: date, // Assuming the game doesn't span multiple days
              division_name: null,
              away_team: null,
              home_team: null,
            });

            // Update start time for the next game, including the break
            startTime = gameEndTime.clone().add(breakBetweenGames, "minutes");
            gameEndTime = startTime.clone().add(gameLength, "minutes");
          }
        });

        // Add the games for this date to the schedule array
        schedule.push(gamesForDate);
      });

      // Sort the schedule array by date in ascending order
      schedule.sort((a, b) =>
        moment(a.date).isAfter(moment(b.date)) ? 1 : -1
      );

      // Return the array containing the schedule
      return schedule;
    },
    mergedScheduleLayout() {
      return this.computedScheduleLayout.map((daySchedule) => {
        return {
          ...daySchedule,
          games: daySchedule.games.map((game) => {
            const matchingGame = this.games.find((g) => g.id === game.id);
            return matchingGame ? matchingGame : game;
          }),
        };
      });
    },
    sortGamesByGamesOnly() {
      // Use Array.prototype.reduce to flatten the games arrays into a single array
      return this.mergedScheduleLayout.reduce((allGames, daySchedule) => {
        return allGames.concat(daySchedule.games);
      }, []);
    },
    computedRoundRobins() {
      let roundRobins = [];

      // Generate round robins for each individual division
      this.teamsData.divisions.forEach((division) => {
        const roundRobin = robin(division.teams.length, division.teams);
        roundRobins.push({
          division_name: division.name,
          division_color: division.color,
          specialRequests: division.specialRequests,
          schedule: roundRobin,
        });
      });

      // If divisions play each other, generate round robins for combined divisions
      if (this.settings.divisionsPlayEachOther) {
        this.settings.divisionsThatPlayEachOther.forEach((divisionGroup) => {
          // Combine all teams from the divisions in the group
          const combinedTeams = divisionGroup.flatMap(
            (division) => division.teams
          );

          // Generate a round robin for the combined teams
          const roundRobin = robin(combinedTeams.length, combinedTeams);

          // Create a new division name
          const divisionNames = divisionGroup.map((division) => division.name);
          const combinedDivisionName = `Interleague - ${divisionNames.join(
            " & "
          )}`;

          roundRobins.push({
            division_name: combinedDivisionName,
            division_color: null, // You might want to decide on a color for these combined divisions
            schedule: roundRobin,
          });
        });
      }

      return roundRobins;
    },
    teamsByColor() {
      let teamsByColor = {};

      this.teamsData.divisions.forEach((division) => {
        division.teams.forEach((team) => {
          teamsByColor[team.color] = team;
        });
      });

      return teamsByColor;
    },
    filteredRoundRobins() {
      if (!this.searchTerm) {
        return this.computedRoundRobins;
      }

      const term = this.searchTerm.toLowerCase();

      return this.computedRoundRobins
        .map((roundRobin) => {
          const divisionNameMatches = roundRobin.division_name
            .toLowerCase()
            .includes(term);

          // If the division name matches, include all games
          if (divisionNameMatches) {
            return roundRobin;
          }

          // Otherwise, filter the games in each round
          const filteredSchedule = roundRobin.schedule
            .map((round) =>
              round.filter(
                (matchup) =>
                  matchup[0].name.toLowerCase().includes(term) ||
                  matchup[1].name.toLowerCase().includes(term)
              )
            )
            .filter((round) => round.length > 0); // Remove rounds that have no games

          // Only include round robins that have at least one game
          if (filteredSchedule.length > 0) {
            return {
              ...roundRobin,
              schedule: filteredSchedule,
            };
          }
        })
        .filter((roundRobin) => roundRobin); // Remove undefined values
    },
    filteredRounds() {
      // Create a cache for game counts to avoid unnecessary computations
      const gameCountsCache = {};

      return this.computedRoundRobins
        .map((roundRobin) => {
          const filteredSchedule = [];

          for (const round of roundRobin.schedule) {
            const filteredRound = [];

            for (const game of round) {
              const gameKey = this.getGameKey(game);

              // If the game count is not in the cache, calculate it
              if (!(gameKey in gameCountsCache)) {
                gameCountsCache[gameKey] = this.calculateGameCount(game);
              }

              // Only include the game if it is allowed
              if (
                !this.isGameNotAllowed(
                  game,
                  roundRobin,
                  gameCountsCache[gameKey]
                )
              ) {
                filteredRound.push(game);
              }
            }

            // Only include rounds that have at least one game
            if (filteredRound.length > 0) {
              filteredSchedule.push(filteredRound);
            }
          }

          // Only include round robins that have at least one round
          if (filteredSchedule.length > 0) {
            return {
              ...roundRobin,
              schedule: filteredSchedule,
            };
          }
        })
        .filter((roundRobin) => roundRobin); // Remove undefined values
    },
    perTeamGameCount() {
      // Initialize an empty object to hold the game counts
      const gameCounts = {};

      // Iterate over each team
      this.teamsData.divisions.forEach((division) => {
        division.teams.forEach((team) => {
          // Initialize the game count for this team
          gameCounts[team.color.color] = 0;

          // Iterate over each game in the schedule
          this.mergedScheduleLayout.forEach((daySchedule) => {
            daySchedule.games.forEach((game) => {
              // If this team is the away team or the home team, increment the game count
              if (
                (game.away_team &&
                  game.away_team.color.color === team.color.color) ||
                (game.home_team &&
                  game.home_team.color.color === team.color.color)
              ) {
                gameCounts[team.color.color]++;
              }
            });
          });
        });
      });

      // Return the object containing the game counts
      return gameCounts;
    },
    perMatchupCount() {
      // Initialize an empty object to hold the matchup counts
      const matchupCounts = {};

      // Iterate over each game in the schedule
      this.mergedScheduleLayout.forEach((daySchedule) => {
        daySchedule.games.forEach((game) => {
          // If both teams exist
          if (game.away_team && game.home_team) {
            // Create a key by concatenating the colors of the two teams
            const key = game.away_team.color.color + game.home_team.color.color;
            // If this key already exists in the object, increment the count
            // Otherwise, initialize the count to 1
            if (key in matchupCounts) {
              matchupCounts[key]++;
            } else {
              matchupCounts[key] = 1;
            }
          }
        });
      });

      // Return the object containing the matchup counts
      return matchupCounts;
    },
    
  },
  methods: {
    // generateRulesetForDivision(division) {
    //     console.log('division', division)

    //     let playsOnTheseDays = [...new Set([...division.specialRequests.plays_on_these_days, ...game[0].specialRequests.plays_on_these_days, ...game[1].specialRequests.plays_on_these_days])];

    //     return 'test'
    // },
    autoGeneratingSchedule() {
      console.log("mergedScheduleLayout", this.mergedScheduleLayout);
      //   const ruleset = {};

      console.log("computedRoundRobins", this.computedRoundRobins);

      this.generatingYourSchedule = true;

      const result = generateSchedule({ mergedScheduleLayout: this.mergedScheduleLayout, computedRoundRobins: this.computedRoundRobins, settings: this.settings, teamsData: this.teamsData });
      
      console.log("Generated Schedule Result:", result);



      this.generatingYourSchedule = false;     

      return true;
    },
    openAutoGenerateModal() {
      this.showAutoGenerateModal = true;
    },
    closeAutoGenerateModal() {
      this.showAutoGenerateModal = false;
    },
    formatDate(date) {
      return moment(date).format("dddd, MMMM Do, YYYY");
    },
    isGameNotAllowed(game, division) {
      if (!this.selectedRow) {
        return false;
      }

      // game[0] = team 1
      // game[1] = team 2

      // Combine the division plays_on_these_days as well as each individual team.specialRequests.plays_on_these_days
      let playsOnTheseDays = [
        ...new Set([
          ...division.specialRequests.plays_on_these_days,
          ...game[0].specialRequests.plays_on_these_days,
          ...game[1].specialRequests.plays_on_these_days,
        ]),
      ];

      // If playsOnTheseDays contains any specific day, remove 'any'
      if (playsOnTheseDays.some((day) => day !== "any")) {
        playsOnTheseDays = playsOnTheseDays.filter((day) => day !== "any");
      }

      // If the division is set to "any" but one team is set to "mondays", "mondays" trumps "any"
      const dayOfWeek = moment(this.selectedRow.start_date)
        .format("dddd")
        .toLowerCase();

      const cannotPlayOnDay =
        playsOnTheseDays.length > 0 &&
        !playsOnTheseDays.includes(dayOfWeek) &&
        !playsOnTheseDays.includes("any");

      // Confirm that the park in this.selectedRow.park_diamond_name is valid in the list of cannot_play_at_parks
      const cannotPlayAtPark =
        division.specialRequests.cannot_play_at_parks.some(
          (park) =>
            park.park_diamond_name === this.selectedRow.park_diamond_name
        );

      // Check if the teams have reached the maximum number of games per day
      const currentDate = this.selectedRow.start_date;
      const team1GamesToday = this.games.filter(
        (newGame) =>
          newGame.away_team.color.color === game[0].color.color &&
          newGame.start_date === currentDate
      ).length;

      const team2GamesToday = this.games.filter(
        (newGame) =>
          newGame.home_team.color.color === game[1].color.color &&
          newGame.start_date === currentDate
      ).length;

      const maxGamesReached =
        this.settings.maxNumberOfGamesPerDayCheck &&
        (team1GamesToday >= this.settings.maxNumberOfGamesPerDay ||
          team2GamesToday >= this.settings.maxNumberOfGamesPerDay);

      // Return true if it's not allowed
      return cannotPlayOnDay || cannotPlayAtPark || maxGamesReached;
    },
    openSettingsModal() {
      this.showSettingsModal = true;
    },
    closeSettingsModal() {
      this.showSettingsModal = false;
    },
    openModal(game) {
      this.selectedRow = game;
      this.showModal = true;
    },
    closeModal(game) {
      console.log(game);
      this.selectedRow = null;
      this.showModal = false;
    },
    addRow() {
      this.settings.divisionsThatPlayEachOther.push([]);
    },
    removeRow(index) {
      this.settings.divisionsThatPlayEachOther.splice(index, 1);
    },
    selectGameIntoMatchup(game, division) {
      // Create a clone of this.selectedRow with away_team being game[0] and home_team being game[1]
      const newGame = {
        ...this.selectedRow,
        away_team: game[0],
        home_team: game[1],
        division_name: division,
        flip_home: false,
      };

      // Add the new game to the games array
      this.$emit("updateGame", newGame);

      // If doubleheaders are automatically assigned, create a second game
      if (
        this.settings.singleDouble === "double" &&
        this.settings.assignDoubleheadersAutomatically
      ) {
        // Find the next game in mergedScheduleLayout
        const nextGameIndex =
          this.sortGamesByGamesOnly.findIndex((g) => g.id === newGame.id) + 1;

        const nextGame = this.sortGamesByGamesOnly[nextGameIndex];

        if (nextGame) {
          const newGame2 = {
            ...nextGame,
            away_team: game[0],
            home_team: game[1],
            division_name: division,
            flip_home: true,
          };

          // Add the second game to the games array
          this.$emit("updateGame", newGame2);
        }
      }

      this.closeModal();
    },
    flipHome(game) {
      // Create a clone of the game
      const updatedGame = { ...game, flip_home: !game.flip_home };

      // Emit the updated game
      this.$emit("updateGame", updatedGame);
    },
    deleteGame() {
      this.$emit("deleteGame", this.selectedRow);
      this.closeModal();
    },
    clearDatesGames(date) {
      var r = confirm(
        "Are you sure you want to clear all the games for this date?"
      );

      if (!r) {
        return;
      }

      this.$emit("clearDatesGames", date);
    },
  },
};
</script>

<style>
.row-header {
  padding: 10px;
  text-align: center;
  background: #222;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

.row-item {
  padding: 10px;
  text-align: center;
  background: #222;
  color: #fff;
  min-height: 44px;
  cursor: pointer;
  flex: 1;
}

.row-item:hover {
  background: #333;
  transition: 0.5s;
}

.selected {
  border: 2px solid white;
}

/* .day-label:hover {
    background: rgba(255,255,255,0.1);
    transition: 0.5s;
} */

.modal-content {
  padding: 20px;
}

.search-bar {
  border: 1px solid #ddd;
  margin: 5px;
  display: flex;
}

.search-icon {
  padding: 13px 10px;
  padding-bottom: 0px;
}

.round-robins {
  margin-top: 20px;
}

.division-row {
  background: #333;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}

.division-header {
  font-weight: 700;
  padding: 10px;
}

.round-row {
  background: #262626;
  border-radius: 10px;
  margin: 5px 0;
}

.round-header {
  padding: 10px;
  font-weight: 700;

  font-size: 12px;
}

.round-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px; /* adjust as needed */
}

.game-options {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 5px;
}

.games-row {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #333;
  margin: 5px;
  cursor: pointer;
  background: #161616;
  border-radius: 10px;
  padding-bottom: 10px;
}

.games-row:hover {
  background: #333;
  transition: 0.5s;
}

.game-item {
  padding: 10px;

  display: flex;
}
.game-name {
  font-size: 18px;
  font-weight: 700;
}

.game-team {
  flex: 1;
  text-align: center;
}

.game-team-vs {
  flex: 0.2;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  padding-top: 5px;
}

.game-count {
  font-size: 13px;
}

.game-division-name {
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  color: #aaa;
}

.matchup-count {
  font-size: 10px;
  text-align: center;
}

.game-not-allowed {
  opacity: 0.3;
}

.game-not-allowed:hover {
  background: #5f3d3d !important;
}

.schedule-header {
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}

.filled-row {
  background: #444;
}

.full-row {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.tall-modal {
  --height: 100%;
  --width: 100%;
}
</style>
