<template>
  <td v-if="gameStore.showSelectGames">
    <ion-checkbox slot="start" :checked="isGameSelected"></ion-checkbox>
  </td>
  <!-- <td v-if="isGameEdited">
    <ion-button color="medium" expand="full" @click="emit('resetGame')"
      >Reset</ion-button
    >
  </td> -->
</template>
<script setup>
import { defineComponent, defineProps, computed } from "vue";
import { useGameStore } from "@/stores/gameStore";
import { IonCheckbox } from "@ionic/vue";
// import { useReportGameStore } from "@/stores/reportGameStore";

defineComponent({
  name: "GameTableRows",
});

const props = defineProps({ game: Object });
// const emit = defineEmits(["resetGame"]);
const gameStore = useGameStore();
// const reportGames = useReportGameStore();

const isGameSelected = computed(() => {
  const game = gameStore.selectedGames.filter(
    (game) => game.id === props.game.id
  );
  return game.length > 0 ? true : false;
});
// const isGameEdited = computed(() => {
//   const index = reportGames.games.findIndex(
//     (game) => game.id === props.game.id
//   );
//   return index >= 0 ? true : false;
// });
</script>
