<template>
    <div>
        <div class="container">
            <div class="at-bat-header">Game Log</div>
            
            <div class="at-bat-container">
                <template v-for="(play, index) in scorekeepStore.fullGamePlayByPlay" :key="index">
                    <div v-if="isHeader(play)" class="playbyplayheader">
                        {{ play }}
                    </div>
                    <div v-else class="playbyplay">
                        {{ play }}
                    </div>
                </template>
            </div>
            <!-- {{ scorekeepStore.fullGamePlayByPlay }} -->
            <!-- <ion-button @click="showModal = true" expand="full" color="tertiary">View Scorecard</ion-button> -->
        </div>
    </div>
    <!-- <ion-modal :is-open="showModal" @did-dismiss="showModal = false">
        <ion-header translucent>
            <ion-toolbar>
                <ion-title>Scorecard</ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="showModal = false">Close</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        
            <div style="overflow: scroll; height: 100%; width: 100%;">
                <ScorecardView></ScorecardView>
                
            </div>
        
    </ion-modal> -->
</template>

<script setup>
// calculating who is at bat
// import { ref } from 'vue';
// import {  } from '@ionic/vue';
import { useScorekeepStore } from "@/stores/scorekeepStore";
// import ScorecardView from './ScorecardView.vue';
const scorekeepStore = useScorekeepStore();
// const showModal = ref(false);

function isHeader(play) {
    return play.includes('Top of') || play.includes('Bottom of');
}
</script>

<style>
     .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid #555;
    }

    .at-bat-header {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        border-top: 1px solid #555;
        border-bottom: 1px solid #555;
        width: 100%;
        text-align: center;
    }

    .at-bat-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px 20px;
        
    }

    .choose-result-area {
        /* background: rgba(150, 150, 150, 0.1); */
        margin-top: 10px;
        padding: 5px;
    }

    .player-details {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .on-deck-player-name {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 5px;
    }

    .player-pos {
        font-size: 12px;
        font-weight: 700;
        border-radius: 5px;
        color: #eee;
        
        padding: 5px;
        background: rgba(200,200,200,0.1)
    }

    .choose-result {
        font-size: 10px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
    }

    .player-res {
        font-size: 12px;
        color: #bbb;
        font-weight: 700;
        border-radius: 5px;
        font-style: italic;
        padding: 5px;
        background: rgba(200,200,200,0.1)
    }

    .at-bat-selectors {
        display: flex;
        justify-content: center;
        /* padding-top: 10px; */
    }

    .playbyplayheader {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 5px;
        background: rgba(150,150,150,0.1);
        text-align: center;
    }

    .playbyplay {
        padding: 5px;
        font-size: 15px;
        border-bottom: 1px solid #555;
    }

</style>