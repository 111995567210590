<template>
  <tr>
    <td>
      <ion-datetime-button
        v-if="props.choice === 'Reschedule'"
        :datetime="'date' + props.index"
      ></ion-datetime-button>
      <span v-else style="padding-right: 10px">{{
        gameStore.selectedGames[props.index].start_date
      }}</span>

      <ion-modal :keep-contents-mounted="true">
        <ion-datetime
          @ion-change="dateTimeChanged($event, 'date')"
          presentation="date"
          :show-default-buttons="true"
          :id="'date' + props.index"
          :value="
            formatISO(
              parse(
                gameStore.selectedGames[props.index].start_date,
                'yyyy-MM-dd',
                new Date()
              )
            )
          "
        ></ion-datetime>
      </ion-modal>
    </td>
    <td>
      <ion-datetime-button
        v-if="props.choice === 'Reschedule'"
        :datetime="'start-time' + props.index"
      ></ion-datetime-button>
      <span v-else style="padding-right: 10px">{{
        format(
          parse(
            gameStore.selectedGames[props.index].start_time,
            "HH:mm:ss",
            new Date()
          ),
          "h:mm a"
        )
      }}</span>

      <ion-modal :keep-contents-mounted="true">
        <ion-datetime
          @ion-change="dateTimeChanged($event, 'start_time')"
          presentation="time"
          :minuteValues="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]"
          :show-default-buttons="true"
          :id="'start-time' + props.index"
          :value="
            formatISO(
              parse(
                gameStore.selectedGames[props.index].start_time,
                'HH:mm:ss',
                new Date()
              )
            )
          "
        ></ion-datetime>
      </ion-modal>
    </td>
    <td>
      <ion-datetime-button
        v-if="props.choice === 'Reschedule'"
        :datetime="'end-time' + props.index"
      ></ion-datetime-button>
      <span v-else style="padding-right: 10px">{{
        format(
          parse(
            gameStore.selectedGames[props.index].end_time,
            "HH:mm:ss",
            new Date()
          ),
          "h:mm a"
        )
      }}</span>

      <ion-modal :keep-contents-mounted="true">
        <ion-datetime
          @ion-change="dateTimeChanged($event, 'end_time')"
          presentation="time"
          :minuteValues="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]"
          :show-default-buttons="true"
          :id="'end-time' + props.index"
          :value="
            formatISO(
              parse(
                gameStore.selectedGames[props.index].end_time,
                'HH:mm:ss',
                new Date()
              )
            )
          "
        ></ion-datetime>
      </ion-modal>
    </td>
    <td>
      <ion-input
        v-if="props.choice === 'Reschedule'"
        type="text"
        placeholder="Park Name"
        style="max-width: 10rem; padding: 0.5rem 0.25rem"
        v-model="gameStore.selectedGames[props.index].park_name"
      />
      <span v-else>{{ gameStore.selectedGames[props.index].park_name }}</span>
    </td>
    <td>
      <ion-input
        style="padding: 0.5rem 0.25rem; max-width: 10rem"
        v-if="props.choice === 'Reschedule'"
        type="text"
        placeholder="Diamond"
        v-model="gameStore.selectedGames[props.index].diamond_signifier"
      />
      <span v-else>{{
        gameStore.selectedGames[props.index].diamond_signifier
      }}</span>
    </td>
    <td v-if="props.choice === 'EditTeams'">
      <v-select
        placeholder="Select Division"
        :options="
          leagueStore.divisions.map((division) => division.division_name)
        "
        :clearable="false"
        append-to-body
        :calculate-position="withPopper"
        v-model="gameStore.selectedGames[props.index].division_id"
        @option:selected="resetTeams"
      >
        <template #open-indicator="{ attributes }">
          <ion-icon
            :icon="caretDownOutline"
            style="font-size: 0.8rem"
            v-bind="attributes"
          ></ion-icon>
        </template>
      </v-select>
    </td>
    <td v-else>
      {{ gameStore.selectedGames[props.index].division_id }}
    </td>
    <td v-if="props.choice === 'EditTeams'">
      <v-select
        placeholder="Away Team"
        :options="teamOptions"
        :clearable="false"
        append-to-body
        :calculate-position="withPopper"
        :disabled="!gameStore.selectedGames[props.index].division_id"
        v-model="gameStore.selectedGames[props.index].select_away_team"
        :reduce="(game) => game.value"
        @option:selected="setMode"
      >
        <template #open-indicator="{ attributes }">
          <ion-icon
            :icon="caretDownOutline"
            style="font-size: 0.8rem"
            v-bind="attributes"
          ></ion-icon>
        </template>
      </v-select>
    </td>
    <td v-else>
      {{
        gameStore.selectedGames[props.index].awayTeam?.name ||
        gameStore.selectedGames[props.index].away_team_freetext
      }}
    </td>
    <td v-if="props.choice === 'EditTeams'">
      <v-select
        placeholder="Home Team"
        :options="teamOptions"
        :clearable="false"
        append-to-body
        :calculate-position="withPopper"
        :disabled="!gameStore.selectedGames[props.index].division_id"
        v-model="gameStore.selectedGames[props.index].select_home_team"
        :reduce="(game) => game.value"
        @option:selected="setMode"
      >
        <template #open-indicator="{ attributes }">
          <ion-icon
            :icon="caretDownOutline"
            style="font-size: 0.8rem"
            v-bind="attributes"
          ></ion-icon>
        </template>
      </v-select>
    </td>
    <td v-else>
      {{
        gameStore.selectedGames[props.index].homeTeam?.name ||
        gameStore.selectedGames[props.index].home_team_freetext
      }}
    </td>
    <td v-if="props.choice === 'EditTeams'">
      <v-select
        placeholder="Mode"
        :options="['Playoffs', 'Practice', 'Season']"
        :clearable="false"
        append-to-body
        :calculate-position="withPopper"
        v-model="gameStore.selectedGames[props.index].mode"
      >
        <template #open-indicator="{ attributes }">
          <ion-icon
            :icon="caretDownOutline"
            style="font-size: 0.8rem"
            v-bind="attributes"
          ></ion-icon>
        </template>
      </v-select>
    </td>
    <td v-if="props.choice === 'EditTeams'">
      <ion-checkbox
        @ion-change="ifNecessaryChanged"
        :checked="!!gameStore.selectedGames[props.index].if_necessary"
      ></ion-checkbox>
    </td>
    <td v-if="props.choice !== 'Delete' && props.choice !== 'EditTeams'">
      <ion-select
        v-model="gameStore.selectedGames[props.index].reschedule_reason"
        placeholder="Reason"
        style="max-width: 13rem"
        value="other"
      >
        <ion-select-option value="rainout">Rainout</ion-select-option>
        <ion-select-option value="double-booking"
          >Double Booking</ion-select-option
        >
        <ion-select-option value="uic-cancels">UIC Cancels</ion-select-option>
        <ion-select-option value="umpire-cancels-on-site">
          Umpire Cancels On Site ($)
        </ion-select-option>
        <ion-select-option value="away-team-cancels"
          >Away Team Cancels</ion-select-option
        >
        <ion-select-option value="home-team-cancels"
          >Home Team Cancels</ion-select-option
        >
        <ion-select-option value="other">Other</ion-select-option>
      </ion-select>
    </td>
    <td>
      <ion-button
        color="danger"
        fill="clear"
        @click="gameStore.removeSelectedGameIndex(props.index)"
        style="text-align: center; font-size: 12px"
        ><ion-icon slot="icon-only" :icon="closeCircleOutline"
      /></ion-button>
    </td>
  </tr>
</template>
<script setup>
import { defineComponent, defineProps, computed } from "vue";
import { formatISO, parseISO, parse, format, set } from "date-fns";
import {
  IonInput,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonButton,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonCheckbox,
} from "@ionic/vue";
import useVuelidate from "@vuelidate/core";
import { required, sameAs, not } from "@vuelidate/validators";
import { closeCircleOutline, caretDownOutline } from "ionicons/icons";
import { useGameStore } from "@/stores/gameStore";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useLeagueStore } from "@/stores/leagueStore";
var ordinal = require("ordinal");
import { createPopper } from "@popperjs/core";

defineComponent({
  name: "EditNewGameForm",
});
const props = defineProps({ index: Number, choice: String });
const leagueStore = useLeagueStore();
const gameStore = useGameStore();
gameStore.selectedGames[props.index].reschedule_reason = "other"; // default reason
gameStore.selectedGames[props.index].select_away_team =
  gameStore.selectedGames[props.index].awayTeam?.id ||
  gameStore.selectedGames[props.index].away_team_freetext;
gameStore.selectedGames[props.index].select_home_team =
  gameStore.selectedGames[props.index].homeTeam?.id ||
  gameStore.selectedGames[props.index].home_team_freetext;

function dateTimeChanged(payload, type) {
  // to format date and time for date-time input in selectedgames
  if (type === "date") {
    const formattedString = format(
      parseISO(payload.detail.value),
      "yyyy-MM-dd"
    );
    gameStore.selectedGames[props.index].start_date = formattedString;
    gameStore.selectedGames[props.index].end_date = formattedString;
  }
  if (type === "start_time") {
    const formattedString = format(parseISO(payload.detail.value), "HH:mm:ss");
    gameStore.selectedGames[props.index].start_time = formattedString;
  }
  if (type === "end_time") {
    const formattedString = format(parseISO(payload.detail.value), "HH:mm:ss");
    gameStore.selectedGames[props.index].end_time = formattedString;
  }
}
function resetTeams() {
  gameStore.selectedGames[props.index].select_away_team = null;
  gameStore.selectedGames[props.index].select_home_team = null;
}
const rules = {
  start_date: { required },
  start_time: { required },
  end_time: { required },
  park_name: { required },
  diamond_signifier: { required },
  division_id: { required },
  select_away_team: {
    required,
    sameAsHome: not(
      sameAs(
        computed(() => gameStore.selectedGames[props.index].select_home_team)
      )
    ),
  },
  select_home_team: {
    required,
    sameAsAway: not(
      sameAs(
        computed(() => gameStore.selectedGames[props.index].select_away_team)
      )
    ),
  },
  mode: { required },
};
if (props.choice !== "Delete") {
  rules["reschedule_reason"] = { required };
}
useVuelidate(rules, gameStore.selectedGames[props.index]);

const teams = computed(() => {
  if (gameStore.selectedGames[props.index].division_id) {
    return leagueStore.divisions.filter(
      (division) =>
        division.division_name ===
        gameStore.selectedGames[props.index].division_id
    )[0].teams;
  } else if (leagueStore.league?.teams) {
    return leagueStore.league.teams;
  } else {
    return [];
  }
});

const teamOptions = computed(() => {
  const options = [];
  for (const team of teams.value) {
    options.push({ label: team.name, value: team.id });
  }
  if (gameStore.selectedGames[props.index].division_id) {
    for (const game of gameStore.games) {
      if (
        game.division_id === gameStore.selectedGames[props.index].division_id
      ) {
        options.push({
          label: `
    Winner of
  
        ${format(
          set(new Date(), {
            hours: game.start_time.split(":")[0],
            minutes: game.start_time.split(":")[1],
          }),
          "h:mm"
        )}
  
      on
      ${format(new Date(game.start_date.replace(/-/g, "/")), "EEEE, MMM do")}
      - ${game.diamond_signifier} (Gm #${game.event_specific_game_id})
    `,
          value: `winner-of-${game.id}`,
        });
        options.push({
          label: `
    Loser of
  
        ${format(
          set(new Date(), {
            hours: game.start_time.split(":")[0],
            minutes: game.start_time.split(":")[1],
          }),
          "h:mm"
        )}
  
      on
      ${format(new Date(game.start_date.replace(/-/g, "/")), "EEEE, MMM do")}
      - ${game.diamond_signifier} (Gm #${game.event_specific_game_id})
    `,
          value: `loser-of-${game.id}`,
        });
      }
    }
    const division = leagueStore.divisions.find(
      (division) =>
        division.division_name ===
        gameStore.selectedGames[props.index].division_id
    );

    for (let team = 1; team <= division.teams.length; team++) {
      options.push({
        label: `${ordinal(team)} Place -
        ${division.division_name}`,
        value: "placed-" + team + "-" + division.division_name,
      });
    }
  }

  return options;
});
function withPopper(dropdownList, component) {
  dropdownList.style.width = "30rem";
  const popper = createPopper(component.$refs.toggle, dropdownList, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -1],
        },
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          component.$el.classList.toggle("drop-up", state.placement === "top");
        },
      },
    ],
  });

  return () => popper.destroy();
}
function setMode(ev) {
  if (!Number(ev.value)) {
    gameStore.selectedGames[props.index].mode = "Playoffs";
  } else {
    if (
      Number(gameStore.selectedGames[props.index].select_away_team) &&
      Number(gameStore.selectedGames[props.index].select_home_team)
    )
      gameStore.selectedGames[props.index].mode = "Season";
  }
}
function ifNecessaryChanged(ev) {
  if (ev.detail.checked) {
    gameStore.selectedGames[props.index].if_necessary = 1;
  } else {
    gameStore.selectedGames[props.index].if_necessary = 0;
  }
}
</script>
<style scoped>
td {
  text-align: center;
  margin: 0 auto;
  /* border-right: 1px solid var(--ion-color-light); */
}
tr:nth-child(odd) {
  background-color: var(--ion-color-light);
}
</style>
<style scoped>
.v-select {
  --vs-controls-color: var(--ion-color-step-300);
  --vs-border-width: 0;

  --vs-dropdown-bg: var(--ion-background-color);
  --vs-dropdown-option-color: var(--ion-text-color);

  --vs-selected-bg: var(--ion-color-primary);
  --vs-selected-color: var(--ion-text-color);

  --vs-search-input-color: var(--ion-color-step-300);

  --vs-dropdown-option--active-bg: #664cc3;
  --vs-dropdown-option--active-color: #eeeeee;
  --vs-dropdown-min-width: 1rem;
  --vs-dropdown-option--deselect-color: red;

  --vs-disabled-bg: var(--ion-color-light);
  --vs-disabled-color: var(--ion-color-light);
  --vs-disabled-controls-color: var(--ion-color-light);
}
/* .vs__selected-options {
  flex-direction: row-reverse;
}
.vs--open .vs__selected-options {
  flex-direction: row;
}
.vs--open .vs__selected {
  flex-grow: 0;
} */
</style>
