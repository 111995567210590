<template>
    <div class="site-banner" v-if="notice">
        <p>{{ notice }}</p>
    </div>
</template>

<script setup>
import { ref, onMounted, defineComponent } from 'vue';
import { api } from "@/utils/axios";

let notice = ref('');

onMounted(async () => {
    try {
        const response = await api.get('/api/sitewide-notice');
        if (response.data) {
            notice.value = response.data.text;
        }
    } catch (error) {
        console.error(error);
    }
});

defineComponent({
    name: "SiteNotice",
    setup() {
        return { notice };
    },
})
</script>

<style scoped>
.site-banner {
    background-color: #f02d2d;
    
    text-align: center;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
    color: #fff;
    
}
.site-banner p {
    margin: 0;
    padding: 0.5rem 0;
}
</style>