<template>
  <page-wrapper>
    <div
      style="
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 15%;
        background-position: center;
        background-size: cover;
      "
      :style="{
        'background-image': `url('${leagueStore.leagueBackground}')`,
      }"
    ></div>
    <ion-toolbar style="margin-top: 2.7rem" color="primary">
      <ion-row>
        <ion-col size="2">
          <ion-button
            color="tertiary"
            expand="full"
            @click="handlePreviousStep"
            v-if="step !== 1"
          >
            <ion-icon :icon="caretBackOutline"></ion-icon>
            Previous Step
          </ion-button>
        </ion-col>
        <ion-col size="8">
          <ion-breadcrumbs>
            <ion-breadcrumb
              v-for="breadcrumb in breadcrumbs"
              :key="breadcrumb.step"
            >
              <div :class="{ content: step === breadcrumb.step }">
                <div
                  class="small"
                  :class="{ active: step === breadcrumb.step }"
                >
                  Step {{ breadcrumb.step }}
                </div>
                <div
                  class="normal"
                  :class="{ active: step === breadcrumb.step }"
                >
                  {{ breadcrumb.label }}
                </div>
              </div>
            </ion-breadcrumb>
          </ion-breadcrumbs>
        </ion-col>
        <ion-col size="2">
          <ion-button
            color="tertiary"
            expand="full"
            @click="handleNextStep"
            v-if="step !== breadcrumbs.length"
          >
            Next Step
            <ion-icon :icon="caretForwardOutline"></ion-icon>
          </ion-button>
          <ion-button
            color="success"
            expand="full"
            @click="finalizeSchedule"
            v-if="step === breadcrumbs.length && games.length > 0"
          >
            <ion-icon :icon="saveOutline"></ion-icon> Finalize Schedule
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-toolbar>
    <div style="display: flex; flex-direction: column; justify-content: center">
      <step-one
        :schedule-data="scheduleData"
        @update:scheduleData="updateScheduleData"
        @nextStep="handleNextStep"
        :countries="countries"
        @clearSavedData="handleClearLocalstorage"
        v-if="step === 1"
      >
      </step-one>
      <step-two
        v-if="step === 2"
        :parks-data="parksData"
        @update:parksData="updateParksData"
        @nextStep="handleNextStep"
      >
      </step-two>
      <step-three
        v-if="step === 3"
        @nextStep="handleNextStep"
        :parks-data="parksData"
        :schedule-data="scheduleData"
        @update:parksData="updateParksData"
        :days-of-week="daysOfWeek"
        @saveBooking="handleSaveBooking"
        @clearBookings="handleClearBookings"
        :bookings-data="bookingsData"
      >
      </step-three>
      <step-four
        v-if="step === 4"
        :teams-data="teamsData"
        :parks-data="parksData"
        @nextStep="handleNextStep"
        @update:teamsData="updateTeamsData"
      >
        >
      </step-four>
      <step-five
        v-if="step === 5"
        :schedule-data="scheduleData"
        :teams-data="teamsData"
        :parks-data="parksData"
        :days-of-week="daysOfWeek"
        :countries="countries"
        :bookings-data="bookingsData"
        :games="games"
        @updateGame="handleUpdateGame"
        @deleteGame="handleDeleteGame"
        @clearDatesGames="handleClearDatesGame"
      >
      </step-five>
    </div>
  </page-wrapper>
</template>

<script setup>
import { ref, reactive, watch } from "vue";
import PageWrapper from "@/components/layouts/PageWrapper.vue";
import StepOne from "@/components/generator/StepOne.vue";
import StepTwo from "@/components/generator/StepTwo.vue";
import StepThree from "@/components/generator/StepThree.vue";
import StepFour from "@/components/generator/StepFour.vue";
import StepFive from "@/components/generator/StepFive.vue";
import AddGameModal from "@/components/schedule/new-game/AddGameModal.vue";

import {
  showToast,
  showAlert,
  showLoading,
  openModal,
} from "@/utils/useIonicComponents.js";
import { useLeagueStore } from "@/stores/leagueStore";
import { useNewGameStore } from "@/stores/newGameStore";

import uniqolor from "uniqolor";
import { api } from "@/utils/axios";

import {
  IonBreadcrumb,
  IonBreadcrumbs,
  IonToolbar,
  IonRow,
  IonCol,
  //   IonGrid,
  IonButton,
  IonIcon,
} from "@ionic/vue";

import {
  caretForwardOutline,
  caretBackOutline,
  saveOutline,
} from "ionicons/icons";
const leagueStore = useLeagueStore();

const step = ref(5);

const breadcrumbs = reactive([
  { step: 1, label: "Initial Details" },
  { step: 2, label: "Parks & Diamonds" },
  { step: 3, label: "Reservations" },
  { step: 4, label: "Divisions & Teams" },
  { step: 5, label: "Generate Schedule" },
]);

const countries = ["Canada", "United States"];

const daysOfWeek = reactive([
  {
    label: "Monday",
    value: "monday",
  },
  {
    label: "Tuesday",
    value: "tuesday",
  },
  {
    label: "Wednesday",
    value: "wednesday",
  },
  {
    label: "Thursday",
    value: "thursday",
  },
  {
    label: "Friday",
    value: "friday",
  },
  {
    label: "Saturday",
    value: "saturday",
  },
  {
    label: "Sunday",
    value: "sunday",
  },
]);

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
let currentMonth = currentDate.getMonth() + 1; // getMonth() returns month index starting from 0
currentMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth; // add leading zero if month is less than 10
let currentDay = currentDate.getDate();
currentDay = currentDay < 10 ? `0${currentDay}` : currentDay; // add leading zero if day is less than 10
let currentHour = currentDate.getHours();
currentHour = currentHour < 10 ? `0${currentHour}` : currentHour; // add leading zero if hour is less than 10
let currentMinute = currentDate.getMinutes();
currentMinute = currentMinute < 10 ? `0${currentMinute}` : currentMinute; // add leading zero if minute is less than 10

const startMonthDate = new Date();
const endMonthDate = new Date(
  startMonthDate.setMonth(startMonthDate.getMonth() + 3)
);

let endYear = endMonthDate.getFullYear();
let endMonth = endMonthDate.getMonth() + 1; // getMonth() returns month index starting from 0
endMonth = endMonth < 10 ? `0${endMonth}` : endMonth; // add leading zero if month is less than 10
let endDay = endMonthDate.getDate();
endDay = endDay < 10 ? `0${endDay}` : endDay; // add leading zero if day is less than 10
let endHour = endMonthDate.getHours();
endHour = endHour < 10 ? `0${endHour}` : endHour; // add leading zero if hour is less than 10
let endMinute = endMonthDate.getMinutes();
endMinute = endMinute < 10 ? `0${endMinute}` : endMinute; // add leading zero if minute is less than 10

const scheduleData = reactive({
  startMonth: `${currentYear}-${currentMonth}-${currentDay}T${currentHour}:${currentMinute}`,
  endMonth: `${endYear}-${endMonth}-${endDay}T${endHour}:${endMinute}`,
  gameLength: "75",
  breakBetweenGames: "0",
  country: "Canada",
  defaultWeekdayStartTime: "18:00",
  defaultWeekdayEndTime: "23:00",
  defaultWeekendStartTime: "10:00",
  defaultWeekendEndTime: "16:00",
});

const bookingsData = reactive({});

const teamsData = reactive({
  divisions: [
    {
      name: "Division 1",
      color: uniqolor.random(),
      specialRequests: {
        cannot_play_at_parks: [],
        preferred_start_time: "none", // earliest start time, latest start time, none
        plays_on_these_days: ["any"],
        preference_frequency: "never",
        notes: null, // options include always, sometimes, never
      },
      teams: [
        {
          name: "Team 1",
          color: uniqolor.random(),
          specialRequests: {
            preferred_start_time: "none", // earliest start time, latest start time, none
            preference_frequency: "never",
            plays_on_these_days: ["any"],
            coach_name: null,
            coach_email: null,
            coach_phone: null,
            notes: null, // options include always, sometimes, never
          },
        },
        {
          name: "Team 2",
          color: uniqolor.random(),
          specialRequests: {
            preferred_start_time: "none", // earliest start time, latest start time, none
            preference_frequency: "never",
            plays_on_these_days: ["any"],
            coach_name: null,
            coach_email: null,
            coach_phone: null,
            notes: null, // options include always, sometimes, never
          },
        },
        {
          name: "Team 3",
          color: uniqolor.random(),
          specialRequests: {
            preferred_start_time: "none", // earliest start time, latest start time, none
            preference_frequency: "never",
            plays_on_these_days: ["any"],
            coach_name: null,
            coach_email: null,
            coach_phone: null,
            notes: null, // options include always, sometimes, never
          },
        },
        {
          name: "Team 4",
          color: uniqolor.random(),
          specialRequests: {
            preferred_start_time: "none", // earliest start time, latest start time, none
            preference_frequency: "never",
            plays_on_these_days: ["any"],
            coach_name: null,
            coach_email: null,
            coach_phone: null,
            notes: null, // options include always, sometimes, never
          },
        },
      ],
    },
  ],
});

const parksData = reactive({
  parks: [
    {
      name: "Henning Park",
      diamonds: [
        {
          name: "Diamond 1",
          bookings: [],
        },
        {
          name: "Diamond 2",
          bookings: [],
        },
        {
          name: "Diamond 3",
          bookings: [],
        },
        {
          name: "Diamond 4",
          bookings: [],
        },
        {
          name: "Diamond 5",
          bookings: [],
        },
        {
          name: "Diamond 6",
          bookings: [],
        },
      ],
    },
  ],
});

const handlePreviousStep = () => {
  if (step.value > 1) {
    step.value -= 1;
  }
};

const games = reactive([]);

const handleNextStep = () => {
  step.value += 1;
};

const updateScheduleData = (newData) => {
  for (let key in newData) {
    scheduleData[key] = newData[key];
  }
};

const updateParksData = (newData) => {
  for (let key in newData) {
    parksData[key] = newData[key];
  }
};

const handleSaveBooking = (bookingDetails) => {
  bookingDetails.dates.forEach((date) => {
    if (!bookingsData[date]) {
      bookingsData[date] = [];
    }

    // Remove the bookings for the date
    bookingsData[date] = [];

    // Add the updated bookings
    bookingDetails.parks.forEach((parkDiamond) => {
      bookingsData[date].push({
        park_name: parkDiamond.park_name,
        diamond_name: parkDiamond.diamond_name,
        park_diamond_name: parkDiamond.park_diamond_name,
        startTime: bookingDetails.startTime,
        endTime: bookingDetails.endTime,
        gameLength: bookingDetails.gameLength,
        breakBetweenGames: bookingDetails.breakBetweenGames,
      });
    });
  });

  showToast("Booking Saved!", "success", 6000);
};

const handleClearBookings = (dates) => {
  dates.forEach((date) => {
    if (bookingsData[date]) {
      // Remove the key from the bookingsData object
      delete bookingsData[date];
    }
  });

  showToast("Bookings Cleared!", "success", 6000);
};

const updateTeamsData = (newData) => {
  for (let key in newData) {
    teamsData[key] = newData[key];
  }
};

const handleUpdateGame = (newGame) => {
  // Check if a game with the same id already exists in the games array
  const existingGameIndex = games.findIndex((g) => g.id === newGame.id);

  // If it does, remove it
  if (existingGameIndex !== -1) {
    games.splice(existingGameIndex, 1);
  }

  // Add the new game to the games array
  games.push(newGame);
};

const handleClearDatesGame = (date) => {
  const gamesOnDate = games.filter((game) => game.start_date === date);

  gamesOnDate.forEach((gameOnDate) => {
    const index = games.indexOf(gameOnDate);
    if (index !== -1) {
      games.splice(index, 1);
    }
  });
};

const handleDeleteGame = (gameId) => {
  // Check if a game with the same id already exists in the games array
  const index = games.findIndex((game) => game.id === gameId.id);
  if (index !== -1) {
    games.splice(index, 1);
  }
};

watch(
  teamsData,
  (newVal) => {
    localStorage.setItem("teamsData", JSON.stringify(newVal));
  },
  { deep: true }
);

watch(
  bookingsData,
  (newVal) => {
    localStorage.setItem("bookingsData", JSON.stringify(newVal));
  },
  { deep: true }
);

watch(
  scheduleData,
  (newVal) => {
    localStorage.setItem("scheduleData", JSON.stringify(newVal));
  },
  { deep: true }
);

watch(
  parksData,
  (newVal) => {
    localStorage.setItem("parksData", JSON.stringify(newVal));
  },
  { deep: true }
);

watch(
  games,
  (newVal) => {
    localStorage.setItem("games", JSON.stringify(newVal));
  },
  { deep: true }
);

if (localStorage.getItem("teamsData")) {
  Object.assign(teamsData, JSON.parse(localStorage.getItem("teamsData")));
}

if (localStorage.getItem("bookingsData")) {
  Object.assign(bookingsData, JSON.parse(localStorage.getItem("bookingsData")));
}

if (localStorage.getItem("scheduleData")) {
  Object.assign(scheduleData, JSON.parse(localStorage.getItem("scheduleData")));
}

if (localStorage.getItem("parksData")) {
  Object.assign(parksData, JSON.parse(localStorage.getItem("parksData")));
}

if (localStorage.getItem("games")) {
  Object.assign(games, JSON.parse(localStorage.getItem("games")));
}

const handleClearLocalstorage = () => {
  localStorage.removeItem("teamsData");
  localStorage.removeItem("bookingsData");
  localStorage.removeItem("scheduleData");
  localStorage.removeItem("parksData");
  localStorage.removeItem("games");
};

async function finalizeSchedule() {
  await showAlert({
    header: "Finalize Schedule ?",
    message:
      "The teams in step 4 will be added to you account before creating the games",
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
      },
      {
        text: "OK",
        role: "confirm",
        handler: async () => {
          await submitSchedule();
        },
      },
    ],
  });
}

async function saveTeams(teams) {
  const loading = await showLoading("Saving Teams. Please Wait...");
  const result = await api.post("/api/import-teams", {
    event: leagueStore.league.id,
    teams,
  });
  loading.dismiss();

  if (result.data.type === "success") {
    await leagueStore.reloadEverything(leagueStore.league.slug);
    showToast("Teams successfully Added.", "success", 6000);
  } else {
    showToast(
      "Something went wrong. If this persists, please contact us.",
      "danger",
      6000
    );
  }
}

const submitSchedule = async () => {
  // Submit the Schedule to the Add Games functionality the same way it is done with the import script
  console.log("submitting schedule");

  const extractTeams = teamsData.divisions
    .map((division) => {
      return division.teams.map((team) => {
        team.team_color = team.color.color;
        team.division = division.name;
        team.coach_full_name = team.specialRequests.coach_name;
        team.coach_email = team.specialRequests.coach_email;
        team.coach_phone = team.specialRequests.coach_phone;
        return team;
      });
    })
    .flat();

  await saveTeams(extractTeams);

  const extractGames = games.map((game) => {
    const g = {};
    g.select_away_team = leagueStore.league.teams.filter(
      (t) =>
        t.name === game.away_team.name &&
        t.division_name === game.division_name.division_name
    )[0].id;
    g.select_home_team = leagueStore.league.teams.filter(
      (t) =>
        t.name === game.home_team.name &&
        t.division_name === game.division_name.division_name
    )[0].id;

    g.division_id = game.division_name.division_name;
    g.start_date = game.start_date;
    g.start_time = game.start_time;
    g.end_time = game.end_time;
    g.diamond_signifier = game.diamond_name;
    g.park_name = game.park_name;
    return g;
  });

  const newGameStore = useNewGameStore();
  newGameStore.importGamesFromGenerator(extractGames);
  await openModal(AddGameModal, "addGameModal");
};
</script>

<style scoped>
ion-breadcrumb {
  flex-direction: row;
}

.content {
  border-bottom: 2px solid #ddd;
}

.small {
  font-size: 10px;
  color: #777;
}

.normal {
  font-weight: 400;
  color: #777;
}

.active {
  color: #fff !important;
}
</style>
