<template>
  <ion-card style="min-width: 20rem; margin: 0.5rem 0.5rem">
    <ion-card-header>
      <ion-card-subtitle>No Games matching this filter</ion-card-subtitle>
      <ion-button
        v-if="gameStore.isFiltersApplied"
        size="small"
        @click="resetFilter"
        >Reset Filters</ion-button
      >
    </ion-card-header>
  </ion-card>
</template>
<script setup>
import { defineComponent } from "vue";
import { IonCard, IonCardHeader, IonCardSubtitle, IonButton } from "@ionic/vue";
import { useGameStore } from "@/stores/gameStore";
import { useRouter, useRoute } from "vue-router";
import { usePreferenceStore } from "@/stores/preferenceStore";

defineComponent({
  name: "NoGameCard",
});
const gameStore = useGameStore();
const router = useRouter();
const route = useRoute();
const preferenceStore = usePreferenceStore();

function resetFilter() {
  gameStore.resetFilters();
  preferenceStore.setFilters({
    ...gameStore.gameFilters,
    showReportedGames: gameStore.showReportedGames,
  });
  router.replace({
    query: {
      ...route.query,
      showReportedGames: null,
      division_id: null,
      park_name: null,
      team: null,
      timeslot: null,
      month: null,
      day: null,
    },
  });
}
</script>
