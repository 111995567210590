<template>
  <base-modal
    :modal-title="
      props.choice === 'EditTeams' ? 'Edit Teams' : `${props.choice} Games`
    "
    @close="close"
  >
    <edit-selected-game :choice="props.choice" v-if="showDesktopMode" />
    <edit-selected-game-mobile :choice="props.choice" v-else />
    <template #footer>
      <ion-button
        expand="full"
        @click="confirm"
        v-if="props.choice === 'Delete'"
        color="danger"
        >Delete</ion-button
      >
      <ion-button expand="full" @click="confirm" v-else>Save</ion-button>
    </template>
  </base-modal>
</template>

<script setup>
import { IonButton, modalController } from "@ionic/vue";
import {
  defineComponent,
  defineProps,
  watchEffect,
  onBeforeUnmount,
  ref,
} from "vue";
import { useGameStore } from "@/stores/gameStore";
import BaseModal from "@/components/layouts/BaseModal.vue";
import EditSelectedGame from "@/components/schedule/select-game/desktop-view/EditSelectedGame.vue";
import EditSelectedGameMobile from "@/components/schedule/select-game/mobile-view/EditSelectedGameMobile.vue";
import useVuelidate from "@vuelidate/core";
import { showToast } from "@/utils/useIonicComponents";
import { debounce } from "lodash";
import { useRouter, useRoute } from "vue-router";

defineComponent({
  name: "SelectedGameModal",
});
const props = defineProps({ choice: String });
const gameStore = useGameStore();

const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);

const router = useRouter();
const route = useRoute();
router.replace({
  query: {
    ...route.query,
    open_modal: "SelectedGames",
  },
});

watchEffect(async () => {
  if (gameStore.selectedGames.length < 1) {
    await modalController.dismiss();
  }
});
async function close() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss();
}
const v$ = useVuelidate();
async function confirm() {
  if (props.choice === "Delete") {
    await gameStore.deleteGames();
    return;
  }
  const isFormValid = await v$.value.$validate();
  if (isFormValid) {
    if (props.choice === "Postpone") {
      await gameStore.postponeGames();
    }
    if (props.choice === "Reschedule") {
      await gameStore.rescheduleGames();
    }

    if (props.choice === "EditTeams") {
      await gameStore.editTeams();
    }
    await modalController.dismiss();
  } else {
    console.log(v$.value.$errors);
    showToast("Valid data in all the fields required", "danger", 6000);
  }
}

window.addEventListener("resize", debounce(resizeHandler, 1000));

function resizeHandler() {
  if (window.innerWidth >= 960) {
    showDesktopMode.value = true;
  } else {
    showDesktopMode.value = false;
  }
}
onBeforeUnmount(() => {
  window.removeEventListener("resize", resizeHandler);
});
</script>
