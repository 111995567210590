<template>
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <ion-title>Manage Links</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="emitClose">
              <ion-icon :icon="closeOutline" />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-segment mode="md" v-model="segment">
          <ion-segment-button value="add">
            <ion-label>Add</ion-label>
          </ion-segment-button>
          <ion-segment-button value="edit">
            <ion-label>Edit</ion-label>
          </ion-segment-button>
        </ion-segment>
        <div class="ion-padding">
          <div v-if="segment === 'add'">
            <ion-item>
                <ion-label position="stacked">Title</ion-label>
                <ion-input v-model="linkTitle" placeholder="Enter title"></ion-input>
            </ion-item>
            <ion-item>
              <ion-label position="stacked">Link Type</ion-label>
              <ion-select v-model="linkType" placeholder="Select Link Type">
                <ion-select-option value="external">External Link</ion-select-option>
                <ion-select-option value="page">Create a Page</ion-select-option>
              </ion-select>
            </ion-item>
            
            <ion-item v-if="linkType === 'external'">
              <ion-label position="stacked">URL</ion-label>
              <ion-input v-model="linkUrl" type="url" placeholder="Enter URL"></ion-input>
            </ion-item>
            <div v-if="linkType === 'page'" lines="none">
              <QuillEditor
                v-model="pageContent"
                :options="editorOptions"
                theme="snow"
                placeholder="Enter page content here..."
              />
            </div>
            <ion-button expand="block" @click="submitLink">Save</ion-button>
            <br>
            <br>
            <br>
            <br>
            <ion-button expand="block" color="light" @click="clearForm">Clear</ion-button>
          </div>
          <div v-if="segment === 'edit'">
            <ion-list>
              <ion-item v-for="linkItem in linkItems" :key="linkItem.id" class="ion-align-items-center">
                <ion-label>
                  <h2>{{ linkItem.link_title }}</h2>
                  <p>{{ linkItem.url || 'Page Content' }}</p>
                </ion-label>
                <ion-button color="danger" @click="deleteLink(linkItem.id)">Delete</ion-button>
              </ion-item>
            </ion-list>
          </div>
        </div>
      </ion-content>
    </ion-page>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonSegment, IonSegmentButton, IonLabel, IonList, IonItem, IonInput, IonSelect, IonSelectOption } from '@ionic/vue';
  import { closeOutline } from 'ionicons/icons';
  import { defineEmits } from 'vue';
  import { api } from "@/utils/axios";
  import { useLeagueStore } from "@/stores/leagueStore";
  import { QuillEditor } from '@vueup/vue-quill';
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  
  const leagueStore = useLeagueStore();

  const segment = ref('add');
  const linkItems = ref([]);
  
  const linkType = ref(null);
  const linkUrl = ref('');
  const pageContent = ref('');
  const isEditMode = ref(false);
  const selectedLinkItem = ref(null);

  const linkTitle = ref('');
  
  const editorOptions = {
    // ... your editor options ...
  };
  
  const emit = defineEmits(['close']);
  
  const emitClose = () => {
    emit('close');
  };
  
  const submitLink = async () => {
    try {
        const payload = {
        title: linkTitle.value,
        type: linkType.value,
        url: linkType.value === 'external' ? linkUrl.value : '',
        content: linkType.value === 'page' ? pageContent.value : '',
        };

        const response = await api.post(`/players/${leagueStore.league.slug}/save-links`, payload);
        console.log(response);
        clearForm();
        emitClose();
    } catch (error) {
        console.error('Error submitting link:', error);
    }
    };

    const clearForm = () => {
    linkTitle.value = '';
    linkType.value = null;
    linkUrl.value = '';
    pageContent.value = '';
    isEditMode.value = false;
    selectedLinkItem.value = null;
    };

    // const editLink = (linkItem) => {
    // selectedLinkItem.value = linkItem;
    // linkTitle.value = linkItem.title;
    // linkType.value = linkItem.type;
    // linkUrl.value = linkItem.url;
    // pageContent.value = linkItem.content;
    // segment.value = 'add';
    // isEditMode.value = true;
    // };

const deleteLink = async (linkId) => {
  try {
    await api.post(`/players/${leagueStore.league.slug}/delete-link`, { id: linkId });
    // Refresh the list of links after deletion
    fetchLinks();
  } catch (error) {
    console.error('Error deleting link:', error);
  }
};

const fetchLinks = async () => {
  try {
    const response = await api.get(`/players/${leagueStore.league.slug}/links`);
    linkItems.value = response.data;
  } catch (error) {
    console.error('Error fetching links:', error);
  }
};

onMounted(() => {
  fetchLinks();
});
  
</script>