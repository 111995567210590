<template>
  <base-modal modal-title="Edit Event Permissions" @close="close(false)">
    <template #headerStart>
      <ion-button @click="close(false)"> Close </ion-button>
    </template>
    <ion-card>
      <ion-card-header>
        <ion-card-title>Convenors</ion-card-title>
        <ion-card-subtitle>{{ leagueStore.leagueName }}</ion-card-subtitle>
      </ion-card-header>
      <ion-toolbar v-if="authStore.isOwner"
        ><ion-buttons slot="end">
          <ion-button @click="addAdmin">
            <ion-icon :icon="addOutline"></ion-icon>Add Convenor</ion-button
          >
          <ion-button color="tertiary" @click="transferOwnership">
            <ion-icon :icon="exitOutline"></ion-icon> Transfer
            Ownership</ion-button
          >
        </ion-buttons></ion-toolbar
      >
      <ion-card-content style="padding: 0">
        <ion-list>
          <ion-item color="medium">
            <ion-label> Email </ion-label>
            <ion-label slot="end" style="padding-right: 1rem">
              Action</ion-label
            >
          </ion-item>
          <ion-item>
            <ion-label>{{ eventAdmins.owner }}</ion-label>
            <span slot="end">
              <ion-chip>Owner</ion-chip>
            </span>
          </ion-item>
          <ion-item
            v-for="(convenor, index) of eventAdmins.convenors"
            :key="index"
          >
            <ion-label>{{ convenor.email }}</ion-label>
            <span slot="end" v-if="authStore.isOwner">
              <ion-button
                @click="removeAdmin(convenor)"
                fill="outline"
                color="danger"
                >Remove</ion-button
              >
            </span>
          </ion-item>
        </ion-list>
      </ion-card-content>
    </ion-card>
  </base-modal>
</template>
<script setup>
import { defineComponent, ref } from "vue";
import {
  IonButton,
  modalController,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonChip,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonToolbar,
  IonButtons,
} from "@ionic/vue";
import { addOutline, exitOutline } from "ionicons/icons";
import BaseModal from "@/components/layouts/BaseModal.vue";
import { showAlert, showToast } from "@/utils/useIonicComponents.js";
import { api } from "@/utils/axios";
import { useRouter, useRoute } from "vue-router";
import { useLeagueStore } from "@/stores/leagueStore";
import { useAuthStore } from "@/stores/authStore";

defineComponent({
  name: "PermissionsModal",
});
const authStore = useAuthStore();
const leagueStore = useLeagueStore();
const router = useRouter();
const route = useRoute();
const eventAdmins = ref({ owner: "", convenors: [] });
//   const preferenceStore = usePreferenceStore();

grabEventAdmins();

async function grabEventAdmins() {
  const res = await api.post("/api/grab-event-admins", {
    eventId: leagueStore.league.id,
  });
  console.log(res);
  eventAdmins.value = res.data;
}

async function removeAdmin(convenor) {
  const response = await showAlert({
    header: `Confirm Remove Convenor`,
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
      },
      {
        text: "OK",
        role: "confirm",
      },
    ],
  });
  console.log(response);
  if (response.role === "confirm") {
    const res = await api.post("/api/remove-event-admin", {
      eventId: leagueStore.league.id,
      convenorId: convenor.id,
    });
    if (res) {
      grabEventAdmins();
      showToast("Convenor removed!", "success", 2000);
    }
    console.log(res);
  }
}

function isEmail(str) {
  var regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,6})+$/;
  return regex.test(str);
}

async function addAdmin() {
  const response = await showAlert({
    header: `Add Convenor`,
    inputs: [{ placeholder: "Email" }],
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
      },
      {
        text: "OK",
        role: "confirm",
      },
    ],
  });
  console.log(response);
  if (isEmail(response.data.values[0]) && response.role === "confirm") {
    const res = await api.post("/api/add-event-admin", {
      eventId: leagueStore.league.id,
      userEmail: response.data.values[0],
    });

    if (res) {
      grabEventAdmins();
      showToast("Convenor added!", "success", 2000);
    }
    console.log(res);
  }
  if (response.role === "confirm" && !isEmail(response.data.values[0])) {
    showToast("Invalid Email", "danger", 4000);
  }
}

async function transferOwnership() {
  const res = await api.post("/api/grab-registered-event-admins", {
    eventId: leagueStore.league.id,
  });

  console.log(res.data);
  if (!res.data.length) {
    showToast(
      "No registered convenors found to transfer ownership to",
      "danger",
      4000
    );
    return;
  }
  const response = await showAlert({
    header: `Transfer Ownership`,
    message: "Select the convenor to transfer ownership to",
    inputs: res.data.map((c) => ({
      label: c.email,
      value: c.id,
      type: "radio",
    })),
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
      },
      {
        text: "OK",
        role: "confirm",
      },
    ],
  });
  console.log(response);
  if (response.role === "confirm" && response.data.values) {
    const res = await api.post("/api/transfer-event-ownership", {
      eventId: leagueStore.league.id,
      convenorId: response.data.values,
    });
    console.log(res);
    await close();
    await leagueStore.reloadEverything(route.params.leagueSlug);
  }
}

router.replace({
  query: {
    ...route.query,
    open_modal: "EditPermissions",
  },
});

async function close(success = false) {
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss(success);
}
</script>
