<template>
  <base-modal modal-title="Install Slo-Pitch Central">
    <template #headerStart>
      <ion-button @click="close()"> Close </ion-button>
    </template>
    <ion-card>
      <ion-card-header
        ><ion-card-title>Add to Home Screen</ion-card-title>
        <ion-card-subtitle
          >Install Slo-Pitch Central on your iPhone</ion-card-subtitle
        >
      </ion-card-header>
      <ion-card-content>
        <ion-list>
          <ion-item
            ><ion-label class="ion-text-wrap" style="font-size: medium"
              >1. Tap
              <img
                style="margin: 0 2px"
                height="20"
                width="15"
                src="https://help.apple.com/assets/64067987823C71654C27CD1A/64067990823C71654C27CD47/en_US/d26fe35d3438fe81179a80c2b6c9b0c9.png"
              />
              in the menu bar.</ion-label
            ></ion-item
          >
          <ion-item>
            <ion-label class="ion-text-wrap" style="font-size: medium">
              2. Scroll the list of options, then tap Add to Home Screen.
            </ion-label>
          </ion-item>
        </ion-list>
        <img
          src="https://www.contaware.com/images/stories/ContaCam/iphone-addtohomescreen.png"
        />
      </ion-card-content>
    </ion-card>
    <template #footer>
      <ion-button expand="full" @click="close">Dismiss</ion-button>
    </template>
  </base-modal>
</template>

<script setup>
import {
  modalController,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonList,
  IonLabel,
} from "@ionic/vue";
import { defineComponent } from "vue";
import BaseModal from "@/components/layouts/BaseModal.vue";

defineComponent({
  name: "AddToHomeModal",
});

async function close() {
  localStorage.setItem("a2hsModalDismissed", JSON.stringify(true));
  await modalController.dismiss();
}
</script>
