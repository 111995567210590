<template>
    <div class="on-base-container-row" style="min-height: 50px;">
        <div class="on-base-container"  style="min-height: 50px;">
            <div class="at-bat-header">On Deck</div>
            
            <div class="at-bat-container" v-if="scorekeepStore.onDeck">
                <div class="player-details on-deck-player-name">
                    {{  scorekeepStore.onDeck.name }}
                   
                </div>
                <div class="player-details">
                    <div class="player-pos">{{ scorekeepStore.onDeck.position }} - #{{ scorekeepStore.onDeck.number }}</div>
                    <div class="player-res">3 for 4, 2 2Bs</div>
                </div>
                
            </div>
        </div>
        <div class="on-base-container" style="min-height: 50px;" v-if="scorekeepStore.inTheHole">
            <div class="at-bat-header">In The Hole</div>
            
            <div class="at-bat-container">
                <!-- {{  scorekeepStore.inTheHole }} -->
                <div class="player-details on-deck-player-name">
                    {{  scorekeepStore.inTheHole.name }}
                   
                </div>
                <div class="player-details">
                    <div class="player-pos">{{ scorekeepStore.inTheHole.position }} - #{{  scorekeepStore.inTheHole.number }}</div>
                    <div class="player-res">3 for 4, 2 2Bs</div>
                </div>
         
                
                
            </div>
        </div>
    </div>
</template>

<script setup>
// calculating who is at bat
import { useScorekeepStore } from "@/stores/scorekeepStore"
const scorekeepStore = useScorekeepStore()
console.log('scorekeepStore', scorekeepStore)
// if currentHalf = 0 then away is hitting
// if currentHalf = 1 then home is hitting

</script>

<style>
     .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid #555;
    }

    .at-bat-header {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        border-top: 1px solid #555;
        border-bottom: 1px solid #555;
        width: 100%;
        text-align: center;
    }

    .at-bat-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px 20px;
        
    }

    .choose-result-area {
        /* background: rgba(150, 150, 150, 0.1); */
        margin-top: 10px;
        padding: 5px;
    }

    .player-details {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .on-deck-player-name {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 5px;
    }

    .player-pos {
        font-size: 12px;
        font-weight: 700;
        border-radius: 5px;
        color: #eee;
        
        padding: 5px;
        background: rgba(200,200,200,0.1)
    }

    .choose-result {
        font-size: 10px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
    }

    .player-res {
        font-size: 11px;
        color: #bbb;
        font-weight: 700;
        border-radius: 5px;
        text-align: center;
        font-style: italic;
        padding: 5px;
        background: rgba(200,200,200,0.1)
    }

    .at-bat-selectors {
        display: flex;
        justify-content: center;
        /* padding-top: 10px; */
    }

</style>