<template>
  <td>
    <span v-if="disableEdit">
      {{ props.game.away_team_score }}
    </span>
    <ion-input
      v-else
      class="team-score-input"
      inputmode="numeric"
      slot="end"
      debounce="500"
      type="number"
      v-model.number="awayScore"
      :style="{
        borderBottom:
          awayScore !== null ? '' : '3px solid var(--ion-color-medium)',
        maxWidth: '3rem',
        margin: '0 auto',
      }"
    ></ion-input>
  </td>
  <td>
    <span v-if="disableEdit">
      {{ props.game.home_team_score }}
    </span>
    <ion-input
      v-else
      class="team-score-input"
      inputmode="numeric"
      slot="end"
      debounce="500"
      type="number"
      v-model.number="homeScore"
      :style="{
        borderBottom:
          homeScore !== null ? '' : '3px solid var(--ion-color-medium)',
        maxWidth: '3rem',
        margin: '0 auto',
      }"
    ></ion-input>
  </td>
</template>
<script setup>
import {
  defineComponent,
  defineEmits,
  defineProps,
  computed,
  ref,
  watchEffect,
} from "vue";
import { useAuthStore } from "@/stores/authStore";
import { useGameStore } from "@/stores/gameStore";
import { useReportGameStore } from "@/stores/reportGameStore";
import { IonInput } from "@ionic/vue";

defineComponent({
  name: "GameTableRows",
});
const props = defineProps({ game: Object });
const emit = defineEmits(["resetGame"]);
const authStore = useAuthStore();
const gameStore = useGameStore();
const reportStore = useReportGameStore();

reportStore.removeGameFromReport(props.game); //to remove when re rendering  still contain the game in report

const awayScore = ref(props.game.away_team_score);
const homeScore = ref(props.game.home_team_score);

const disableEdit = computed(() => {
  if (
    (parseInt(props.game.away_team_score) >= 0 &&
      parseInt(props.game.home_team_score) >= 0 &&
      !authStore.isConvenor) ||
    gameStore.showSelectGames ||
    props.game.game_status === "postponed"
  ) {
    return true;
  }
  return false;
});

watchEffect(async () => {
  //conditions for when to consider a game to report/edit

  //when game is not reported yet
  if (
    props.game.away_team_score === null &&
    props.game.home_team_score === null
  ) {
    if (awayScore.value === "" && homeScore.value === "") {
      //remove for games to report if score is manually removed
      reportStore.removeGameFromReport(props.game);
      return;
    }

    // games where score is not reported
    // enforce both score

    if (
      awayScore.value !== props.game.away_team_score &&
      homeScore.value !== props.game.home_team_score &&
      parseInt(awayScore.value) >= 0 &&
      parseInt(homeScore.value) >= 0
    ) {
      const reportGame = Object.assign({}, props.game);
      reportGame.away_team_score = awayScore.value;
      reportGame.home_team_score = homeScore.value;
      const response = await reportStore.addGamesToReport(reportGame);
      // console.log("added to report", response);
      if (response === false) {
        emit("resetGame");
      }
    }
  } else {
    //when game is reported and score is edited

    if (
      awayScore.value === props.game.away_team_score &&
      homeScore.value === props.game.home_team_score
    ) {
      // if score is reset, remove the game from report
      reportStore.removeGameFromReport(props.game);
      return;
    }

    if (awayScore.value === "" || homeScore.value === "") {
      //when reported games score remains empty, remove score from report
      reportStore.removeGameFromReport(props.game);
      return;
    }

    // games where score is already reported
    // even one side score edit will trigger report
    if (
      (awayScore.value !== props.game.away_team_score &&
        parseInt(awayScore.value) >= 0) ||
      (homeScore.value !== props.game.home_team_score &&
        parseInt(homeScore.value) >= 0)
    ) {
      const reportGame = Object.assign({}, props.game);
      reportGame.away_team_score = awayScore.value;
      reportGame.home_team_score = homeScore.value;
      reportStore.addGamesToReport(reportGame);
    }
  }
});
</script>
