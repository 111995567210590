<template>
  <div class="card-header" :style="cardHeaderStyle">
    <div class="grid-container">
      <div class="slot-container" v-if="gameStore.showSelectGames && props.inPopup !== true">
        <slot></slot>
      </div>

      
      <div class="game-data left">
        <div class="game-data-content" :class="{ 'in-popup': inPopup }">
          <b>{{ props.game.park_name }} {{ props.game.diamond_signifier }}</b>
        </div>
      </div>

      <div class="game-data right">
        <div class="game-data-content" :class="{ 'in-popup': inPopup }">
          <b>{{
            props.game.start_time
              ? format(
                  set(new Date(), {
                    hours: props.game.start_time.split(":")[0],
                    minutes: props.game.start_time.split(":")[1],
                  }),
                  "h:mm aa"
                )
              : "TBA"
          }}</b>
        </div>
      </div>

      
    </div>
  </div>
</template>

<script setup>
import { defineComponent, defineProps } from "vue";
import { format, set } from "date-fns";
import uniqolor from "uniqolor";
import { useGameStore } from "@/stores/gameStore";
const gameStore = useGameStore();

defineComponent({
  name: "GameCardHeader",
});
const props = defineProps(["game", "inPopup"]);

const cardHeaderStyle = {
  // padding: '0.2rem',
  backgroundColor: uniqolor(props.game.division_id, {
    lightness: [25, 30],
  }).color,
};
</script>

<style scoped lang="scss">
.card-header {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  
}

.grid-container {
  display: flex;
  width: 100%;
  
}

.game-data, .slot-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  
}

.game-data {
  padding: 0.4rem 0.6rem;
}

.game-data, .slot-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  color: #fff;
}

.game-data.left {
  align-items: flex-start;
}


.game-data.right {
  align-items: flex-end;
}

.game-data-content, .slot-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.in-popup {
  font-size: 1.1em;
}
</style>