<template>
    <ion-page class="container">
        <div class="content">
            <img :src="leagueStore.league.logo_url || 'https://cdn.blacktiecollab.com/slopitchcentral/images/slopitchcentral.png'" class="logo" alt="">
            <h1 style="color: var(--ion-color-dark); text-align: center; text-transform: uppercase;">
                <span
                    style="background-color: var(--ion-color-primary); padding: 0 10px"
                >
                    Join your team in {{ leagueStore.league.name }}
                </span>
            </h1>
            <p>
                <ion-icon :icon="checkboxOutline"></ion-icon>
                Communicate with teammates
            </p>
            <p>
                <ion-icon :icon="checkboxOutline"></ion-icon>
                Track attendance
            </p>
            <p>
                <ion-icon :icon="checkboxOutline"></ion-icon>
                Track your stats
            </p>
            <form @submit.prevent="submitForm"  v-if="!formSubmitted">
                <ion-item>
                    <ion-label position="stacked">Select Your Team</ion-label>
                    <ion-select v-model="selectedTeam" placeholder="Select One">
                        <ion-select-option v-for="team in leagueStore.league.teams" :value="team" :key="team.id">{{ team.name }}</ion-select-option>
                    </ion-select>
                    <ion-note color="danger" v-if="v$.selectedTeam.$error">
                        Team selection is required.
                    </ion-note>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">First Name</ion-label>
                    <ion-input v-model="firstName"></ion-input>
                    <ion-note color="danger" v-if="v$.firstName.$error">
                        First name is required.
                    </ion-note>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">Last Name</ion-label>
                    <ion-input v-model="lastName"></ion-input>
                    <ion-note color="danger" v-if="v$.lastName.$error">
                        Last name is required.
                    </ion-note>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">Email</ion-label>
                    <ion-input type="email" v-model="email"></ion-input>
                    <ion-note color="danger" v-if="v$.email.$error">
                        <span v-if="v$.email.required.$invalid">Email is required.</span>
                        <span v-if="v$.email.emailValidator.$invalid">Please enter a valid email.</span>
                    </ion-note>
                </ion-item>
                <p>A real email is needed as after you submit, you will be required to confirm your email.</p>
                
                <ion-button type="submit" expand="block" :disabled="v$.$invalid || isLoading">
                    <ion-spinner v-if="isLoading" name="lines"></ion-spinner>
                    <span v-else>Submit</span>
                </ion-button>
            </form>
            <div v-else>
                <h2>Please check your email to confirm.</h2>
            </div>
        </div>
    </ion-page>
</template>

<script setup>
import { IonPage, IonItem, IonLabel, IonSelect, IonSelectOption, IonInput, IonButton, IonIcon } from "@ionic/vue";
import { IonSpinner } from '@ionic/vue';
import { ref } from 'vue';
import { useLeagueStore } from "@/stores/leagueStore";
import { checkboxOutline } from 'ionicons/icons';
import { api } from "@/utils/axios";
import useVuelidate from "@vuelidate/core";
import { required, email as emailValidator } from "@vuelidate/validators";
const formSubmitted = ref(false);
const isLoading = ref(false);

const leagueStore = useLeagueStore();
const selectedTeam = ref('');
const firstName = ref('');
const lastName = ref('');
const email = ref('');

const rules = {
  selectedTeam: { required },
  firstName: { required },
  lastName: { required },
  email: { required, emailValidator },
};

const v$ = useVuelidate(rules, { selectedTeam, firstName, lastName, email });

async function submitForm() {
  const isFormValid = await v$.value.$validate();
  if (!isFormValid) {
    console.log(v$.value.$errors);
    return; // Prevent form submission if validation fails
  }
  
  isLoading.value = true; // Start loading
  
  // Form submission logic here
  api.post('/api/save-via-league-invite', {
    team_id: selectedTeam.value.id,
    first_name: firstName.value,
    last_name: lastName.value,
    email: email.value
  }).then(() => {
    
    formSubmitted.value = true; // Set formSubmitted to true on successful submission
  }).catch(err => {
    console.error('err', err);
  }).finally(() => {
    isLoading.value = false; // Stop loading regardless of outcome
  });
}
</script>


<style scoped>

.container {
    overflow-y: scroll;
}

.content {
    text-align: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 60px;
}

.logo {
  max-width: 200px; /* Adjust based on your needs */
  margin-bottom: 20px;
}

.text {
  margin-bottom: 20px;
  font-size: 25px;
}

.buttons ion-button {
  margin: 5px;
}
</style>