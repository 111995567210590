<template>
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <ion-title>Contact Convenor</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="emitClose">
              <ion-icon :icon="closeOutline" />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding"  style="height: 300px;">
        <ion-textarea v-model="message" placeholder="Your message here..."></ion-textarea>
        <ion-button expand="block" @click="sendMessage">Send</ion-button>
      </ion-content>
    </ion-page>
  </template>
  
  <script setup>
    import { closeOutline } from 'ionicons/icons';
    import { defineEmits } from 'vue';
    import {  IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonTextarea } from '@ionic/vue';
    import { api } from "@/utils/axios";
    import { useLeagueStore } from "@/stores/leagueStore";

    const leagueStore = useLeagueStore();
    
    import { ref } from 'vue';

    const message = ref(''); // This creates a reactive reference for a message

    const emit = defineEmits(['close']);

    const emitClose = () => {
        // Emit an event to the parent component to close the modal
        emit('close');
    };
  
  
    const sendMessage = async () => {
    // Logic to send the message to the convenor
    try {
        const response = await api.post(`/players/${leagueStore.league.slug}/contact`, { message: message.value });
        
        if(response) {
          emitClose(); // Close the modal after sending the message
        }
        // Handle response
        
    } catch (error) {
        // Handle error
    }
    };
  </script>