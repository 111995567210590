import axios from "axios";

// Base URL for the first API
const baseURL = process.env.VUE_APP_BASEURL || "http://localhost:3002";
// Creating the first Axios instance
const api = axios.create({ baseURL, withCredentials: true });

// Base URL for the second API
const sloPitchBaseURL = process.env.VUE_APP_SLOPITCH_BASEURL || "https://slo-pitch.com/";
// Creating the second Axios instance
const sloPitchApi = axios.create({ baseURL: sloPitchBaseURL });

export { api, sloPitchApi };