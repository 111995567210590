<template>
    <div style="padding-bottom: 30px;">
        <ion-segment mode="md" v-model="selectedSegment">
            <ion-segment-button value="awayTeam">
                <ion-label>{{ scorekeepStore.awayTeam.name || 'Away Team' }}</ion-label>
            </ion-segment-button>
            <ion-segment-button value="homeTeam">
                <ion-label>{{ scorekeepStore.homeTeam.name || 'Home Team' }}</ion-label>
            </ion-segment-button>
        </ion-segment>
        
        <table class="scorecard-table" style="width: 800px;"> 
            <thead>
                <tr>
                    <th class="player-column">Player</th>
                    <th 
                        class="inning-box inning-box-header"
                        v-for="inning of parseInt(scorekeepStore.maxInningsCount)" :key="inning">
                        <div class="inning-word">
                            Inning
                        </div>
                        <div class="inning-number ">
                            {{ inning }}
                        </div>
                    </th>

                    <th class="inning-box inning-box-header double-wide">Stats</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(player, index) in currentLineup" :key="index">
                    <td class="player-column">
                        <div class="player-name" style="font-size: 15px;">{{ player.name }}</div>
                        <div class="player-pos" style="text-align: center;">{{ player.position }} - #{{ player.number }}</div>
                    </td>
                    <td 
                        class="inning-box"
                        v-for="inning of parseInt(scorekeepStore.maxInningsCount)" :key="inning">
                        <div class="inning-box-content">
                            <ScorecardAtBatView 
                                :currentLineup="currentLineup"
                                :currentPlayByPlay="currentPlayByPlay"
                                :index="index"
                                :key="selectedSegment"
                                :selectedSegment="selectedSegment"
                                :player="player" 
                                :inning="inning">
                            </ScorecardAtBatView>
                        </div>
                    </td>
                    <td class="inning-box double-wide">
                        {{ statsSummary(player, currentPlayByPlay) }}
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr 
                    v-for="(player, index) in currentBench" 
                    :key="index"
                    >
                    <td class="player-column" style="border-top: 1px solid #fff;">
                        <div class="player-name" style="font-size: 15px;">{{ player.name }}</div>
                        <div class="player-pos" style="text-align: center;">{{ player.position }} - #{{ player.number }}</div>
                    </td>
                    <td 
                        class="inning-box"
                        style="border-top: 1px solid #fff;"
                        v-for="inning of parseInt(scorekeepStore.maxInningsCount)" :key="inning">
                        <div class="inning-box-content">
                            <ScorecardAtBatView 
                                :currentLineup="currentBench"
                                :currentPlayByPlay="currentPlayByPlay"
                                :index="index"
                                :key="selectedSegment"
                                :selectedSegment="selectedSegment"
                                :player="player" 
                                :inning="inning">
                            </ScorecardAtBatView>
                        </div>
                    </td>
                    <td class="inning-box double-wide">
                        {{ statsSummary(player, currentPlayByPlay) }}
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td class="player-column">Totals</td>
                    <td 
                        class="inning-box"
                        v-for="inning of parseInt(scorekeepStore.maxInningsCount)" :key="inning">
                        {{  runsPerInningSummary(inning, currentPlayByPlay) }}
                        <!-- {{ scorekeepStore.awayInningScores[inning - 1] }} -->
                    </td>
                    <td class="inning-box double-wide">0</td>
                </tr>
            </tfoot>
        </table>
    </div>    
</template>

<script setup>
import { useScorekeepStore } from "@/stores/scorekeepStore"
import { IonSegment, IonSegmentButton, IonLabel } from '@ionic/vue';
import ScorecardAtBatView from "./ScorecardAtBatView.vue";
import { ref, computed } from 'vue';

const scorekeepStore = useScorekeepStore()

const selectedSegment = ref('awayTeam');

const currentPlayByPlay = computed(() => {
    // console.log('selectedSegment', selectedSegment.value)
    if(selectedSegment.value === 'awayTeam') {

        return scorekeepStore.awayTeamPlayByPlay
    }
    if(selectedSegment.value === 'homeTeam') {

        return scorekeepStore.homeTeamPlayByPlay
    }
    
    return scorekeepStore.homeTeamPlayByPlay

})

const currentLineup = computed(() => {
    if(selectedSegment.value === 'awayTeam') {
        return scorekeepStore.awayTeamLineup
    }
    if(selectedSegment.value === 'homeTeam') {
        return scorekeepStore.homeTeamLineup
    }
    
    return scorekeepStore.homeTeamLineup
})

const currentBench = computed(() => {
    if(selectedSegment.value === 'awayTeam') {
        return scorekeepStore.awayTeamBench
    }
    if(selectedSegment.value === 'homeTeam') {
        return scorekeepStore.homeTeamBench
    }
    
    return scorekeepStore.homeTeamBench
})

const statsSummary = (player, currentPlayByPlay) => {

    const playerPlays = currentPlayByPlay.filter(play => {
        return play.substitution === false && play.atBat && play.atBat.id === player.id
    })

    const hits = playerPlays.filter(play => play.hitResult === 'single' || play.hitResult === 'double' || play.hitResult === 'triple' || play.hitResult === 'homerun').length;

    

    const atBats = playerPlays.filter(play => play.hitResult === 'single' || play.hitResult === 'double' || play.hitResult === 'triple' || play.hitResult === 'homerun' || play.hitResult === 'out').length;

    const walks = playerPlays.filter(play => play.hitResult === 'walk').length

    const doubles = playerPlays.filter(play => play.hitResult === 'double').length
    const triples = playerPlays.filter(play => play.hitResult === 'triple').length
    const homeruns = playerPlays.filter(play => play.hitResult === 'homerun').length
    
    // const outs = playerPlays.filter(play => play.hitResult === 'out').length
    
    // const strikeouts = playerPlays.filter(play => play.hitResult === 'strikeout').length
    // const hitByPitch = playerPlays.filter(play => play.hitResult === 'hitByPitch').length
    const sacFly = playerPlays.filter(play => play.hitResult === 'sacfly').length
    const gidp = playerPlays.filter(play => play.hitResult === 'doubleplay').length
    // const doublePlay = playerPlays.filter(play => play.hitResult === 'doubleplay').length

    let resultString = `${hits} for ${atBats}`;
    if (doubles > 0) {
        resultString += `, ${doubles} 2B`;
    }
    if (triples > 0) {
        resultString += `, ${triples} 3B`;
    }
    if (homeruns > 0) {
        resultString += `, ${homeruns} HR`;
    }
    if (walks > 0) {
        resultString += `, ${walks} BB`;
    }
    if(sacFly > 0) {
        resultString += `, ${sacFly} SF`;
    }
    if(gidp > 0) {
        resultString += `, ${gidp} GIDP`;
    }
    if (atBats > 0) {
        const average = (hits / atBats).toFixed(3);
        const formattedAverage = average.startsWith('1') ? average : average.substring(1);
        resultString += `, ${formattedAverage} AVG`;
    }
    
    return resultString;
}

const runsPerInningSummary = (inning, currentPlayByPlay) => {
    const inningPlays = currentPlayByPlay.filter(play => play.inning === inning);
    
    let runsThisInning = 0;

    inningPlays.forEach(play => {
        console.log('play', play)
        if (play.runners.third && play.runners.third.scoredResult) {
            runsThisInning++;
        }
        if (play.runners.second && play.runners.second.scoredResult) {
            runsThisInning++;
        }
        if (play.runners.first && play.runners.first.scoredResult) {
            runsThisInning++;
        }
        if (play.scoredResult) {
            runsThisInning++;
        }
    });

    return runsThisInning;
}


</script>

<style scoped>

.player-column {
    width: 100px;
    position: sticky;
    left: 0;
    z-index: 200;
    padding: 0 20px;
    color: #fff;
    background-color: #191919; /* Ensures the cell remains visible over other content */
}

.inning-box {
    width: 50px;
    height: 65px;
    text-align: center;
}

.inning-box-header {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #191919; /* Ensures the header remains visible over other content */
}

.inning-word {
    font-size: 10px;
    color: #fff;
    font-weight: 700;
}

.inning-number {
    font-size: 24px;
    color: #fff;
    font-weight: 700;
}

.inning-box-content {
    width: 100%;
    color: #fff;
    height: 100%;
}

.double-wide {
    width: 200px;
}


</style>
