<template>
  <div>
    <div class="block">
      <div 
        class="division-title" 
        @click="toggleAccordion"
        :style="[
          {
            backgroundColor: uniqolor(props.division.division_name, {
              lightness: [25, 30],
            }).color,
          },
        ]">
        <div class="title-text">
          {{ props.division.division_name }} - {{ props.division.teams.length }} Teams
        </div>
        <ion-icon class="chevron-icon" :icon="isOpen ? chevronUpOutline : chevronDownOutline"></ion-icon>
      </div>
    </div>
    <transition name="slide-fade">
      <div v-if="isOpen">
        <teams-desktop :division="props.division"></teams-desktop>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { defineComponent, defineProps, ref, onBeforeUnmount } from "vue";
import { IonIcon } from "@ionic/vue";
import { chevronUpOutline, chevronDownOutline } from "ionicons/icons";
import { debounce } from "lodash";
import uniqolor from "uniqolor";
import TeamsDesktop from "./TeamsDesktop.vue";

defineComponent({
  name: "TeamsTable",
});
const props = defineProps({ division: Object });
const isOpen = ref(true); // Set to true to have the accordion open by default
const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);

window.addEventListener("resize", debounce(resizeHandler, 1000));

function resizeHandler() {
  if (window.innerWidth >= 960) {
    showDesktopMode.value = true;
  } else {
    showDesktopMode.value = false;
  }
}

function toggleAccordion() {
  isOpen.value = !isOpen.value;
}

onBeforeUnmount(() => {
  window.removeEventListener("resize", resizeHandler);
});
</script>

<!-- ...existing styles... -->

<style scoped>
table {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
th {
  border-bottom: 1px solid var(--ion-color-medium);
}

.division-title {
  margin-top: 4rem;
  background: #7d38f0;
  padding: 10px;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; /* Add this */
  z-index: 1; /* Add this */
}

.title-text {
  flex-grow: 1;
  cursor: pointer;
}

.block {
  width: 100%;
  height: 45px;
  cursor: pointer;
  display: block;
}

.chevron-icon {
  margin-left: auto;
}

.left-align {
  text-align: left;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>