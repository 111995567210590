<template>
  <base-modal modal-title="Edit Standings" @close="close">
    <!-- <template #headerStart>
      <ion-button @click="close"> Close </ion-button>
    </template> -->
    
    <ion-list>
      <ion-item>
        <ion-label>Name</ion-label>
        <ion-label>{{ props.team.name }}</ion-label>
      </ion-item>
      <ion-item>
        <ion-label>Mode</ion-label>
        <ion-label>{{ props.team.mode }}</ion-label>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Wins</ion-label>
        <ion-input class="standings-input" v-model="team.wins"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Losses</ion-label>
        <ion-input class="standings-input" v-model="team.losses"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Ties</ion-label>
        <ion-input class="standings-input" v-model="team.ties"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Runs For</ion-label>
        <ion-input class="standings-input" v-model="team.runs_for"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Runs Against</ion-label>
        <ion-input class="standings-input" v-model="team.runs_against"></ion-input>
      </ion-item>
    </ion-list>
    
    <template #footer>
      <ion-button expand="full" @click="saveGame">Save</ion-button>
    </template>
  </base-modal>
</template>
<script setup>
import BaseModal from "@/components/layouts/BaseModal.vue";
import {
  // IonCol,
  // IonRow,
  // IonGrid,
  IonLabel,
  // IonCard,
  // IonCardContent,
  IonInput,
  IonItem,
  IonList,
  IonButton,
  modalController,
} from "@ionic/vue";
import { defineComponent, defineProps, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
import { showToast, showLoading } from "@/utils/useIonicComponents.js";
import { api } from "@/utils/axios";
import { useRouter, useRoute } from "vue-router";
import { useLeagueStore } from "@/stores/leagueStore";

defineComponent({
  name: "EditStandingsModal",
});
const props = defineProps({ team: Object, mode: String });
const team = ref({ ...props.team });
const leagueStore = useLeagueStore();

const router = useRouter();
const route = useRoute();
router.replace({
  query: {
    ...route.query,
    open_modal: "EditStanding",
  },
});

const rules = {
  wins: { required, numeric },
  losses: { required, numeric },
  ties: { required, numeric },
  runs_for: { required, numeric },
  runs_against: { required, numeric },
};

const v$ = useVuelidate(rules, team);

async function saveGame() {
  const isFormValid = await v$.value.$validate();
  if (isFormValid) {
    await saveGameRequest();
  } else {
    showToast("Invalid Input!", "danger", 6000);
  }
}

async function saveGameRequest() {
  const loading = await showLoading("Updating Standings. Please Wait...");
  const result = await api.post("/api/save-standings", {
    event: leagueStore.league.id,
    team: team.value,
  });
  loading.dismiss();

  if (result.data.type === "success") {
    await leagueStore.reloadEverything(leagueStore.league.slug);
    await close();
    showToast("Standings successfully updated.", "success", 6000);
  } else {
    showToast(
      "Something went wrong. If this persists, please contact us.",
      "danger",
      6000
    );
  }
}

async function close() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss();
}
</script>
<style scoped>
/* .standings-input {
  border-bottom: 3px solid var(--ion-color-medium);
  max-width: 5rem;
  text-align: center;
} */
</style>
