<template>
    <div :class="['item', 'item-' + game.id]">
      <div :class="{ 'item-parent': game.children && game.children.length > 0, 'item-child': !game.children || game.children.length === 0 }">
        <!-- <ion-card> -->
        <game-card :game="game" style="width: 350px"></game-card>
        
        <!-- Display game details here -->
        <!-- <div class="game-details">

            Game ID: {{ game.id }}
        </div> -->
        <!-- </ion-card> -->
        
      </div>
      <div class="item-childrens" v-if="game.children && game.children.length > 0">
        <div class="item-child" v-for="childGame in game.children" :key="childGame.id">
          <BracketGame
            :game="childGame"
            @selectedMatchUpdate="selectedMatchUpdate($event)"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import BracketGame from './BracketGame.vue';
  import GameCard from '../schedule/game-grid/GameCard.vue';
  export default {
    name: 'BracketGame',
    props: {
      game: {
        type: Object,
        required: true
      }
    },
    components: {
      BracketGame,
     GameCard
    },
    methods: {

      selectedMatchUpdate(match) {
        this.$emit('selectedMatchUpdate', match);
      }
    }
  };
  </script>
  
  <style scoped lang="scss">
    $side-margin: 50px;
    $vertical-margin: 0px;

    .game-details {
        width: 350px;
        background: #111;
        border-radius: 5px;
    }

    .item {
        display: flex;
        flex-direction: row-reverse;
        margin-left: 20px;

        &-parent {
            position: relative;
            margin-left: $side-margin;
            display: flex;
            align-items: center;
            &:after {
            position: absolute;
            content: "";
            width: $side-margin/2;
            height: 2px;
            left: 0;
            top: calc(50% + 22px);
            background-color: #fff;
            transform: translateX(-100%);
            }
        }
        &-childrens {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        &-child {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: $vertical-margin;
            margin-bottom: $vertical-margin;
            position: relative;
            &:before {
            content: "";
            position: absolute;
            background-color: #fff;
            right: 0;
            top: calc(50% + 22px);
            transform: translateX(100%);
            width: $side-margin/2;
            height: 2px;
            }
            &:after {
            content: "";
            position: absolute;
            background-color: #fff;
            right: -$side-margin / 2;
            height: calc(50% + 22px);
            width: 2px;
            top: calc(50% + 22px);
            }
            &:last-child {
            &:after {
                transform: translateY(-100%);
            }
            }
            &:only-child:after {
            display: none;
            }
        }
    }
  </style>
  