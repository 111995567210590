<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Drag and Drop To Change Sort Order</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <draggable v-model="localItems" @end="handleEnd" item-key="item">
        <template #item="{ element, index }">
          <ion-item>
            <ion-label>{{ element }}</ion-label>
            <ion-note slot="end">{{ index + 1 }}</ion-note>
          </ion-item>
        </template>
      </draggable>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonNote,
} from "@ionic/vue";
import { ref, watch, defineProps, defineEmits } from "vue";
import draggable from "vuedraggable";

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["updateOrder"]);

// Create a local copy of the items prop
const localItems = ref([...props.items]);

// Watch for changes in the items prop and update the local copy if necessary
watch(
  () => props.items,
  (newItems) => {
    if (JSON.stringify(newItems) !== JSON.stringify(localItems.value)) {
      localItems.value = [...newItems];
    }
  }
);

const handleEnd = (event) => {
  console.log("List reordered", event);
  // Emit the new order to the parent component
  emit("updateOrder", localItems.value);
};
</script>

<style scoped>
ion-item {
  --border-width: 1px;
  margin: 5px 0;
  border-radius: 5px;
}
</style>
