<template>
  <base-modal modal-title="Change Password" @close="close(false)">
    <template #headerStart>
      <ion-button @click="close(false)"> Close </ion-button>
    </template>
    <ion-card style="min-width: 20rem; padding: 0; margin: 0">
      <ion-card-content>
        <ion-grid>
          <ion-row>
            <ion-col class="ion-align-self-center label-left-col"
              ><ion-label>Current Password</ion-label></ion-col
            >
            <ion-col
              ><ion-input
                type="password"
                placeholder="Current Password"
                class="details-input"
                v-model="user.current_password"
              ></ion-input>
              <ion-note color="danger" v-if="v$.current_password.$errors.length"
                ><p style="text-align: center; margin-top: 0.5rem">
                  {{ v$.current_password.$errors[0].$message }}
                </p>
              </ion-note>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-align-self-center label-left-col"
              ><ion-label>New Password</ion-label></ion-col
            >
            <ion-col
              ><ion-input
                type="password"
                placeholder="New Password"
                class="details-input"
                v-model="user.new_password"
              ></ion-input>
              <ion-note color="danger" v-if="v$.new_password.$errors.length"
                ><p style="text-align: center; margin-top: 0.5rem">
                  {{ v$.new_password.$errors[0].$message }}
                </p>
              </ion-note>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-align-self-center label-left-col"
              ><ion-label>Confirm Password</ion-label></ion-col
            >
            <ion-col
              ><ion-input
                type="password"
                placeholder="Confirm Password"
                class="details-input"
                v-model="user.confirm_password"
              ></ion-input>
              <ion-note color="danger" v-if="v$.confirm_password.$errors.length"
                ><p style="text-align: center; margin-top: 0.5rem">
                  {{ v$.confirm_password.$errors[0].$message }}
                </p>
              </ion-note>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
    <template #footer>
      <ion-button expand="full" @click="changePassword"
        >Change Password</ion-button
      >
    </template>
  </base-modal>
</template>
<script setup>
import BaseModal from "@/components/layouts/BaseModal.vue";
import {
  IonCol,
  IonRow,
  IonGrid,
  IonLabel,
  IonCard,
  IonCardContent,
  IonInput,
  IonButton,
  IonNote,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref, computed } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers, sameAs } from "@vuelidate/validators";
import { showToast, showLoading } from "@/utils/useIonicComponents.js";
import { api } from "@/utils/axios";
import { useAuthStore } from "@/stores/authStore";
import { useRouter } from "vue-router";

defineComponent({
  name: "ChangePasswordModal",
});
const authStore = useAuthStore();
const user = ref({});
const router = useRouter();

const getNewPassword = computed(() => user.value.new_password);

const rules = {
  current_password: { required },
  new_password: { required, minLengthValue: minLength(8) },
  confirm_password: {
    required,
    sameaAsPassword: helpers.withMessage(
      "The New Password and Confirm Password should Match",
      sameAs(getNewPassword)
    ),
  },
};

const v$ = useVuelidate(rules, user);

async function changePassword() {
  const isFormValid = await v$.value.$validate();
  if (isFormValid) {
    await changePasswordRequest();
  } else {
    console.log(v$.value.$errors);
    showToast("Invalid Input", "danger", 6000);
  }
}

async function changePasswordRequest() {
  const loading = await showLoading("Changing Passwords. Please Wait...");
  try {
    const result = await api.post("/auth/change-password", {
      password: user.value.current_password,
      newpassword: user.value.new_password,
    });
    if (result.status === 200) {
      await close(true);
      showToast("Password successfully Changed.", "success", 6000);
      await authStore.refreshToken();
      router.push("/");
    } else {
      showToast(
        "Something went wrong. If this persists, please contact us.",
        "danger",
        6000
      );
    }
    loading.dismiss();
  } catch (err) {
    console.log(err);
    loading.dismiss();
    if (err.response.status === 403) {
      showToast("Check if you entered correct password!", "danger", 6000);
    } else {
      showToast(
        "Something went wrong. If this persists, please contact us.",
        "danger",
        6000
      );
    }
  }
}

async function close(success = false) {
  await modalController.dismiss(success);
}
</script>
<style scoped>
.details-input {
  border-bottom: 3px solid var(--ion-color-medium);
  text-align: center;
}
</style>
