<template>
  <ion-card>
    <ion-card-header>
      <ion-card-subtitle
        >This is to verify you're allowed to report this
        score.</ion-card-subtitle
      >
      <ion-card-title style="text-align: center; font-size: 1.5rem">
        <p
          style="
            display: flex;
            justify-content: space-around;
            align-items: center;
          "
        >
          <game-card-content-view :game="props.game"></game-card-content-view>
        </p>
      </ion-card-title>
    </ion-card-header>
    <ion-item>
      <ion-input
        style="text-align: center"
        placeholder="ENTER YOUR SHORTCODE"
        @ion-input="updateValue($event.target.value)"
        :value="props.modelValue"
      ></ion-input>
    </ion-item>
  </ion-card>
</template>

<script setup>
import { defineComponent, defineProps, defineEmits } from "vue";
import {
  IonInput,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonItem,
} from "@ionic/vue";
import GameCardContentView from "@/components/schedule/game-grid/GameCardContentView.vue";

defineComponent({
  name: "ReportScore",
});
const props = defineProps({ game: Object, modelValue: String });
const emit = defineEmits(["update:modelValue"]);
console.log(props.game);
function updateValue(value) {
  emit("update:modelValue", value);
}
</script>
