<template>
  <div
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    "
  >
    <ion-card
      style="width: 60rem; text-align: center; height: 30rem"
      class="upload-card"
      color="light"
    >
      <div>
        <ion-card-header>
          <ion-card-title>Upload CSV</ion-card-title>
          <ion-card-subtitle>Select your csv file to import</ion-card-subtitle>
        </ion-card-header>

        <ion-card-content>
          <ion-button @click="fileInput.click()">
            Select CSV
            <ion-icon slot="end" :icon="cloudUploadOutline"></ion-icon>
          </ion-button>
        </ion-card-content>
        <ion-card-header>
          <ion-card-title>Having issues?</ion-card-title>
          <ion-card-subtitle>Make sure you're using our CSV. If you're still having troubles, contact us and include your CSV, and we'll get it uploaded for you bug free!</ion-card-subtitle>
        </ion-card-header>
        <a
          href="https://cdn.blacktiecollab.com/slopitchcentral/Slo-Pitch Central CSV - Enter Your Schedule HERE.csv"
          target="_blank"
          style="background: #fff; padding: 10px; text-decoration: none;"
          >Download Our CSV Template</a
        >
        <a
          href="https://tally.so/r/w8xXrO"
          target="_blank"
          style="background: #fff; padding: 10px; text-decoration: none; margin-left: 10px;"
          >Contact Us to Upload It</a
        >
      </div>
    </ion-card>
    <input
      type="file"
      style="display: none"
      ref="fileInput"
      @change="onFilePicked"
      accept=".csv"
    />
  </div>
</template>

<script setup>
import { defineComponent, ref } from "vue";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonButton,
  IonIcon,
} from "@ionic/vue";
import { cloudUploadOutline } from "ionicons/icons";
import { useUploadCSVStore } from "@/stores/UploadCSVStore";
import { showAlert } from "@/utils/useIonicComponents";

defineComponent({
  name: "UploadComponent",
});
const fileInput = ref();
const uploadCSV = useUploadCSVStore();

async function onFilePicked(event) {
  await uploadCSV.parseCSV(event.target.files[0]);
  fileInput.value.value = "";
}

if (uploadCSV.isTherePreviousFileData) {
  showAlert({
    header: `Previously Uploaded Data found. Would you like to load that ?`,
    buttons: [
      {
        text: "Yes",
        role: "confirm",
        handler: async () => {
          uploadCSV.userChoiceForLoadingPreviousData = true;
          await uploadCSV.loadPreviousData();
          await uploadCSV.associateHeaders();
        },
      },
      {
        text: "No",
        role: "cancel",
      },
    ],
  });
}
</script>
<style scoped>
.upload-card {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='7' stroke-dasharray='6%2c 25' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
