<template>
    <div class="on-base-container-row">
        <div class="on-base-container">
            <div class="at-bat-header base-margin">On 3rd</div>
            
            <div class="on-base-at-bat-container" v-if="scorekeepStore.onThirdBase">
                <div
                    class="fives-on-a-base" 
                    v-if="checkIfTheyAreMaxRunPerInning('third')">
                    {{ `${scorekeepStore.runsPerInning} is on 3rd Blue!`}}
                </div>
                <div class="player-details on-base-player-name">
                    {{ scorekeepStore.onThirdBase.name }}
                   
                </div>
                <div class="player-details">
                    <div class="player-pos">{{ scorekeepStore.onThirdBase.position }} - #{{ scorekeepStore.onThirdBase.number }}</div>
                </div>
                <ion-button 
                    size="small"
                    color="danger"
                    style="width: 100%;"
                    :fill="scorekeepStore.currentPlay.runners.third.outResult ? 'solid' : 'outline'"
                    @click="scorekeepStore.updateCurrentPlay({ 
                        runners: {
                            third: {
                                movesTo: null,
                                scoredResult: false,
                                outResult: true
                            }
                        }
                    })">
                    Out
                </ion-button>
                <ion-button 
                    size="small"
                    color="success"
                    style="width: 100%;"
                    :fill="scorekeepStore.currentPlay.runners.third.scoredResult ? 'solid' : 'outline'"
                    
                    @click="scorekeepStore.updateCurrentPlay({ 
                        runners: {
                            third: {
                                movesTo: 'home',
                                scoredResult: true,
                                outResult: false
                            }
                        }
                    })">
                    Scoring
                </ion-button>
                <ion-button 
                    size="small"
                    color="warning"
                    style="width: 100%;"
                    :fill="scorekeepStore.currentPlay.runners.third.scoredResult === false && scorekeepStore.currentPlay.runners.third.outResult === false && scorekeepStore.currentPlay.runners.third.movesTo === null ? 'solid' : 'outline'"
                    @click="scorekeepStore.updateCurrentPlay({ 
                        runners: {
                            third: {
                                movesTo: null,
                                scoredResult: false,
                                outResult: false
                            }
                        }
                    })">
                    Stay
                </ion-button>
            </div>
            <div class="on-base-at-bat-container base-empty" v-if="!scorekeepStore.onThirdBase">
                3rd Empty
            </div>
        </div>
        <div class="on-base-container">
            <div class="at-bat-header">On 2nd</div>
            
            <div class="on-base-at-bat-container" v-if="scorekeepStore.onSecondBase">
                <div
                    class="fives-on-a-base" 
                    v-if="checkIfTheyAreMaxRunPerInning('second')">
                    {{ `${scorekeepStore.runsPerInning} is on 2nd Blue!`}}
                </div>
                <div class="player-details on-base-player-name">
                    {{ scorekeepStore.onSecondBase.name }}
                   
                </div>
                <div class="player-details">
                    <div class="player-pos">{{ scorekeepStore.onSecondBase.position }} - # {{ scorekeepStore.onSecondBase.number }}</div>
                </div>
                <ion-button 
                    size="small"
                    color="danger"
                    style="width: 100%;"
                    :fill="scorekeepStore.currentPlay.runners.second.outResult ? 'solid' : 'outline'"
                    @click="scorekeepStore.updateCurrentPlay({ 
                        runners: {
                            second: {
                                movesTo: null,
                                outResult: true,
                                scoredResult: false
                            }
                        }
                    })">
                    Out
                </ion-button>
                <ion-button 
                    size="small"
                    color="warning"
                    style="width: 100%;"
                    :fill="scorekeepStore.currentPlay.runners.second.movesTo === 'third' ? 'solid' : 'outline'"
                    
                    @click="scorekeepStore.updateCurrentPlay({ 
                        runners: {
                            second: {
                                movesTo: 'third',
                                outResult: false,
                                scoredResult: false
                            }
                        }
                    })">
                    To 3rd
                </ion-button>
                <ion-button 
                    size="small"
                    color="success"
                    style="width: 100%;"
                    :fill="scorekeepStore.currentPlay.runners.second.scoredResult ? 'solid' : 'outline'"
                    
                    @click="scorekeepStore.updateCurrentPlay({ 
                        runners: {
                            second: {
                                movesTo: 'home',
                                outResult: false,
                                scoredResult: true
                            }
                        }
                    })">
                    Scoring
                </ion-button>
                <ion-button 
                    size="small"
                    color="secondary"
                    style="width: 100%;"
                    :fill="scorekeepStore.currentPlay.runners.second.scoredResult === false && scorekeepStore.currentPlay.runners.second.outResult === false && scorekeepStore.currentPlay.runners.second.movesTo === null ? 'solid' : 'outline'"
                    @click="scorekeepStore.updateCurrentPlay({ 
                        runners: {
                            second: {
                                movesTo: null,
                                scoredResult: false,
                                outResult: false
                            }
                        }
                    })">
                    Stay
                </ion-button>
            </div>
            <div class="on-base-at-bat-container base-empty" v-if="!scorekeepStore.onSecondBase">
                2nd Empty
            </div>
        </div>
        <div class="on-base-container">
            <div class="at-bat-header  base-margin">On 1st</div>
            
            <div class="on-base-at-bat-container" v-if="scorekeepStore.onFirstBase">
                <div
                    class="fives-on-a-base" 
                    v-if="checkIfTheyAreMaxRunPerInning('first')">
                    {{ `${scorekeepStore.runsPerInning} is on 1st Blue!`}}
                </div>
                <div class="player-details on-base-player-name">
                    {{  scorekeepStore.onFirstBase.name }}
                </div>
                <div class="player-details">
                    <div class="player-pos">{{ scorekeepStore.onFirstBase.position }} - #{{ scorekeepStore.onFirstBase.number }} </div>
                </div>
                
                <ion-button 
                    size="small"
                    color="danger"
                    style="width: 100%;"
                    :fill="scorekeepStore.currentPlay.runners.first.outResult ? 'solid' : 'outline'"
                    @click="scorekeepStore.updateCurrentPlay({ 
                        runners: {
                            first: {
                                movesTo: null,
                                scoredResult: false,
                                outResult: true
                            }
                        }
                    })">
                    Out
                </ion-button>
                <ion-button 
                    size="small"
                    color="warning"
                    style="width: 100%;"
                    :fill="scorekeepStore.currentPlay.runners.first.movesTo === 'third' ? 'solid' : 'outline'"
                    @click="scorekeepStore.updateCurrentPlay({ 
                        runners: {
                            first: {
                                movesTo: 'third',
                                scoredResult: false,
                                outResult: false
                            }
                        }
                    })">
                    To 3rd
                </ion-button>
                <ion-button 
                    size="small"
                    color="secondary"
                    style="width: 100%;"
                    :fill="scorekeepStore.currentPlay.runners.first.movesTo === 'second' ? 'solid' : 'outline'"
                    @click="scorekeepStore.updateCurrentPlay({ 
                        runners: {
                            first: {
                                movesTo: 'second',
                                scoredResult: false,
                                outResult: false
                            }
                        }
                    })">
                    To 2nd
                </ion-button>
                <ion-button 
                    size="small"
                    color="success"
                    style="width: 100%;"
                    :fill="scorekeepStore.currentPlay.runners.first.scoredResult ? 'solid' : 'outline'"
                    @click="scorekeepStore.updateCurrentPlay({ 
                        runners: {
                            first: {
                                movesTo: 'home',
                                scoredResult: true,
                                outResult: false
                            }
                        }
                    })">
                    Scoring
                </ion-button>
                <ion-button 
                    size="small"
                    color="primary"
                    style="width: 100%;"
                    :fill="scorekeepStore.currentPlay.runners.first.scoredResult === false && scorekeepStore.currentPlay.runners.first.outResult === false && scorekeepStore.currentPlay.runners.first.movesTo === null ? 'solid' : 'outline'"
                    @click="scorekeepStore.updateCurrentPlay({ 
                        runners: {
                            first: {
                                movesTo: null,
                                scoredResult: false,
                                outResult: false
                            }
                        }
                    })">
                    Stay
                </ion-button>
            </div>
            <div class="on-base-at-bat-container base-empty" v-if="!scorekeepStore.onFirstBase">
                1st Empty
            </div>
        </div>
    </div>
</template>

<script setup>
// calculating who is at bat
import { IonButton } from '@ionic/vue';
import { useScorekeepStore } from "@/stores/scorekeepStore"
const scorekeepStore = useScorekeepStore()
console.log('scorekeepStore', scorekeepStore)
// if currentHalf = 0 then away is hitting
// if currentHalf = 1 then home is hitting

const checkIfTheyAreMaxRunPerInning = (base) => {
    console.log('base', base);
    if (isNaN(scorekeepStore.runsPerInning)) return false;

    // Check if the current inning matches the maximum innings count
    if (parseInt(scorekeepStore.currentInning) === parseInt(scorekeepStore.maxInningsCount)) {
        console.log('Current inning matches the maximum innings count. Skipping further checks.');
        return false;
    }
    
    let numberOfRunnersOn = 0;

    if (base === 'first') {
        if (scorekeepStore.onFirstBase) numberOfRunnersOn++;
        if (scorekeepStore.onSecondBase) numberOfRunnersOn++;
        if (scorekeepStore.onThirdBase) numberOfRunnersOn++;
    }
    if (base === 'second') {
        if (scorekeepStore.onSecondBase) numberOfRunnersOn++;
        if (scorekeepStore.onThirdBase) numberOfRunnersOn++;
    }
    if (base === 'third') {
        if (scorekeepStore.onThirdBase) numberOfRunnersOn++;
    }

    let possibleRuns = 0;
    let inningIndex = scorekeepStore.currentInning - 1; // Adjust for zero-based index if necessary
    console.log('scorekeepStore.awayInningScores', scorekeepStore.awayInningScores)
    // Check if the inning index is within the bounds of the array
    if (inningIndex >= 0 && inningIndex < scorekeepStore.awayInningScores.length) {
        if (scorekeepStore.currentHalf === 0) {
            possibleRuns = scorekeepStore.awayInningScores[inningIndex] + numberOfRunnersOn;
        } else {
            possibleRuns = scorekeepStore.homeInningScores[inningIndex] + numberOfRunnersOn;
        }
    } else {
        console.error('Inning index is out of bounds:', inningIndex);
        return false;
    }

    return possibleRuns === parseInt(scorekeepStore.runsPerInning);
}

</script>

<style>
    .on-base-container-row {
        display: flex;
        flex-direction: row; /* Ensures horizontal layout */
        align-items: stretch; /* Aligns children's height to be the same */
        justify-content: space-evenly; /* Ensures equal spacing */
        width: 100%; /* Ensures it spans the full width */
        min-height: 200px;
    }

    .on-base-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1; /* Each container will take equal width */
        /* border-bottom: 1px solid #555; */
        height: 100%; /* Ensures each container takes full available height */
    }

    .at-bat-header {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        border-top: 1px solid #555;
        border-bottom: 1px solid #555;
        width: 100%;
        text-align: center;
    }

        
    .on-base-at-bat-container {
        display: flex;
        flex-direction: column;
        align-items: center; /* Centers content horizontally */
        width: 100%;
        padding: 10px 20px;
    }

    .choose-result-area {
        background: rgba(0, 0, 0, 0.3);
        margin-top: 10px;
        padding: 5px;
    }

    .player-details {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .on-base-player-name {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 5px;
    }

    .player-pos {
        font-size: 12px;
        font-weight: 700;
        border-radius: 5px;
        color: #eee;
        
        padding: 5px;
        background: rgba(200,200,200,0.1)
    }

    .choose-result {
        font-size: 10px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
    }

    .player-res {
        font-size: 12px;
        color: #bbb;
        font-weight: 700;
        border-radius: 5px;
        font-style: italic;
        padding: 5px;
        margin-left: 20px;
        background: rgba(200,200,200,0.1)
    }

    .at-bat-selectors {
        display: flex;
        justify-content: center;
        /* padding-top: 10px; */
    }

    .base-empty {
        font-weight: 700;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 200px;
        color: #666;
    }

    .fives-on-a-base {
        padding: 5px;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        text-align: center;
        color: #000;
        background: #e27523;
    }

</style>