<template>
    <span>
        <div
            class="game-card-footer"
            :style="[
                {
                    backgroundColor: uniqolor(props.game.division_id, {
                    lightness: [0, 20],
                    }).color,
                },
            ]">
            <div class="footer-content"> <!-- Added a wrapper for flexbox -->
                <div class="game-data">
                    <b  v-if="leagueStore.league.hide_game_mode !== 1">{{ modeLabel }}</b>
                </div>
                
                <div class="game-data" style="text-align: center;">
                    {{ props.game.division_id }}
                </div>
                <div 
                    class="game-num">
                     <b>#{{ props.game.event_specific_game_id }}</b>
                </div>
            </div>
        </div>
    </span>
</template>

<script setup>
import { defineProps, computed } from "vue";
import uniqolor from "uniqolor";
import { useLeagueStore } from "@/stores/leagueStore";

const leagueStore = useLeagueStore();
const props = defineProps(["game"]);

const gameTypes = computed(() => {
  if (leagueStore.league.type === "tournament") {
    return [
      { label: "Pre-Seed", value: "Practice" },
      { label: "Round Robin", value: "Season" },
      { label: "Eliminations", value: "Playoffs" },
    ];
  } else {
    return [
      { label: "Practice", value: "Practice" },
      { label: "Season", value: "Season" },
      { label: "Playoffs", value: "Playoffs" },
    ];
  }
});

const modeLabel = computed(() => {
  const mode = props.game.mode;
  const type = gameTypes.value.find(type => type.value === mode);
  return type ? type.label : 'Unknown Mode';
});

</script>

<style scoped>
.footer-content {
    display: flex;
    padding: 0.2rem;
    justify-content: space-between; /* Aligns items on the main-axis */
    align-items: center; /* Aligns items on the cross-axis */
    width: 100%; /* Ensures the footer content uses the full width */
}


/* Added styles for .game-data to ensure consistency */
.game-data {
    
    padding: 0 5px;
    text-align: left;
    color: #ccc;
    flex-grow: 1; 
    text-transform: uppercase;
    font-size: 12px;
}


.game-num {
    padding: 0 5px;
    text-align: right;
    color: #ccc;
    font-size: 12px;
    flex-grow: 1; 
}

.game-light {
    color: #999;
}
</style>