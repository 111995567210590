import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { format, parseISO } from "date-fns";
import QRCodeStyling from "qr-code-styling";

const columns = [
  { header: "#", dataKey: "gameNumber" },
  { header: "Start Date", dataKey: "startDate" },
  { header: "Time", dataKey: "time" },
  { header: "Park Name", dataKey: "parkName" },
  { header: "Diamond", dataKey: "diamond" },
  { header: "Division", dataKey: "division" },
  { header: "Away Team", dataKey: "awayTeam" },
  { header: "Score", dataKey: "awayScore" },
  { header: "Score", dataKey: "homeScore" },
  { header: "Home Team", dataKey: "homeTeam" },
];

const columnStyles = {
  gameNumber: { cellWidth: "1%" },
  startDate: { cellWidth: "9%" },
  time: { cellWidth: "5%" },
  parkName: { cellWidth: "10%" },
  diamond: { cellWidth: "5%" },
  division: { cellWidth: "10%" },
  awayTeam: { cellWidth: "28%", halign: "left" },
  awayScore: { cellWidth: "2%", halign: "right" },
  homeScore: { cellWidth: "2%", halign: "left" },
  homeTeam: { cellWidth: "28%", halign: "right" },
};

function transformGames(gamesArray) {
  return gamesArray.map((game) => {
    const startTimeFormatted = format(
      parseISO(`${game.start_date}T${game.start_time}`),
      "hh:mm aa"
    );
    const startDateFormatted = format(parseISO(game.start_date), "MM/dd - EEE");

    const awayTeamName = game.awayTeam
      ? game.awayTeam.name
      : game.away_team_freetext_formatted_game_num
      ? formatTeamName(game.away_team_freetext_formatted_game_num)
      : game.away_team_freetext_formatted
      ? formatTeamName(game.away_team_freetext_formatted)
      : "Unknown Away Team";

    const homeTeamName = game.homeTeam
      ? game.homeTeam.name
      : game.home_team_freetext_formatted_game_num
      ? formatTeamName(game.home_team_freetext_formatted_game_num)
      : game.home_team_freetext_formatted
      ? formatTeamName(game.home_team_freetext_formatted)
      : "Unknown Home Team";
    return {
      gameNumber: game.event_specific_game_id,
      startDate: startDateFormatted,
      time: startTimeFormatted,
      parkName: game.park_name,
      diamond: game.diamond_signifier,
      division: game.division_id,
      awayTeam: awayTeamName,
      awayScore: game.away_team_score,
      homeScore: game.home_team_score,
      homeTeam: homeTeamName,
    };
  });
}

export default async function generatePDF(games_data, event_data) {
  const doc = new jsPDF();
  if (event_data.name.length > 50) {
    doc.setFontSize(12);
  } else {
    doc.setFontSize(14);
  }
  // doc.addImage(QRCode, 180, 0, 20, 20);
  doc.text(`${event_data.name}`, 45, 18);

  //add the event logo

  const logoData = await getImageData(
    event_data.logo_url ? event_data.logo_url : "/assets/img/slo-pitch logo.png"
  );

  //scaling the image
  const aspectRatio = logoData.width / logoData.height;
  console.log("aspectratio", aspectRatio);
  if (aspectRatio > 1.25) {
    // The logo is wider than it is tall (landscape)
    doc.addImage(logoData.dataURL, 14, 10, 25, 15);
  } else if (aspectRatio < 0.25) {
    // The logo is taller than it is wide (portrait)
    doc.addImage(logoData.dataURL, 14, 10, 15, 25);
  } else {
    // The logo is assumed square
    console.log("assume square");
    doc.addImage(logoData.dataURL, 14, 5, 20, 20);
  }

  //generate QRCODE

  const QRCode = await createQRCode(
    `https://slopitchcentral.com/${event_data.type}/${event_data.slug}`,
    "/assets/img/slo-pitch logo.png"
  );
  doc.addImage(QRCode, 180, 5, 20, 20);

  //footer will appear on every page

  const footer = function () {
    doc.setFontSize(8);
    doc.text(
      `Go to slopitchcentral.com and search shortcode ${event_data.shortcode}`,
      12,
      295
    );
  };

  //generate table

  doc.autoTable(columns, transformGames(games_data), {
    startY: 30,
    margin: { horizontal: 10 },
    styles: { overflow: "linebreak", fontSize: 8 },
    bodyStyles: { valign: "top", halign: "center" },
    headStyles: {
      halign: "center",
      fontSize: 9,
      fillColor: "#7d38f0",
    },
    columnStyles: columnStyles,
    showHead: "everyPage",
    didDrawPage: function (data) {
      footer(data);
    },
    didDrawCell: function (data) {
      if (data.column.dataKey === "homeScore" && data.cell.section === "body") {
        const doc = data.doc;
        const cell = data.cell;
        doc.setDrawColor("#989aa2"); // Set the line color, black in this example
        doc.setLineWidth(0.5); // Set the line width
        // Draw a line at the left edge of the 'homeTeam' cell
        doc.line(cell.x, cell.y, cell.x, cell.y + cell.height);
      }
    },
  });

  doc.save(`${event_data.name} - schedule.pdf`);
}

function formatTeamName(teamName) {
  if (!teamName) return "Unknown Team"; // Fallback for missing data

  // Normalize the case for easier matching
  const lowerTeamName = teamName.toLowerCase();

  // Handling "Winner of Game X" and "Loser of Game X"
  if (lowerTeamName.includes("winner of game")) {
    const gameNumber = lowerTeamName.match(/game (\d+)/i)[1];
    return `W G#${gameNumber}`;
  } else if (lowerTeamName.includes("loser of game")) {
    const gameNumber = lowerTeamName.match(/game (\d+)/i)[1];
    return `L G#${gameNumber}`;
  }

  // Handling "Placed Xth in Y"
  if (lowerTeamName.includes("placed") && lowerTeamName.includes("in")) {
    return teamName.replace(/placed /i, ""); // Removes "placed " prefix
  }

  // Return the original name if none of the above conditions apply
  return teamName;
}

async function createQRCode(dataURL, logoURL) {
  const qrCode = new QRCodeStyling({
    width: 500,
    height: 500,
    type: "svg", // 'svg' is more flexible and easily embeddable in PDFs
    data: dataURL,
    image: logoURL,

    dotsOptions: {
      color: "#000000",
      type: "rounded",
    },
    cornersDotOptions: {
      color: "#7d38f0",
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 0,
    },
    backgroundOptions: {
      color: "#ffffff",
    },
  });

  return blobToDataURL(await qrCode.getRawData());
}

function blobToDataURL(blob) {
  console.log(blob);
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsDataURL(blob);
  });
}

async function getImageData(url) {
  const img = new Image();
  img.crossOrigin = "Anonymous";
  img.src = url;

  return new Promise((resolve, reject) => {
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL(getMimeType(url));
      resolve({ dataURL, width: img.naturalWidth, height: img.naturalHeight });
    };
    img.onerror = (error) => {
      reject(error);
    };
  });
}
function getMimeType(url) {
  const extension = url.split(".").pop().toLowerCase();
  switch (extension) {
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "gif":
      return "image/gif";
    case "bmp":
      return "image/bmp";
    case "svg":
      return "image/svg+xml";
    case "webp":
      return "image/webp";
    default:
      return "image/jpeg";
  }
}
