<template>
    <div>
        <div class="container">
            <div class="at-bat-header">Admin Options</div>
            
            <div class="at-bat-container">
               
                <ion-button color="tertiary" @click="showUndoAlert = true">
                    Undo Last Play
                </ion-button>
                <ion-button color="success" @click="showOverrideAlert = true">
                    Override Score
                </ion-button>
                <ion-button color="warning" @click="showEndInningAlert = true">
                    End Inning Early
                </ion-button>
                <ion-button color="danger" @click="showEndGameAlert = true">
                    End Game Early
                </ion-button>
                <br>
                <br>
                <ion-button color="danger" @click="showResetGameAlert = true">
                    Reset Game (Clear All Data)
                </ion-button>
            </div>
        </div>

        <ion-alert
            v-model:is-open="showUndoAlert"
            header="Confirm Action"
            message="Are you sure you want to undo the last play?"
            :buttons="[
                'Cancel',
                { text: 'Yes', handler: () => undoLastPlay() }
            ]"
        ></ion-alert>

        <ion-alert
            v-model:is-open="showOverrideAlert"
            header="Confirm Action"
            message="Are you sure you want to override the score?"
            :buttons="[
                'Cancel',
                { text: 'Yes', handler: () => overrideScore() }
            ]"
        ></ion-alert>

        <ion-alert
            v-model:is-open="showEndInningAlert"
            header="Confirm Action"
            message="Are you sure you want to end the inning early?"
            :buttons="[
                'Cancel',
                { text: 'Yes', handler: () => endInningEarly() }
            ]"
        ></ion-alert>

        <ion-alert
            v-model:is-open="showEndGameAlert"
            header="Confirm Action"
            message="Are you sure you want to end the game early?"
            :buttons="[
                'Cancel',
                { text: 'Yes', handler: () => endGameEarly() }
            ]"
        ></ion-alert>

        <ion-alert
            v-model:is-open="showResetGameAlert"
            header="Confirm Action"
            message="Are you sure you want to reset the game setup? You will lose all data."
            :buttons="[
                'Cancel',
                { text: 'Yes', handler: () => resetGameToStart() }
            ]"
        ></ion-alert>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { IonButton, IonAlert } from '@ionic/vue';
import { useScorekeepStore } from "@/stores/scorekeepStore";
import { showToast } from "@/utils/useIonicComponents.js";
const scorekeepStore = useScorekeepStore();
console.log('scorekeepStore', scorekeepStore);
const showUndoAlert = ref(false);
const showOverrideAlert = ref(false);
const showEndInningAlert = ref(false);
const showEndGameAlert = ref(false);
const showResetGameAlert = ref(false);

function undoLastPlay() {
    // Logic to undo last play
    console.log('Undoing last play');
    showUndoAlert.value = false;
    scorekeepStore.undoPlay();
    showToast(
        "Last play has been undone",
        "success",
        6000
    );
}

function overrideScore() {
    // Logic to override score
    console.log('Overriding score');
    showOverrideAlert.value = false;
}

function endInningEarly() {
    showEndInningAlert.value = false;
    scorekeepStore.flipHalfInning()
    showToast(
        "Inning ended early",
        "success",
        6000
    );
}

function endGameEarly() {
    // Logic to end game early
    console.log('Ending game early');
    showEndGameAlert.value = false;
    scorekeepStore.endGameEarly();
    showToast(
        "Game ended early",
        "success",
        6000
    );
}

function resetGameToStart() {
    // Logic to end game early
    console.log('Ending game early');
    showResetGameAlert.value = false;
    scorekeepStore.resetGameToStart();
    showToast(
        "Reset game to beginning",
        "success",
        6000
    );
}
</script>