<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="header-toolbar">
        <ion-buttons slot="start">
          <ion-menu-button color="dark"></ion-menu-button>
        </ion-buttons>
        <ion-title>Reset Password</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-card>
        <ion-card-header>
          <ion-card-title>Create New Password</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <form @submit.prevent="requestNewPassword">
            <ion-input
              placeholder="Password"
              type="password"
              v-model="user.password"
              @ionBlur="v$.password.$touch"
            ></ion-input>
            <ion-label color="danger" v-if="v$.password.$errors.length">{{
              v$.password.$errors[0].$message
            }}</ion-label>
            <ion-button
              style="margin-top: 1rem"
              expand="full"
              type="submit"
              v-if="!loading"
              >Reset Password</ion-button
            >
            <ion-button expand="full" v-if="loading"
              ><ion-spinner
            /></ion-button>
          </form>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>
<script setup>
import { defineComponent, ref, reactive } from "vue";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonButtons,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonButton,
  IonInput,
  IonSpinner,
  onIonViewWillEnter,
} from "@ionic/vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { showToast } from "@/utils/useIonicComponents.js";
import { useRoute, useRouter } from "vue-router";
import { api } from "@/utils/axios";

defineComponent({
  name: "ResetPassword",
});
const user = reactive({
  password: "",
});
const rules = {
  password: { required },
};
const v$ = useVuelidate(rules, user);

const loading = ref(false);
const route = useRoute();
const router = useRouter();

onIonViewWillEnter(() => {
  if (!route.query.u || !route.query.t) {
    router.replace("/");
  }
});

async function requestNewPassword() {
  const isFormValid = await v$.value.$validate();
  if (isFormValid) {
    loading.value = true;
    try {
      const result = await api.post("/auth/new-password", {
        email: route.query.u,
        token: route.query.t,
        password: user.password,
      });
      if (result.status === 200) {
        showToast("New Password Set!", "success");
        loading.value = false;
        router.replace("/");
      } else {
        throw "Something went wrong";
      }
    } catch (err) {
      loading.value = false;
      showToast(
        "Something went wrong. If this persists, please contact us.",
        "danger",
        6000
      );
    }
  }
}
</script>
<style scoped>
ion-card {
  width: 40%;
  margin: 2rem auto;
}
@media only screen and (max-width: 940px) {
  ion-card {
    width: 100%;
    margin: 2rem auto;
  }
}
</style>
