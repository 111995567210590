<template>
  <ion-content v-if="!selectDataToggle">
    <div style="display: flex; justify-content: center; align-items: center">
      <ion-card style="width: 80rem; text-align: center" color="light">
        <ion-card-header>
          <ion-card-title>Submit Scores in CSV</ion-card-title>
          <ion-card-subtitle
            >Select columns containing scores to report with games
            it</ion-card-subtitle
          >
        </ion-card-header>
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col size="12" size-md="4">
              <div>
                <ion-card-content>
                  <ion-button
                    fill="outline"
                    color="danger"
                    @click="
                      selectScoreType = 'Away Team Score';
                      selectDataToggle = !selectDataToggle;
                    "
                    >Select Away Team Score</ion-button
                  >
                </ion-card-content>

                <ion-card-content>
                  <ion-list>
                    <ion-item color="medium">
                      <ion-label style="text-align: center"
                        >Away Team Scores</ion-label
                      >
                    </ion-item>
                    <ion-item v-if="!scoreKeys['away_team_score']">
                      <ion-label style="text-align: center"
                        >Away Team Score not selected yet</ion-label
                      >
                    </ion-item>
                    <span v-else>
                      <ion-item v-for="score in getAwayScore" :key="score">
                        <ion-label style="text-align: center">{{
                          score
                        }}</ion-label>
                      </ion-item>
                      <ion-item
                        ><ion-label style="text-align: center"
                          >.....</ion-label
                        ></ion-item
                      >
                    </span>
                  </ion-list>
                </ion-card-content>
              </div>
            </ion-col>
            <ion-col size="12" size-md="4">
              <div>
                <ion-card-content>
                  <ion-button
                    fill="outline"
                    color="danger"
                    @click="
                      selectScoreType = 'Home Team Score';
                      selectDataToggle = !selectDataToggle;
                    "
                    >Select Home Team Score</ion-button
                  >
                </ion-card-content>

                <ion-card-content>
                  <ion-list>
                    <ion-item color="medium">
                      <ion-label style="text-align: center"
                        >Home Team Scores</ion-label
                      >
                    </ion-item>
                    <ion-item v-if="!scoreKeys['home_team_score']">
                      <ion-label style="text-align: center"
                        >Home Team Score not selected yet</ion-label
                      >
                    </ion-item>
                    <span v-else>
                      <ion-item v-for="score in getHomeScore" :key="score">
                        <ion-label style="text-align: center">{{
                          score
                        }}</ion-label>
                      </ion-item>
                      <ion-item
                        ><ion-label style="text-align: center"
                          >.....</ion-label
                        ></ion-item
                      >
                    </span>
                  </ion-list>
                </ion-card-content>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-button
          v-if="scoreKeys['away_team_score'] && scoreKeys['home_team_score']"
          expand="full"
          style="margin-top: 4rem"
          @click="navnext"
        >
          Next
        </ion-button>
        <ion-button
          v-else
          expand="full"
          style="margin-top: 4rem"
          color="medium"
          @click="navnext"
        >
          Skip
        </ion-button>
      </ion-card>
    </div>
  </ion-content>
  <select-data
    :column="selectScoreType"
    :columnAssociationKey="scoreKeys['away_team_score']"
    @data-selected="dataSelected"
    v-if="selectDataToggle && selectScoreType === 'Away Team Score'"
  />
  <select-data
    :column="selectScoreType"
    :columnAssociationKey="scoreKeys['home_team_score']"
    @data-selected="dataSelected"
    v-if="selectDataToggle && selectScoreType === 'Home Team Score'"
  />
</template>

<script setup>
import { defineComponent, ref, computed } from "vue";
import {
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";
import { showToast } from "@/utils/useIonicComponents.js";
import { useUploadCSVStore } from "@/stores/UploadCSVStore";
import AssociateTeams from "@/components/schedule/upload-csv/AssociateTeams.vue";
import SelectData from "./SelectData.vue";
import { uniq } from "lodash";

defineComponent({
  name: "SelectScores",
});
const uploadCSV = useUploadCSVStore();
const selectDataToggle = ref();
const selectScoreType = ref();
const scoreKeys = ref({
  away_team_score: uploadCSV.columnAssociation["away_team_score"]
    ? uploadCSV.columnAssociation["away_team_score"]
    : null,
  home_team_score: uploadCSV.columnAssociation["home_team_score"]
    ? uploadCSV.columnAssociation["home_team_score"]
    : null,
});

function areAllNumbers(array) {
  return array.every((item) => !isNaN(parseFloat(item)) && isFinite(item));
}

function dataSelected(ev) {
  if (
    areAllNumbers(uploadCSV.fileData.map((data) => data[ev])) ||
    ev === null
  ) {
    if (selectScoreType.value === "Away Team Score") {
      scoreKeys.value["away_team_score"] = ev;
    } else if (selectScoreType.value === "Home Team Score") {
      scoreKeys.value["home_team_score"] = ev;
    }
    selectDataToggle.value = !selectDataToggle.value;
  } else {
    showToast("The column should only contain numbers", "danger");
  }
}

const getAwayScore = computed(() => {
  if (scoreKeys.value["away_team_score"]) {
    return uniq(
      uploadCSV.fileData.map((item) => item[scoreKeys.value["away_team_score"]])
    ).slice(0, 8);
  } else {
    return [];
  }
});
const getHomeScore = computed(() => {
  if (scoreKeys.value["home_team_score"]) {
    return uniq(
      uploadCSV.fileData.map((item) => item[scoreKeys.value["home_team_score"]])
    ).slice(0, 8);
  } else {
    return [];
  }
});

function navnext() {
  if (
    scoreKeys.value["away_team_score"] &&
    scoreKeys.value["home_team_score"]
  ) {
    uploadCSV.columnAssociation["away_team_score"] =
      scoreKeys.value["away_team_score"];
    uploadCSV.columnAssociation["home_team_score"] =
      scoreKeys.value["home_team_score"];
  }
  const ionNav = document.querySelector("ion-nav");
  ionNav.push(AssociateTeams);
}
</script>
