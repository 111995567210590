import { defineStore } from "pinia";
import { openModal } from "@/utils/useIonicComponents";

import AddGameModal from "@/components/schedule/new-game/AddGameModal.vue";
import UploadScheduleModal from "@/components/schedule/upload-csv/UploadScheduleModal.vue";
import NewAnnouncementModal from "@/components/announcements/NewAnnouncementModal.vue";
import FilterModal from "@/components/schedule/filters/FilterModal.vue";
import LeagueSwitchModal from "@/components/LeagueSwitchModal.vue";
import NewTeamModal from "@/components/teams/NewTeamModal.vue";
import AppearanceModal from "@/components/info/AppearanceModal.vue";
import RulesModal from "@/components/info/RulesModal.vue";
import InfoModal from "@/components/info/InfoModal.vue";
import EditTeamsModal from "@/components/teams/EditTeamsModal.vue";
import EditStandingsModal from "@/components/standings/EditStandingsModal.vue";
import SelectedGameModal from "@/components/schedule/select-game/SelectedGameModal.vue";
import EditLocationsModal from "@/components/location/EditLocationsModal.vue";
import TeamsImportModal from "@/components/teams/import/TeamsImportModal.vue";
import PermissionModal from "@/components/info/PermissionModal.vue";
import AddBracketModal from "@/components/brackets/AddBracketModal.vue";
import RegistrationModal from "@/components/info/RegistrationModal.vue";
import PricesModal from "@/components/info/PricesModal.vue";

import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/authStore";

export const useUIStateStore = defineStore("UIState", () => {
  const authStore = useAuthStore();
  async function checkOpenModals() {
    const route = useRoute();
    const modal = route.query?.open_modal;

    if (route.params.leagueSlug) {
      if (modal === "SwitchLeague") {
        await openModal(LeagueSwitchModal, "LeagueSwitchModal");
      }
      if (modal === "Filters") {
        await openModal(FilterModal, "filterModal");
      }
      if (authStore.isConvenor) {
        if (modal === "UploadCSV") {
          await openModal(UploadScheduleModal, "uploadScheduleModal");
        }
        if (modal === "TeamsImport") {
          await openModal(TeamsImportModal, "teamsImportModal");
        }
        if (modal === "AddBracket") {
          await openModal(AddBracketModal, "addBracketModal");
        }
        if (modal === "NewGames") {
          await openModal(AddGameModal, "addGameModal");
        }
        if (modal === 'Registration') {
          await openModal(RegistrationModal, 'registrationModal');
        }
        if (modal === "NewAnnouncements") {
          await openModal(NewAnnouncementModal, "newAnnouncementModal");
        }
        if (modal === "NewTeam") {
          await openModal(NewTeamModal, "newTeamModal");
        }
        if (route.name === "teams") {
          if (modal === "EditTeam") {
            const editTeamsModalData =
              JSON.parse(localStorage.getItem("editTeamsModalData")) || {};
            if (editTeamsModalData?.leagueSlug === route.params.leagueSlug) {
              await openModal(EditTeamsModal, "editTeamsModal", {
                team: editTeamsModalData.team,
                division: editTeamsModalData.division,
              });
            }
          }
        }
        if (route.name === "standings") {
          if (modal === "EditStanding") {
            const editStandingsModalData =
              JSON.parse(localStorage.getItem("editStandingsModalData")) || {};
            if (
              editStandingsModalData?.leagueSlug === route.params.leagueSlug
            ) {
              await openModal(EditStandingsModal, "editStandingsModal", {
                team: editStandingsModalData.team,
              });
            }
          }
        }
        if (route.name === "schedule") {
          if (modal === "SelectedGames") {
            const selectedGamesModalData =
              JSON.parse(localStorage.getItem("selectedGamesModalData")) || {};
            if (
              selectedGamesModalData?.leagueSlug === route.params.leagueSlug
            ) {
              await openModal(SelectedGameModal, "selectModal", {
                choice: selectedGamesModalData.choice,
              });
            }
          }
        }
        if (modal === "EditInfo") {
          await openModal(InfoModal, "infoModal");
        }
        if (modal === "EditRules") {
          await openModal(RulesModal, "rulesModal");
        }
        if (modal === "EditAppearance") {
          await openModal(AppearanceModal, "appearanceModal");
        }
        if (modal === "EditLocations") {
          await openModal(EditLocationsModal, "editLocationsModal");
        }
        if (modal === "EditPermissions") {
          await openModal(PermissionModal, "permissionModal");
        }
        if (modal === "Prices") {
          await openModal(PricesModal, "pricesModal");
        }
      }
    }
  }
  return { checkOpenModals };
});
