<template>
  <ion-content v-if="interdivisionTeamWithoutDivision.length === 0">
    <div style="display: flex; justify-content: center; align-items: center">
      <ion-card style="width: 60rem; text-align: center" color="light">
        <div>
          <ion-card-header>
            <ion-card-title
              >{{ selectedTeams.length }} New Teams Found in CSV</ion-card-title
            >
            <ion-card-subtitle
              >These new teams will be created before adding the
              games</ion-card-subtitle
            >
          </ion-card-header>

          <ion-card-content>
            <ion-list>
              <ion-item color="medium">
                <ion-label style="text-align: center">Team</ion-label>
                <ion-label style="text-align: center">Division</ion-label>
                <ion-label style="text-align: center" slot="end"
                  >Playoff</ion-label
                >
              </ion-item>
              <ion-item v-for="team in newTeams" :key="team">
                <ion-label style="text-align: center">{{
                  team.name
                }}</ion-label>
                <ion-label style="text-align: center">{{
                  team.division
                }}</ion-label>
                <ion-checkbox
                  slot="end"
                  :checked="!selectedTeams.includes(team)"
                ></ion-checkbox>
              </ion-item>
            </ion-list>
          </ion-card-content>
          <ion-button expand="full" @click="saveTeamRequest">
            Confirm
          </ion-button>
        </div>
      </ion-card>
    </div>
  </ion-content>
  <ion-content v-else>
    <div style="display: flex; justify-content: center; align-items: center">
      <ion-card style="width: 60rem; text-align: center" color="light">
        <div>
          <ion-card-header>
            <ion-card-title
              >{{ interdivisionTeamWithoutDivision.length }} Team{{
                interdivisionTeamWithoutDivision.length > 1 ? "s" : ""
              }}
              found without Division</ion-card-title
            >
            <ion-card-subtitle
              >Please add a division to these interdivisional play
              teams</ion-card-subtitle
            >
          </ion-card-header>

          <ion-card-content>
            <ion-grid>
              <ion-row style="background-color: var(--ion-color-medium)">
                <ion-col class="ion-align-self-center label-left-col"
                  ><ion-label>Team</ion-label></ion-col
                >
                <ion-col><ion-label>Division</ion-label></ion-col>
              </ion-row>
              <ion-row
                style="margin: 1rem 0"
                v-for="team in interdivisionTeamWithoutDivision"
                :key="team.name"
              >
                <ion-col class="ion-align-self-center label-left-col"
                  ><ion-label>{{ team.name }}</ion-label></ion-col
                >
                <ion-col
                  ><ion-input
                    placeholder="Division Name"
                    v-model="team.division"
                    style="
                      border-bottom: 3px solid var(--ion-color-medium);
                      text-align: center;
                    "
                  ></ion-input
                ></ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
          <ion-button expand="full" @click="addTeamsWithoutDivision"
            >Confirm</ion-button
          >
        </div>
      </ion-card>
    </div>
  </ion-content>
</template>

<script setup>
import { defineComponent, watchEffect, ref } from "vue";
import {
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonCheckbox,
  modalController,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";
import {
  showToast,
  showLoading,
  openModal,
} from "@/utils/useIonicComponents.js";
import { api } from "@/utils/axios";
import { useUploadCSVStore } from "@/stores/UploadCSVStore";
import { useLeagueStore } from "@/stores/leagueStore";
import AddGameModal from "@/components/schedule/new-game/AddGameModal.vue";

defineComponent({
  name: "AssociateTeams",
});
const selectedTeams = ref([]); // these are the teams which are not marked as playoffs
const interdivisionTeamWithoutDivision = ref([]);
const uploadCSV = useUploadCSVStore();
const leagueStore = useLeagueStore();

const newTeams = ref(findNewTeams());

function findNewTeams() {
  let teamsNotFound = [];
  for (const team of uploadCSV.getTeams) {
    const foundTeam = leagueStore.league.teams.filter(
      (t) => t.name === team.name || t.division_name === team.division
    );
    const findDuplicate = teamsNotFound.find(
      (t) => t.name === team.name && t.division === team.division
    );
    if (
      !foundTeam.length &&
      !findDuplicate &&
      team.division.toLowerCase() !== "interdivision"
    ) {
      teamsNotFound.push(team);
    }
  }

  return teamsNotFound;
}

playoffCheck();
// console.log(selectedTeams.value);

function playoffCheck() {
  // if these are found in team.name then don't add them to selected games
  const checkFor = ["Placed", "Winner", "Loser", "Seed"];
  selectedTeams.value = newTeams.value.filter((team) => {
    for (const check of checkFor) {
      if (team.name.search(check) > -1) {
        return false;
      }
    }
    return true;
  });
}

interdivisionCheck();

function interdivisionCheck() {
  const interdivisionTeams = uploadCSV.getTeams.filter(
    (team) => team.division.toLowerCase() === "interdivision"
  );
  interdivisionTeams.forEach((team) => {
    const checkIfInterdivisionTeamHasDivision = uploadCSV.getTeams.find(
      (csvTeam) => {
        if (
          csvTeam.name === team.name &&
          csvTeam.division.toLowerCase() !== "interdivision"
        ) {
          return true;
        }
        return false;
      }
    );
    if (!checkIfInterdivisionTeamHasDivision) {
      const checkIfTeamExistsInLeague = leagueStore.league.teams.find(
        (t) => t.name === team.name
      );
      if (!checkIfTeamExistsInLeague) {
        interdivisionTeamWithoutDivision.value.push({
          name: team.name,
          division: "",
        });
      }
    }
  });
  // console.log(interdivisionTeamWithoutDivision.value);
}

function addTeamsWithoutDivision() {
  if (
    interdivisionTeamWithoutDivision.value.filter((team) => !team.division)
      .length === 0
  ) {
    newTeams.value = [
      ...newTeams.value,
      ...interdivisionTeamWithoutDivision.value,
    ];
    selectedTeams.value = [
      ...selectedTeams.value,
      ...interdivisionTeamWithoutDivision.value,
    ];
    interdivisionTeamWithoutDivision.value = [];
    // console.log(selectedTeams.value);
  }
}

async function saveTeamRequest() {
  const loading = await showLoading("Saving Teams. Please Wait...");
  const result = await api.post("/api/import-teams", {
    event: leagueStore.league.id,
    teams: selectedTeams.value,
  });
  loading.dismiss();

  if (result.data.type === "success") {
    await leagueStore.reloadEverything(leagueStore.league.slug);
    newTeams.value = findNewTeams();
    showToast("Teams successfully Added.", "success", 6000);
  } else {
    showToast(
      "Something went wrong. If this persists, please contact us.",
      "danger",
      6000
    );
  }
}

watchEffect(async () => {
  // when all teams in the csv are present in system
  try {
    if (newTeams.value.length === 0) {
      showToast("Adding Games Please Wait", "warning", 2000);
      await showLoading("Adding Games Please Wait....");
      uploadCSV.sendGamesToAddNewGames();
      await modalController.dismiss();
      await openModal(AddGameModal, "addGameModal");
    }
  } catch (err) {
    console.log(err);
  }
});
</script>
