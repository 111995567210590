<template>
  <ion-list>
    <!-- <h1 style="color: var(--ion-color-dark); text-align: center; text-transform: uppercase;">
      <span
          style="background-color: var(--ion-color-primary); padding: 0 10px"
      >
          
      </span>
    </h1> -->
    <div class="header-container"  v-if="leagueStore.league.rules || authStore.isConvenor" >
        <h1 style="color: var(--ion-color-dark); text-align: center; text-transform: uppercase;">
          <span
              style="background-color: var(--ion-color-primary); padding: 0 10px"
          >
            {{ leagueStore.league.type }} Details
          </span>
        </h1>
        <div class="header-plus-button" @click="openInfoModal" v-if="authStore.isConvenor">
            <ion-icon :icon="pencilOutline"></ion-icon>
        </div>
    </div>
    <!-- <ion-list-header><ion-label>LEAGUE DETAILS</ion-label></ion-list-header> -->
    <ion-item
      ><ion-label>{{ leagueStore.league.type.charAt(0).toUpperCase() + leagueStore.league.type.slice(1) }} Name</ion-label>
      <ion-label style="text-align: right;">{{ leagueStore.league.name }}</ion-label></ion-item
    >
    <ion-item
      ><ion-label>
        <h3>Remember this Shortcode</h3>
        <p>You can use this to find the league from anywhere inside Slo-Pitch Central.</p>
      </ion-label>
      <ion-label style="text-align: right;">{{ leagueStore.league.shortcode }}</ion-label></ion-item
    >
    <ion-item
      ><ion-label>Points for Win</ion-label>
      <ion-label style="text-align: right;">{{ leagueStore.league.points_for_win }}</ion-label></ion-item
    >
    <ion-item
      ><ion-label>Points for Loss</ion-label>
      <ion-label style="text-align: right;">{{ leagueStore.league.points_for_loss }}</ion-label></ion-item
    >
    <ion-item
      ><ion-label>Points for Tie</ion-label>
      <ion-label style="text-align: right;">{{ leagueStore.league.points_for_tie }}</ion-label></ion-item
    >
    <ion-item v-if="authStore.isConvenor"
      ><ion-label>Show Away / Home</ion-label>
      <ion-label style="text-align: right;">{{
        leagueStore.league.show_home_away === 1 ? "Yes" : "No"
      }}</ion-label></ion-item
    >
  </ion-list>
</template>
<script setup>
import { defineComponent, ref, onBeforeUnmount } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { IonList, IonItem, IonLabel, IonIcon } from "@ionic/vue";
import { debounce } from "lodash";
import InfoModal from "@/components/info/InfoModal.vue";
import { pencilOutline } from "ionicons/icons";
import { useLeagueStore } from "@/stores/leagueStore";
import {
  openModal,
} from "@/utils/useIonicComponents.js";
const authStore = useAuthStore();
defineComponent({
  name: "InfoComponent",
});
const leagueStore = useLeagueStore();
const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);
// const authStore = useAuthStore();

window.addEventListener("resize", debounce(resizeHandler, 1000));

function resizeHandler() {
  if (window.innerWidth >= 960) {
    showDesktopMode.value = true;
  } else {
    showDesktopMode.value = false;
  }
}
async function openInfoModal() {
  await openModal(InfoModal, "infoModal");
}
onBeforeUnmount(() => {
  window.removeEventListener("resize", resizeHandler);
});
</script>

<style scoped>
  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .header-plus-button {
    margin-left: 8px; /* Adjust as needed for spacing */
    margin-top: 10px;
    width: 39px;
    height: 39px;
    line-height: 41px;
    background: #333;
  }

  .header-plus-button:hover {
      background: #444;
      transition: 0.5s;
      cursor: pointer;
  }
</style>