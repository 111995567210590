<template>
  
    <ion-title 
      @click="OpenLeagueSwitch"  
      class="ion-title" 
    >
      <div class="title-hover">
        {{ leagueStore.leagueName.toUpperCase()
        }}<ion-icon
          :icon="caretDownOutline"
          style="
            font-size: 1rem;
            margin-left: 0.5rem;
            color: var(--ion-color-dark);
          "
        ></ion-icon>
      </div>
      
    </ion-title>
  
</template>
<script setup>
import { defineComponent } from "vue";
import { IonTitle, IonIcon } from "@ionic/vue";
import { caretDownOutline } from "ionicons/icons";
import { useLeagueStore } from "@/stores/leagueStore";
import { openModal } from "@/utils/useIonicComponents.js";
import LeagueSwitchModal from "@/components/LeagueSwitchModal.vue";
import { useRouter, useRoute } from "vue-router";

defineComponent({
  name: "TabsPage",
});
const leagueStore = useLeagueStore();
const router = useRouter();
const route = useRoute();

async function OpenLeagueSwitch() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "SwitchLeague",
    },
  });
  setTimeout(() => {
    openModal(LeagueSwitchModal, "LeagueSwitchModal");
  }, 1);
}
</script>

<style scoped>
.ion-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 600;
  color: var(--ion-color-dark);
  cursor: pointer;
}

.title-hover:hover {
  background: rgba(0, 0, 0, 0.2);
  display: inline-block;
  padding: 6px 15px;
}
</style>