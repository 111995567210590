<template>
  <page-wrapper>
    <div
      style="
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 15%;
        background-position: center;
        background-size: cover;
      "
      :style="{
        'background-image': `url('${leagueStore.leagueBackground}')`,
      }"
    ></div>
    <div style="display: flex; margin-top: 4rem; justify-content: center">
      <div
        :style="[
          {
            width: showDesktopMode ? '60%' : '90%',
            'flex-grow': authStore.isConvenor && showDesktopMode ? 8 : 'unset',
            margin:
              authStore.isConvenor && showDesktopMode ? '0 2rem 0 1rem' : '',
          },
        ]"
      >
        
        
      </div>
    </div>
    <div style="width: 100%; height: 280px; margin-top: 5rem">
      <div id="infoPage"></div>
    </div>
    <!-- <info-fab
      v-if="authStore.isConvenor && !showDesktopMode"
      @rulesUpdated="refreshKey++"
    /> -->
  </page-wrapper>
</template>

<script setup>
import { defineComponent, ref, onBeforeUnmount } from "vue";
import PageWrapper from "@/components/layouts/PageWrapper.vue";

// import AdminActions from "@/components/info/AdminActions.vue";
// import RulesComponent from "@/components/info/RulesComponent.vue";
// import InfoFab from "@/components/info/InfoFab.vue";
import { useLeagueStore } from "@/stores/leagueStore";
import { useAuthStore } from "@/stores/authStore";
import { debounce } from "lodash";
// import NewAdComponent from "@/components/ads/NewAdComponent.vue";

defineComponent({
  name: "InfoPage",
});
// const refreshKey = ref(0);
const leagueStore = useLeagueStore();
const authStore = useAuthStore();

const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);

function resizeHandler() {
  if (window.innerWidth >= 960) {
    showDesktopMode.value = true;
  } else {
    showDesktopMode.value = false;
  }
}

window.addEventListener("resize", debounce(resizeHandler, 1000));
onBeforeUnmount(() => {
  window.removeEventListener("resize", resizeHandler);
});
</script>
