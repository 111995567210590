<template>
  <base-modal modal-title="Login">
    <card-wrapper
      style="margin: 2rem auto"
      cardTitle="Login"
      cardSubtitle="Login to edit your leagues"
    >
      <div>
        <login-component @loggedIn="close" />
        <div style="margin-top: 1rem; display: flex; justify-content: center">
          <a href="#" @click.prevent="openForgotPasswordModal">
            <ion-label>Forgot Password ?</ion-label>
          </a>
        </div>
      </div>
    </card-wrapper>
  </base-modal>
</template>

<script setup>
import { modalController, IonLabel } from "@ionic/vue";
import { defineComponent } from "vue";
import LoginComponent from "./LoginComponent.vue";
import CardWrapper from "@/components/layouts/CardWrapper.vue";
import BaseModal from "@/components/layouts/BaseModal.vue";
import ForgotPasswordModal from "@/components/forgotPassword/ForgotPasswordModal.vue";
import { openModal } from "@/utils/useIonicComponents.js";

defineComponent({
  name: "LoginModal",
});

async function close() {
  await modalController.dismiss();
}

function openForgotPasswordModal() {
  openModal(ForgotPasswordModal, "forgotPasswordModal");
}
</script>
