<template>
    <div>
        <ion-card>
            <ion-card-content>
                <ion-row>
                    <ion-col size-sm="4" size="12">
                        <img
                            src="https://cdn.blacktiecollab.com/slopitchcentral/images/slopitchcentral.png"
                            style="max-width: 300px; width: 70%"
                            alt="Slo-Pitch Central Logo"
                        />
                    </ion-col>
                    <ion-col size-sm="4" size="12" class="ion-text-left">
                        <p><b>DIRECTORY</b></p>
                        <a
                            v-for="(link, key) in links"
                            :key="key"
                            class="footer-link"
                            :href="link.path">
                            {{ link.name}}
                        </a>
                    </ion-col>
                    <ion-col size-sm="4" size="12">
                        <p><b>FREE TOOLS</b></p>
                        <a
                            v-for="(link, key) in links2"
                            :key="key"
                            class="footer-link"
                            :href="link.path">
                            {{ link.name}}
                        </a>
                    </ion-col>
                </ion-row>
            </ion-card-content>
        </ion-card>
    </div>
</template>

<script setup>
import { defineComponent } from 'vue'

import { IonRow, IonCardContent, IonCol } from '@ionic/vue'

import {
    IonCard
} from '@ionic/vue'

const links = [
    {
        name: 'Home',
        path: 'https://slopitchcentral.com/'
    },
    {
        name: 'Major Events',
        path: 'https://slopitchcentral.com/major-events'
    },
    {
        name: 'Event Directory',
        path: 'https://slopitchcentral.com/event-directory'
    },
    {
        name: 'Blogs',
        path: 'https://slopitchcentral.com/posts'
    },
    {
        name: 'Contact',
        path: 'https://slopitchcentral.com/contact-us'
    }
]

const links2 = [
    {
        name: 'Ballpark Directory',
        path: 'https://slopitchcentral.com/ballparks'
    },
    {
        name: 'League/Tournament Budget Generator',
        path: 'https://slopitchcentral.com/budget-generator'
    },
    {
        name: 'Round Robin Generator',
        path: 'https://slopitchcentral.com/round-robin-brackets'
    },
    {
        name: 'Team Gear Canada',
        path: 'https://teamgear.ca'
    },
    {
        name: 'Slo-Pitch National',
        path: 'https://slo-pitch.com'
    },
    {
        name: 'Play Slopitch',
        path: 'https://playslopitch.com'
    },
    {
        name: 'Blacktie Collab - Web Development without Borders',
        path: 'https://blacktiecollab.com'
    }
    // {
    //     name: 'Team Directory',
    //     path: '/team-directory'
    // },
    // {
    //     name: 'Tournament Directory',
    //     path: '/tournament-directory'
    // },
    // {
    //     name: 'League Directory',
    //     path: '/league-directory'
    // }
]

defineComponent({
    name: 'FooterComponent'
})
</script>

<style scoped>
.footer-link {
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 5px 0;
}

.footer-link:hover {
    text-decoration: underline;
}
</style>