<template>
  <v-select
    style="width: 100%;"
    placeholder="Select Team"
    :options="[
      { label: 'All Teams', value: null },
      ...leagueStore.league.teams.map((team) => ({
        label: team.name,
        color: team.color,
        value: team.id,
      })),
    ]"
    :clearable="false"
    append-to-body
    :calculate-position="withPopper"
    :modelValue="gameStore.gameFilters.team"
    :reduce="(team) => team.value"
    @option:selected="teamFilterSelected"
    @option:deselected="teamFilterDeselected"
    multiple
    :key="teamFilterKey"
  >
    <template #open-indicator="{ attributes }">
      <ion-icon
        :icon="caretDownOutline"
        style="font-size: 0.8rem"
        v-bind="attributes"
      ></ion-icon>
    </template>
    <template v-slot:option="option">
      <div
        :style="`margin-left: -10px; border-left: 22px solid ${option.color}; padding-left: 10px;`"
      >
        {{ option.label }}
      </div>
    </template>
  </v-select>
</template>
<script setup>
import { defineComponent, ref } from "vue";
import { IonIcon } from "@ionic/vue";
import { caretDownOutline } from "ionicons/icons";
import { useLeagueStore } from "@/stores/leagueStore";
import { useGameStore } from "@/stores/gameStore";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { useRouter, useRoute } from "vue-router";
import { createPopper } from "@popperjs/core";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

defineComponent({
  name: "FiltersComponent",
});
const leagueStore = useLeagueStore();
const gameStore = useGameStore();
const preferenceStore = usePreferenceStore();
const router = useRouter();
const route = useRoute();
const teamFilterKey = ref(0);

function teamFilterDeselected(ev) {
  if (ev.value !== null) {
    if (route.params.leagueSlug === leagueStore.league.slug) {
      gameStore.gameFilters.team.splice(
        gameStore.gameFilters.team.indexOf(ev.value),
        1
      );
      if (gameStore.gameFilters.team.length === 0) {
        gameStore.gameFilters.team[0] = null;
      }
      router.replace({
        query: {
          ...route.query,
          team: gameStore.gameFilters.team.toString(),
        },
      });
      preferenceStore.setFilters({
        ...gameStore.gameFilters,
        showReportedGames: gameStore.showReportedGames,
      });
      teamFilterKey.value++;
    }
  }
}
function teamFilterSelected(ev) {
  if (route.params.leagueSlug === leagueStore.league.slug) {
    if (ev.length) {
      const teams = ev.map((team) => team.value);
      if (teams[0] === null) {
        teams.splice(0, 1);
      }
      if (teams[teams.length - 1] === null) {
        gameStore.gameFilters.team = [null];
      } else {
        gameStore.gameFilters.team = [...teams];
      }
    } else {
      gameStore.gameFilters.team[0] = null;
    }
    router.replace({
      query: {
        ...route.query,
        team: gameStore.gameFilters.team.toString(),
      },
    });
    preferenceStore.setFilters({
      ...gameStore.gameFilters,
      showReportedGames: gameStore.showReportedGames,
    });
  }
}

function withPopper(dropdownList, component) {
  dropdownList.style.width = "20rem";
  const popper = createPopper(component.$refs.toggle, dropdownList, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -1],
        },
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          component.$el.classList.toggle("drop-up", state.placement === "top");
        },
      },
    ],
  });

  return () => popper.destroy();
}
</script>
<style scoped>
.v-select {
  --vs-controls-color: var(--ion-color-step-300);
  --vs-border-width: 0;

  --vs-dropdown-bg: var(--ion-background-color);
  --vs-dropdown-option-color: var(--ion-text-color);

  --vs-selected-bg: var(--ion-color-light);
  --vs-selected-color: var(--ion-text-color);

  --vs-search-input-color: var(--ion-color-step-300);

  --vs-dropdown-option--active-bg: #664cc3;
  --vs-dropdown-option--active-color: #eeeeee;
  --vs-dropdown-min-width: 1rem;
  --vs-dropdown-option--deselect-color: red;

  --vs-disabled-bg: var(--ion-color-light);
  --vs-disabled-color: var(--ion-color-light);
  --vs-disabled-controls-color: var(--ion-color-light);
}
</style>
