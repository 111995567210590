<template>
  <ion-page>
    <ion-content>
      <ion-header>
        <ion-toolbar class="header-toolbar">
          <ion-buttons slot="start">
            <ion-menu-button menu="main-menu" color="dark"></ion-menu-button>
          </ion-buttons>
          <ion-title>My Account</ion-title>
        </ion-toolbar>
      </ion-header>
      <account-component />
      <account-fab @accountUpdated="refreshKey++" />
    </ion-content>
  </ion-page>
</template>

<script setup>
import { defineComponent, ref } from "vue";
import AccountComponent from "@/components/account/AccountComponent.vue";
import AccountFab from "@/components/account/AccountFab.vue";
import {
  IonHeader,
  IonMenuButton,
  IonButtons,
  IonToolbar,
  IonTitle,
  IonPage,
  IonContent,
} from "@ionic/vue";
defineComponent({
  name: "AccountPage",
});

const refreshKey = ref(0);
</script>
