<template>
  <ion-card-content
    style="padding: 0; flex: 1; width: 100%"
    @click="inPopup && isInputDisabled && notifyWithToast()"
  >
    <ion-list>
      <ion-item class="top-item" :style="{ borderColor: awayBorderColor }">
        <div class="team-names-container">
          <div class="team-name">
            <!-- <transition name="fade" mode="out-in"> -->
            <div class="main-name" v-if="props.game.awayTeam?.name">
              {{ props.game.awayTeam.name }}
            </div>
            <ion-row>
              <ion-col class="ion-no-padding">
                <div class="team-standings" v-if="props.game.awayTeam?.name">
                  {{ awayTeamStandings }}
                </div>
                
              </ion-col>
              <ion-col class="ion-no-padding">
                <div class="prev-game-name" v-if="props.game.away_team_freetext_formatted">
                  {{ props.game.away_team_freetext_formatted_game_num }}
                </div>
              </ion-col>
            </ion-row>
          </div>
        </div>
        <ion-label slot="end" class="team-score" v-if="disableEdit">
          {{ props.game.away_team_score }}
        </ion-label>
        <ion-input
          v-else
          @ionChange="scoreChanged"
          class="team-score-input"
          inputmode="numeric"
          slot="end"
          type="number"
          placeholder="-"
          v-model="awayScore"
          :disabled="isInputDisabled"
          :style="{
            border:
              awayScore || awayScore === 0
                ? ''
                : '3px solid var(--ion-color-light)',
          }"
        ></ion-input>
      </ion-item>
      <ion-item class="bottom-item" :style="{ borderColor: homeBorderColor }">
        <div class="team-names-container">
          <div class="team-name">
            <!-- <transition name="fade" mode="out-in"> -->
              <div class="main-name" v-if="props.game.homeTeam?.name">
                <span v-if="leagueStore.league?.show_home_away === 1"> at</span>
                {{ props.game.homeTeam.name }}
              </div>
              <ion-row>
                <ion-col class="ion-no-padding">
                  <div class="team-standings" v-if="props.game.homeTeam?.name">
                    {{ homeTeamStandings }}
                  </div>
                </ion-col>
                <ion-col class="ion-no-padding">
                  <div class="prev-game-name" v-if="props.game.away_team_freetext_formatted">
                    {{ props.game.home_team_freetext_formatted_game_num }}
                  </div>
                </ion-col>
              </ion-row>
            <!-- </transition> -->
            <!-- <span
              style="color: var(--ion-color-medium)"
              v-if="props.game.homeTeam && preferenceStore.showTeamIDs"
              >| {{ props.game.homeTeam?.id }}</span
            > -->
          </div>
          
        </div>
        <ion-label slot="end" class="team-score" v-if="disableEdit">{{
          props.game.home_team_score
        }}</ion-label>
        <ion-input
          v-else
          @ionChange="scoreChanged"
          class="team-score-input"
          inputmode="numeric"
          slot="end"
          type="number"
          placeholder="-"
          :disabled="isInputDisabled"
          v-model="homeScore"
          :style="{
            border:
              homeScore || homeScore === 0
                ? ''
                : '3px solid var(--ion-color-light)',
          }"
        ></ion-input>
      </ion-item>
    </ion-list>
    <ion-list
      v-if="
        props.game.game_status === 'postponed' && !gameStore.showSelectGames
      "
    >
      <ion-item color="medium">
        <ion-label
          >Postponed due to {{ props.game.reschedule_reason }}</ion-label
        >
      </ion-item>
    </ion-list>
    <div class="best-of-bar" v-if="props.game.best_of > 1">
      Best of {{ props.game.best_of }} Game #1
    </div>
    <div class="best-of-bar" v-if="props.game.part_of">
      Best of {{ findPartOfGames(props.game.part_of) }} - Game #{{
        props.game.part_of_order
      }}
    </div>
  </ion-card-content>
</template>
<script setup>
import {
  defineComponent,
  defineProps,
  defineEmits,
  ref,
  computed,
  onMounted,
} from "vue";
import {
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  modalController,
} from "@ionic/vue";
import { useLeagueStore } from "@/stores/leagueStore";
import { useAuthStore } from "@/stores/authStore";
import { useGameStore } from "@/stores/gameStore";

import { useReportGameStore } from "@/stores/reportGameStore";
// import { usePreferenceStore } from "@/stores/preferenceStore";
import { watch } from "vue";

import { showToast } from "@/utils/useIonicComponents.js";
import { IonRow, IonCol } from "@ionic/vue";

import uniqolor from "uniqolor";

defineComponent({
  name: "GameCardContent",
});
const props = defineProps([
  "game",
  "isGameSelected",
  "inPopup",
  "convenorPopup",
  "clearScoreCount",
  "showReportButton",
  "reportScoreCount",
]);
const emit = defineEmits([
  "resetGame",
  "showReportButton",
  "hideReportButton",
  "close",
]);
const leagueStore = useLeagueStore();
const authStore = useAuthStore();
const gameStore = useGameStore();
const reportStore = useReportGameStore();
// const preferenceStore = usePreferenceStore();

// const awayTeamDisplay = ref("");
// const homeTeamDisplay = ref("");

const awayScore = ref();
const homeScore = ref();

const reportedHomeScore = ref(null);
const reportedAwayScore = ref(null);

const previouslyReportedScore = reportStore.getGameUserReportScore(props.game);

if (props.game.score_reported === 1) {
  awayScore.value = props.game.away_team_score.valueOf();
  homeScore.value = props.game.home_team_score.valueOf();
  reportedAwayScore.value = props.game.away_team_score.valueOf();
  reportedHomeScore.value = props.game.home_team_score.valueOf();
}

watch(
  () => props.clearScoreCount,
  (newCount, oldCount) => {
    if (newCount > oldCount) {
      if (props.game.score_reported === 1) {
        awayScore.value = props.game.away_team_score.valueOf();
        homeScore.value = props.game.home_team_score.valueOf();
      } else {
        awayScore.value = "";
        homeScore.value = "";
      }

      scoreChanged();
    }
  }
);

const emitClose = () => {
  console.log("emitting close");
  modalController.dismiss();
  emit("close");
};

watch(
  () => props.reportScoreCount,
  async () => {
    const reportGame = Object.assign({}, props.game);
    reportGame.away_team_score = awayScore.value;
    reportGame.home_team_score = homeScore.value;

    const response = await reportStore.addGamesToReport(reportGame);
    if (response === true) {
      await reportStore.sendReportRequest();
      await gameStore.updateSingleGameStore(props.game);
      reportStore.removeGameFromReport(props.game);
      gameStore.removeSelectedGame(props.game);
      // resetAllEditedScore();
      if (gameStore.selectedGames.length === 0) {
        emitClose();
      }
    }
    // console.log()
  }
);

if (previouslyReportedScore.length > 0) {
  awayScore.value = previouslyReportedScore[0].valueOf();
  homeScore.value = previouslyReportedScore[1].valueOf();
}

const findPartOfGames = (game_part_of) => {
  const games = gameStore.games;
  const partOfGames = games.find((game) => game.id === game_part_of);
  if (partOfGames) return partOfGames.best_of;
};

const disableEdit = computed(() => {
  if (props.convenorPopup) return false;
  if (
    (props.game.score_reported === 1 && !authStore.isConvenor) ||
    gameStore.showSelectGames ||
    props.game.game_status === "postponed"
  ) {
    return true;
  }
  return false;
});

const isInputDisabled = computed(() => {
  if (!props.inPopup) return true;
  return !props.game.awayTeam || !props.game.homeTeam;
});

const awayBorderColor = computed(() => {
  return (
    props.game.awayTeam?.color ||
    uniqolor(
      props.game.awayTeam?.name || props.game.away_team_freetext_formatted
    ).color
  );
});

const homeBorderColor = computed(() => {
  return (
    props.game.homeTeam?.color ||
    uniqolor(
      props.game.homeTeam?.name || props.game.home_team_freetext_formatted
    ).color
  );
});

// Function to cycle through team names
// function cycleTeamNames() {
//   const awayNames = [
//     props.game.awayTeam?.name,
//     props.game.awayTeam?.name +
//       " - " +
//       props.game.away_team_freetext_formatted_game_num,
//     props.game.awayTeam?.name + " - " + props.game.away_team_freetext_formatted,
//   ].filter(Boolean);

//   const homeNames = [
//     props.game.homeTeam?.name,
//     props.game.homeTeam?.name +
//       " - " +
//       props.game.home_team_freetext_formatted_game_num,
//     props.game.homeTeam?.name + " - " + props.game.home_team_freetext_formatted,
//   ].filter(Boolean);

//   awayTeamDisplay.value = awayNames[0];
//   homeTeamDisplay.value = homeNames[0];

//   let awayIndex = 1;
//   let homeIndex = 1;

//   setInterval(() => {
//     awayTeamDisplay.value = awayNames[awayIndex++ % awayNames.length];
//     homeTeamDisplay.value = homeNames[homeIndex++ % homeNames.length];
//   }, 10000); // Change every 10 seconds
// }

onMounted(() => {
  // cycleTeamNames();
});

const awayTeamStandings = computed(() => {
  const team = props.game.awayTeam;
  return team
    ? `(${team.wins || 0}-${team.losses || 0}-${team.ties || 0})`
    : "";
});

const homeTeamStandings = computed(() => {
  const team = props.game.homeTeam;
  return team
    ? `(${team.wins || 0}-${team.losses || 0}-${team.ties || 0})`
    : "";
});

// Function to validate score input
function isValidScore(score) {
  const parsedScore = parseInt(score);
  return !isNaN(parsedScore) && parsedScore >= 0;
}

function notifyWithToast() {
  showToast(
    `Can't submit scores for games that do not have teams. Report previous scores to move teams forward into this game or edit your schedule.`,
    "danger",
    2000
  );
}

// to do - figure out if you still need this
async function scoreChanged() {
  // console.log('scoreChangedTriggered')
  const validAwayScore = isValidScore(awayScore.value);
  const validHomeScore = isValidScore(homeScore.value);
  // console.log(awayScore.value, homeScore.value)
  // console.log('validAwayScore', validAwayScore)
  // console.log('validHomeScore', validHomeScore)

  if (props.game.score_reported !== 1) {
    if (validAwayScore === true && validHomeScore === true) {
      emit("showReportButton");
    }

    if (validAwayScore === false || validHomeScore === false) {
      emit("hideReportButton");
    }
  } else {
    if (
      reportedAwayScore.value != awayScore.value ||
      reportedHomeScore.value != homeScore.value
    ) {
      emit("showReportButton");
    } else {
      emit("hideReportButton");
    }
  }

  // if (!validAwayScore && !validHomeScore) {
  //   // if user manually removes both score values
  //   // reportStore.removeGameFromReport(props.game);
  //   // emit("resetGame");
  // } else if (validAwayScore && validHomeScore) {
  //   if (props.game.score_reported && !authStore.isConvenor) {
  //     // reported score can only be edited by convenor

  //   } else {
  //     // const reportGame = Object.assign({}, props.game);
  //     // reportGame.away_team_score = awayScore.value;
  //     // reportGame.home_team_score = homeScore.value;
  //     // reportStore.showReportButton = true
  //     // const response = await reportStore.addGamesToReport(reportGame);
  //     // if (response === false) {
  //     //   // if user fails to input shortcode
  //     //   emit("resetGame");
  //     // }
  //   }
  // }
}
</script>
<style scoped>
.bottom-item {
  --border-style: none;
  --background: #191919;
  border-left-style: solid;
  border-width: 0.75rem;
}

.top-item {
  --border-style: none;
  border-left-style: solid;
  border-width: 0.75rem;
}
.team-names-container {
  min-height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70px;
  /* align-items: center; */
  width: 100%;
  /* padding-left: 0.5rem; */
}

.team-names {
  font-size: 14px;
  /* display: flex; */
  /* flex-direction: row; */
  text-transform: uppercase;
  font-weight: 500;
  /* text-align: center; */
}

.prev-game-name {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
}

.prev-game-name { 
  text-align: right;
}

.team-standings {
  font-size: 10px;
}


.main-name {
  font-size: 13.5px;
  text-transform: uppercase;
  font-weight: 500;
  padding-bottom: 3px;
}


.team-standings {
  font-size: 10px;
  /* text-align: center; */
  /* align-self: center; */
}
.team-score {
  /* padding: 0 2rem; */
  min-width: 3.5rem;
  text-align: center;
}
.team-score-input {
  max-width: 3.5rem;
  text-align: center;
  height: 70px;
  --padding-start: 10px !important;
  /* --background: var(--ion-color-light); */
  /* border-bottom: 3px solid var(--ion-color-light); */
  background: var(--ion-color-light);
}
.team-score-input.has-focus {
  border: inset !important;
  caret-color: var(--ion-color-dark);
}

ion-item.team-score-input.sc-ion-input-ios-h:not(.item-label),
ion-item:not(.item-label) .sc-ion-input-ios-h {
  color: #fff;
  background: #1f1f1f;
}
</style>
<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.best-of-bar {
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  padding: 2px 5px;
  font-weight: 700;
  color: #fff;
  background: var(--ion-color-primary);
}
</style>
