<template>
  <base-modal modal-title="Edit Your Details" @close="close(false)">
    <template #headerStart>
      <ion-button @click="close(false)"> Close </ion-button>
    </template>
    <ion-card style="min-width: 20rem; padding: 0; margin: 0">
      <ion-card-content>
        <ion-grid>
          <ion-row>
            <ion-col class="ion-align-self-center label-left-col"
              ><ion-label>Name</ion-label></ion-col
            >
            <ion-col
              ><ion-input
                class="details-input"
                v-model="user.full_name"
              ></ion-input
            ></ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-align-self-center label-left-col"
              ><ion-label>Email</ion-label></ion-col
            >
            <ion-col
              ><ion-input class="details-input" v-model="user.email"></ion-input
            ></ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-align-self-center label-left-col"
              ><ion-label>Phone</ion-label></ion-col
            >
            <ion-col
              ><ion-input class="details-input" v-model="user.phone"></ion-input
            ></ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
    <template #footer>
      <ion-button expand="full" @click="saveUser">Save Your Details</ion-button>
    </template>
  </base-modal>
</template>
<script setup>
import BaseModal from "@/components/layouts/BaseModal.vue";
import {
  IonCol,
  IonRow,
  IonGrid,
  IonLabel,
  IonCard,
  IonCardContent,
  IonInput,
  IonButton,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, numeric, email } from "@vuelidate/validators";
import { showToast, showLoading } from "@/utils/useIonicComponents.js"; 
import { api } from "@/utils/axios";
import { useAuthStore } from "@/stores/authStore";

defineComponent({
  name: "AccountModal",
});
const authStore = useAuthStore();
const user = ref({ ...authStore.user });

const rules = {
  full_name: { required },
  email: { required, email },
  phone: { required, numeric },
};

const v$ = useVuelidate(rules, user);

async function saveUser() {
  const isFormValid = await v$.value.$validate();
  if (isFormValid) {
    await saveUserRequest();
  } else {
    showToast("Invalid Inputs!", "danger", 6000);
  }
}

async function saveUserRequest() {
  const loading = await showLoading("Saving Your Details. Please Wait...");
  try {
    const result = await api.post("/auth/save-your-details", {
      user: user.value,
    });
    loading.dismiss();
    if (result.status === 200) {
      await authStore.refreshToken();
      await close(true);
      showToast("Your Details successfully Saved.", "success", 6000);
    } else {
      showToast(
        "Something went wrong. If this persists, please contact us.",
        "danger",
        6000
      );
    }
  } catch (err) {
    console.log(err);
    loading.dismiss();
    showToast(
      "Something went wrong. If this persists, please contact us.",
      "danger",
      6000
    );
  }
}

async function close(success = false) {
  await modalController.dismiss(success);
}
</script>
<style scoped>
.details-input {
  border-bottom: 3px solid var(--ion-color-medium);
  text-align: center;
}
</style>
