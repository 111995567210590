import { defineStore } from "pinia";
import { ref, reactive, computed } from "vue";
import { format, differenceInDays, set } from "date-fns";
import { parse, isBefore, isAfter } from "date-fns";
import { keyBy, orderBy } from "lodash";
import { api } from "@/utils/axios";
import {
  showToast,
  showLoading,
  showAlert,
  openModal,
} from "@/utils/useIonicComponents";
import { unparse } from "papaparse";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { useLeagueStore } from "@/stores/leagueStore";
// import { useReportGameStore } from "@/stores/reportGameStore";
import NewAnnouncementModal from "@/components/announcements/NewAnnouncementModal.vue";
import io from "socket.io-client";

//
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import generatePDF from "../utils/generatePDF";
import generateResultsPDF from "../utils/generateResultsPDF";

export const useGameStore = defineStore("game", () => {
  const games = ref(null);
  const selectedGames = ref([]);
  const currentShownDate = ref(null);
  const showSelectGames = ref(false);

  const sortOrder = ref(["Time", "Game #", "Division", "Location", "Diamond"]);
  const gameFilters = reactive({
    division_id: null,
    park_name: null,
    diamond_signifier: null,
    team: [null],
    start_time: null,
    day: null,
    month: null,
  });
  const showReportedGames = ref(true); // this is a game filter value but sepereate

  // localhost:3555
  const socket = io("https://gamebeast-socket.onrender.com/");

  socket.on("connect", () => {
    console.log("~~~Connected to the socket server~~~");
  });

  // Listen for the 'score.reported' event
  socket.on("receive.score.reported", async (event) => {
    console.log("Score reported event received", event);

    // recieves league_id, slug, game_id, home_team_score, away_team_score
    // Call a store method to handle the update
    // updateSingleGameStore(details.event_id);
    const leagueStore = useLeagueStore();

    if (event.event.slug == leagueStore.league.slug) {
      await leagueStore.reloadEverything(leagueStore.league.slug);
    }
  });

  async function getGames(leagueSluge) {
    const loading = await showLoading("Please wait...");
    const results = await api
      .get("/api/get-games/web/" + leagueSluge)
      .catch((err) => {
        loading.dismiss();
        showToast("Failed to find Games, Please try again!", "danger", 6000);
        throw err;
      });

    games.value = results.data.games;
    games.value.forEach((game) => {
      game.datetime = game.start_date + " " + game.start_time;
      if (game.start_date) {
        game.day = format(new Date(game.start_date.replace(/-/g, "/")), "EEEE");
        game.month = format(
          new Date(game.start_date.replace(/-/g, "/")),
          "MMMM"
        );
      } else {
        //TBA
        game.day = game.month = null;
      }
    });

    //calculation for which date to show the user
    currentShownDate.value = nearestDate.value;
    const preferenceStore = usePreferenceStore();
    if (preferenceStore.getCurrentShownDates !== "all") {
      if (
        preferenceStore.getCurrentShownDates &&
        availableDates.value.indexOf(preferenceStore.getCurrentShownDates) >= 0
      ) {
        currentShownDate.value = preferenceStore.getCurrentShownDates;
      }
    } else {
      currentShownDate.value = "all";
    }

    // if (preferenceStore.getUserReportGames.length > 0) {
    //   const reportGameStore = useReportGameStore();
    //   reportGameStore.games = preferenceStore.getUserReportGames;
    //   console.log("report loading", reportGameStore.games);
    // } else {
    console.log(preferenceStore.getUserSelectedGames);
    if (preferenceStore.getUserSelectedGames.length > 0) {
      showSelectGames.value = true;
      selectedGames.value = preferenceStore.getUserSelectedGames;
    }
    // }

    loading.dismiss();
  }

  const sortGames = (games, sortOrder) => {
    const fieldMapping = {
      Time: "start_time",
      Location: "park_name",
      Division: "division_id",
      Diamond: "diamond_signifier",
      "Game #": "event_specific_game_id",
    };

    return [...games].sort((a, b) => {
      for (let field of sortOrder) {
        const fieldA = a[fieldMapping[field]];
        const fieldB = b[fieldMapping[field]];

        // Handle null or undefined values
        if (fieldA == null && fieldB != null) return 1;
        if (fieldA != null && fieldB == null) return -1;
        if (fieldA == null && fieldB == null) continue;

        if (field === "Time") {
          // Parse the time strings
          const timeA = parse(fieldA, "HH:mm:ss", new Date());
          const timeB = parse(fieldB, "HH:mm:ss", new Date());

          if (isBefore(timeA, timeB)) return -1;
          if (isAfter(timeA, timeB)) return 1;
        } else {
          // Convert to string for comparison if not already strings
          const valueA =
            typeof fieldA === "string"
              ? fieldA.toLowerCase()
              : String(fieldA).toLowerCase();
          const valueB =
            typeof fieldB === "string"
              ? fieldB.toLowerCase()
              : String(fieldB).toLowerCase();

          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
        }
      }
      return 0;
    });
  };

  const filteredGames = computed(() => {
    // filtered games without current date applied
    // this is needed to find the available dates
    const filtered = games.value?.filter((game) => {
      if (showReportedGames.value === false && game.score_reported == 1) {
        return false;
      }

      for (const [key] of Object.entries(gameFilters)) {
        var filter_item = gameFilters[key];

        if (filter_item && filter_item[0] !== null) {
          if (key === "team") {
            if (
              !filter_item.includes(game["away_team_id"]) &&
              !filter_item.includes(game["home_team_id"])
            ) {
              return false;
            }
          } else if (key === "start_time") {
            //comparing games ignoring seconds
            if (filter_item === "TBA" && game[key] === null) {
              return true;
            }
            if (game[key].split(":").slice(0, 2).join(":") !== filter_item) {
              return false;
            }
          } else {
            if (game[key] != filter_item) {
              return false;
            }
          }
        }
      }
      return true;
    });
    // Apply sorting to the filtered games
    return sortGames(filtered, sortOrder.value);
  });
  const filteredGamesCurrentDate = computed(() => {
    // this further filters down to current date
    return filteredGames.value.filter((game) => {
      if (
        (game.start_date ? game.start_date : "null") !==
          currentShownDate.value &&
        currentShownDate.value !== "all"
      ) {
        return false;
      }
      return true;
    });
  });
  function organizeDateWise(gameList) {
    const dateWise = [];

    var keyByObj = keyBy(gameList, function (game) {
      return game.start_date;
    });
    var keys = Object.keys(keyByObj);

    keys = orderBy(keys, [], "asc");

    keys.map((key) => {
      var item = {};
      var filtered_games = gameList.filter((game) => {
        if (key === "null") {
          //TBA
          return game.start_date === null;
        }
        return game.start_date === key;
      });
      if (filtered_games.length > 0) {
        item.date = key;
        item.games = filtered_games;
        dateWise.push(item);
      }
    });
    return dateWise;
  }
  const dateWiseFilteredGames = computed(() => {
    // used to find unique dates
    return organizeDateWise(filteredGames.value);
  });
  // const dateWiseGames = computed(() => {
  //   return organizeDateWise(games.value);
  // });

  const availableDates = computed(() => {
    return dateWiseFilteredGames.value.map((game) => game.date);
  });

  const nearestDate = computed(() => {
    let nearest;
    availableDates.value.forEach((date) => {
      if (date !== "null") {
        let diff = Math.abs(
          differenceInDays(new Date(date.replace(/-/g, "/")), new Date())
        );

        if (nearest) {
          const nearestdiff = Math.abs(
            differenceInDays(new Date(nearest.replace(/-/g, "/")), new Date())
          );

          if (nearestdiff > diff) {
            nearest = date;
          }
        } else {
          nearest = date;
        }
      }
    });
    const nearestIndex = availableDates.value.indexOf(nearest);
    if (
      nearestIndex < availableDates.value.length - 1 &&
      differenceInDays(new Date(nearest.replace(/-/g, "/")), new Date()) < 0
    ) {
      // if nearest date is before today and there is next date available. use the next date
      if (availableDates.value[nearestIndex + 1] !== "null") {
        nearest = availableDates.value[nearestIndex + 1];
      }
    }
    // console.log("nearest date", nearest);
    return nearest;
  });

  const filterOptions = computed(() => {
    const object = {};

    const filters = [
      "division_id",
      "park_name",
      "diamond_signifier",
      "start_time",
      "datetime",
      "day",
      "month",
    ];

    filters.map((filter) => {
      const kb = keyBy(games.value, filter);
      const keys = Object.keys(kb);
      object[filter] = keys;
    });
    //add exception for diamonds
    object.diamond_signifier = object.diamond_signifier.filter((d) => d !== "");
    object.start_time = object.start_time.map((time) => {
      if (time === "null") {
        return "TBA";
      } else {
        // If not null, proceed to remove seconds
        return time.split(":").slice(0, 2).join(":");
      }
    });

    // remove duplicates, if any
    object.start_time = Array.from(new Set(object.start_time));
    object.month = object.month.filter((month) => month !== "null");
    object.day = object.day.filter((day) => day !== "null");

    return object;
  });

  const isFiltersApplied = computed(() => {
    if (showReportedGames.value === false) {
      return true;
    }
    if (gameFilters.team.length > 0 && gameFilters.team[0] !== null) {
      return true;
    } else {
      const filters = { ...gameFilters };
      delete filters.team;
      return !Object.values(filters).every((filter) => filter === null);
    }
  });

  const currentShownDateIndex = computed(() => {
    return availableDates.value.indexOf(currentShownDate.value);
  });

  const isNextDateAvail = computed(() => {
    return currentShownDateIndex.value < availableDates.value.length - 1;
  });
  function resetFilters() {
    Object.keys(gameFilters).forEach((i) => (gameFilters[i] = null));
    showReportedGames.value = true;
    gameFilters.team = [null];
  }
  function setNextDate() {
    if (isNextDateAvail.value) {
      currentShownDate.value =
        availableDates.value[currentShownDateIndex.value + 1];
    }
  }
  const isPrevDateAvail = computed(() => {
    return currentShownDateIndex.value > 0;
  });
  function setPreviousDate() {
    if (isPrevDateAvail.value) {
      currentShownDate.value =
        availableDates.value[currentShownDateIndex.value - 1];
    }
  }

  function saveSelectGame() {
    const preferenceStore = usePreferenceStore();
    preferenceStore.setUserSelectedGames();
  }

  function selectGame(game) {
    selectedGames.value.push(Object.assign({}, game));
    saveSelectGame();
  }
  function removeSelectedGameIndex(index) {
    selectedGames.value.splice(index, 1);
    saveSelectGame();
  }
  function removeSelectedGame(game) {
    const index = selectedGames.value.findIndex((g) => g.id === game.id);
    selectedGames.value.splice(index, 1);
    saveSelectGame();
  }

  function isGameSelected(id) {
    return selectedGames.value.filter((game) => game.id === id).length();
  }

  async function updateSingleGameStore() {
    const leagueStore = useLeagueStore();
    // console.log("updateSingleGameStore", game);

    await leagueStore.reloadEverything(leagueStore.league.slug);
  }

  async function resetScores() {
    //reset scores for the selected games
    const loading = await showLoading("Resetting Games. Please Wait...");
    try {
      const leagueStore = useLeagueStore();
      await api.post("/api/reset-games", {
        event: leagueStore.league.id,
        selected_games: selectedGames.value,
      });
      await leagueStore.reloadEverything(leagueStore.league.slug);
      selectedGames.value.length = 0;
      showSelectGames.value = false;
      saveSelectGame();
      loading.dismiss();
      showToast("Selected Games have been reset", "success");
    } catch (err) {
      console.log(err);
      showToast("Resetting Games Failed", "danger");
      loading.dismiss();
    }
  }

  async function postponeGames() {
    const loading = await showLoading("Postponing Games. Please Wait...");
    try {
      const leagueStore = useLeagueStore();
      await api.post("/api/save-postponed-games", {
        event: leagueStore.league.id,
        selected_games: selectedGames.value,
      });
      await leagueStore.reloadEverything(leagueStore.league.slug);
      selectedGames.value.length = 0;
      showSelectGames.value = false;
      saveSelectGame();
      loading.dismiss();
      showToast("Games Postponed Successfully!", "success");
      makeAnnouncementAfterChange();
    } catch (err) {
      console.log(err);
      showToast("Postponing Games Failed", "danger");
      loading.dismiss();
    }
  }

  async function editTeams() {
    const loading = await showLoading("Editing Games. Please Wait...");
    try {
      const leagueStore = useLeagueStore();
      await api.post("/api/save-edited-games", {
        event: leagueStore.league.id,
        selected_games: selectedGames.value,
      });
      await leagueStore.reloadEverything(leagueStore.league.slug);
      selectedGames.value.length = 0;
      showSelectGames.value = false;
      saveSelectGame();
      loading.dismiss();
      showToast("Games Edited Successfully!", "success");
      makeAnnouncementAfterChange();
    } catch (err) {
      console.log(err);
      showToast("Editing Games Failed", "danger");
      loading.dismiss();
    }
  }

  async function rescheduleGames() {
    const loading = await showLoading("Rescheduling Games. Please Wait...");
    try {
      const leagueStore = useLeagueStore();
      await api.post("/api/save-rescheduled-games", {
        event: leagueStore.league.id,
        selected_games: selectedGames.value,
      });
      await leagueStore.reloadEverything(leagueStore.league.slug);
      selectedGames.value.length = 0;
      showSelectGames.value = false;
      saveSelectGame();
      loading.dismiss();
      showToast("Games Rescheduled Successfully!", "success");
      makeAnnouncementAfterChange();
    } catch (err) {
      console.log(err);
      showToast("Rescheduling Games Failed", "danger");
      loading.dismiss();
    }
  }

  async function makeAnnouncementAfterChange() {
    await showAlert({
      header: "Would you like to make an announcement about this ?",
      buttons: [
        {
          text: "No",
          role: "cancel",
        },
        {
          text: "Yes",
          role: "confirm",
          handler: async () => {
            await openModal(NewAnnouncementModal, "newAnnouncementModal");
          },
        },
      ],
    });
  }

  async function deleteGames() {
    await showAlert({
      header: "Are you sure you want to delete these games ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Delete",
          role: "confirm",
          handler: async () => {
            await sendDeleteRequest();
          },
        },
      ],
    });
  }
  async function sendDeleteRequest() {
    // console.log("Delete Requested");
    const loading = await showLoading("Deleting Games. Please Wait...");
    try {
      await api.post("/api/delete-games", {
        selectedGames: selectedGames.value,
      });
      const leagueStore = useLeagueStore();
      await leagueStore.reloadEverything(leagueStore.league.slug);
      selectedGames.value.length = 0;
      showSelectGames.value = false;
      saveSelectGame();
      loading.dismiss();
      showToast("Games Deleted Successfully!", "success");
    } catch (err) {
      console.log(err);
      showToast("Deleting Games Failed", "danger");
      loading.dismiss();
    }
  }

  async function downloadPDF() {
    const leagueStore = useLeagueStore();
    generatePDF(
      dateWiseFilteredGames.value.map((dgames) => dgames.games).flat(),
      {
        id: leagueStore.league.id,
        name: leagueStore.league.name,
        slug: leagueStore.league.slug,
        shortcode: leagueStore.league.shortcode,
        type: leagueStore.league.type,
        color: leagueStore.league.primary_color,
        logo_url: leagueStore.league.logo_url,
      }
    );
  }

  async function downloadResultsPDF() {
    const leagueStore = useLeagueStore();
    generateResultsPDF(
      dateWiseFilteredGames.value.map((dgames) => dgames.games).flat(),
      {
        id: leagueStore.league.id,
        name: leagueStore.league.name,
        slug: leagueStore.league.slug,
        shortcode: leagueStore.league.shortcode,
        type: leagueStore.league.type,
        color: leagueStore.league.primary_color,
        logo_url: leagueStore.league.logo_url,
      }
    );
  }

  async function downloadPDF_Old() {
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    const rowsPerPage = 50;
    const totalRows = filteredGamesCurrentDate.value.length;

    const totalPages = Math.ceil(totalRows / rowsPerPage);

    let currentPage = 0;
    while (currentPage < totalPages) {
      // Generate HTML content for the current page
      let htmlContent = generateHTMLForPage(currentPage, rowsPerPage);

      // Convert the HTML string to an element and render it
      const container = document.createElement("div");
      container.style.width = "1200px";
      container.innerHTML = htmlContent;
      document.body.appendChild(container);

      // Capture the rendered HTML as a canvas
      const canvas = await html2canvas(container, { scale: 1 });
      document.body.removeChild(container);

      if (currentPage > 0) {
        pdf.addPage();
      }

      // Add captured content to the PDF
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 180; // Adjust based on your layout
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const x = (pdf.internal.pageSize.getWidth() - imgWidth) / 2;
      const y = 50; // Adjust based on your layout
      const leagueStore = useLeagueStore();
      pdf.setFontSize(12); // Adjust font size as needed
      pdf.text(leagueStore.league.name, 45, 25);

      pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);

      currentPage++;
    }

    // Save the PDF after all pages have been added
    pdf.save("games-schedule.pdf");
  }

  function generateHTMLForPage(pageNumber, rowsPerPage) {
    const startIndex = pageNumber * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const pageGames = filteredGamesCurrentDate.value.slice(
      startIndex,
      endIndex
    );

    let style = `<style>
      table { width: 100%; border-collapse: collapse; }
      th, td { border: 1px solid black; padding: 5px; text-align: left; }
      th { background-color: #f9f9f9; color: #000 !important; }
      td { color: #000 !important; }
      body { font-family: Arial, sans-serif; }
      .custom-table { width: 1200px; }
      .custom-table th, .custom-table td { width: auto; }
    </style>`;

    let htmlContent = `${style}<table class="custom-table"><tr><th style="font-size: 11px;">Game #</th><th>Start Date</th><th>Time</th><th>Park Name</th><th>Diamond</th><th>Division</th><th>Away Team</th><th>Home Team</th></tr>`;

    pageGames.forEach((game) => {
      htmlContent += `<tr>
        <td>${game.event_specific_game_id}</td>
        <td>${format(
          new Date(game.start_date.replace(/-/g, "/")),
          "yyyy-MM-dd"
        )}</td>
        <td>${format(
          set(new Date(), {
            hours: game.start_time.split(":")[0],
            minutes: game.start_time.split(":")[1],
          }),
          "hh:mm"
        )} - ${format(
        set(new Date(), {
          hours: game.end_time.split(":")[0],
          minutes: game.end_time.split(":")[1],
        }),
        "hh:mm a"
      )}</td>
        <td>${game.park_name}</td>
        <td>${game.diamond_signifier}</td>
        <td>${game.division_id}</td>
        <td>${
          game.awayTeam?.name ||
          game.away_team_freetext_formatted_game_num ||
          game.away_team_freetext_formatted
        }</td>
        <td>${
          game.homeTeam?.name ||
          game.home_team_freetext_formatted_game_num ||
          game.home_team_freetext_formatted
        }</td>
      </tr>`;
    });

    htmlContent += `</table>`;
    return htmlContent;
  }

  function downloadCSV() {
    const games = [];
    filteredGamesCurrentDate.value.forEach((game) => {
      const g = {};
      g["Game #"] = game.event_specific_game_id;
      g["Start Date"] = format(
        new Date(game.start_date.replace(/-/g, "/")),
        "yyyy-MM-dd"
      );

      g["Start Time"] = format(
        set(new Date(), {
          hours: game.start_time.split(":")[0],
          minutes: game.start_time.split(":")[1],
        }),
        "HH:mm:ss"
      );
      // g.end_date = format(
      //   new Date(game.start_date.replace(/-/g, "/")),
      //   "yyyy-MM-dd"
      // );
      g["End Time"] = format(
        set(new Date(), {
          hours: game.end_time.split(":")[0],
          minutes: game.end_time.split(":")[1],
        }),
        "HH:mm:ss"
      );

      g["Park Name"] = game.park_name;
      g["Diamond"] = game.diamond_signifier;
      g["Division"] = game.division_id;
      g["Away Team"] =
        game.awayTeam?.name ||
        game.away_team_freetext_formatted_game_num ||
        game.away_team_freetext_formatted;
      // g["away_score"] = game.away_team_score;
      // g["Home Score"] = game.home_team_score;
      g["Home Team"] =
        game.homeTeam?.name ||
        game.home_team_freetext_formatted_game_num ||
        game.home_team_freetext_formatted;
      games.push(g);
    });
    const csv = unparse(games);
    const leagueStore = useLeagueStore();
    const title = leagueStore.leagueName + " - Schedule";
    // console.log(csv);
    const anchor = document.createElement("a");
    anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    anchor.target = "_blank";
    anchor.download = `${title}.csv`;
    anchor.click();
  }

  // const isAllFilteredGamesReported = computed(() => {
  //   const notReportedGames = filteredGames.value.filter((game) => {
  //     if (!game.awayTeam || !game.homeTeam) {
  //       return false;
  //     }
  //     return game.score_reported !== 1;
  //   });
  //   return notReportedGames.length > 0 ? false : true;
  // });

  return {
    games,
    gameFilters,
    showReportedGames,
    selectedGames,
    currentShownDate,
    showSelectGames,
    filteredGames,
    filteredGamesCurrentDate,
    currentShownDateIndex,
    // dateWiseGames,
    dateWiseFilteredGames,
    nearestDate,
    availableDates,
    isPrevDateAvail,
    isNextDateAvail,
    // isAllFilteredGamesReported,
    filterOptions,
    isFiltersApplied,
    sortOrder,
    getGames,
    resetFilters,
    setNextDate,
    setPreviousDate,
    selectGame,
    removeSelectedGame,
    removeSelectedGameIndex,
    postponeGames,
    rescheduleGames,
    deleteGames,
    isGameSelected,
    downloadCSV,
    resetScores,
    editTeams,
    saveSelectGame,
    downloadPDF,
    updateSingleGameStore,
    downloadPDF_Old,
    downloadResultsPDF
  };
});
