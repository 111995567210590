import { api, sloPitchApi } from "./axios"; // Import both Axios instances
import { useAuthStore } from "@/stores/authStore";
import extractCookie from "./extractCookie";

export default function axiosSetUp() {
  // Define the URLs we don't want to intercept
  const blacklist = ["/auth/logout", "/auth/refresh", "/auth/attempt"];

  // Initialize the authentication store
  const authStore = useAuthStore();

  // API key for sloPitchApi
  const sloPitchApiKey = "sxJaz2131236Za2vHv95NB0qHJinuJ8Zg509ZU";

  // Function to setup interceptors
  const setupInterceptors = (axiosInstance, isSloPitchApi = false) => {
    axiosInstance.interceptors.request.use(
      function (config) {
        // Check if the request URL is not in the blacklist
        if (!blacklist.includes(config.url)) {
          // Extract the access token from cookies
          const token = extractCookie("access_token");
          // If there's no token, refresh it
          if (!token) {
            authStore.refreshToken();
          }
          // If this is the sloPitchApi, add the API key to the headers
          if (isSloPitchApi) {
            config.headers['x-auth-key'] = sloPitchApiKey;
          }
        }
        // Return the config object to proceed with the request
        return config;
      },
      function (error) {
        // Log interceptor errors and logout the user
        console.log("Interceptor Error");
        authStore.logout();
        return Promise.reject(error);
      }
    );
  };

  // Setup interceptors for both Axios instances
  setupInterceptors(api);
  setupInterceptors(sloPitchApi, true); // Indicate this is the sloPitchApi
}