<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title v-if="props.cardTitle">{{
        props.cardTitle
      }}</ion-card-title>
      <ion-card-subtitle v-if="props.cardSubtitle">{{
        props.cardSubtitle
      }}</ion-card-subtitle>
    </ion-card-header>

    <ion-card-content>
      <slot></slot>
    </ion-card-content>
    <slot name="footer"></slot>
  </ion-card>
</template>

<script setup>
import { defineComponent, defineProps } from "vue";
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
} from "@ionic/vue";

defineComponent({
  name: "CardWrapper",
});

const props = defineProps({ cardTitle: String, cardSubtitle: String });
</script>
<style scoped>
ion-card {
  box-shadow: 0px 6px 6px 1px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 6px 6px 1px rgba(0, 0, 0, 0.3);
  background: #111;
}
ion-card-header {
  background: #111;
  margin-bottom: 10px;
  border-top: 3px solid #7d38f0;
}
ion-card-title {
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  --color: var(--ion-color-dark);
}

@media all and (max-width: 800px) {
  ion-card-title {
    font-size: 24px;
  }
}

</style>
