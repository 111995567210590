<template>
  <span>

    <div class="custom-fab"
      >
      <div v-if="!gameStore.showSelectGames && !showDesktopMode">
        <ion-button
          @click="presentEditOptions"
          color="tertiary"
          expand="full">
          <ion-icon :icon="cogOutline"></ion-icon> Admin
        </ion-button>
      </div>
      <div v-if="gameStore.showSelectGames && !showDesktopMode">
        <ion-button 
          expand="full"
          color="tertiary"
          @click="presentGameOptions"
          v-if="gameStore.selectedGames.length > 0">
          Modify Selected Games ({{ gameStore.selectedGames.length }})
        </ion-button>
      </div>
      <div v-if="gameStore.showSelectGames && !showDesktopMode">
        <ion-button
          expand="full"
          @click="toggleSelectGames"
          color="danger"
          >
          <ion-icon :icon="closeOutline"></ion-icon>
        </ion-button>
      </div>
    </div>

    <ion-modal :is-open="showModal" @did-dismiss="closeReportModal">
      <game-cards-popup :games="gameStore.selectedGames" @close="closeReportModal">
      </game-cards-popup>
    </ion-modal>
  </span>
</template>

<script setup>
import { defineComponent, onBeforeUnmount, ref, defineEmits } from "vue";
import { IonIcon, IonModal, IonButton } from "@ionic/vue";
import {
  // optionsOutline,
  // checkmarkDoneOutline,
  closeOutline,
  cogOutline
} from "ionicons/icons";
import { useGameStore } from "@/stores/gameStore";
// import FilterModal from "@/components/schedule/filters/FilterModal.vue";
import { openModal, showAlert } from "@/utils/useIonicComponents.js";
import SelectedGameModal from "@/components/schedule/select-game/SelectedGameModal.vue";
import AddGameModal from "@/components/schedule/new-game/AddGameModal.vue";
import UploadScheduleModal from "@/components/schedule/upload-csv/UploadScheduleModal.vue";
import GameCardsPopup from "./game-grid/GameCardsPopup.vue";
import { debounce } from "lodash";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { useAuthStore } from "@/stores/authStore";

defineComponent({
  name: "EditScheduleFab",
});
const emit = defineEmits(["resetScores"]);
const gameStore = useGameStore();
const preferenceStore = usePreferenceStore();
const authStore = useAuthStore();

const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);
const showModal = ref(false);

function openReportModal() {

  if (authStore.isLoggedIn) {
    showModal.value = true;
  }
}

function closeReportModal() {
  showModal.value = false;
// reportGames.showReportButton = false;
// console.log('props.game', props.game)
}


function toggleSelectGames() {
  gameStore.showSelectGames = !gameStore.showSelectGames;
  if (gameStore.showSelectGames) {
    // showToast("Select the Games to Reschedule or Postpone", "primary");
  } else {
    gameStore.selectedGames = [];
    gameStore.saveSelectGame();
  }
}
async function addGame() {
  await openModal(AddGameModal, "addGameModal");
}
async function uploadCSV() {
  await openModal(UploadScheduleModal, "uploadScheduleModal");
}

async function openSelectedGamesModal(choice) {
  await openModal(SelectedGameModal, "selectModal", { choice });
  gameStore.showSelectGames = !gameStore.showSelectGames;
}

async function presentEditOptions() {
  const choice = await showAlert({
    header: "Select Action",
    buttons: ["OK"],
    inputs: [
      {
        label: "Add Games",
        type: "radio",
        value: "add",
      },

      {
        label: "Upload Your Schedule",
        type: "radio",
        value: "import",
      },
      {
        label: "Select Games",
        type: "radio",
        value: "select",
      },
      {
        label: "Toggle Games Layout",
        type: "radio",
        value: "toggle",
      },
    ],
  });
  if (choice.data?.values) {
    if (choice.data.values === "add") {
      addGame();
    }
    if (choice.data.values === "import") {
      uploadCSV();
    }
    if (choice.data.values === "select") {
      toggleSelectGames();
    }
    if (choice.data.values === "toggle") {
      toggleGamesLayout();
    }
  }
}
async function resetScores() {
  await showAlert({
    header: "The scores for the selected games will be reset!",
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
      },
      {
        text: "Reset",
        role: "confirm",
        handler: async () => {
          await gameStore.resetScores();
          emit("resetScores");
        },
      },
    ],
  });
}

async function presentGameOptions() {
  const choice = await showAlert({
    header: "Actions for Selected Games",
    buttons: ["OK"],
    inputs: [
      {
        label: "Submit Scores for Selected Games",
        type: "radio",
        value: "reportScores",
      },
      {
        label: "Edit Teams for Selected Games",
        type: "radio",
        value: "EditTeams",
      },
      {
        label: "Postpone Selected Games",
        type: "radio",
        value: "Postpone",
      },
      {
        label: "Reschedule Selected Games",
        type: "radio",
        value: "Reschedule",
      },
      {
        label: "Reset Scores for Selected Games",
        type: "radio",
        value: "reset",
      },
      {
        label: "Delete Selected Games",
        type: "radio",
        value: "Delete",
      },
    ],
  });
  if (choice.data?.values) {
    if (choice.data.values === "reset") {
      resetScores();
    } else if (choice.data.values === 'reportScores') {
      openReportModal()
      
    } else {
      openSelectedGamesModal(choice.data.values);
    }
  }
}

function toggleGamesLayout() {
  const currentLayout = preferenceStore.getGamesLayout;
  const nextLayout = currentLayout === "grid" ? "table" : (currentLayout === "table" ? "brackets" : "grid");
  preferenceStore.setGamesLayout(nextLayout);
}


window.addEventListener("resize", debounce(resizeHandler, 1000));

function resizeHandler() {
  if (window.innerWidth >= 960) {
    showDesktopMode.value = true;
  } else {
    showDesktopMode.value = false;
  }
}
onBeforeUnmount(() => {
  //to close of fab if its open and the page is navigated away
  if (gameStore.showSelectGames) {
    toggleSelectGames();
  }
  window.removeEventListener("resize", resizeHandler);
});
</script>

<style scoped>
.custom-fab {
  position: fixed;
  bottom: 15px;
  left: 30px;
  display: flex;
}
</style>