<template>
  <ion-toolbar v-if="showDesktopMode" color="light">
    <ion-buttons slot="end">
      <ion-button
        fill="outline"
        class="selector-button"
        color="secondary"
        @click="openNewTeamModal"
      >
        <ion-icon :icon="addCircleOutline"></ion-icon
        >&nbsp;&nbsp;Add New Team
      </ion-button>
      <ion-button
        fill="outline"
        class="selector-button"
        color="tertiary"
        @click="openTeamsImportModal"
      >
        <ion-icon :icon="cloudUploadOutline"></ion-icon
        >&nbsp;&nbsp; Import Teams
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</template>

<script setup>
import { defineComponent, ref, onBeforeUnmount } from "vue";
import { IonToolbar, IonButtons, IonButton, IonIcon } from "@ionic/vue";
import { openModal } from "@/utils/useIonicComponents.js";
import NewTeamModal from "@/components/teams/NewTeamModal.vue";
import TeamsImportModal from "@/components/teams/import/TeamsImportModal.vue";

import { debounce } from "lodash";
import { addCircleOutline, cloudUploadOutline } from "ionicons/icons";

const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);
defineComponent({
  name: "TeamsToolbar",
});

async function openNewTeamModal() {
  await openModal(NewTeamModal, "newTeamModal");
}
async function openTeamsImportModal() {
  await openModal(TeamsImportModal, "teamsImportModal");
}

function resizeHandler() {
  if (window.innerWidth >= 960) {
    showDesktopMode.value = true;
  } else {
    showDesktopMode.value = false;
  }
}

window.addEventListener("resize", debounce(resizeHandler, 1000));

onBeforeUnmount(() => {
  window.removeEventListener("resize", resizeHandler);
});
</script>

<style scoped>
.selector-button {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  --padding-start: 10px;
  --padding-end: 10px;
}
</style>
