<template>
  <ion-segment
    mode="md"
    @ionChange="segmentChanged($event)"
    v-model="selectedSegment"
  >
    <ion-segment-button value="all">
      <ion-label>All</ion-label>
    </ion-segment-button>
    <ion-segment-button
      v-for="types in gameTypes"
      :key="types.value"
      :value="types.value"
    >
      <ion-label>{{ types.label }}</ion-label>
    </ion-segment-button>
  </ion-segment>
  <table style="width: 100%; table-layout: fixed">
    <tr style="height: 3rem">
      <th class="th-header" style="width: 3%">#</th>
      <th class="padding-right th-header" style="width: 6%">Start Date</th>
      <th class="padding-right th-header" style="width: 5%">Start Time</th>
      <th class="padding-right th-header" style="width: 5%">End Time</th>
      <th class="padding-right th-header" style="width: 6%">Park Name</th>
      <th class="padding-right th-header" style="width: 6%">Diamond</th>
      <th class="th-header" style="width: 10%">Division</th>
      <th class="th-header" style="width: 20%">Away Team</th>
      <th class="th-header" style="width: 20%">Home Team</th>
      <th class="th-header" style="width: 10%">Mode</th>
      <th class="th-header" style="width: 5%">Best of</th>
      <th class="th-header" style="width: 7%">Actions</th>
    </tr>
    <add-new-game-form
      v-for="game in computedGames"
      :key="game.index"
      :game="game"
      :invalidRowIndices="props.invalidRowIndices"
      :index="game.index"
      @update:modelValue="handleGameUpdate(game.index, $event)"
      :frequentlyUsedTimes="frequentlyUsedTimes"
    />
  </table>
  <div class="add-game-button" @click="addNewGames">
    <ion-icon :icon="addCircleOutline"></ion-icon>&nbsp;&nbsp;New Game
  </div>
</template>

<script setup>
import { defineComponent, onMounted, ref, computed, defineProps } from "vue";
import { loadingController, IonIcon } from "@ionic/vue";
import { IonSegment, IonSegmentButton, IonLabel } from "@ionic/vue";
import { useNewGameStore } from "@/stores/newGameStore";
import AddNewGameForm from "@/components/schedule/new-game/desktop-view/AddNewGameForm.vue";
import { api } from "@/utils/axios";
import { useLeagueStore } from "@/stores/leagueStore";
import { addCircleOutline } from "ionicons/icons";

const selectedSegment = ref("all");

defineComponent({
  name: "AddNewGame",
});

// definethe invalid-row-indices prop
const props = defineProps({
  invalidRowIndices: {
    type: Array,
  },
});

// console.log("props", props);

const newGameStore = useNewGameStore();
const leagueStore = useLeagueStore();
const frequentlyUsedTimes = ref();
onMounted(async () => {
  // Used when importing games via csv
  // to dismiss the loading started in uploadcsv
  const isLoading = await loadingController.getTop();
  if (isLoading) {
    setTimeout(() => {
      isLoading.dismiss();
    }, 1000);
  }
  getFrequentlyUsedTimes();
});

const gameTypes = computed(() => {
  if (leagueStore.league.type === "tournament") {
    return [
      { label: "Pre-Seed", value: "Practice" },
      { label: "Round Robin", value: "Season" },
      { label: "Eliminations", value: "Playoffs" },
    ];
  } else {
    return [
      { label: "Practice", value: "Practice" },
      { label: "Season", value: "Season" },
      { label: "Playoffs", value: "Playoffs" },
    ];
  }
});

async function addNewGames() {
  // const isFormValid = await v$.value.$validate();
  // if (isFormValid) {
  newGameStore.addNewGame();
  if (selectedSegment.value === "Practice") {
    newGameStore.games[newGameStore.games.length - 1].mode = "Practice";
  }
  if (selectedSegment.value === "Playoffs") {
    newGameStore.games[newGameStore.games.length - 1].mode = "Playoffs";
  }
  // } else {
  //   showToast("Valid data in all the fields required", "danger", 6000);
  // }
}

async function getFrequentlyUsedTimes() {
  const res = await api.post(`/api/frequently-used-times`, {
    league_id: leagueStore.league.id,
  });
  frequentlyUsedTimes.value = res.data;
}

const segmentChanged = (event) => {
  selectedSegment.value = event.detail.value;
  // newGameStore.filterGames(selectedSegment.value);
};

const computedGames = computed(() => {
  // console.log("selectedSegment", selectedSegment.value);

  const games = newGameStore.games.map((game, index) => {
    return {
      ...game,
      index,
    };
  });

  // console.log("games", games);

  return games.filter((game) => {
    // console.log(game);
    if (selectedSegment.value === "all") {
      return true;
    } else {
      return game.mode === selectedSegment.value;
    }
  });
});

function handleGameUpdate(index, updatedGame) {
  // console.log('handleGameUpdate', index, updatedGame)
  newGameStore.games[index] = updatedGame;
  // Find the game in the original array using its ID
  // const originalIndex = newGameStore.games.findIndex(g => g.id === updatedGame.id);
  // if (originalIndex !== -1) {
  //   // Update the game in the original array
  //   newGameStore.games[originalIndex] = updatedGame;
  // }
}
</script>
<style scoped>
.add-game-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 2px dashed #555;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
}

.add-game-button:hover {
  background: #555;
}
.input-row {
  height: 3.5rem;
}
table {
  width: 100%;
  margin-top: 10px;
}
th {
  border-bottom: 1px solid var(--ion-color-medium);
}
.padding-right {
  padding-right: 5px;
}

.th-header {
  background: #282828;
  font-size: 12px;
  text-transform: uppercase;
}

.th-header {
  border-right: 1px solid var(--ion-color-medium);
  border-top: 1px solid var(--ion-color-medium);
}

.th-header:first-child {
  border-left: 1px solid var(--ion-color-medium);
}
</style>
