<template>
  <ion-content>
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
      "
      v-if="!selectDataToggle"
    >
      <ion-card
        style="width: 80%; margin: 0 auto; text-align: center"
        color="light"
      >
        <ion-card-header>
          <ion-card-title>{{ columns[currentColumn] }}</ion-card-title>
          <ion-card-subtitle
            >{{ columns[currentColumn] }} data that system found in the
            CSV</ion-card-subtitle
          >
        </ion-card-header>
        <ion-card-content>
          <ion-button
            fill="outline"
            color="danger"
            @click="selectDataToggle = !selectDataToggle"
            >Not Correct data ?</ion-button
          >
        </ion-card-content>
        <ion-card-content>
          <ion-list>
            <ion-item color="medium">
              <ion-label style="text-align: center">{{
                columns[currentColumn]
              }}</ion-label>
            </ion-item>
            <ion-item v-if="!getCSVColumnData?.length"
              ><ion-label style="text-align: center" class="ion-text-wrap"
                >Couldn't find {{ columns[currentColumn] }}s, Please click Not
                Correct Data</ion-label
              ></ion-item
            >
            <ion-item
              v-for="item in getCSVColumnData?.slice(0, 10)"
              :key="item"
            >
              <ion-label style="text-align: center">{{ item }}</ion-label>
            </ion-item>
          </ion-list>
        </ion-card-content>
        <ion-grid>
          <ion-row class="ion-justify-content-between">
            <ion-col size="4" size-md="2">
              <ion-button
                expand="full"
                fill="clear"
                color="dark"
                @click="currentColumn--"
                v-if="currentColumn > 0"
              >
                <ion-icon slot="start" :icon="arrowBackOutline" />
                Back
              </ion-button>
            </ion-col>
            <ion-col size="4" size-md="2">
              <ion-button
                expand="full"
                fill="clear"
                color="dark"
                v-if="currentColumn < columns.length - 1"
                @click="currentColumn++"
              >
                Next
                <ion-icon slot="end" :icon="arrowForwardOutline"></ion-icon>
              </ion-button>
              <ion-button expand="full" v-else @click="confirmSelections"
                >Confirm</ion-button
              >
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card>
    </div>
    <select-data
      :column="columns[currentColumn]"
      :columnAssociationKey="
        uploadCSV.columnAssociation[columnMap[columns[currentColumn]]]
      "
      @data-selected="dataSelected"
      v-if="selectDataToggle"
    />
  </ion-content>
</template>

<script setup>
import { defineComponent, ref, computed } from "vue";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonContent,
} from "@ionic/vue";
import { arrowForwardOutline, arrowBackOutline } from "ionicons/icons";
import { useUploadCSVStore } from "@/stores/UploadCSVStore";
import { showAlert } from "@/utils/useIonicComponents";
// import AssociateColumns from "@/components/schedule/upload-csv/AssociateColumns.vue";
import MergeLocations from "@/components/schedule/upload-csv/MergeLocations.vue";
import SelectData from "./SelectData.vue";

defineComponent({
  name: "FixColumns",
});
const columnMap = {
  Division: "division_id",
  "Park Name": "park_name",
  Diamond: "diamond_signifier",
  "Start Date": "start_date",
  "Start Time": "start_time",
  "End Time": "end_time",
  "Away Team": "select_away_team",
  "Home Team": "select_home_team",
};
const columns = Object.keys(columnMap);
const currentColumn = ref(0);
const selectDataToggle = ref();
const uploadCSV = useUploadCSVStore();

if (
  uploadCSV.checkForSavedAssociation &&
  uploadCSV.userChoiceForLoadingPreviousData === true
) {
  uploadCSV.userChoiceForLoadingPreviousData = false;
  uploadCSV.columnAssociation = uploadCSV.checkForSavedAssociation;
  confirmSelections();
}

const getCSVColumnData = computed(() => {
  return uploadCSV.extractDataForSelect[
    uploadCSV.columnAssociation[columnMap[columns[currentColumn.value]]]
  ];
});

function dataSelected(ev) {
  uploadCSV.columnAssociation[columnMap[columns[currentColumn.value]]] = ev;
  selectDataToggle.value = !selectDataToggle.value;
}

function checkForDuplicates(obj) {
  const valuesArray = Object.values(obj);
  const valuesSet = new Set(valuesArray);
  return valuesSet.size === valuesArray.length;
}

function checkForNotNull(keys, obj) {
  for (let i = 0; i < keys.length; i++) {
    if (obj[keys[i]] === null || obj[keys[i]] === undefined) {
      console.log(keys[i], obj, keys);
      return false;
    }
  }
  return true;
}

async function confirmSelections() {
  if (checkForDuplicates(uploadCSV.columnAssociation)) {
    if (
      checkForNotNull(Object.values(columnMap), uploadCSV.columnAssociation)
    ) {
      if ((await uploadCSV.validateData()) && uploadCSV.validateDateTime()) {
        uploadCSV.saveAssociation();
        const ionNav = document.querySelector("ion-nav");
        ionNav.push(MergeLocations);
      }
    } else {
      await showAlert({
        header: "Invalid Association.",
        message:
          "All columns required for import have not been found. Please go back and fix any missing data.",
        buttons: [
          {
            text: "OK",
            role: "confirm",
          },
        ],
      });
    }
  } else {
    await showAlert({
      header: "Invalid Association.",
      message:
        "Same data has been selected for more than one column. Please go back and fix any missing data.",
      buttons: [
        {
          text: "OK",
          role: "confirm",
        },
      ],
    });
  }
}
</script>
<style scoped></style>
