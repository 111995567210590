<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-title>Game Details</ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="emitClose">
                        <ion-icon :icon="closeOutline" />
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content style="position: relative;">
            <ion-card :class="[newGameClass, 'game-card']" style="position: relative; z-index: 10;">
                <game-card-header 
                    :game="props.game" 
                    :inPopup="true"
                />
                <game-card-content
                    :key="resetScore"
                    :game="props.game"
                    :inPopup="true"
                    :clearScoreCount="clearScoreCount"
                    @close="closePopup"
                    @showReportButton="showReportButtonFunction"
                    @hideReportButton="hideReportButtonFunction"
                    :showReportButton="showReportButton"
                    :reportScoreCount="reportScoreCount"
                    @resetGame="resetScore++"
                />
                <!-- <game-card-actions 
                    @resetGame="resetScore++" 
                    :game="props.game" 
                /> -->
            </ion-card>
            <ion-row v-if="showReportButton">
                <ion-col>
                    <ion-button
                        expand="full"
                        color="success"
                        size="small"
                        @click="reportScoreCounter"
                        fill="solid">
                        <ion-icon :icon="alertCircleOutline"></ion-icon>&nbsp;
                        {{ game.score_reported === 1 ? 'UPDATE SCORE' : 'SUBMIT SCORE' }}
                    </ion-button>
                </ion-col>
                <ion-col>
                    <ion-button
                        expand="full"
                        color="danger"
                        size="small"
                        fill="solid"
                        @click="clearScores"
                    >
                        <ion-icon :icon="closeOutline"></ion-icon>&nbsp;
                        CLEAR

                    </ion-button>
                </ion-col>
            </ion-row>
            <div class="arrow-container" v-if="!isInputDisabled && !showReportButton">
                <svg
                    width="200"
                    height="100"
                    viewBox="0 0 200 100"
                    class="arrow-svg"
                    xmlns="http://www.w3.org/2000/svg"
                >
                <path
                    d="M10,80 C50,80 150,80 150,20"
                    fill="none"
                    stroke="var(--ion-color-primary)"
                    stroke-width="3"
                />
                <polygon
                    points="150,20 140,30 160,30"
                    fill="var(--ion-color-primary)"
                />
            </svg>
            </div>
            <h1 style="color: var(--ion-color-dark); text-align: center; text-transform: uppercase; z-index: 20; position: relative; margin-top: 50px;" v-if="!isInputDisabled && !showReportButton">
                <span
                    style="background-color: var(--ion-color-primary); padding: 0 10px"
                >
                    Submit Your Score HERE
                </span>
            </h1>
            <div id="gameCardPopup"></div>

            <h6 style="color: var(--ion-color-dark); text-align: center; text-transform: uppercase; z-index: 20; position: relative; margin-top: 50px;" v-if="isInputDisabled">
                <span
                    style="background-color: var(--ion-color-danger); padding: 0 10px"
                >
                    Can't submit score for games that do not have teams yet.
                </span>
            </h6>
            <p v-if="isInputDisabled" style="text-align: center;">Report previous scores to move teams forward into this game or edit your schedule.</p>


            <!-- <br><br>
            <ion-button
                style="width: 100%"
                expand="full"
                v-if="reportGames.showReportButton"
                @click="reportScoreCount.value++"
                class="report-score-button"
                ><ion-icon slot="start" :icon="checkmarkDoneOutline" />Report
                Score</ion-button
            > -->
           <!-- 
                buttons
                - Enter Your Shortcode (if it's not already there)
                - Edit Teams (if convenor)
                - Undo (if score has been reported and convenor)
                - Postpone
                - Reschedule

                - More coming soon
           -->
        </ion-content>
    </ion-page>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref } from "vue";
import { IonPage, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonContent, IonCard } from "@ionic/vue";
import { closeOutline, alertCircleOutline } from "ionicons/icons";
import { differenceInSeconds } from "date-fns";

// import GameCardActions from "@/components/schedule/game-grid/GameCardActions.vue";
import GameCardHeader from "@/components/schedule/game-grid/GameCardHeader.vue";
import GameCardContent from "@/components/schedule/game-grid/GameCardContent.vue";
import { useReportGameStore } from "@/stores/reportGameStore";
// import { useGameStore } from "@/stores/gameStore";
// import { usePreferenceStore } from "@/stores/preferenceStore";

const showReportButton = ref(false)

const props = defineProps({
    game: {
        type: Object,
        required: true,
    },
});

const reportGames = useReportGameStore();
// const gameStore = useGameStore();
// const preferenceStore = usePreferenceStore();

reportGames.clearGamesFromReport()

const clearScoreCount = ref(0)
const reportScoreCount = ref(0)

const resetScore = ref(0); //to reset score by re rendering game-card-content

// reportGames.showReportButton = false

const newGameClass = computed(() => {
  if (differenceInSeconds(new Date(), new Date(props.game.created_at)) < 10) {
    return "new-game";
  }
  return "";
});

const emit = defineEmits(['close']);
  
const emitClose = () => {
    emit('close');
};

const isInputDisabled = computed(() => {
  return !props.game.awayTeam || !props.game.homeTeam;
});




const reportScoreCounter = () => {
    // console.log('reportScoreCounter on GameCardPopupWrapper')
    reportScoreCount.value++
}

const clearScores = () => {
    // console.log('clearScores on GameCardPopupWrapper')
    clearScoreCount.value++
}


const showReportButtonFunction = () => {
    showReportButton.value = true
    // console.log('showReportButton on GameCardSSSSSPopup')
}

const hideReportButtonFunction = () => {
    showReportButton.value = false
    // console.log('showReportButton', showReportButton.value)
    // console.log('hideReportButton on GameCardPopup')
}


const closePopup = () => {
    showReportButton.value = false
    emitClose()
}

</script>

<style scoped>
.arrow-container {
    position: absolute;
    top: 160px; /* Adjust as needed */
    right: 0%; /* Centers the arrow in the container */
    transform: translateX(-6%); /* Adjust horizontal position */
    z-index: 0; /* Ensure it's above other elements */
}

.arrow-svg {
    /* Adjust the size and rotation as needed */
    width: 100%; /* Take full width to align the arrow */
    height: auto;
}

</style>
