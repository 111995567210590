<template>
  <base-modal modal-title="Add New Bracket" @close="close">
    <template #headerStart>
      <ion-button @click="close"> Close </ion-button>
    </template>
    <ion-card style="min-width: 20rem; padding: 0; margin: 0">
      <ion-card-content>
        <ion-grid>
          <ion-row>
            <ion-col class="ion-align-self-center label-left-col"
              ><ion-label>Name</ion-label></ion-col
            >
            <ion-col
              ><ion-input class="teams-input" v-model="bracket.name"></ion-input
            ></ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-align-self-center label-left-col"
              ><ion-label>Single or Double</ion-label></ion-col
            >
            <ion-col>
              <v-select
                class="teams-input"
                v-model="bracket.type"
                :options="[
                  { label: 'Single', value: 'single_elimination' },
                  { label: 'Double', value: 'double_elimination' },
                ]"
                :clearable="false"
                append-to-body
                :calculate-position="withPopper"
                :reduce="(option) => option.value"
              >
                <template #open-indicator="{ attributes }">
                  <ion-icon
                    :icon="caretDownOutline"
                    style="font-size: 0.8rem"
                    v-bind="attributes"
                  ></ion-icon>
                </template>
              </v-select>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-align-self-center label-left-col"
              ><ion-label>Size</ion-label></ion-col
            >
            <ion-col>
              <ion-input
                class="teams-input"
                type="number"
                v-model="bracket.size"
                @ion-change="addSeedings"
              ></ion-input>
            </ion-col>
          </ion-row>
          <ion-row v-if="bracket.seeding > 0">
            <ion-col>Pick Teams / Seeds</ion-col>
          </ion-row>
          <ion-row v-for="(seeding, index) in bracket.seeding" :key="index"
            ><ion-col>
              <v-select
                v-model="bracket.seeding[index]"
                :options="
                  leagueStore.league.teams.map((team) => ({
                    name: team.name,
                    team_id: team.id,
                  }))
                "
                label="name"
                :clearable="false"
                append-to-body
                :calculate-position="withPopper"
              >
                <template #open-indicator="{ attributes }">
                  <ion-icon
                    :icon="caretDownOutline"
                    style="font-size: 0.8rem"
                    v-bind="attributes"
                  ></ion-icon>
                </template>
              </v-select>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
    <template #footer>
      <ion-button expand="full" @click="createBracket"
        >Create Bracket</ion-button
      >
    </template>
  </base-modal>
</template>
<script setup>
import BaseModal from "@/components/layouts/BaseModal.vue";
import {
  IonCol,
  IonRow,
  IonGrid,
  IonLabel,
  IonCard,
  IonCardContent,
  IonInput,
  IonButton,
  modalController,
  IonIcon,
} from "@ionic/vue";
import { caretDownOutline } from "ionicons/icons";
import { defineComponent, ref } from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import useVuelidate from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
import { showToast, showLoading } from "@/utils/useIonicComponents.js";
import { api } from "@/utils/axios";
import { useLeagueStore } from "@/stores/leagueStore";
import { useRouter, useRoute } from "vue-router";
import { createPopper } from "@popperjs/core";

defineComponent({
  name: "AddBracketModal",
});
const bracket = ref({});
const leagueStore = useLeagueStore();
const router = useRouter();
const route = useRoute();

router.replace({
  query: {
    ...route.query,
    open_modal: "AddBracket",
  },
});

function addSeedings() {
  if (
    bracket.value.size < 2 ||
    (bracket.value.size & (bracket.value.size - 1)) !== 0
  ) {
    showToast("The size should be a power of 2", "danger", 6000);
    bracket.value.seeding = [];
    delete bracket.value.size;
  } else {
    bracket.value.seeding = [];
    for (let i = 0; i < bracket.value.size; i++) {
      bracket.value.seeding.push({
        name: `Team ${i + 1}`,
      });
    }
  }
}
function withPopper(dropdownList, component) {
  dropdownList.style.width = "30rem";
  const popper = createPopper(component.$refs.toggle, dropdownList, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -1],
        },
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          component.$el.classList.toggle("drop-up", state.placement === "top");
        },
      },
    ],
  });

  return () => popper.destroy();
}

async function createBracket() {
  console.log(bracket.value);
  const isFormValid = await v$.value.$validate();
  if (isFormValid) {
    if (
      bracket.value.size < 2 ||
      (bracket.value.size & (bracket.value.size - 1)) !== 0
    ) {
      showToast("The size should be a power of 2", "danger", 6000);
      return;
    }
    bracket.value.tournamentId = leagueStore.league.id;
    const loading = await showLoading("Creating Brackets Please Wait...");
    try {
      const response = await api.post("/brackets/create", {
        bracket: bracket.value,
      });
      if (response.data?.response?.id) {
        loading.dismiss();
        showToast("Brackets successfully Created.", "success", 6000);
        await close();
        router.push({
          name: "bracketDetails",
          params: { bracketId: response.data.response.id },
        });
      } else {
        throw "No id in response";
      }
    } catch (err) {
      console.log(err);
      loading.dismiss();
      showToast(
        "Something went wrong. If this persists, please contact us.",
        "danger",
        6000
      );
    }
  } else {
    showToast("Invalid Inputs", "danger", 6000);
  }
}

const rules = {
  name: { required },
  type: { required },
  size: { required, numeric },
};

const v$ = useVuelidate(rules, bracket);

async function close() {
  router.replace({
    query: {
      ...route.query,
      open_modal: "",
    },
  });
  await modalController.dismiss();
}
</script>
<style scoped>
.teams-input {
  border-bottom: 3px solid var(--ion-color-medium);
  /* text-align: center; */
}
.color-swatch {
  margin-top: 0.5rem;
}
@media only screen and (max-width: 600px) {
  .color-swatch {
    margin-left: 2.5rem;
    margin-top: 1rem;
  }
}
</style>
<style scoped>
.v-select {
  --vs-controls-color: var(--ion-color-step-300);
  --vs-border-width: 0;

  --vs-dropdown-bg: var(--ion-background-color);
  --vs-dropdown-option-color: var(--ion-text-color);

  --vs-selected-bg: var(--ion-color-primary);
  --vs-selected-color: var(--ion-text-color);

  --vs-search-input-color: var(--ion-color-step-300);

  --vs-dropdown-option--active-bg: #664cc3;
  --vs-dropdown-option--active-color: #eeeeee;
  --vs-dropdown-min-width: 1rem;
  --vs-dropdown-option--deselect-color: red;

  --vs-disabled-bg: var(--ion-color-light);
  --vs-disabled-color: var(--ion-color-light);
  --vs-disabled-controls-color: var(--ion-color-light);
}
/* .vs__selected-options {
  flex-direction: row-reverse;
}
.vs--open .vs__selected-options {
  flex-direction: row;
}
.vs--open .vs__selected {
  flex-grow: 0;
} */
</style>
