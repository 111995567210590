<template>
  <ion-page class="container">
    <div class="content">
      <img :src="leagueStore.league.logo_url || 'https://cdn.blacktiecollab.com/slopitchcentral/images/slopitchcentral.png'" class="logo" alt="League Logo">
      <ion-label class="text"><slot></slot></ion-label>
      <div class="buttons" v-if="authStore.isConvenor">
        <ion-button 
          size="small"
          color="secondary"
          fill="outline" @click="openNewTeamModal"> 
            <ion-icon :icon="addCircleOutline"></ion-icon>&nbsp;&nbsp;
            ADD NEW TEAM
        </ion-button>
        <ion-button 
          fill="outline"
          size="small" 
          @click="addGame">
          <ion-icon :icon="addCircleOutline"></ion-icon>&nbsp;&nbsp;
          ADD GAMES
        </ion-button>
        <ion-button 
          size="small" 
          fill="outline"
          color="warning"
          @click="uploadCSV"><ion-icon :icon="cloudUploadOutline"></ion-icon
        >&nbsp;&nbsp; IMPORT GAMES</ion-button>
      </div>
    </div>
  </ion-page>
</template>

<script setup>
import { defineComponent } from "vue";
import {
  IonPage,
  IonLabel,
  IonButton,
  IonIcon
} from "@ionic/vue";
import { addCircleOutline, cloudUploadOutline } from "ionicons/icons";
import { openModal } from "@/utils/useIonicComponents.js";
import NewTeamModal from "@/components/teams/NewTeamModal.vue";
import AddGameModal from "@/components/schedule/new-game/AddGameModal.vue";
import UploadScheduleModal from "@/components/schedule/upload-csv/UploadScheduleModal.vue";
import { useAuthStore } from "@/stores/authStore";
import { useLeagueStore } from "@/stores/leagueStore";
const leagueStore = useLeagueStore(); 

defineComponent({
  name: "NoDataMessage",
});
const authStore = useAuthStore();
async function openNewTeamModal() {
  await openModal(NewTeamModal, "newTeamModal");
}
async function uploadCSV() {
  await openModal(UploadScheduleModal, "uploadScheduleModal");
}
async function addGame() {
  await openModal(AddGameModal, "addGameModal");
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  max-width: 200px; /* Adjust based on your needs */
  margin-bottom: 20px;
}

.text {
  margin-bottom: 20px;
  font-size: 25px;
}

.buttons ion-button {
  margin: 5px;
}
</style>