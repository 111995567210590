<template>
  <ion-grid>
    <ion-row>
      <ion-col v-for="(game, index) in gameStore.selectedGames" :key="index">
        <ion-card style="min-width: 20rem; padding: 0; margin: 0">
          <ion-grid>
            <ion-row>
              <ion-col class="ion-align-self-center ion-no-padding">
                <ion-button
                  style="font-size: 12px"
                  color="danger"
                  fill="clear"
                  @click="gameStore.removeSelectedGameIndex(index)"
                  ><ion-icon slot="icon-only" :icon="closeCircleOutline"
                /></ion-button>
              </ion-col>
              <ion-col
                class="ion-no-padding"
                style="flex-grow: 20; margin-right: 3.5rem"
              >
                <ion-card-header>
                  <ion-card-subtitle style="text-align: center"
                    >Selected Game {{ index + 1 }}
                  </ion-card-subtitle>
                </ion-card-header>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-card-content>
            <edit-selected-game-form-mobile
              :index="index"
              :choice="props.choice"
            />
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script setup>
import { defineComponent, defineProps } from "vue";
import {
  IonCol,
  IonRow,
  IonGrid,
  IonCard,
  IonCardSubtitle,
  IonCardHeader,
  IonCardContent,
  IonButton,
  IonIcon,
} from "@ionic/vue";
import { closeCircleOutline } from "ionicons/icons";
import { useGameStore } from "@/stores/gameStore";
import EditSelectedGameFormMobile from "@/components/schedule/select-game/mobile-view/EditSelectedGameFormMobile.vue";

defineComponent({
  name: "EditSelectedGameMobile",
});
const props = defineProps({ choice: String });
const gameStore = useGameStore();
</script>
<style scoped>
.input-row {
  height: 3.5rem;
}
</style>
