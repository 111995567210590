<template>
  <ion-card
    style="margin: 0 auto"
    :style="{ width: showDesktopMode ? '50%' : '100%' }"
  >
    <ion-grid>
      <ion-row class="ion-justify-content-between">
        <ion-col size="6">
          <h1 style="margin-top: 0">Primary Color</h1>
        </ion-col>
        <ion-col size="2">
          <v-swatches
            class="color-swatch"
            v-model="primaryColor"
            swatches="text-advanced"
            show-fallback
            fallback-input-type="color"
            popover-y="bottom"
            popover-x="left"
            @close="emit('primaryColorChanged', primaryColor)"
          />
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-between">
        <ion-col size="6">
          <h1 style="margin-top: 0">Secondary Color</h1>
        </ion-col>
        <ion-col size="2">
          <v-swatches
            class="color-swatch"
            v-model="secondaryColor"
            swatches="text-advanced"
            show-fallback
            fallback-input-type="color"
            popover-y="bottom"
            popover-x="left"
            @close="emit('secondaryColorChanged', secondaryColor)"
          />
        </ion-col>
      </ion-row>
      <ion-row>
        <h1>Choose Logo</h1>
      </ion-row>

      <div v-if="logoThumbnail">
        <img :src="logoThumbnail" />
        <ion-button expand="full" color="danger" @click="resetLogo"
          >Remove</ion-button
        >
      </div>
      <div
        v-else
        style="
          height: 250px;
          border-style: dashed;
          display: flex;
          align-items: center;
        "
        @drop.prevent="onFilePicked($event, 'logo')"
        @dragover.prevent
      >
        <ion-item
          style="
            width: 100%;
            text-align: center;
            --background: var(--ion-color-background);
          "
          lines="none"
        >
          <ion-label>Drag & Drop here or</ion-label>
          <input
            type="file"
            @change="onFilePicked($event, 'logo')"
            accept="image/*"
          />
        </ion-item>
      </div>
      <ion-row>
        <h1>Upload Background</h1>
      </ion-row>
      <div v-if="backgroundThumbnail">
        <img :src="backgroundThumbnail" />
        <ion-button expand="full" color="danger" @click="resetBackground"
          >Remove</ion-button
        >
      </div>
      <div
        v-else
        style="
          height: 250px;
          border-style: dashed;
          display: flex;
          align-items: center;
        "
        @drop.prevent="onFilePicked($event, 'background')"
        @dragover.prevent
      >
        <ion-item
          style="
            width: 100%;
            text-align: center;
            --background: var(--ion-color-background);
          "
          lines="none"
        >
          <ion-label>Drag & Drop here or</ion-label>
          <input
            type="file"
            @change="onFilePicked($event, 'background')"
            accept="image/*"
          />
        </ion-item>
      </div>
    </ion-grid>
  </ion-card>
</template>
<script setup>
import { defineComponent, ref, onBeforeUnmount, defineEmits } from "vue";
import {
  IonCol,
  IonRow,
  IonGrid,
  IonItem,
  IonLabel,
  IonCard,
  IonButton,
} from "@ionic/vue";
import { VSwatches } from "vue3-swatches";
import { useLeagueStore } from "@/stores/leagueStore";
import { debounce } from "lodash";

import "vue3-swatches/dist/style.css";

defineComponent({
  name: "AppearanceComponent",
});
const emit = defineEmits([
  "primaryColorChanged",
  "secondaryColorChanged",
  "logoFilePicked",
  "backgroundFilePicked",
  "resetLogo",
  "resetBackground",
]);
const leagueStore = useLeagueStore();

const primaryColor = ref(leagueStore.league.primary_color || "#7d38f0");

const secondaryColor = ref(leagueStore.league.secondary_color || "#3dc2ff");

const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);
const logoThumbnail = ref();
const backgroundThumbnail = ref();

function onFilePicked(event, type) {
  let files;
  try {
    //for files picked using file picker
    files = [...Array.from(event.target.files)];
  } catch (err) {
    //if files are dragged and dropped
    files = [...Array.from(event.dataTransfer.files)];
    files = files.filter(
      (file) => !(file.size % 4096 === 0 && file.type === "") //to find if the drag & drop file is directory or file
    );
  }
  const file = files[0];
  if (file.type.startsWith("image/")) {
    let reader = new FileReader();
    reader.onload = (e) => {
      if (type === "logo") {
        logoThumbnail.value = e.target.result;
      } else {
        backgroundThumbnail.value = e.target.result;
      }
    };
    try {
      //for files picked using file picker
      reader.readAsDataURL(event.target.files[0]);
    } catch (err) {
      //if files are dragged and dropped
      reader.readAsDataURL(event.dataTransfer.files[0]);
    }
    if (type === "logo") {
      emit("logoFilePicked", file);
    } else {
      emit("backgroundFilePicked", file);
    }
  }
}

function resetLogo() {
  logoThumbnail.value = null;
  emit("resetLogo");
}
function resetBackground() {
  backgroundThumbnail.value = "";
  emit("resetBackground");
}

window.addEventListener("resize", debounce(resizeHandler, 1000));

function resizeHandler() {
  if (window.innerWidth >= 960) {
    showDesktopMode.value = true;
  } else {
    showDesktopMode.value = false;
  }
}
onBeforeUnmount(() => {
  window.removeEventListener("resize", resizeHandler);
});
</script>
