<template>
  <span>
    <h1 style="color: var(--ion-color-dark); text-align: center; text-transform: uppercase;">
      <span
          style="background-color: var(--ion-color-primary); padding: 0 10px"
      >
          1. Choose Your Favourite Teams
      </span>
    </h1>
    <p style="text-align: center;">Use the dropdown below to display the schedule of only the teams you're on, or all teams.</p>
    <!-- <p>Display:</p> -->
    <ion-item>
      <team-filter />
    </ion-item>
    <p style="text-align: center;">You will only be able to see the teams you select in the schedule.</p>
    <!-- <card-wrapper
      cardTitle="Favourite Teams"
      cardSubtitle="These teams will be highlighted in the schedule, standings and more"
    >
      
    </card-wrapper> -->
  </span>
</template>
<script setup>
import { defineComponent, ref } from "vue";
// import CardWrapper from "@/components/layouts/CardWrapper.vue";
//   import { showToast } from "@/utils/useIonicComponents";
// import { useLeagueStore } from "@/stores/leagueStore";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { IonItem }  from "@ionic/vue";
import TeamFilter from "@/components/schedule/filters/TeamFilter.vue";

defineComponent({
  name: "FilterFavouriteTeam",
});
// const leagueStore = useLeagueStore();
const preferenceStore = usePreferenceStore();
const favouriteTeams = ref([]);
favouriteTeams.value = preferenceStore.userFavouriteTeams || [];
</script>
