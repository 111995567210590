<template>
  <div v-for="games in gameStore.dateWiseFilteredGames" :key="games">
    <div class="date-card" style="width: 100%">
      <h1 style="color: var(--ion-color-dark)">
        <span
          style="background-color: var(--ion-color-primary); padding: 0 10px"
        >
          {{
            games.date !== "null"
              ? format(new Date(games.date.replace(/-/g, "/")), "EEEE, MMM do")
              : "TBA"
          }}
        </span>
      </h1>
    </div>
    <div class="overflow-table">
      <table>
        <tr style="height: 3rem">
          <th>Start</th>
          <th>End</th>
          <th>Venue</th>
          <th>Diamond</th>
          <th>Division</th>
          <th>Away Team</th>
          <th>Score</th>
          <th>Score</th>
          <th>Home Team</th>
          <th>Mode</th>
          <th v-if="gameStore.showSelectGames">Select Games</th>
          <th v-if="reportGames.games.length > 0">Reset</th>
        </tr>
        <game-table-rows
          v-for="game in games.games"
          :key="game.id"
          :game="game"
        />
      </table>
    </div>
    <div class="in-content"></div>
  </div>
  <no-game-card v-if="gameStore.dateWiseFilteredGames.length < 1" />
</template>
<script setup>
import { defineComponent } from "vue";
// import { IonCard, IonCardContent } from "@ionic/vue";
import { useGameStore } from "@/stores/gameStore";
import { useReportGameStore } from "@/stores/reportGameStore";
import NoGameCard from "@/components/schedule/NoGameCard.vue";
import { format } from "date-fns";
import GameTableRows from "@/components/schedule/game-table/GameTableRows.vue";
// import NewAdComponent from "@/components/ads/NewAdComponent.vue";

defineComponent({
  name: "ScheduledGamesTableAll",
});
const gameStore = useGameStore();
const reportGames = useReportGameStore();
reportGames.games = []; // to reset edited score, when component is re rendered.
</script>
<style scoped>
table {
  width: 100%;
  text-align: center;
  min-width: 1000px;
}
th {
  border-bottom: 1px solid var(--ion-color-medium);
}

.date-card {
  border-top: 2px solid #ccc;
  width: 100%;
  padding-bottom: 10px;
  text-align: center;
  margin-top: 15px;
}
.date-card h1 {
  text-transform: uppercase;
  font-weight: 700;
  @media all and (max-width: 600px) {
    font-size: 18px;
  }
}
.overflow-table {
  overflow-x: auto; /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}
</style>
