<template>
  <base-modal modal-title="Forgot Password ?">
    <ion-card>
      <ion-card-content>
        <form @submit.prevent="requestResetEmail">
          <ion-label style="margin-bottom: 1rem"
            >Please Enter you registered email</ion-label
          >
          <ion-input
            inputmode="email"
            placeholder="Email"
            type="email"
            v-model="user.email"
            @ionBlur="v$.email.$touch"
          ></ion-input>
          <ion-label color="danger" v-if="v$.email.$errors.length">{{
            v$.email.$errors[0].$message
          }}</ion-label>
          <ion-button
            style="margin-top: 1rem"
            expand="full"
            type="submit"
            v-if="!loading"
            >Send Reset Email</ion-button
          >
          <ion-button expand="full" v-if="loading"><ion-spinner /></ion-button>
        </form>
      </ion-card-content>
    </ion-card>
  </base-modal>
</template>

<script setup>
import {
  IonInput,
  IonButton,
  IonSpinner,
  IonLabel,
  IonCard,
  IonCardContent,
  modalController,
} from "@ionic/vue";
import { defineComponent, reactive, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import BaseModal from "@/components/layouts/BaseModal.vue";
import { showToast } from "@/utils/useIonicComponents.js";
import { api } from "@/utils/axios";

defineComponent({
  name: "ForgotPasswordModal",
});
const loading = ref(false);
const user = reactive({
  email: "",
});

const rules = {
  email: { required, email },
};

const v$ = useVuelidate(rules, user);

async function requestResetEmail() {
  const isFormValid = await v$.value.$validate();
  if (isFormValid) {
    loading.value = true;
    try {
      const result = await api.post("/auth/reset-password", {
        email: user.email,
      });
      if (result.status === 200) {
        console.log("success");
        showToast("Successfully Sent Reset Email", "success");
        loading.value = false;
        modalController.dismiss();
      }
    } catch (err) {
      loading.value = false;
      console.log(err);
      showToast(
        "Something went wrong. If this persists, please contact us.",
        "danger",
        6000
      );
    }
  }
}
</script>
