<template>
  <ion-list
    style="margin: 4rem auto"
    :style="{ width: showDesktopMode ? '60%' : '95%' }"
  >
    <ion-list-header><ion-label>ACCOUNT DETAILS</ion-label></ion-list-header>
    <ion-item color="light"
      ><ion-label> Name</ion-label>
      <ion-label>{{ authStore.user?.full_name }}</ion-label></ion-item
    >
    <ion-item
      ><ion-label>Email</ion-label>
      <ion-label>{{ authStore.user?.email }}</ion-label></ion-item
    >
    <ion-item color="light"
      ><ion-label>Phone</ion-label>
      <ion-label>{{ authStore.user?.phone }}</ion-label></ion-item
    >
  </ion-list>
</template>
<script setup>
import { defineComponent, ref, onBeforeUnmount } from "vue";
import { IonList, IonListHeader, IonItem, IonLabel } from "@ionic/vue";
import { debounce } from "lodash";
import { useAuthStore } from "@/stores/authStore";

defineComponent({
  name: "AccountComponent",
});
const authStore = useAuthStore();
const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);

window.addEventListener("resize", debounce(resizeHandler, 1000));

function resizeHandler() {
  if (window.innerWidth >= 960) {
    showDesktopMode.value = true;
  } else {
    showDesktopMode.value = false;
  }
}
onBeforeUnmount(() => {
  window.removeEventListener("resize", resizeHandler);
});
</script>
