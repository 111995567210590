<template>

  <div style="width: 100%">
    <div
      class="select-this-game"
      @click="gameStore.selectGame(props.game)"
      v-if="!isGameSelected">
        Select Game
    </div>
    <div
      class="game-selected"
      @click="gameStore.removeSelectedGame(props.game)"
      v-else
      >
      Selected
    </div>  
    <!-- <div
      @click="undoScores"
      v-if="isGameEdited">
      Undo Scores?
    </div> -->
  </div>

</template>

  <!-- <ion-list v-if="gameStore.showSelectGames">
    <ion-item
      color="tertiary"
      class="small-button"
      button
      @click="gameStore.selectGame(props.game)"
      v-if="!isGameSelected"
    >
      Select This Game
    </ion-item>
    <ion-item
      color="success"
      class="small-button"
      button
      v-else
      @click="gameStore.removeSelectedGame(props.game)"
      >Game Selected</ion-item
    >
  </ion-list>
  <ion-list v-if="isGameEdited">
    <ion-item
      class="small-button ion-no-padding"
      button
      size="small"
      color="danger"
      @click="undoScores"
      >Undo Scores</ion-item
    >
  </ion-list> -->

<script setup>
import { defineComponent, defineProps, computed } from "vue";
import { useGameStore } from "@/stores/gameStore";


defineComponent({
  name: "GameCardActions",
});
const props = defineProps(["game"]);

const gameStore = useGameStore();


const isGameSelected = computed(() => {
  const game = gameStore.selectedGames.filter(
    (game) => game.id === props.game.id
  );
  return game.length > 0 ? true : false;
});

</script>

<style scoped>


.select-this-game {
  background-color: var(--ion-color-tertiary);
  padding: 0.5rem 0.2rem;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
}

.game-selected {
  background-color: var(--ion-color-success);
  padding: 0.5rem 0.2rem;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  color: #043506;
  cursor: pointer;
}
</style>
