import { usePreferenceStore } from "@/stores/preferenceStore";
import { useGameStore } from "@/stores/gameStore";

export default function updateFiltersOnLoad(route, router) {
  const preferenceStore = usePreferenceStore();
  const gameStore = useGameStore();
  // if there is filter query parameters then preference store will be overwritten
  //get filter values from preferenceStore on load
  if (Object.values(preferenceStore.userFilter).length) {
    console.log("updateFiltersOnLoad Called", preferenceStore.userFilter);
    const query = {};
    if (preferenceStore.userFilter.showReportedGames === false) {
      gameStore.showReportedGames = false;
      query.showReportedGames = false;
    } else {
      gameStore.showReportedGames = true;
    }
    if (preferenceStore.userFilter.division_id) {
      gameStore.gameFilters.division_id =
        preferenceStore.userFilter.division_id;
      query.division_id = gameStore.gameFilters.division_id;
    }
    if (preferenceStore.userFilter.park_name) {
      gameStore.gameFilters.park_name = preferenceStore.userFilter.park_name;
      query.park_name = gameStore.gameFilters.park_name;
    }
    if (preferenceStore.userFilter.diamond_signifier) {
      gameStore.gameFilters.diamond_signifier =
        preferenceStore.userFilter.diamond_signifier;
      query.diamond_signifier = gameStore.gameFilters.diamond_signifier;
    }
    if (preferenceStore.userFilter.team) {
      gameStore.gameFilters.team = preferenceStore.userFilter.team;
      if (
        gameStore.gameFilters.team.length > 0 &&
        gameStore.gameFilters.team[0] !== null
      ) {
        query.team = gameStore.gameFilters.team.toString();
      }
    }
    if (preferenceStore.userFilter.start_time) {
      gameStore.gameFilters.start_time = preferenceStore.userFilter.start_time;
      query.timeslot = gameStore.gameFilters.start_time;
    }
    if (preferenceStore.userFilter.month) {
      gameStore.currentShownDate = "all";
      gameStore.gameFilters.month = preferenceStore.userFilter.month;
      query.month = gameStore.gameFilters.month;
    }
    if (preferenceStore.userFilter.day) {
      gameStore.currentShownDate = "all";
      gameStore.gameFilters.day = preferenceStore.userFilter.day;
      query.day = gameStore.gameFilters.day;
    }
    router.replace({
      query: {
        ...route.query,
        ...query,
      },
    });
  }
}
